
<ng-container *ngIf="existenEventos; else noItems">
  <ng-container *ngFor ="let dia of diasAgenda">
      <div class="bloque-fecha" *ngIf="dia?.eventosDia.length>0"
        [ngClass]="{'fechaActual': esHoy(dia.date), 'cal-past': (dia.isPast && !esHoy(dia.date))}">
        <div class="fecha" [ngClass]="{'cal-past': (dia.isPast && !esHoy(dia.date))}">
          <div>
            <span class="fechaDia">{{ dia.date | calendarioFecha: vistaActual + 'ViewColumnaFechaDia':locale | uppercase }}</span>
            <span>{{ dia.date | calendarioFecha: vistaActual + 'ViewColumnaFecha':locale | uppercase }}</span>
          </div>
          <span class="totalNinos">M({{ dia?.totalNinosDia?.manana }}) T({{ dia?.totalNinosDia?.tarde }}) N({{ dia?.totalNinosDia?.noche }})</span>
        </div>
        <div class="eventos">
          <!-- marcador -->
          <div class="cal-current-time-marker-agenda" *ngIf="dia.isToday">
          </div>
          <ng-container *ngFor=" let  evento of dia.eventosDia;let i = index;">
            <div class="evento" (click)="eventClicked.emit(evento)"
              [ngStyle]="{background: hover==evento.meta.entidad.id  ? auxiliarService.obtenerRgba(evento.color.secondary, 0.2) : '' }"
              (mouseover)="hover=evento.meta.entidad.id" (mouseleave)="hover=-1" [ngClass]="{'cal-past': (evento.meta.isPast)}">
              <neo-icono [color]="auxiliarService.obtenerRgba(evento.color.primary, 1)"
                [iconoClase]="'icon-8 icon-ibm--circle--solid mr-5'" [ngClass]="evento.cssClass"></neo-icono>
              <div class="evento-hora">
                <ng-container *ngIf="evento.allDay; else intervaloHorario"> Todo el día</ng-container>
                <ng-template #intervaloHorario>
                  <ng-container *ngIf="evento?.end; else horaInicioSinFin">
                    {{ evento.start | calendarioFecha: vistaActual + 'ViewColumnaIntervaloHorarioInicio':locale }}
                    -
                    {{ evento.end | calendarioFecha: vistaActual + 'ViewColumnaIntervaloHorarioFin':locale }}
                  </ng-container>
                  <ng-template #horaInicioSinFin>
                    {{ evento.start | calendarioFecha: vistaActual + 'ViewColumnaIntervaloHorarioFin':locale }}
                  </ng-template>
                </ng-template>
              </div>
              <div class="evento-titulo"> <span class="text-truncate">{{evento.title}}</span></div>
            </div>
          </ng-container>
        </div>
      </div>
  </ng-container>
</ng-container>

<ng-template #noItems>
  <neo-sin-elementos [texto]="'No hay eventos para este rango de fechas'"></neo-sin-elementos>
</ng-template>
