import { ExtensionFormInput, FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";

export interface IDemoEntidadWizard {
    nombre: string;
    apellidos: string;
    email: string,
    fechaNacimiento: Date;
    edad: number;
    tipo: string;
}
export interface IDemoTipoEntidadWizard {
    id: string,
    nombre: string,
}

export const TIPO_ENTIDAD: IDemoTipoEntidadWizard[] = [
    {
        id: '1',
        nombre: 'Tipo A'
    },
    {
        id: '2',
        nombre: 'Tipo B'
    },
    {
        id: '3',
        nombre: 'Tipo C'
    },
]
export const FORM_INPUT_STEP_1: IFormInput[] = [
    {
        tag: FormTagEnum.input,
        formControlName: 'nombre',
        label: 'Nombre',
        // maxLength: 5000,
        textoAyuda: '',
        placeholder: 'Nombre',
        type: 'text',
        obligatorio: true,
        validadores:[]
    },
    {
        tag: FormTagEnum.input,
        formControlName: 'apellidos',
        label: 'Apellidos',
        // maxLength: 5000,
        textoAyuda: '',
        placeholder: 'Apellidos',
        type: 'text',
        obligatorio: true,
        validadores:[]
    },
    {
        tag: FormTagEnum.input,
        formControlName: 'email',
        label: 'Email',
        // maxLength: 5000,
        textoAyuda: '',
        placeholder: 'Email',
        type: 'email',
        obligatorio: true,
        validadores:[]
    },
];

export const FORM_INPUT_STEP_2: IFormInput[] = [
    {
        tag: FormTagEnum.date,
        label: 'Fecha nacimiento',
        type: 'fecha',
        placeholder: 'dd/mm/aaaa',
        formControlName: 'fechaNacimiento',
        textoAyuda: 'Texto opcional de ayuda',
        max: new Date(),
        obligatorio: true,
        validadores: [],
    }
];

export const FORM_INPUT_STEP_3: IFormInput[] = [
    {
        tag: FormTagEnum.cantidad,
        label: 'Edad',
        type: '',
        min: 0,
        max: 120,
        placeholder: 'Edad',
        formControlName: 'edad',
        obligatorio: true,
        validadores: [],
    },
];

export const FORM_INPUT_STEP_4: IFormInput[] = [
    {
        tag: FormTagEnum.select,
        label: 'Select',
        type: '',
        placeholder: 'Seleccione un tipo',
        formControlName: 'tipo',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: true,
        datos: TIPO_ENTIDAD,
        obligatorio: true,
        validadores: [],
    },
];

export const FORM_INPUT_EDICION_CUERPO: IFormInput[] = [


];
