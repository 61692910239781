 import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AliasFicherosEnum } from '@appNeo/neoShared/helpers/enums/AliasFIcheros.enum';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { IValorMultiple } from '@appNeo/neoShared/helpers/interfaces/IValorMultiple';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { environment } from '@environments/environment';
import { addDays, format, isFirstDayOfMonth, lastDayOfMonth } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import { addMonths } from 'date-fns/esm';
import {LoremIpsum} from 'lorem-ipsum';
import { AccionesTablaEnum } from '../tabla/tabla.service';
@Injectable({
  providedIn: 'root'
})

//TODO: DARLE UNA VUELTA AL USO DEL SERVICIO
export class AuxiliarService {

  loremIpsum = new LoremIpsum();
  constructor(
    private router: Router,
  ) { }

  //FORMATO: dd/m/yyyy o dd-m-yyyy
  regexFecha = new RegExp('^([0-2][0-9]|3[0-1])(\\/|-)([1-9]|1[0-2])\\2(\\d{4})$');
  //FORMATO: dd/mm/yyyy hh:mm o dd-mm-yyyy hh:mm
  regexFechaHora = new RegExp('^([0-2][0-9]|3[0-1])(\\/|-)([1-9]|1[0-2])\\2(\\d{4})(\\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])$');
  formatFechaApi = 'dd/MM/yyyy';

  getBoolean(value){
    switch(value){
         case true:
         case 'true':
         case 1:
         case '1':
         case 'on':
         case 'yes':
             return true;
         default:
             return false;
     }
  }

  convertirBooleanApi(value){
    switch(value){
         case true:
         case 'true':
         case 1:
         case '1':
         case 'on':
         case 'yes':
             return 1;
         default:
             return 0;
     }
  }

  extraerFiltrosPaginacion( paginator?: Paginator ) {
    let params = `numeroElementosPaginador=`;
    if ( paginator?._numeroElementosPagina !== undefined ) {
      params =  `numeroElementosPaginador=${ paginator?._numeroElementosPagina }`;
      params       += `&pagina=${ paginator?._pagina  + 1 }`;
    }
    return params;
  }

  extraerFiltros( filtros: any) {
    let params = '';
    for (const property in filtros) {
      params +=  ( (filtros[property] && filtros[property].toString().length) || filtros[property] === 0) ? `&${property}=${filtros[property]}`: '';
    }
    return params;
  }

  filtrosDefecto(filtros?: any) {
    let _filtros = filtros;
    if(!_filtros) {
      _filtros = {orden: 'nombre', tipoOrden: 'asc', numeroElementosPaginador: environment.numero_elementos_paginador, pagina: 1};
    } else {
      if(!_filtros.orden) {
        _filtros = {..._filtros, orden: 'nombre', tipoOrden: 'desc'};
      }
      if(!_filtros.numeroElementosPaginador) {
        _filtros = {..._filtros, numeroElementosPaginador: environment.numero_elementos_paginador, pagina: 1};
      }
    }
    return _filtros;
  }

  today() {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if(month < 10){
      return `${day}/0${month}/${year}`;
    }else{
      return `${day}/${month}/${year}`;
    }
  }

  convertirDateEs(fecha: string): Date {
    let dateParts: number[] = fecha.split("/").map(item => parseInt(item));
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return new Date(dateObject);
  }

  buscarObjetosArrayByValorAtributo( elementos, propiedad, valor: boolean | string): object [] {
    const elementosFiltrados = [];
    if (elementos && elementos.length) {
      elementos.forEach(element => {
        if (typeof valor === 'boolean') {
          if (element[propiedad] === valor) {
            elementosFiltrados.push(element);
          }
        } else {
          if ((element[propiedad].toUpperCase()).includes(valor.toString().toUpperCase())) {
            elementosFiltrados.push(element);
          }
        }
      });
    }
    return elementosFiltrados;
  }


 buquedaPosicionPropiedad( elementos, propiedad, valor ): number {
   return (elementos) ? elementos?.findIndex(element => element[propiedad] === valor) : -1;
}



  // TODO generalizar idioma activo
  conversionContenidoIdiomaDefecto(request, campos: string[] = ['nombre'], indiceCampo = null) {
    let codigoIdiomaDefecto = 'es';
    let idiomas = new Object();
    let idiomaDefecto = new Object();
    if (campos.length>0) {
      campos.forEach(campo => {
        let id_campo = (indiceCampo != null) ? indiceCampo : campo;
        if (request[campo]) {
          idiomaDefecto[id_campo] = request[campo];
        } else {
          idiomaDefecto[id_campo] = '';
        }
      });
    }
    idiomas[codigoIdiomaDefecto] = idiomaDefecto;
    return idiomas;
  }

  conversionFicheroExtendido(file, nombreIdFichero = 'id', idExterno?: any, datosExternos?: object, acciones?: any): IFicheroExtendido {
    let idFichero = (file[nombreIdFichero]) ? file[nombreIdFichero] : 1;
    let nombreFichero = (file.nombre) ? file.nombre : file.title;
    // TODO: REVISAR deberían de llegar siempre los atributos con la misma nomenclatura
    let ruta;
    file?.rutaAbsoluta ? ruta = file.rutaAbsoluta : ruta = file.ruta;
    let tituloSeo;
    file?.tituloSeo ? tituloSeo = file.tituloSeo : tituloSeo = file?.title;
    let textoAlternativoSeo;
    file?.textoAlternativoSeo ? textoAlternativoSeo = file.textoAlternativoSeo : textoAlternativoSeo = file?.alt;
    const accionesDefecto = [
      {
        idAccion: AccionesTablaEnum.Editar,
        tooltip: 'Editar',
        icono: true,
        iconClass: 'icon-20 icon-ibm--edit',
        disabled: false,
      },
      {
        idAccion: AccionesTablaEnum.Eliminar,
        tooltip: 'Eliminar',
        icono: true,
        iconClass: 'icon-20 icon-ibm--trash-can text-danger',
        disabled: false,
      },
    ];
    if (!acciones) acciones = accionesDefecto;
    return {
      id: idFichero,
      idExterno,
      datosExternos,
      iconDrag: { nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500' },
      nombre: nombreFichero,
      extension: '.' + this.getFicheroExtension(nombreFichero),
      peso: file.peso,
      posicion: file.posicion,
      tituloSeo: tituloSeo,
      textoAlternativoSeo: textoAlternativoSeo,
      destacado: file.destacado,
      imagen: {
        src: ruta
      },
      acciones: acciones
    };
  }


  obtenerContenidoPorIdioma(contenidoDataIdiomas: {[key: string]: any}, idIdioma?: string) {
    // TODO instancia de idioma activo para admin o publico
    let codigoIdioma = 'es';
    let camposTraduccion = {};
    Object.entries(contenidoDataIdiomas).forEach(([key, value]) => {
      if (key === codigoIdioma) {
        camposTraduccion = value;
      }
    }
    );
    return {...camposTraduccion};
  }

  reemplazarComillas ( texto: string ): string {
    let texto2 = texto.replace('', '\'');
    return texto2;
  }

  convertTZ(date, tzString='Europe/Madrid') {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-ES', {timeZone: tzString}));
  }

  ordenarArrayObjetosByAtributo(array: any[], atributo: string) {
    return array.sort((a, b) => {
      if (!isNaN(Number(a[atributo]))) {
        return Number(a[atributo]) < Number(b[atributo]) ? -1 : Number(a[atributo]) > Number(b[atributo]) ? 1 : 0;
      } else {
        return a[atributo] < b[atributo] ? -1 : a[atributo] > b[atributo] ? 1 : 0;
      }
    });
  }

  intercambiarObjetoArrays(arrayOrigen: any, arrayDestino: object[], elemento: object) {
    const posicionBorrado = arrayOrigen.indexOf(elemento);
    if ( posicionBorrado !== -1 ) {
      arrayOrigen.splice( posicionBorrado, 1 );
      arrayDestino.push(elemento);
    }

    const result = [arrayOrigen, arrayDestino];
    return result;
  }

  copiarArrayObjetosSinReferencia(arrayOriginal: object []): object[] {
    return arrayOriginal.map(row => ({ ...row }));
  }

  formatBytes(bytes, decimals  = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatFecha(fecha: Date | string, formato = 'dd MMMM yyyy', valorDefecto = '') {
    if (typeof (fecha) !== 'undefined') {
      if (typeof (fecha) === 'string') {
        return format(this.convertirDateEs(fecha), formato);
      } else {
        return (typeof (fecha) === 'object') ? format(fecha, formato) : fecha;
      }
    } else {
      return valorDefecto;
    }
  }

  // deuelve parte entera importe si no tiene decimales . poniendo + delante cantidad, obtenemos la misma funcionalidad
  formatImporte(importe) {
    if (importe - Math.floor(importe) == 0) {
      return Math.trunc(importe);
    } else {
      return importe;
    }
  }

  bytesToMb(bytes: number): number {
    return (1024 * 1024) * bytes;
  }

  tipoArchivo(extension: string): string {
    return ([ '.jpg', '.jpeg', '.gif', '.bmp', '.png', '.svg' ].indexOf(extension)>=0) ? AliasFicherosEnum.imagen : AliasFicherosEnum.documento ;
  }

  getFicheroExtension(filename) {
    // console.log('Debug get extension', filename);
    return filename?.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  eliminarEspaciosEnBlancoString(texto: string): string {
    return texto.replace(/\s+/g, '').trim();
  }

  eliminarTildes(texto: string): string {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  patternEmail() {
    return  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  }

  randomString(length: number) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  get _DialoDialogActionEnum() {
    return DialogActionEnum;
  }


  textoAleatorio(tam : number = 300): string {
    return this.loremIpsum.generateWords(tam)
  }

  filtrarParametros (entrada: any, parametrosPeticion: any) {
    let parametros : Object = new Object();
    parametrosPeticion.forEach(item => {
      if (entrada[item]) {
        parametros[item] = entrada[item];
        // Object.defineProperty(parametros, item, {
        //   value: entrada[item]
        // });
      }
    });
    return parametros;
  }

  busquedaPropiedad( elementos, propiedad, valor ) {
    return elementos?.find(element => element[propiedad] === valor);
  }


  busquedaPropiedadFechas( elementos, propiedad, valor ) {
    // console.log(propiedad, valor);
    // elementos.find(element => console.log(element[propiedad].getTime(), ' === ',  valor ));
    return  elementos.find(element => element[propiedad].getTime() === valor );
  }


  dispositivoNoMovil() {
    var a = navigator.userAgent;
    var agents = new Array("iPhone","iPad","Android","SymbianOS", "Windows Phone","iPod");
    var flag = true;
    for(var i = 0; i < agents.length; i++)
    {
      if(a.indexOf(agents[i]) > 0)
      {
        flag = false;
        break;
      }
    }
    return flag;
  }

  conversionStringHora(value): Date {
      if (value) {
        value = value.toString().trim();
        let date = new Date();
        let hora = value.split(':')[0];
        let minuto = value.split(':')[1];
        date.setHours(hora);
        date.setMinutes(minuto);
        return date;
      }
    }

  conversionHoraString(value: Date): string {
      if (value) {
        let hora = value.getHours();
        let minuto = value.getMinutes();
        return `${hora}:${minuto}`;
      }
  }


  conversionStringFecha(value): Date {
    if (value) {
      value = value.toString().trim();
      let date = new Date();
      let dia = value.split('/')[0];
      let mes = (value.split('/')[1]) ? value.split('/')[1] - 1 : 0;
      let anio = value.split('/')[2];
      date.setDate(dia);
      date.setMonth(mes);
      date.setFullYear(anio);
      return date;
    }
  }

  establecerFechaConHora(fecha = new Date(), hora = new Date()) {
    return new Date(format(fecha, 'yyyy/MM/dd') + ' ' + `${hora.getHours()}:${hora.getMinutes()}`);
  }

  obtenerUrlDominio() {
    let pathActual = this.router.url;
    return document.location.href.slice(0, -pathActual.length);
  }

  esUrlInterna(url) {
    let urlBase = this.obtenerUrlDominio();
    let inicioPath = url.indexOf(urlBase);
    return inicioPath >= 0;
  }
  extraerPathDeUrlInterna(url, valorCoincidenciaExacta = '/') {
    let path = undefined;
    if (this.esUrlInterna(url)) {
      let urlDominio = this.obtenerUrlDominio();
      path = url.slice(urlDominio.length, url.length);
      path = (path.length == 0) ? valorCoincidenciaExacta : path;
    }
    return path;
  }

// TODO: Revisar funciones utilidad migradas de Matrix para teléfono
//   /**
//  * A partir de un número de teléfono, le añade un prefijo y lo devuelve con espacios para mostrarlo en formato humano
//  *
//  * @param string telefono
//  * @param string prefijo
//  *
//  * @return string
//  */
//  telefonoTexto(telefono, prefijo = '+34') {
//    console.log('DEBUG VALOR', telefono);
//   // Lo dejamos solo números o símbolo +
//   telefono = telefono.replace('/[^0-9+]+/', '', telefono);
//   if (!this.stringEmpiezaPor(telefono, '+') && !this.stringEmpiezaPor(telefono, '00')) {
//       telefono = prefijo + telefono;
//   } else if (this.stringEmpiezaPor(telefono, '00')) {
//       telefono = '+' + telefono.substr(2, telefono.length);
//   }
//   let result = '';
//   let i = 0;
//   while (telefono.length) {
//       let $aux = telefono.substr(telefono.length - 3, 3);
//       if (i && $aux != '+') {
//           $aux = $aux + ' ';
//       }
//       result = $aux + result;
//       telefono = telefono.substr(0, telefono.length - 3);
//       i++;
//   }
//   return result;
// }

// /**
//  * Comprueba si una cadena empieza por la cadena busqueda
//  *
//  * @param string string Cadena donde se realiza la comprobación
//  * @param string busqueda Cadena que se quiere buscar al principio de la cadena string
//  * @return bool
//  */
// stringEmpiezaPor(string, busqueda) {
//   return busqueda === "" || string.indexOf(string, busqueda) === 0;
// }

// /**
//  * A partir de un número de teléfono, le añade un prefijo y lo devuelve sin espacios para montar un enlace de "tel:"
//  *
//  * @param string telefono
//  * @param string prefijo
//  *
//  * @return string
//  */
// telefonoEnlace(telefono, prefijo = '+34') {
//   // Lo dejamos solo números o símbolo +
//   telefono = telefono.replace('/[^0-9+]+/', '');
//   if (this.stringEmpiezaPor(telefono, '+') || this.stringEmpiezaPor(telefono, '00')) {
//       return telefono;
//   }
//   return prefijo + telefono;

// }


  get hoy() {
    let fechaActual = new Date();
    let dia = fechaActual.getDate();
    let mes = fechaActual.getMonth() + 1;
    let anio= fechaActual.getFullYear();
    let fecha_actual = String(mes+"/"+dia+"/"+anio);
    return new Date(fecha_actual);
  }
  get manana() {
    let fechaActual = this.hoy;
    let DIAS_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    return new Date(fechaActual.getTime()  + DIAS_EN_MILISEGUNDOS);
  }

  sumarDias(dias = 1, fecha) {
    let fechaActual = (fecha) ? fecha : this.hoy;
    let DIAS_EN_MILISEGUNDOS = dias * 24 * 60 * 60 * 1000;
    return new Date(fechaActual.getTime() + DIAS_EN_MILISEGUNDOS);
  }

  get ayer() {
    let fechaActual = this.hoy;
    let DIAS_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    return new Date(fechaActual.getTime()  - DIAS_EN_MILISEGUNDOS);
  }

  get pasadomanana() {
    let fechaActual = this.hoy;
    let DIAS_EN_MILISEGUNDOS = 0;
    DIAS_EN_MILISEGUNDOS = 2 * 24 * 60 * 60 * 1000;
    return new Date(fechaActual.getTime() + DIAS_EN_MILISEGUNDOS);
  }

  get anteayer() {
    let fechaActual = this.hoy;
    let DIAS_EN_MILISEGUNDOS = 2 * 24 * 60 * 60 * 1000;
    return new Date(fechaActual.getTime() - DIAS_EN_MILISEGUNDOS);
  }

  ultimoDiaMes(fecha: Date): Date {
    return lastDayOfMonth(fecha);
  }

  primerDiaMes(fecha?: Date): Date | false {
    let fechaReferencia = (fecha) ? fecha : this.hoy;
    let primerDiaMes = new Date(String(fechaReferencia.getMonth() + 1 + "/" + 1 + "/" + fechaReferencia.getFullYear()));
    return (isFirstDayOfMonth(primerDiaMes)) ? primerDiaMes : false;
  }

  fechaDentroDeDias(dias) {
    let fechaActual = this.hoy;
    return addDays(fechaActual, dias);
    // let DIAS_EN_MILISEGUNDOS = dias * 24 * 60 * 60 * 1000;
    // return new Date(fechaActual.getTime() - DIAS_EN_MILISEGUNDOS);
  }

  fechaDentroDeMeses(meses) {
    let fechaActual = this.hoy;
    return addMonths(fechaActual, meses);;
  }

  fechaEntre(fecha, fechaIni, fechaFin) {
    return compareAsc(new Date(fecha), new Date(fechaIni)) >= 0 && compareDesc(new Date(fecha), new Date(fechaFin)) > 0
  }

  rangoValido(fechaDesde, fechaHasta) {
    return compareAsc(new Date(fechaHasta), new Date(fechaDesde)) >= 0;
  }

  getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate)
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  obtenerRgba(hex, a) {
    let rgba = '';
    let rgb = this.hexToRgb(hex)
    if (rgb) {
      rgba = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + a + ')';
    }
    return rgba;
  }

  obtenerSeleccionNumero(desde: number, hasta: number, iconoClase?, color?, meta?): IValorMultiple[] {
    let valores: IValorMultiple[] = [];
    if (desde <= hasta) {
      for (let step = desde; step < hasta; step++) {
        valores.push({
          id: step.toString(),
          nombre: step.toString(),
          iconoClase,
          color,
          meta
        })
      }
    }
    return valores;
  }
  obtenerSeleccion(data, iconoClase?, color?, meta?): IValorMultiple[] {
    let valores: IValorMultiple[] = [];
    if (data) {
      data.forEach(item => {
        valores.push({
          id: item.id,
          nombre: item.nombre,
          iconoClase,
          color,
          meta
        })
      })
    }
    return valores;
  }
  extraerMensajeApiError(e) {
    let avisos = '';
    if (e.data) {
      if (typeof e.data === 'string') {
        avisos = e.data;
      } else {
        e.data.forEach(element => {
          if (element.id === 999) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
    }
    return e?.mensaje + ((avisos.length > 0) ? ':' : '') + avisos;
  }
}

