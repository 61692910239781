import { Component, Input} from '@angular/core';
import { FormControl } from '@angular/forms';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';

export enum TipoCampoVideoEnum {
  plataforma = 'Plataforma',
  identificador = 'Identificador'
}


@Component({
  selector: 'neo-campo-video [campo]',
  templateUrl: './campo-video.component.html',
  styleUrls: ['./campo-video.component.scss']
})
export class CampoVideoComponent  {
  @Input() campo;
  @Input() labelPrincipal: string;
  @Input() labelPlataforma = 'Seleccionar Plataforma';
  @Input() labelIdentificador = 'Identificador del vídeo en la plataforma';
  @Input() longitudMaximaIdentificador: number;
  @Input() textoAyudaPlataforma: string;
  @Input() textoAyudaIdentificador: string;
  @Input() plataformas = [];
  @Input() validadores;


  constructor() { }

  get _TipoCampoVideoEnum() {
    return TipoCampoVideoEnum;
  }

  getFormControlCampo(campo: IFormInput, tipoCampo: TipoCampoVideoEnum): FormControl {
    let formControlName = campo.formControlName + tipoCampo
    console.log(formControlName);
    return this.validadores.get(formControlName);
  }
}
