<ng-container *ngIf="barraDerechaService.menu() | async as menuBarra">
    <div id="barra-dcha-cont" *ngFor="let seccionMenu of menuBarra">
      <div id="barra-top" class="mb-30">
        <h2 class="titulo-seccion" >{{seccionMenu.seccion}}</h2>
        <neo-button class="ml-auto btn-close" data-testid="btn-cerrar"
                    [icono]="true"
                    [type]="'button'"
                    [iconClass]="'icon-20 icon-ibm--close'"
                    (click)="cerrarBarra()"
        ></neo-button>
      </div>
      <mat-nav-list>
        <neo-menu-item class="mat-accent" *ngFor="let item of seccionMenu.menu"
          [item]="item"
          (seleccionMenuItem)="seleccionMenuItem()"
        ></neo-menu-item>
      </mat-nav-list>
    </div>
</ng-container>
