import {ExtensionFormInput, FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {ClasesCampoLayoutCamposPorcentajeFormularioEnum, ClasesCampoLayoutCamposAnchoFijoFormularioEnum, IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {CHIPS_ITEMS, CHIPS_ITEMS_PRESTABLECIDOS} from '@appNeo/neoShared/pages/demo-data';
import {TipoDatePickerEnum} from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { FormatosFicherosEnum } from '@appNeo/neoShared/helpers/enums/FormatosFicheros.enum';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { IFichero } from '@appNeo/neoShared/helpers/interfaces/IFichero';

export const DEMOARCHIVOSSUBIDA: IFichero[] = [
    {
      id: '1',
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
    },
    {
      id: '2',
      nombre: 'imagen2.pdf',
      extension: '.pdf',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      }
    },
    {
      id: '3',
      nombre: 'imagen3.jpg',
      extension: '.jpg',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://tinypng.com/images/social/website.jpg'
      }
    },
    {
      id: '4',
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      }
    },
    {
      id: '5',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '6',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '7',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    }
  ];
  
export const PERFILPROPIEDADESEDICION: IPerfilPropiedad[] = [
    {
        tagCampo: TagCampoEnum.Boolean,
        tag: PerfilPropiedadTagEnum.Boolean,
        identificador: 'idBoolean',
        label: 'Boolean',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
        clasePersonalizadaLayoutCamposAnchoFijo: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x]
    }, 
    {
        tagCampo: TagCampoEnum.Boolean,
        tag: PerfilPropiedadTagEnum.Boolean,
        label: 'Boolean disabled',
        identificador: 'idBooleanDisabled',
       
    },
    {
        tagCampo: TagCampoEnum.Boolean,
        tag: PerfilPropiedadTagEnum.Boolean,
        label: 'Boolean disabled activo disabled',
        identificador: 'idBooleanDisabledActivo'        
    },
    {
        tagCampo: TagCampoEnum.Checkbox,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Valor checkbox',
        identificador: 'idCheckbox'
    },
    {
        tagCampo: TagCampoEnum.Checkbox,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Valor checkbox disabled',
        identificador: 'idCheckboxDisabled'
    },
    {
        tagCampo: TagCampoEnum.Checkbox,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Valor checkbox disabled',
        identificador: 'idCheckboxDisabledActivo',
    },
    {
        tagCampo: TagCampoEnum.Radio,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Radio',
        identificador: 'idRadio'
    },
    {
        tagCampo: TagCampoEnum.Radio,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Radio disabled',
        identificador: 'idRadioDisabled'
    },
    {
        tagCampo: TagCampoEnum.Radio,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Radio disabled activo',
        identificador: 'idRadioDisabledActivo'
    },
    {
        tagCampo: TagCampoEnum.Contrasena,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Contraseña',
        identificador: 'idContrasena'
    },
    {
        tagCampo: TagCampoEnum.Contrasena,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Contraseña disabled',
        identificador: 'idContrasenaDisabled'
    },
    {
        tagCampo: TagCampoEnum.Input,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input',
        identificador: 'idInput',
    },
    {
        tagCampo: TagCampoEnum.Input,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input disabled',
        identificador: 'idInputDisabled'
    },
    {
        tagCampo: TagCampoEnum.InputFilter,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input Filter',
        identificador: 'idInputFilter'
    },
    {
        tagCampo: TagCampoEnum.InputFilter,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input Filter Disabled',
        identificador: 'idInputFilterDisabled'
    },
    {
        tagCampo: TagCampoEnum.Estado,
        tag: PerfilPropiedadTagEnum.Estado,
        label: 'Select Estado',
        identificador: 'idSelectEstado'
    },
    {
        tagCampo: TagCampoEnum.Estado,
        tag: PerfilPropiedadTagEnum.Estado,
        label: 'Select Estado Multiple',
        identificador: 'idSelectEstadoMultiple',
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Select',
        identificador: 'idSelect',        
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectIndividualIcono',
        label: 'Select individual con icono'
    },    
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectDisabled',
        label: 'Select disabled'
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectIndividualIconoDisabled',
        label: 'Select individual con icono disabled'
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectMultiple',
        label: 'Select múltiple'
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectMultipleIcono',
        label: 'Select múltiple con icono'
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectMultipleDisabled',
        label: 'Select múltiple disabled'
    },
    {
        tagCampo: TagCampoEnum.Select,
        tag: PerfilPropiedadTagEnum.Basico,
        identificador: 'idSelectMultipleIconoDisabled',
        label: 'Select múltiple con icono disabled'
    },
    {
        tagCampo: TagCampoEnum.ChipsAutocomplete,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Chips Autocomplete',
        identificador: 'idChipsAutocomplete'        
    },
    {
        tagCampo: TagCampoEnum.ChipsAutocomplete,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Chips Autocomplete disabled',
        identificador: 'idChipsAutocompleteDisabled',        
    },
    {
        tagCampo: TagCampoEnum.Date,
        tag: PerfilPropiedadTagEnum.Fecha,
        tipoDatePicker: TipoDatePickerEnum.FechaHora,
        label: 'Fecha completa',
        identificador: 'idFecha1',        
    },
    {
        tagCampo: TagCampoEnum.Date,
        tag: PerfilPropiedadTagEnum.Fecha,
        tipoDatePicker: TipoDatePickerEnum.FechaHora,
        label: 'Fecha completa disabled',
        identificador: 'idFecha1Disabled',        
    },
    {
        tagCampo: TagCampoEnum.Date,
        tag: PerfilPropiedadTagEnum.Fecha,
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        label: 'Fecha corta',
        identificador: 'idFecha2',       
    },
    {
        tagCampo: TagCampoEnum.Date,
        tag: PerfilPropiedadTagEnum.Fecha,
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        label: 'Fecha corta disabled',
        identificador: 'idFecha2Disabled', 
    },
    {
        tagCampo: TagCampoEnum.Date,
        tag: PerfilPropiedadTagEnum.Fecha,
        tipoDatePicker: TipoDatePickerEnum.Hora,
        label: 'Hora',
        identificador: 'idHora',       
    },
    {
        tagCampo: TagCampoEnum.Importe,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Importe',
        identificador: 'idImporte',        
    },
    {
        tagCampo: TagCampoEnum.Importe,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Importe disablad',
        identificador: 'idImporteDisabled',       
    },
    {
        tagCampo: TagCampoEnum.Cantidad,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Cantidad',
        identificador: 'idCantidad'        
    },
    {
        tagCampo: TagCampoEnum.Cantidad,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Cantidad disabled',
        identificador: 'idCantidadDisabled',        
    },
    {
        tagCampo: TagCampoEnum.Telefono,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Telefono',
        identificador: 'idTelefono',        
    },
    {
        tagCampo: TagCampoEnum.Telefono,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Telefono disabled',
        identificador: 'idTelefonoDisabled'
    },
    {
        tagCampo: TagCampoEnum.Textarea,
        tag: PerfilPropiedadTagEnum.Texto,
        label: 'Textarea',
        identificador: 'idTextarea',        
    },
    {
        tagCampo: TagCampoEnum.Textarea,
        tag: PerfilPropiedadTagEnum.Texto,
        label: 'Textarea disabled',
        identificador: 'idTextareaDisabled',
    },
    {
        tagCampo: TagCampoEnum.Editor,
        tag: PerfilPropiedadTagEnum.Texto,
        label: 'Editor',
        identificador: 'idEditor',        
    },
    {
        tagCampo: TagCampoEnum.Editor,
        tag: PerfilPropiedadTagEnum.Texto,
        label: 'Editor disabled',
        identificador: 'idEditorDisabled',        
    },
    {
        tagCampo: TagCampoEnum.Clipboard,
        tag: PerfilPropiedadTagEnum.Enlace,
        label: 'Input clipboard',
        identificador: 'idInputClipboard',        
    },
    {
        tagCampo: TagCampoEnum.Fichero,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input File',
        identificador: 'idInputFichero',
        
    },
    {
        tagCampo: TagCampoEnum.Fichero,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input File Disabled',
        identificador: 'idInputFicheroDisabled'      
    },
    {
        tagCampo: TagCampoEnum.FicheroZonaArrastre,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Fichero zona arrastre',
        identificador: 'idFicheroZonaArrastre',        
    },
    {
        tagCampo: TagCampoEnum.FicheroZonaArrastre,
        tag: PerfilPropiedadTagEnum.Basico,
        label: 'Input File Disabled',
        identificador: 'idFicheroZonaArrastreDisabled'       
    },








];

export const FORMINPUTEDICION: IFormInput[] = [
     {
        tag: FormTagEnum.boolean,
        label: 'Boolean',
        type: '',
        placeholder: 'Boolean',
        formControlName: 'idBoolean',
        textoAyuda: 'Texto ayuda opcional',
        obligatorio: true,
        validadores: [],
        color: 'primary',
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
        clasePersonalizadaLayoutCamposAnchoFijo: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x]
    },
    {
        tag: FormTagEnum.boolean,
        label: 'Boolean disabled',
        type: '',
        placeholder: 'Boolean disabled',
        formControlName: 'idBooleanDisabled',
        textoAyuda: 'Texto ayuda opcional',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.boolean,
        label: 'Boolean disabled activo disabled',
        type: '',
        placeholder: 'Boolean disabled activo',
        formControlName: 'idBooleanDisabledActivo',
        textoAyuda: 'Texto ayuda opcional',
        obligatorio: false,
        disabled: true,
        valor: "1",
        validadores: [],
        color: 'primary'
    },
    {
        tag: FormTagEnum.checkbox,
        label: 'Valor checkbox',
        type: '',
        formControlName: 'idCheckbox',
        color: 'primary',
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.checkbox,
        label: 'Valor checkbox disabled',
        type: '',
        formControlName: 'idCheckboxDisabled',
        color: 'primary',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.checkbox,
        label: 'Valor checkbox disabled',
        type: '',
        formControlName: 'idCheckboxDisabledActivo',
        color: 'primary',
        obligatorio: false,
        disabled: true,
        valor: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.radio,
        label: 'Radio',
        type: '',
        formControlName: 'idRadio',
        color: 'primary',
        datos: [{id: 1, nombre: 'Valor 1'}, {id: 2, nombre: 'Valor 2'}],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.radio,
        label: 'Radio disabled',
        type: '',
        formControlName: 'idRadioDisabled',
        color: 'primary',
        datos: [{id: 1, nombre: 'Valor 1'}, {id: 2, nombre: 'Valor 2'}],
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.radio,
        label: 'Radio disabled activo',
        type: '',
        formControlName: 'idRadioDisabledActivo',
        color: 'primary',
        datos: [{id: 1, nombre: 'Valor 1'}, {id: 2, nombre: 'Valor 2'}],
        obligatorio: false,
        disabled: true,
        valor: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.contrasena,
        label: 'Contraseña',
        minLength: 8,
        type: '',
        formControlName: 'idContrasena',
        activarExtensiones: [ExtensionFormInput.criteriosValidez],
        obligatorio: false,
        validadores: [],
    },
    {
        tag: FormTagEnum.contrasena,
        label: 'Contraseña disabled',
        minLength: 8,
        type: '',
        formControlName: 'idContrasenaDisabled',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.input,
        label: 'Input',
        type: 'text',
        icon: 'face',
        placeholder: 'Valor',
        formControlName: 'idInput',
        textoAyuda: 'Texto opcional de ayuda',
        maxLength: 10,
        minLength: 5,
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.input,
        label: 'Input disabled',
        type: 'text',
        icon: 'face',
        placeholder: 'Valor disabled',
        formControlName: 'idInputDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        maxLength: 10,
        minLength: 5,
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
      tag: FormTagEnum.inputFilter,
      label: 'Input Filter',
      type: 'text',
      placeholder: 'Valor',
      formControlName: 'idInputFilter',
      textoAyuda: 'Texto opcional de ayuda',
      datos: [
        {id: '1', value: 'Calcugal'},
        {id: '2', value: 'Delikia'},
      ],
      obligatorio: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.inputFilter,
      label: 'Input Filter Disabled',
      type: 'text',
      placeholder: 'Valor',
      formControlName: 'idInputFilterDisabled',
      textoAyuda: 'Texto opcional de ayuda',
      datos: [
        {id: '1', value: 'Calcugal'},
        {id: '2', value: 'Delikia'},
      ],
      obligatorio: false,
      disabled: true,
      validadores: [],
    },
    {
        tag: FormTagEnum.estado,
        label: 'Select Estado',
        type: '',
        placeholder: 'Select Estado',
        formControlName: 'idSelectEstado',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: false,
        datos: [{ id: 1, nombre: 'Caso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Caso 2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.estado,
        label: 'Select Estado Multiple',
        type: '',
        placeholder: 'Select Estado Multiple',
        formControlName: 'idSelectEstadoMultiple',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: true,
        datos: [{ id: 1, nombre: 'Opción caso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Opción caso 2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select',
        type: '',
        placeholder: 'Select individual sin icono',
        formControlName: 'idSelect',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: false,
        datos: [{id: 1, nombre: 'Opción caso 1'}, {id: 2, nombre: 'Opción caso 2'}],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select individual con icono',
        type: '',
        placeholder: 'Select icono',
        formControlName: 'idSelectIndividualIcono',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: false,
        datos: [{ id: 1, nombre: 'Opción caso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Opción caso 2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: true,
        validadores: [],
    },    
    {
        tag: FormTagEnum.select,
        label: 'Select disabled',
        type: '',
        placeholder: 'Select individual disabled',
        formControlName: 'idSelectDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: false,
        datos: [{id: 1, nombre: 'Opción caso 1'}, {id: 2, nombre: 'Opción caso 2'}],
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select individual con icono disabled',
        type: '',
        placeholder: 'Select individual disabled',
        formControlName: 'idSelectIndividualIconoDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        multiple: false,
        datos: [{ id: 1, nombre: 'Opción caso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Opción caso 2', iconoClase: 'icon-20 -ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select múltiple',
        type: '',
        placeholder: 'Select',
        formControlName: 'idSelectMultiple',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        // opcionGeneralNombre: ' --Todos-- ',
        multiple: true,
        datos: [{id: 1, nombre: 'Caso 1'}, {id: 2, nombre: 'Caso 2'}, {id: 3, nombre: 'Caso 3'}, {id: 4, nombre: 'Caso 4'}, {id: 5, nombre: 'Caso 5'}, {id: 6, nombre: 'Caso 6'}, {id: 7, nombre: 'Caso 7'}, {id: 8, nombre: 'Caso 8'}, {id: 9, nombre: 'Caso 9'}, {id: 10, nombre: 'Caso 10'},  {id: 11, nombre: 'Caso 11'},  {id: 10, nombre: 'Caso 12'}],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select múltiple con icono',
        type: '',
        placeholder: 'Select',
        formControlName: 'idSelectMultipleIcono',
        textoAyuda: 'Texto opcional de ayuda 2',
        opcionGeneral: false,
        // opcionGeneralNombre: ' --Todos-- ',
        multiple: true,
        datos: [{ id: 1, nombre: 'Eso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Caso 2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 3, nombre: 'Caso 3', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 4, nombre: 'Caso 4', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 5, nombre: 'Caso 5', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select múltiple disabled',
        type: '',
        placeholder: 'Select disabled',
        formControlName: 'idSelectMultipleDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        opcionGeneral: false,
        // opcionGeneralNombre: ' --Todos-- ',
        multiple: true,
        datos: [{id: 1, nombre: 'Caso 1'}, {id: 2, nombre: 'Caso 2'}, {id: 3, nombre: 'Caso 3'}, {id: 4, nombre: 'Caso 4'}, {id: 5, nombre: 'Caso 5'}],
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.select,
        label: 'Select múltiple con icono disabled',
        type: '',
        placeholder: 'Select disabled',
        formControlName: 'idSelectMultipleIconoDisabled',
        textoAyuda: 'Texto opcional de ayuda 2',
        opcionGeneral: false,
        // opcionGeneralNombre: ' --Todos-- ',
        multiple: true,
        datos: [{ id: 1, nombre: 'Eso 1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 2, nombre: 'Caso 2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 3, nombre: 'Caso 3', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 4, nombre: 'Caso 4', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }, { id: 5, nombre: 'Caso 5', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385' }],
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.chipsAutocomplete,
        label: 'Chips Autocomplete',
        type: '',
        placeholder: 'Select chipsAutocomplete',
        formControlName: 'idChipsAutocomplete',
        textoAyuda: 'Texto opcional de ayuda 2',
        datos: CHIPS_ITEMS,
        datosPreestablecidos: [CHIPS_ITEMS[0]],
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.chipsAutocomplete,
        label: 'Chips Autocomplete disabled',
        type: '',
        placeholder: 'Select chipsAutocomplete',
        formControlName: 'idChipsAutocompleteDisabled',
        textoAyuda: 'Texto opcional de ayuda 2',
        datos: CHIPS_ITEMS,
        datosPreestablecidos:  CHIPS_ITEMS_PRESTABLECIDOS,
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.date,
        label: 'Fecha completa',
        type: 'fechaHora',
        tipoDatePicker: TipoDatePickerEnum.FechaHora,
        placeholder: 'dd/mm/aaaa hh:mm',
        formControlName: 'idFecha1',
        textoAyuda: 'Texto opcional de ayuda',
        min: new Date(2021, 8, 1),
        max: new Date(),
        horaMax: 12,
        minutoMax: 30,
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.date,
        label: 'Fecha completa disabled',
        type: 'fechaHora',
        tipoDatePicker: TipoDatePickerEnum.FechaHora,
        placeholder: 'dd/mm/aaaa hh:mm',
        formControlName: 'idFecha1Disabled',
        textoAyuda: 'Texto opcional de ayuda',
        min: new Date(2021, 8, 1),
        max: new Date(2021, 11, 12),
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.date,
        label: 'Fecha corta',
        type: 'fecha',
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        placeholder: 'dd/mm/aaaa',
        formControlName: 'idFecha2',
        textoAyuda: 'Texto opcional de ayuda',
        min: new Date(2021, 8, 1),
        max: new Date(2021, 11, 12),
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.date,
        label: 'Fecha corta disabled',
        type: 'fecha',
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        placeholder: 'dd/mm/aaaa',
        formControlName: 'idFecha2Disabled',
        textoAyuda: 'Texto opcional de ayuda',
        min: new Date(2021, 8, 1),
        max: new Date(2021, 11, 12),
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.date,
        label: 'Hora',
        type: 'hora',
        tipoDatePicker: TipoDatePickerEnum.Hora,
        placeholder: 'hh:mm',
        formControlName: 'idHora',
        horaMin: 12,
        minutoMin: 0,
        horaMax: 12,
        minutoMax: 50,
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: true,
        disabled: false,
        validadores: [],
    },
    {
        tag: FormTagEnum.importe,
        label: 'Importe',
        type: '',
        formControlName: 'idImporte',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: true,
        unidades: '€',
        validadores: [],
    },
    {
        tag: FormTagEnum.importe,
        label: 'Importe disablad',
        type: '',
        formControlName: 'idImporteDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        unidades: '€',
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.cantidad,
        label: 'Cantidad',
        type: '',
        min: 0,
        max: 10,
        unidades: 'uds',
        placeholder: 'Cantidad',
        formControlName: 'idCantidad',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.cantidad,
        label: 'Cantidad disabled',
        type: '',
        min: 0,
        max: 10,
        unidades: 'uds',
        placeholder: 'Cantidad disabled',
        formControlName: 'idCantidadDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.telefono,
        label: 'Telefono',
        type: '',
        placeholder: 'Telefono',
        formControlName: 'idTelefono',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.telefono,
        label: 'Telefono disabled',
        type: '',
        placeholder: 'Telefono disabled',
        formControlName: 'idTelefonoDisabled',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.textarea,
        label: 'Textarea',
        type: '',
        placeholder: 'Textarea placeholder',
        formControlName: 'idTextarea',
        maxLength: 10,
        minLength: 5,
        textoAyuda: 'Texto ayuda',
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.textarea,
        label: 'Textarea disabled',
        type: '',
        placeholder: 'Textarea placeholder disabled',
        formControlName: 'idTextareaDisabled',
        maxLength: 10,
        minLength: 5,
        textoAyuda: 'Texto ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.editor,
        label: 'Editor',
        type: '',
        placeholder: 'Textarea placeholder',
        formControlName: 'idEditor',
        textoAyuda: 'Texto ayuda',
        obligatorio: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.editor,
        label: 'Editor disabled',
        type: '',
        placeholder: 'Textarea placeholder disabled',
        formControlName: 'idEditorDisabled',
        textoAyuda: 'Texto ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
        tag: FormTagEnum.clipboard,
        label: 'Input clipboard',
        type: 'text',
        placeholder: 'Valor disabled',
        formControlName: 'idInputClipboard',
        textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
    },
    {
      tag: FormTagEnum.fichero,
      label: 'Input File',
      type: 'file',
      placeholder: 'Adjuntar documento',
      formControlName: 'idInputFichero',
      textoAyuda: 'Texto opcional de ayuda',
      obligatorio: true,
      disabled: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.fichero,
      label: 'Input File Disabled',
      type: 'file',
      placeholder: 'Adjuntar documento',
      formControlName: 'idInputFicheroDisabled',
      textoAyuda: 'Texto opcional de ayuda',
      obligatorio: false,
      disabled: true,
      validadores: [],
    },
    {
        tag: FormTagEnum.ficheroZonaArrastre,
        label: 'Fichero zona arrastre',
        type: '',
        placeholder: 'Fichero zona arrastre',
        formControlName: 'idFicheroZonaArrastre',
        // textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        disabled: false,
        validadores: [],
        formatosPermitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg],
        subidaMultiple: true,
        maximoElementosASubir: 10,
        tamanoMaximoTotal: 1000000000000000000,
        tamanoMaximoFichero: 200000000000000000,
    },
    {
        tag: FormTagEnum.ficheroZonaArrastre,
        type: '',
        label: 'Input File Disabled',
        placeholder: 'Fichero zona arrastre',
        formControlName: 'idFicheroZonaArrastreDisabled',
        // textoAyuda: 'Texto opcional de ayuda',
        obligatorio: false,
        disabled: true,
        validadores: [],
        formatosPermitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg],
        subidaMultiple: true,
        maximoElementosASubir: 10,
        tamanoMaximoTotal: 1000000000000000000,
        tamanoMaximoFichero: 200000000000000000,
    },

    
];


