<ng-container [ngSwitch]="orientation">
    <mat-stepper *ngSwitchDefault
        [labelPosition]="labelPosition"
        [linear]="linear"
        [selected]="selected"
        [selectedIndex]="selectedIndex"
        (animationDone)="animationDone.emit($event)"
        (selectionChange)="selectionChange.emit($event)">

        <ng-template matStepperIcon="number" let-index="index">            
            <neo-icono *ngIf="iconoPasoPorEstado(index, 'number') as icono; else sinIcono"
                [iconoClase]="icono"
             ></neo-icono>
             <ng-template #sinIcono>
                {{index + 1}}
            </ng-template>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
            <neo-icono *ngIf="iconoPasoPorEstado(index, 'edit') as icono; else sinIcono"
                [iconoClase]="icono"
             ></neo-icono>
             <ng-template #sinIcono>
                {{index + 1}}
            </ng-template>
        </ng-template>

        <ng-template matStepperIcon="done" let-index="index">
            <neo-icono *ngIf="iconoPasoPorEstado(index, 'done') as icono; else sinIcono"
                [iconoClase]="icono"
            ></neo-icono>
            <ng-template #sinIcono>
                {{index + 1}}
            </ng-template>
        </ng-template>       
        
    </mat-stepper>
</ng-container>