import { Component, OnInit } from '@angular/core';
import {
  compareAsc, eachDayOfInterval, isSameDay, isSameMonth,
  subMonths,
  addMonths,
  addDays,
  addWeeks,
  subDays,
  subWeeks,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from 'date-fns';
@Component({
  selector: 'neo-reserva-evento',
  templateUrl: './reserva-evento.component.html',
  styleUrls: ['./reserva-evento.component.scss']
})
export class ReservaEventoComponent implements OnInit {

  
  configuracion = {
    vistaSemana: false,
    vistaDia: false,
    vistaAgenda: false,
  }

  minFecha = subDays(new Date(), 1);
  maxFecha = addMonths(new Date(), 6);
  configuracionDiasDisponibles = [];

  fechaSeleccionada;
  constructor() { }

  ngOnInit(): void {
    this.configuracionDiasDisponibles = [
      {
        dia: new Date(2022,2,7).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,8).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,9).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,10).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,11).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },

      {
        dia: new Date(2022,2,12).getTime(),
        configuracion: 'Tarifa B',
        clase: 'bg-codigoB'
      },
      {
        dia: new Date(2022,2,13).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoC'
      },
      {
        dia: new Date(2022,2,14).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,15).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,16).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,17).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },
      {
        dia: new Date(2022,2,18).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoA'
      },

      {
        dia: new Date(2022,2,19).getTime(),
        configuracion: 'Tarifa B',
        clase: 'bg-codigoB'
      },
      {
        dia: new Date(2022,2,20).getTime(),
        configuracion: 'Tarifa C',
        clase: 'bg-codigoC'
      }
    ];
  }

  clickMomento(momento) {
    this.fechaSeleccionada = momento.fecha;
  }


}
