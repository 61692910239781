
export enum EdicionTipoEnum {
    EDICION_TIPO_ITEM_INPUT = 'input', //FormTagEnum.input
    EDICION_TIPO_ITEM_TEXTAREA = 'textarea', //FormTagEnum.textarea
    EDICION_TIPO_ITEM_TINYMCE_COMPLETO = 'editor', //FormTagEnum.editor
    EDICION_TIPO_ITEM_TINYMCE_MINIMO = 'editor', //FormTagEnum.editor
    EDICION_TIPO_ITEM_IMAGEN_UNICA = 'imagen', //FormTagEnum.fichero
    EDICION_TIPO_ITEM_ADJUNTO_UNICO = 'fichero', //FormTagEnum.fichero
    EDICION_TIPO_ITEM_IMAGEN_MULTIPLE = 'ficheroZonaArrastre', //FormTagEnum.ficheroZonaArrastre
    EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE= 'ficheroZonaArrastre', //FormTagEnum.ficheroZonaArrastre
    EDICION_TIPO_ITEM_VIDEO = 'video', // TODO [feature edicion] generar campo video
    EDICION_TIPO_ITEM_COLORPICKER = 'colorpicker' // TODO [feature edicion] generar campo video
  }



  export enum EdicionTipoItemEnum {
    EDICION_TIPO_ITEM_INPUT = '1', //FormTagEnum.input
    EDICION_TIPO_ITEM_TEXTAREA = '2', //FormTagEnum.textarea
    EDICION_TIPO_ITEM_TINYMCE_COMPLETO = '3', //FormTagEnum.editor
    EDICION_TIPO_ITEM_TINYMCE_MINIMO = '4', //FormTagEnum.editor
    EDICION_TIPO_ITEM_IMAGEN_UNICA = '6', //FormTagEnum.fichero
    EDICION_TIPO_ITEM_ADJUNTO_UNICO = '7', //FormTagEnum.fichero
    EDICION_TIPO_ITEM_IMAGEN_MULTIPLE = '8', //FormTagEnum.ficheroZonaArrastre
    EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE= '9', //FormTagEnum.ficheroZonaArrastre
    EDICION_TIPO_ITEM_VIDEO = '5', // TODO [feature edicion] generar campo video
    EDICION_TIPO_ITEM_COLORPICKER = '10' // TODO [feature edicion] generar campo video
  }


