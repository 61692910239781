import { Directionality } from '@angular/cdk/bidi';
import { CdkStep, CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output, QueryList, ViewChildren } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';

const MAT_STEPPER_PROXY_FACTORY_PROVIDER = {
  provide: MatStepper,
  deps: [
      forwardRef(() => StepperComponent),
      [new Optional(), Directionality],
      ChangeDetectorRef,
      [new Inject(DOCUMENT)]
  ],
  useFactory: MAT_STEPPER_PROXY_FACTORY
};

const CDK_STEPPER_PROXY_FACTORY_PROVIDER = { ...MAT_STEPPER_PROXY_FACTORY_PROVIDER, provide: CdkStepper };

export function MAT_STEPPER_PROXY_FACTORY(component: StepperComponent, directionality: Directionality,
                                        changeDetectorRef: ChangeDetectorRef, document: Document) {

  const elementRef = new ElementRef(document.createElement('mat-horizontal-stepper'));
  const stepper = new MatStepper(directionality, changeDetectorRef, elementRef, document);
  return new Proxy(stepper, {
      get: (target, property) => Reflect.get(component.stepper || target, property),
      set: (target, property, value) => Reflect.set(component.stepper || target, property, value)
  });
}
@Component({
  selector: 'neo-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
      MAT_STEPPER_PROXY_FACTORY_PROVIDER,
      CDK_STEPPER_PROXY_FACTORY_PROVIDER
  ],
})
export class StepperComponent  implements AfterViewInit, AfterViewChecked {

  // public properties
  @Input() labelPosition?: 'bottom' | 'end' = 'bottom';
  @Input() linear?: boolean;
  @Input() orientation?: 'horizontal' | 'vertical';
  @Input() selected?: CdkStep;
  @Input() selectedIndex?: number = 0;
  // Si existe icono para paso en el icono[Accion]Set tiene prioridad sobre el icono[Accion]
  @Input() iconoNumberSet: string[] = [];
  @Input() iconoNumber: string = '';
  @Input() iconoEdicionSet: string[] = [];
  @Input() iconoEdicion: string = '';
  @Input() iconoCompletoSet: string[] = [];
  @Input() iconoCompleto: string = 'icon-24 icon-ibm--checkmark';

  // public events
  @Output() animationDone = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<StepperSelectionEvent>();
  @Output() orientationChange = new EventEmitter<string>();

  // internal properties
  @ViewChildren(MatStepper) stepperList!: QueryList<MatStepper>;
  @ContentChildren(MatStep) steps!: QueryList<MatStep>;

  get stepper(): MatStepper {
      return this.stepperList && this.stepperList.first;
  }

  // private properties
  private lastSelectedIndex?: number;
  private needsFocus = false;
  private htmlSteps: Array<HTMLElement> = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
      this.reset();
      this.stepperList.changes.subscribe(() => this.reset());
      // Emitted from (animationDone) event
      this.selectionChange.subscribe((e: StepperSelectionEvent) => this.lastSelectedIndex = e.selectedIndex);
      this.syncHTMLSteps();
      // Initial step selection with enter animation if initial step > 1
      setTimeout(() => {
        if (this.selectedIndex > 0) { this.stepper.selectedIndex = this.selectedIndex}
      }, 400);
  }

  ngAfterViewChecked() {
      if (this.needsFocus) {
          this.needsFocus = false;
          const { _elementRef, _keyManager, selectedIndex } = this.stepper as any;
          _elementRef.nativeElement.focus();
          _keyManager.setActiveItem(selectedIndex);
      }
  }

  get isHorizontal(): boolean {
      return this.orientation === 'horizontal';
  }

  get isVertical(): boolean {
      return this.orientation === 'vertical';
  }

  next() {
     this.stepper.next();
  }

  previous() {
      this.stepper.previous();
  }

  /**
   * Enable/Disable the click on the step header.
   *
   * @param step The step number
   * @param enabled The new state
   */
  updateStepState(step: number, enabled: boolean) {
      if (this.htmlSteps.length > 0) {
          this.htmlSteps[step - 1].style.pointerEvents = enabled ? '' : 'none';
      }
  }

  /**
   * Sync from the dom the list of HTML elements for the steps.
   */
  private syncHTMLSteps() {
      this.htmlSteps = [];
      let increment = 1;
      let stepper: HTMLElement = document.querySelector('.mat-stepper-vertical');
      if (!stepper) {
          increment = 2; // 2, because Angular adds 2 elements for each horizontal step
          stepper = document.querySelector('.mat-horizontal-stepper-header-container');
      }
      for (let i = 0; stepper && i < stepper.children.length; i += increment) {
          this.htmlSteps.push(stepper.children[i] as HTMLElement);
      }
  }

  private reset() {
      // Delay is necessary (Too early in AfterViewInit: HTMLElements not loaded)
      setTimeout(() => this.syncHTMLSteps(), 100);

      const { stepper, steps, changeDetectorRef, lastSelectedIndex } = this;
      stepper.steps.reset(steps.toArray());
      stepper.steps.notifyOnChanges();
      if (lastSelectedIndex) {
          stepper.selectedIndex = lastSelectedIndex;
          // After htmlSteps have been synced
          setTimeout(() => this.orientationChange.emit(this.orientation), 101);
      }
      Promise.resolve().then(() => {
          this.needsFocus = true;
          changeDetectorRef.markForCheck();
      });
  }

  private iconoPasoPorEstado(indice, estado ){
      let arrIconos: string[] = [];
      let icono: string = '';
      let iconoPaso = '';
      switch(estado) {
        case 'number': 
            arrIconos = this.iconoNumberSet;
            icono = this.iconoNumber;
            break;
        case 'edit': 
            arrIconos = this.iconoEdicionSet;
            icono = this.iconoEdicion;
            break;
        case 'done': 
            arrIconos = this.iconoCompletoSet;
            icono = this.iconoCompleto;
            break;        
      }
    if (arrIconos.length && arrIconos.indexOf(indice)) {
        iconoPaso = arrIconos[indice];
    } else if (icono) {
        iconoPaso = icono;
    }
    return iconoPaso;
  }

}