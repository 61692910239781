<div class="subida" [ngClass]="{'fichero-subida-desactivado': desactivado}">
  <neo-notificacion #notificacion></neo-notificacion>
  <div class="info-top" *ngIf="maximoElementosASubir || formatosPermitidos">
    <neo-icono  [iconoClase]="'icon-18 icon-ion--information-circle'"></neo-icono>
    <span>
      <span *ngIf="mensajeCabecera">
        {{mensajeCabecera}}
      </span>
      <span *ngIf="formatosPermitidos">
        &nbsp;Formato {{mensajeFormatosPermitidos}}.
      </span>
    </span>
  </div>

  <div class="zona-drag zona-draggable-subir-fichero" appDnd (fileDropped)="arrastarArchivo($event)">
    <input type="file" [disabled]="desactivado" #fileDropRef [id]="identificador" [accept]="formatosPermitidos" max-size="1000" 
           [multiple]="subidaMultiple" (change)="seleccionarArchivo($event)" />
    <neo-icono  [iconoClase]="'icon-60 icon-ibm--document--add'"></neo-icono>

    <p>
      Arrastra y suelta {{((maximoElementosASubir>1) ? 'tus ' + obtenerTipoFicheroPlural().toLowerCase(): 'tu ' + obtenerTipoFichero().toLowerCase()).toString()}} aquí
      <br/>
      o
    </p>

    <neo-button  tabindex="7"  class="mr-2"
                [disabled]=desactivado
                [text]="(maximoElementosASubir < 2) ? 'Busca tu ' + (obtenerTipoFichero().toLowerCase()).toString() : 'Busca tus ' + ( obtenerTipoFicheroPlural().toLowerCase()).toString()"
                [stroked]="true"
                [color]="'primary'"
    ></neo-button>
  </div>

  <div class="btn-subir-fichero">
    <div>
      <!--TODO controlar que dispositivo permite hacer foto -->
      <neo-button *ngIf="botonHacerFotoResponsivo && auxiliarService.dispositivoNoMovil()"
                  [disabled]="desactivado"
                  [text]="'Hacer Foto '"
                  [color]="'primary'"
                  [stroked]="true"
                  [clase]="'button-block'"
                  (accion)="eventoBtnHacerFoto()"
      ></neo-button>
      <!-- trasera -->
      <!-- <input #inputHacerFoto type="file" accept="image/*" capture="environment" style="display: none;" /> -->
      <!-- delantera -->
      <input #inputHacerFoto type="file" accept="image/*" capture="camera" style="display: none;" />
    </div>
    <div class="mt-10">
      <neo-button
                  [disabled]="desactivado"
                  [iconClass]="iconoBotonSubirFicheroResponsivo"
                  [text]="(textoBotonSubirFicheroResponsivo.length) ? textoBotonSubirFicheroResponsivo :'Subir ' + ( (maximoElementosASubir>1) ? obtenerTipoFicheroPlural(): obtenerTipoFichero())?.toString()"
                  [color]="'primary'"
                  [stroked]="true"
                  [clase]="'button-block'"
                  (accion)="eventoBtnSubirFichero()"
      ></neo-button>
      <input #inputSubirFichero type="file" [accept]="formatosPermitidos" style="display: none;" [multiple]="subidaMultiple"/>
    </div>
  </div>

  <!-- render files estados OK-->
  <div class="files-list">
    <div class="single-file" *ngFor="let item of fileAdjuntos; let i = index">
      <div>
        <ng-container *ngIf="item?.fichero?.id && (item?.fichero?.ruta || item?.fichero?.imagen?.src) ; else visorImagenDefecto">
          <ng-container
            *ngIf="auxiliarService.tipoArchivo('.' + item.fichero.nombre?.split('.')[1]).toLowerCase() == 'imagen'; else visorImagenDefecto">
            <img class="file-img" *ngIf="item?.fichero?.ruta" [src]="getRuta(item?.fichero?.ruta)" >
            <img class="file-img" *ngIf="item?.fichero?.imagen?.src" [src]="item?.fichero?.imagen?.src" >
          </ng-container>
        </ng-container>

        <ng-template #visorImagenDefecto>
          <span class="file-icon">
            <neo-icono *ngIf="item?.file?.type; else imagenTipoArchivoDefecto" [iconoClase]="item?.file?.type.includes('image/') ? 'icon-18 icon-ibm--image text-primary' : 'icon-18 icon-ibm--document text-primary' "></neo-icono>
          </span>

          <!-- candidado a componente -->
          <ng-template #imagenTipoArchivoDefecto>
            <ng-container *ngIf="auxiliarService.tipoArchivo(item?.fichero?.extension) | lowercase as tipoArchivo">
              <span class="file-icon">
                <neo-icono  *ngIf="tipoArchivo == (getAliasFicherosEnum().documento | lowercase)  " [iconoClase]="'icon-18 icon-ibm--document text-primary'"></neo-icono>
                <neo-icono  *ngIf="tipoArchivo == (getAliasFicherosEnum().imagen | lowercase)  " [iconoClase]="'icon-18 icon-ibm--image text-primary'"></neo-icono>
              </span>
            </ng-container>
          </ng-template>

        </ng-template>
      </div>

      <div class="info-file">
        <div>
          <h4 class="file-name">
            <span *ngIf="item?.fichero?.id; else nombreFile">{{ item?.fichero?.tituloSeo }}</span>
            <ng-template #nombreFile>
              <span *ngIf="item?.file?.name">{{ item?.file.name }}</span>
            </ng-template>
          </h4>
          <p class="file-size">
            <span *ngIf="item?.file?.size">{{auxiliarService.formatBytes(item.file.size)}}</span>
            <span *ngIf="item?.fichero?.peso">{{auxiliarService.formatBytes(item?.fichero.peso)}}</span>
          </p>
        </div>

        <div *ngIf="!item?.edicion">
          <neo-circulo-progreso-icono-completado #progresoCarga  class="inline-flex-center"  [completado]="item?.idFichero" [identificador]="item.hash"></neo-circulo-progreso-icono-completado>
        </div>

      </div>
      
      <neo-button class="btn-close"
                  [miniFab]="true"
                  [iconClass]="'icon-ibm--close icon-16'"
                  (accion)="borrarArchivo(i, 'fileAdjuntos', sobreescrituraFichero)"
      ></neo-button>
    </div>
  </div>

  <!-- render files estados KO-->
  <div class="files-list">
    <div class="single-file archivo-con-aviso" *ngFor="let file of filesNoPermitidos; let i = index">
      <ng-container *ngTemplateOutlet="myTemplate;context:{item:file,i:i, coleccion: 'filesNoPermitidos'}"></ng-container>
    </div>
    <div class="single-file archivo-con-aviso" *ngFor="let file of filesErrorCarga; let i = index">
      <ng-container *ngTemplateOutlet="myTemplate;context:{item:file,i:i, coleccion: 'filesErrorCarga'}"></ng-container>
    </div>
  </div>

  <ng-template #myTemplate let-item="item" let-i="i" let-coleccion='coleccion'>
    <span class="file-icon">
      <neo-icono [iconoClase]="item?.type.includes('image/') ? 'icon-18 icon-ibm--image text-primary' : 'icon-18 icon-ibm--document text-primary' "></neo-icono>
    </span>
    <div class="info-file">
      <div >
        <h4 class="file-name">
          {{ item?.name }}
        </h4>
        <p class="file-size">
          {{auxiliarService.formatBytes(item?.size)}}
        </p>
      </div>
      <div>
        <ng-container *ngIf="coleccion === 'filesErrorCarga'">
          <div class="aviso-errordecarga">
            <span>Error de carga</span>
            <neo-icono [iconoClase]=" 'icon-20 icon-ion--information-circle' "></neo-icono>
          </div>
        </ng-container>
        <ng-container *ngIf="coleccion === 'filesNoPermitidos'">
          <div class="aviso-formatonovalido">
            <span>Formato no válido</span>
            <neo-icono [iconoClase]=" 'icon-20 icon-ibm--warning--alt--filled' "></neo-icono>
          </div>
        </ng-container>
      </div>
    </div>
    <neo-button class="btn-close"
                [miniFab]="true"
                [iconClass]="'icon-ibm--close icon-16'"
                (accion)="borrarArchivo(i, coleccion)"
    ></neo-button>
  </ng-template>
</div>
