<div class="blq-card">
  <neo-contenedor-cabecera [titulo]="(editando) ? 'Campos formulario edición' : 'Campos formulario perfil'" [botonera]="[
  {
    label: 'Cancelar',
    id: 'btn-cancelar-edicion',
    color: 'primary',
    basic: true,
    disabled: false,
    iconoClase: 'icon-20 icon-ibm--close',
    activo: (editando)
},
{
    label: 'Guardar',
    id: 'btn-guardar',
    color: 'primary',
    flat: true,
    disabled: false,
    iconoClase: 'icon-20 icon-ibm--save',
    activo: (editando)
},
{
    label: 'Editar',
    id: 'btn-editar',
    color: 'primary',
    flat: true,
    disabled: false,
    iconoClase: 'icon-20 icon-ibm--edit',
    activo: !(editando)
}
]" (accionBoton)="onAccionContenedorCabeceraEntidad($event)">
<neo-notificacion #notificacion></neo-notificacion>
    <neo-perfil #perfilDatosFormulario *ngIf="!editando"
                  [perfilFormulario]=true
                  [propiedades]="perfilPropiedadesDatosFormulario"
                  [aplicarFlex]="true"
                  [perfilAncho]="'perfil-ancho-reducido'"
            >
            </neo-perfil>
          <neo-formulario  *ngIf="editando"
              #formulario 
              [aplicarFlex]="true"
              [formularioAncho]="'formulario-ancho-reducido'"
              [valoresIniciales]="datos">
            </neo-formulario>
</neo-contenedor-cabecera>
</div>

<!-- {{ formulario?.formValidators?.valid}}-->
<!-- <pre>{{datos | json}}</pre>
<pre>{{formulario?.formValidators.value | json}}</pre> -->
<!-- <pre>{{perfilPropiedadesDatosFormulario | json}}</pre> -->