import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { IMigasDePan } from '@appNeo/neoShared/helpers/interfaces/IMigasDePan';
import { MigasDePanService } from '@appNeo/neoShared/services/migas-de-pan/migas-de-pan.service';
import { environment } from '@environments/environment';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'neo-migas-de-pan',
  templateUrl: './migas-de-pan.component.html',
  styleUrls: ['./migas-de-pan.component.scss']
})
export class MigasDePanComponent implements OnInit {
  @Input() maxMigasDePan = 5;
  migasDePan$: Observable<IMigasDePan[]>;
  migas: IMigasDePan[];
  tituloSeccion: string = '';

  constructor(
    private readonly migasDePanService: MigasDePanService,
    public router: Router,
    public tituloService: Title
  ) {
    this.migasDePan$ = migasDePanService.migasDePan$;   
    this.getDataRoute().subscribe( data => {
      // console.log('Migas de pan ', data);
      const tituloPagina = ( data?.titulo ) ? environment.title_page_compose + ` - ${ data.titulo }`: environment.title_page;
      this.tituloService.setTitle( tituloPagina );
      
    });   
    this.migasDePan$.subscribe( data => {
      // console.log('Migas de pan 2 ', data);
      const ultimoMigasDePan : IMigasDePan = data[data.length-1];
      this.tituloSeccion = ultimoMigasDePan?.tituloPagina;
      const tituloPagina = ( ultimoMigasDePan?.tituloPagina ) ? environment.title_page_compose + ` - ${ this.tituloSeccion }`: environment.title_page;
      this.tituloService.setTitle( tituloPagina );    
    });
  }

  ngOnInit(): void {    
  }

  getDataRoute() {
    return this.router.events.pipe(
      filter( evento => evento instanceof ActivationEnd ),
      filter( (evento: ActivationEnd ) => evento.snapshot.firstChild === null ),
      map( (evento: ActivationEnd) => evento.snapshot.data )
    );    
  }

  
}
