<neo-contenedor-cabecera
  [titulo]="'Historial'"
  [botonera]="botoneraOrdenacion"
  (accionBoton)="determinarOrdenacion()">

  <div class="body">
    <ul *ngIf="listadoHistorial.data.length > 0; else noItems" class="timeline card-container"  [perfectScrollbar]>
      <li *ngFor="let item of listadoHistorial$ | async; index as i;"  [attr.data-index]="i">
          <div [ngSwitch]="item.tipo">

            <div *ngSwitchCase="'comentario'">
              <div class="comentario">
                <div class="timeline-badge primary">
                  <!-- <ngx-avatar name="'P'"  src="" size="40" class="ngx-mi-avatar"></ngx-avatar> -->
                  <img class="avatarComentario" src="{{ item.avatar }}" [alt]="(item?.avatarAlt) ? item.avatarAlt : 'anónimo'"
                    [title]="(item?.avatarAlt) ? item.avatarAlt : 'Anónimo'">
                </div>
                <neo-historial-comentario [item]="item"></neo-historial-comentario>
              </div>
            </div>

            <neo-historial-estado *ngSwitchCase="'estado'" [item]="item"></neo-historial-estado>

          </div>
      </li>
    </ul>


    <ng-template #noItems>
      <neo-sin-elementos [texto]="'No hay comentarios'"></neo-sin-elementos>
    </ng-template>
  </div>

  <div class="chat-message">
    <neo-notificacion #notificacion></neo-notificacion>
    <form [formGroup]="form"  name="form">
      <mat-form-field appearance="outline" class="campo-editable-textarea" [class.textarea-abierto]="expandirTextarea">
        <textarea matInput
          cdkTextareaAutosize
          #textAreaColumnaEditable = "cdkTextareaAutosize"
          cdkAutosizeMinRows = "{{numeroRowsTextareaInicial}}"
          cdkAutosizeMaxRows = "{{numeroRowsTextareaInicial}}"
          formControlName="inputHistorial"
          (click)="$event.stopPropagation()"
          (focus)="focusTextAreaEdicion()"
          (focusout)="focusOutTextAreaEdicion()">
        </textarea>
      </mat-form-field>
    </form>
  </div>

  <div class="footer"  align="end" *ngIf="botoneraPieVisible">
    <!-- botonera -->
    <neo-button tabindex="7" class="mr-10"
        [basic]="true"
        [type]="'button'"
        [text]="'Cancelar'"
        (accion)="cancelar()"
    ></neo-button>
    <neo-button tabindex="7"
        [raised]="true"
        [type]="'button'"
        [text]="'Guardar'"
        [color]="'primary'"
        (accion)="guardar()"
    ></neo-button>
  </div>
</neo-contenedor-cabecera>
