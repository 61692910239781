import { IUsuario } from "@appNeo/neoCore/interfaces/IUsuario";
import { FormatosFicherosEnum } from "@appNeo/neoShared/helpers/enums/FormatosFicheros.enum";
import { FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";
import { CLIENTE_DEMO } from "./layout-tickets-data";

export interface ITicket {
    id: string;
    identificador?: string;
    idUsuario: string;
    importante?: boolean;
    asunto: string;
    descripcion: string;
    fecha: Date;
    idCliente: string;
    cliente?: ICliente;
    usuario?: IUsuario;
    idEstado: string;
    estado: ITicketEstado
}

export enum EstadosPropuestaEnum {
  Nuevo = '1',
  Abierto = '2',
  Pendiente = '3',
  EnEspera = '4',
  Resuelto = '5',    
  Perdida = '6',    
  Cerrado = '7',
}

export interface ICliente {
  cif?:           string;
  calle?:           string;
  numero?:           string;
  municipio?:           string;
  cp?:           string;
  provincia?:           string;
  puerta?: string;
  piso?: string;
  pais?: string;
  id?:                                 string;
  identificador?:                      string;
  nombre?:                             string;
  nombreComercial?:                    string;  
}
export interface ITicketEstado{
    id: string;
    nombre: string;
    iconoClase?: string;
    color?: string;
    icono?: string;
    esFinal?:boolean;

}

export const INPUTSCREACIONENTIDAD1 : IFormInput[] = [
  {
    tag: FormTagEnum.select,
    label: 'Cliente',
    type: '',
    placeholder: 'Cliente',
    formControlName: 'idCliente',
    opcionGeneral: false,
    multiple: false,
    datos: CLIENTE_DEMO,
    obligatorio: true,
    validadores: [],
},
];

export const INPUTSCREACIONENTIDAD2 : IFormInput[] = [

  {
    tag: FormTagEnum.input,
    formControlName: 'asunto',
    label: 'Asunto',
    maxLength: 100,
    textoAyuda: '',
    placeholder: 'Asunto',
    type: 'text',
    obligatorio: true,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  }, 
  {
    tag: FormTagEnum.textarea,
    formControlName: 'descripcion',
    label: 'Descripcion',
    maxLength: 5000,
    textoAyuda: '',
    placeholder: 'Descripcion',
    type: 'text',
    obligatorio: true,
    validadores:[],
  clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  },
  // {
  //   tag: FormTagEnum.ficheroZonaArrastre,
  //   label: 'Subir ficheros',
  //   type: '',
  //   placeholder: 'Subir ficheros',
  //   formControlName: 'ficheros',
  //   obligatorio: false,
  //   disabled: false,
  //   validadores: [],
  //   formatosPermitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg],
  //   subidaMultiple: true,
  //   maximoElementosASubir: 10,
  //   tamanoMaximoTotal: 1000000000000000000,
  //   tamanoMaximoFichero: 200000000000000000,
  //   clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  // }  
];