<div id="barra-top">

  <neo-logo data-testid="logo" *ngIf="!minivariante; else logoMiniVariante" class="logo"></neo-logo>
  <ng-template #logoMiniVariante>
    <neo-logo data-testid="logo-mini" class="isotipo"></neo-logo>
  </ng-template>

  <neo-button class="ml-auto btn-close" data-testid="btn-cerrar" [icono]="true" [type]="'button'"
    [iconClass]="'icon-20 icon-ibm--close'" (click)="cerrarBarra()"></neo-button>
</div>
<div id="barra-izq-cont" *ngIf="menuBarra">
  <ng-container *ngFor="let seccionMenu of menuBarra">
    <h2 class="titulo-seccion">
      <span *ngIf="!minivariante">
        {{seccionMenu.seccion}}
      </span>
    </h2>
    <mat-nav-list *ngIf="seccionMenu.menu">
      <neo-menu-item class="mat-primary" *ngFor="let item of seccionMenu.menu" [item]="item" [minivariante]=minivariante
        (seleccionMenuItem)="seleccionMenuItem()"></neo-menu-item>
    </mat-nav-list>
  </ng-container>

  <ng-container *ngFor="let seccionMenu of menuBarraEdicionSeccion">
    <h2 class="titulo-seccion">
      <span *ngIf="!minivariante">
        {{seccionMenu.seccion}}
      </span>
    </h2>
    <mat-nav-list *ngIf="seccionMenu.menu">
      <neo-menu-item class="mat-primary" *ngFor="let item of seccionMenu.menu" [item]="item" [minivariante]=minivariante
        (seleccionMenuItem)="seleccionMenuItem()"></neo-menu-item>
    </mat-nav-list>
  </ng-container>
</div>