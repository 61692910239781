
<!-- <pre>
    {{ clasesDias | json }}
</pre> -->
<neo-calendario #calendario
    [titulo]="'Calendario'"
    [(vista)]="vista" 
    [(diaVista)]="diaVista"
    [eventos]="eventos"
    [configuracionDias]="clasesDias"
    [permitirConfigurarCalendario]="true"
    [accionesMultiples]="accionesMultiples"
    [permitirFiltrado]="true"
    [inputsFormulario]="inputsFormulario"
    [clickCeldaMesDesplegarInfo]="false"
    [formularioGestionEventoDefecto]="true"
    (clickMomento)="crearEvento($event)"
    (clickEvento)="clickEvento($event)"
    (clickElementosSeleccionados)="clickElementosSeleccionados($event)"
    (clickAccionElementosSeleccionados)="clickAccionElementosSeleccionados($event)"
    (clickAplicarRangoTipo)="clickAplicarRangoTipo($event)"
    (clickFiltrar)="mockFiltrar($event)"
    (clickGestionarEvento)="gestionarEvento($event)"
    (clickAccionCabeceraClick)="navegacionCalendario($event)"
    (clickEliminarEvento)="eliminarEvento($event)"
>
<!-- [plantillaDiaEventos]="customCellTemplate"
(clickAccionCabeceraClick)="crearEvento($event)"-->
</neo-calendario>

<ng-template #dialogoEvento>
    <neo-acciones-dialog  #accionDialogoEvento
      [dialogTittle]="tituloDialogo"
      [botonera]="[
        {
            label: 'Ver detalle',
            id: 'btn-verDetalle',
            tipoAccion: 'accion',
            type: 'button',
            color: 'primary',
            disabled: grabando,
            activo: true,
            flat: true
        },
        {
            label: 'Cancelar',
            id: 'btn-cancelar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: 'primary',
            disabled: grabando,
            activo: true,
            flat: true
        },
        {
            label: 'Guardar',
            id: 'btn-confirmar',
            tipoAccion: 'accion',
            type: 'button',
            color: '',
            disabled: grabando,
            activo: true,
            flat: true
        }
    ]"
      (accionBoton)="submitAccionDialogoEvento($event)"
      >
      Formulario con data modificable propia evento, y tmb del metadata
      <pre>
          {{ eventoSeleccionado | json}}
      </pre>
  </neo-acciones-dialog>
  </ng-template>



  <!-- si extender plantilla para lciente en concreto, en ts puente codigo template y se le pasa a [plantillaDiaEventos]="customCellTemplate" -->

  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <!-- <pre>
        {{day | json}}
    </pre>  -->
     <div class="cal-cell-top">
       <span class="cal-day-badge" *ngIf="day?.badgeTotal > 0">{{ day?.badgeTotal }}</span>
       <span class="cal-day-number">{{ day?.date | calendarDate:'monthViewDayNumber':locale }}</span>
     </div>
 
 
     <!-- eventos todo el dia -->
     <!-- <pre>{{day?.events | json}}</pre> -->
     <neo-etiqueta-input class="etiqueta-input mb-5" #campoChip 
         *ngFor="let evento of day?.events | slice: 0:numeroEtiquetas; let i = index;"
         [texto]="evento.title"
         [eliminable]="false"
         (seleccionar)="onClickEvento(evento)" >
 
     </neo-etiqueta-input>
     <neo-etiqueta-input 
         *ngIf="day?.events?.length > numeroEtiquetas && (day?.events | slice:numeroEtiquetas) as restoSeleccion"
         class="etiqueta-input mb-5" 
         [ngClass]="{'etiqueta-abreviada-contador': numeroEtiquetas > 1 }"
         [texto]="(numeroEtiquetas == 1) ?  day?.events .length + ' Eventos' : ' + ' + (restoSeleccion.length).toString()"
         [eliminable]="false"  
         (seleccionar)="onClickCeldaMesDesplegarInfo(day)"      
     ></neo-etiqueta-input>
     <!-- <small style="margin: 5px">There are {{ day?.events.length }} events on this day</small> -->
 </ng-template>