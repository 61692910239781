import { Component, Input, OnInit } from '@angular/core';

export enum EstadoBooleanModoVisualEnum {
  icono = 'icono',
  si_no = 'si-no',
  ambos = 'ambos'
}

export interface IConfiguracionColumnaTablaEstadoBoolean {
  colorTrue?: string;
  colorFalse?: string;
  iconoTrue?: string;
  iconoClaseTrue?: string;
  iconoFalse?: string;
  iconoClaseFalse?: string;
  modoVisual?: EstadoBooleanModoVisualEnum;
}

@Component({
  selector: 'neo-estado-boolean-label[valor]',
  templateUrl: './estado-boolean-label.component.html',
  styleUrls: ['./estado-boolean-label.component.scss']
})
export class EstadoBooleanLabelComponent implements OnInit {

  @Input() valor: boolean;
  @Input() configuracion: IConfiguracionColumnaTablaEstadoBoolean;

  configuracionDefecto: IConfiguracionColumnaTablaEstadoBoolean = {
    colorTrue: '#00D385',
    colorFalse: 'red',
    iconoTrue: '',
    iconoClaseTrue: 'icon-20 icon-ibm--checkmark--outline',
    iconoFalse: '',
    iconoClaseFalse: 'icon-20 icon-ibm--close--outline',
    modoVisual: EstadoBooleanModoVisualEnum.icono,
  };

  _configuracion: IConfiguracionColumnaTablaEstadoBoolean = this.configuracionDefecto;
  constructor() {

  }
  ngOnInit(): void {
    this._configuracion = { ...this.configuracionDefecto, ...this.configuracion };
  }

}