  <!-- <input hidden  #inputFichero (change)="handlerSubidaFicheros(inputFichero['files'], nombreCampo)" type="file" multiple="false" formnovalidate> -->
  <div class="form-field field-fichero" *ngIf="controlCampo" [ngClass]="{'tiene-documento': controlCampo?.value?.length}">
    <mat-form-field class="input-file icono-mat-suffix" appearance="outline">
      <mat-label>{{label}}</mat-label>
      <input matInput [accept]="formatosPermitidos" (click)="f_input.click()" type="text" name="{{ nombreCampo }}"
        placeholder="{{ placeholder }}" [formControl]="controlCampo" readonly>
      <neo-circulo-progreso-icono-completado #circuloProgresoInputFile class="inline-flex-center" [identificador]="nombreCampo"></neo-circulo-progreso-icono-completado>
      <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
      <ng-container *ngIf="controlCampo.value">
        <neo-icono-form class="icono-eliminar-fichero"
                        [matsuffix]=true
                        [iconoClase]="'icon-ibm--close icon-16'"
                        (click)="eliminarFicheros(controlCampo, nombreCampo)"
        ></neo-icono-form>
      </ng-container>
      <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
        <neo-mat-error-form
          [nombreCampo]="label"
          [controlCampo]=controlCampo>
        </neo-mat-error-form>
      </mat-error>
    </mat-form-field>
    <neo-button *ngIf="!controlCampo.value;" class="btn-subir-fichero"
                [text]="'Examinar'"
                [color]="'primary'"
                [flat]="true"
                [iconClass]="'icon-20 icon-ibm--upload'"
                [disabled]="controlCampo.disabled"
                (accion)="!controlCampo.disabled && f_input.click();"
    ></neo-button>
    <input hidden [accept]="formatosPermitidos" #f_input (change)="handlerSubidaFicheros(f_input.files, nombreCampo)"
      type="file" multiple="false">

  </div>
