<mat-form-field class="example-full-width" appearance="outline" >
  <mat-label *ngIf="label"> {{ label }} </mat-label>
  <mat-select [formControl]="controlCampo" placeholder="label" #select>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="controlCampoFiltrado"
        [placeholderLabel]="label"
        [noEntriesFoundLabel]="'No hay coincidencias'"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let entidadFiltrada of entidadesFiltradas | async" [value]="entidadFiltrada.id">
      {{entidadFiltrada.nombre}}
    </mat-option>
  </mat-select>
  <mat-icon matSuffix *ngIf="icon"> {{ icon }} </mat-icon>
  <mat-error *ngIf="controlCampo.errors">
    <ng-container *ngIf="controlCampo.errors?.required">  Campo obligatorio </ng-container>
    <ng-container *ngIf="controlCampo.errors?.errorApi">  {{controlCampo.errors?.errorApi}} </ng-container>
  </mat-error>
  <mat-hint *ngIf="textoAyuda">
    {{textoAyuda}}
  </mat-hint>
</mat-form-field>
