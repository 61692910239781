import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '@appNeo/neoCore/services/auth/auth.service';
import {Router} from '@angular/router';
import { environment } from '@environments/environment';

export const RESPUESTA_API_ERROR_ENVIO_EMAIL_417 = 417;


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    public router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( environment.debug_interceptor_api ) {
      console.log('INI INTERCEPTOR HttpErrorInterceptor CABECERA');
      let formDataString = '';
      // Display formdata key/value pairs
      // (formateado de forma que es muy fácil copiar de la consola y meter directamente en postman con bulk edit)
      for (let clave in request.body){
        formDataString += '"' + clave + '":"' + request.body[clave] + '",\n';
      }
      console.log('\n');
      console.log('**************************************');
      console.log('-----------INI SERVICIO API-----------');
      console.log(request);
      console.log('**************************************');
      console.log(formDataString);
      console.log('**************************************');
      console.log('-----------FIN SERVICIO API-----------');
      console.log('**************************************');
      console.log('\n');
      console.log('FIN INTERCEPTOR HttpErrorInterceptor');
    }
    return next.handle(request)
      .pipe(
        catchError(this.checkError.bind(this))
      )
  }

  checkError(err): Observable<any> {
    // 401 desde cualquier modulo no neoAuth, controlados desde este punto
    console.log('Error interceptor ', err, err.url, err.status);
    if (err.status === 401 && !err.url.includes('neoAuth/')) {
      // TODO: Interesa en todos los casos hacer logout?
      let validarLogout401 = environment.validar401Logout;
      if (!validarLogout401 || this.router.url.includes('/admin')) {
        this.authService.logout();
        this.router.navigate(['auth/login']);
      }
    }
    let error = err?.error?.message || err?.statusText;
    if (
        err.status === 401 ||
        err.status === 403 ||
      err.status === 417 ||
        err.status === 404 ||
        err.status === 422 ||
        err.status === 500
      ) {
      error = err.error;
    }
    if (err.status === 500) {
      this.router.navigate(['error/500']);
    }

    console.log('FIN INTERCEPTOR HttpErrorInterceptor, extendemos error ', err.status, err, error);
    return throwError(error);
    // return throwError(() => new Error(error));
  }

  // check401(err): Observable<any> {
  //   // 401 desde cualquier modulo no neoAuth, controlados desde este punto
  //   if (err.status === 401 && !err.url.includes('neoAuth/')) {
  //     this.authService.logout();
  //     // window.location.href = 'https://web.sateliun.com/';
  //     location.reload();
  //   }
  //   let error = err.error.message || err.statusText;
  //   if (err.status === 422 ) {
  //     error = err.error;
  //   }
  //   if (err.status === 404 ) {
  //     error = err.error;
  //   }

  //   if (err.status === 403 ) {
  //     error = err.error;
  //   }

  //   if (err.status === 500 ) {
  //     error = err.error;
  //     // this.router.navigate(['error/500']);
  //   }
  //   console.log('FIN INTERCEPTOR HttpErrorInterceptor, extendemos error ', err.status, err);
  //   return throwError(error);
  // }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   console.log('INI INTERCEPTOR HttpErrorInterceptor');
  //   return next.handle(request);
  //   // TODO: Implementar control de errores
  //     // .pipe(
  //     //   catchError(this.checkError.bind(this))
  //     // );
  // }

  // checkError(err): Observable<any> {
  //   if (err.status === 401) {
  //     this.authService.logout();
  //     window.location.href = 'https://web.sateliun.com/';
  //   } else {
  //     this.router.navigate(['error/500']);
  //   }

  //   const error = err.error.message || err.statusText;
  //   return throwError(error);
  // }
}
