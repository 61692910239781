import { IUsuario, IUsuarioEstadoActual } from '@appNeo/neoCore/interfaces/IUsuario';
import { IArbolData } from '../../demo-componentes/demo-componentes.component';
import { IFichero, IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import {AccionesTablaEnum} from '@appNeo/neoShared/services/tabla/tabla.service';

export const USUARIOS_ESTADOS_DEMO: IUsuarioEstadoActual[] = [
  {id: '1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Activo' },
{id: '2', iconoClase: 'icon-20 icon-ibm--restart', color: '#00D400', nombre: 'Nuevo' }]

export const USUARIO_SESSION_ROL = [
  {
    "id": "2",
    "identificador": "ADMIN",
    "nombre": "Administrador"
  },
  // {
  //     "id": "17",
  //     "identificador": "MKV-T",
  //     "nombre": "Marketing y ventas - Técnico"
  // }
];

export const USUARIO_SESSION_PERMISOS = [
  {
      "identificador": "administrador",
      "descripcion": "Administrador"
  }  
];
export const USUARIOS_DEMO : IUsuario[]= [
    {
        id: '1',
        nombre: 'Martina',
        apellidos: 'Gómez Pérez',
        email: 'martina.gomez@duacode.com',
        avatar: {
            id: '1',
            nombre: 'Martina',
            extension: '.jpg',
            peso: 2300,
            imagen: {
                src: '@assets/images/avatardemo.png'
            }
        },
        usuarioEstadoActual: USUARIOS_ESTADOS_DEMO[0],
        fechaNacimiento: new Date(),
        imagen: '',
        imagenListado: '',
        imagenPerfil: '',
        nombreCompleto: '',
        rutaFoto: '',
        alergia: ''
    }
]


export const TREE_DATA: IArbolData[] = [
    {
      item: 'cuentas-front',
      id: 'cuentas-fron',
      children: [
        {
            item: 'cuentas-front-acceso **',
            id: 'cuentas-front-editar',
            seleccionado: true
        },
        {
          item: 'cuentas-front-editar',
          id: 'cuentas-front-editar',
          seleccionado: true
        },
        {
          item: 'cuentas-front-ver',
          id: 'cuentas-front-ver',
          seleccionado: true,
          children: [
            {
              item: 'cuentas-front-ver-datosBancarios',
              id: 'cuentas-front-ver-datosBancarios',
              seleccionado: true
            },
            {
              item: 'cuentas-front-ver-precios',
              id: 'cuentas-front-ver-precios',
              seleccionado: true
            },
            {
                item: 'cuentas-front-ver-infoBasica **',
                id: 'cuentas-front-ver-infoBasica',
                seleccionado: true
            }

          ]
        }

      ]
    }
  ];

  export const DEMOARCHIVOS: IFicheroExtendido[] = [
    {
      id: '1',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '2',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen2.jpg',
      extension: '.jpg',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '3',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen3.pdf',
      extension: '.pdf',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '4',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: '',
          disabled: false,
        },
      ],
    },
    {
      id: '5',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    }
  ];

  export const DEMOARCHIVOSSUBIDA: IFichero[] = [
    {
      id: '1',
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
    },
    {
      id: '2',
      nombre: 'imagen2.pdf',
      extension: '.pdf',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      }
    },
    {
      id: '3',
      nombre: 'imagen3.jpg',
      extension: '.jpg',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://tinypng.com/images/social/website.jpg'
      }
    },
    {
      id: '4',
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      }
    },
    {
      id: '5',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '6',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '7',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    }
  ];
