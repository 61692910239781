import { Injectable } from '@angular/core';
import { IBarraIzquierdaMenuItems, ISeccionMenu } from '@appNeo/neoShared/helpers/interfaces/IBarraIzquierdaMenuItems';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of} from 'rxjs';
import { BARRA_DERECHA_MENU } from './barra-derecha-menu-data';

@Injectable({
  providedIn: 'root'
})
export class BarraDerechaService {
  // Por defecto barra desplegada
  private expandido: boolean = false;
  public expandido$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.expandido);
  seccionesMenu: ISeccionMenu[] = BARRA_DERECHA_MENU;
  constructor() {}

  alternarExpandido() {
    this.expandido = !this.expandido;
    this.expandido$.next(this.expandido);
  }

  establecerExpandido(valor) {
    if ( this.expandido !== valor ) {
      this.alternarExpandido();
    }
  }

  establecerMenu(menu: ISeccionMenu[]) {
    this.seccionesMenu = menu;
  }

  menu(): Observable<ISeccionMenu[]> {
    return of(this.seccionesMenu);
  }
}
