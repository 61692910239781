  <div id="dashboard">
    <neo-notificacion #notificacion></neo-notificacion>


    <div id="dashboard-header">
        <div class="row">
            <div class="col-lg-12">
                <neo-notificacion #notificacion></neo-notificacion>
                <neo-tabla #tablaTickets
                [botoneraAccionesCabecera]="[]"
                    [tablaOnline]="tablaOnline"
                    [responsiveDinamico] = false
                    [titulo]="'Dashboard'"
                    [paginacion]="true"
                    [ordenacionColumnas]="true"
                    [btnDescargarPdfCabecera]="false"
                    [btnAccionesLote]="false"
                    [mostrarColumnasOcultasEnDialogo]=false
                    [visualizarTotales]="false"
                ></neo-tabla>
            </div>

        </div>
    </div>
</div>

<!-- ejemplo roles y permisos -->
<div class="blq-card mt-15" *hasPermission="'administrador'">
    Tengo permiso de Administrador
</div>

