import { DOCUMENT } from '@angular/common';
import { Component,  Inject} from '@angular/core';
import { BarraDerechaService } from '@appNeo/neoShared/services/barra-derecha/barra-derecha-service';


@Component({
  selector: 'neo-barra-derecha',
  templateUrl: './barra-derecha.component.html',
  styleUrls: ['./barra-derecha.component.scss'],
})
export class BarraDerechaComponent {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public barraDerechaService: BarraDerechaService
  ) {}

  cerrarBarra(): void {
    this.barraDerechaService.establecerExpandido(false);
  }

  esMobile() {
    const esMobile = this.document.body.classList.contains('menu-mobile');
    return esMobile;
  }

  seleccionMenuItem() {
    if (this.esMobile()) {
      this.cerrarBarra();
    }
  }

}
