<div>

    <div class="botonera-minifab mb-10">
      <neo-button class="mr-20"
        [color]="'primary'"
        [miniFab]="true"
        [type]="'button'"
        [iconClass]="'icon-20 icon-ibm--password'"
        (click)="abrirDialogoDemoNotificaciones()"
      ></neo-button>
    </div>

    <h4 class="page-title mb-10">Notificaciones</h4>
    <neo-notificacion #notificacionPag></neo-notificacion>
    <!-- <neo-notificacion #notificacionesError></neo-notificacion>
    <neo-notificacion #notificacionesAdvertencia></neo-notificacion>
    <neo-notificacion #notificacionesInformacion></neo-notificacion> -->
</div>

<ng-template #dialogoDemoNotificaciones>

  <neo-acciones-dialog #accionesDialogoDemoNotificacion
    [dialogTittle]="'Demo notificaciones'"
    [botonCerrarDialogoCabecera]=true>

  </neo-acciones-dialog>
</ng-template>
