import { Component, OnInit, ViewChild } from '@angular/core';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { DEMOARCHIVOSSUBIDA, FORMINPUTEDICION, PERFILPROPIEDADESEDICION } from '@appNeo/neoShared/pages/demo-formulario/demo-formulario-data';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';


@Component({
  selector: 'neo-demo-formulario',
  templateUrl: './demo-formulario.component.html',
  styleUrls: ['./demo-formulario.component.scss'],
  providers: [FormularioService]
})
export class DemoFormularioComponent implements OnInit {
  grabando: boolean = false;
  datos = {
    idInputClipboard: 'Este es un texto de pruebas',
    idTelefonoDisabled: '981782323',
    idBoolean: true,
    idCheckbox: true,
    idRadio: "1",
    idInput: "asdfasdf",
    idInputFilter: "Calcugal",
    // estado-select
    idSelectEstado: "1",
    idSelectEstadoMultiple: ['1'],
    //select-individual
    idSelect: "1",
    idSelectIndividualIcono: "1",
    idSelectDisabled: "2",
    idSelectIndividualIconoDisabled: "2",

    idSelectMultiple: ['1' ],
    idSelectMultipleIcono: ['3'],
    idSelectMultipleDisabled: ['1' ],
    idSelectMultipleIconoDisabled: ['3', '4'],
    idChipsAutocomplete: "laraPerez@dua.es,laraGarcia@dua.es",
    idFecha1: new Date('2022-07-01 00:00:00'),
    idFecha2: new Date('2022-07-01 00:00:00'),
    idHora: new Date('2022-07-01 10:15:00'),
    idImporte: "150.00",
    idCantidad: 10,
    idTelefono: "+34987454545",
    idTextarea: "fvfgd",
    idEditor: "<p>dd</p>",
    idInputFichero: "marca-agua-mosaico (3).png",
    idFicheroZonaArrastre: DEMOARCHIVOSSUBIDA,
    // idBooleanDisabled :false,
    // idBooleanDisabledActivo :true,

    // idCheckboxDisabled :false,
    // idCheckboxDisabledActivo :true,

    // idRadioDisabled :false,
    // idRadioDisabledActivo :true,


    // idInputDisabled :'lorem ipsum',
    // idInputFilterDisabled :'tag',
    idFecha1Disabled :new Date('2022-07-01 10:15:00'),
    idFecha2Disabled :new Date('2022-07-01 10:15:00'),
    // idImporteDisabled :'150.50',
    // idCantidadDisabled :'10',
    // idTextareaDisabled :'Lorem ipsum',
    // idEditorDisabled :'<p>Hola</p>',
  };
  formInputEdicion: IFormInput[] = FORMINPUTEDICION;
  @ViewChild('formulario') formulario: FormularioComponent;
  @ViewChild('notificacion') notificacion: NotificacionComponent;

  perfilPropiedadesDatosFormulario: IPerfilPropiedad[] = PERFILPROPIEDADESEDICION;


  public editando = false;

  constructor(
    private formularioService: FormularioService,
    private auxiliarService: AuxiliarService
  ) {
    this.iniciarFormulario();
  }

  ngOnInit(): void {
    this.cargarPerfil();
  }

  iniciarFormulario() {
    this.formularioService.inputs = this.formInputEdicion;
  }

  // guardar() {
  //   //Todo: Analizar gestión de ficheros inputs formulario
  //   this.formulario.camposFichero.forEach(campo => {
  //     this.formulario.ficheros.push({id: campo.nombreCampo, ficheros: campo.storeFicheros});
  //   });
  //   console.log('Ficheros formulario: ', this.formulario.ficheros);
  //   let objeto = this.formulario.validaCampos( false );
  //   if (objeto && objeto.formulario && objeto.formulario.valid) {
  //     console.log('VALORES FORMULARIO ', objeto.valores);
  //     this.datos = objeto.valores;
  //     this.editando = false;
  //     let message = 'Datos guardados correctamente';
  //     this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
  //     // Emit y llamada Servicio correspondiente. Simulamos operacion correcta

  //   }
  // }

  editar () {
    this.editando = true;
  }

  onAccionContenedorCabeceraEntidad(evento) {
    this.notificacion.eliminarNotificaciones();
    if (evento.id === 'btn-editar') {
      this.editar();
    }

    if( evento.id === 'btn-cancelar-edicion') {
      
      this.editando = false;
    }

    if (evento.id === 'btn-guardar') {
      this.grabando = true;
      this.formulario.camposFichero.forEach(campo => {
        this.formulario.ficheros.push({id: campo.nombreCampo, ficheros: campo.storeFicheros});
      });
      // this.formulario.appFicheroSubida.forEach(zonaArrastre => {
      //   console.log(zonaArrastre);
      // });
      // this.formulario.ficherosZonaArrastre = DEMOARCHIVOSSUBIDA;
      let objeto = this.formulario.validaCampos( false );
      if (objeto && objeto.formulario && objeto.formulario.valid) {
        console.log('VALORES FORMULARIO ', objeto.valores);
        this.datos = {...objeto.valores};
        this.editando = false;
        let message = 'Datos guardados correctamente';
        this.cargarPerfil();
        this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
        // Emit y llamada Servicio correspondiente. Simulamos operacion correcta
  
      } else {
        this.grabando = false;
        let message = 'Se ha producido un error. Se deben rellenar los campos obligatorios.';
        this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Operación incorrecta.', message, false, IconEnum.Error, '', true);
        return;
      }
    }
  }

  cargarPerfil() 
  {
    console.log('Datos son ', this.datos);
    this.perfilPropiedadesDatosFormulario.map( propiedad => 
    {
        propiedad.valor = this.datos[propiedad.identificador] ? this.datos[propiedad.identificador] : '';
        let campoFormulario = this.auxiliarService.busquedaPropiedad(this.formInputEdicion, 'formControlName', propiedad.identificador);
        propiedad.unidad = campoFormulario?.unidades;
    });
    this.perfilPropiedadesDatosFormulario.map( propiedad => {
      if (propiedad.tagCampo == TagCampoEnum.Select || propiedad.tagCampo == TagCampoEnum.Estado || propiedad.tagCampo == TagCampoEnum.Radio ) {
        let datosSeleccionables = [];
        let campoFormulario = this.auxiliarService.busquedaPropiedad(this.formInputEdicion, 'formControlName', propiedad.identificador);
        datosSeleccionables = campoFormulario?.datos;
        
        propiedad.opcionesSeleccionadas = datosSeleccionables.filter(dept =>propiedad.valor.includes(dept.id));
      }
    });
  }
}
