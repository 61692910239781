import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NeoSharedModule} from './neoShared/neoShared.module';
import {NeoCoreModule} from './neoCore/neoCore.module';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './neoCore/interceptors/jwt/jwt.interceptor';
import {HttpErrorInterceptor} from './neoCore/interceptors/httpError/http-error.interceptor';
import localeEs from '@angular/common/locales/es';
import { BackendInterceptor } from './neoCore/interceptors/simulateBack/backend.interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import {LoaderComponent} from '@appNeo/pages/plugins/loader/loader.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environments/environment';
import { LoaderInterceptor } from '@appNeo/neoCore/interceptors/loaderInterceptor/loader.interceptor';
import { SentryService } from './neoCore/services/sentry/sentry.service';
import { NgxCookiebotComponent, NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
// TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
// import {WINDOW_PROVIDERS} from './neoShared/services/window/window.service';
import { CookiebotConfig } from 'cookiebot.config';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NeoSharedModule,
    NeoCoreModule,
    TranslocoRootModule,
    (environment.cookie_bot_id != null) ? NgxCookiebotModule.forRoot(CookiebotConfig) : [],
    NgxStripeModule.forRoot(environment.stripePublicKey),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: ErrorHandler, useClass: SentryService }
    // TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
    // WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
