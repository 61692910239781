import { IUsuario, IUsuarioEstadoActual } from "@appNeo/neoCore/interfaces/IUsuario";
import { FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { PerfilPropiedadTagEnum } from "@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum";
import { TagCampoEnum } from "@appNeo/neoShared/helpers/enums/TagCampo.enum";
import { TipoDatePickerEnum } from "@appNeo/neoShared/helpers/enums/TipoDatePicker.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";
import { IPerfilPropiedad } from "@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad";

//entidades
export interface IDepartamento {
    id: string;
    identificador: string;
    idDepartamentoPadre: string;
    nombre: string;
    descripcion: string;
}

// TODO ENLAZAR
export interface IPermiso {
    id: string,
    identificador: string,
    idRolPadre: string,
    nombre: string,
    editable: string,
}

export const PROPIEDADESPERFILUSUARIO: IPerfilPropiedad[] = [
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'nombre',
      label: 'Nombre',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
    },
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'apellidos',
      label: 'Apellidos',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
    },
    {
      tagCampo: TagCampoEnum.Date,
      tag: PerfilPropiedadTagEnum.Fecha,
      identificador: 'fechaNacimiento',
      label: 'Fecha nacimiento',
      tipoDatePicker: TipoDatePickerEnum.Fecha,
    },
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'direccion',
      label: 'Dirección',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
    },
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'cp',
      label: 'Código postal',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
    },
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'municipio',      
      label: 'Municipio',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
    },
    {
      tagCampo: TagCampoEnum.Input,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'pais',
      label: 'País',     
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
    },
    {
      tagCampo: TagCampoEnum.Telefono,
      tag: PerfilPropiedadTagEnum.Basico,
      identificador: 'telefono',
      label: 'Telefono',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
    },
    {
      tagCampo: TagCampoEnum.Boolean,
      tag: PerfilPropiedadTagEnum.Boolean,
      identificador: 'teletrabajo',
      label: 'Teletrabajo',    
    },
    {
      tagCampo: TagCampoEnum.InputFilter,
      tag: PerfilPropiedadTagEnum.Texto,
      identificador: 'alergia',
      label: 'Alergia',    
    },
  ];

export const CAMPOSFORMULARIO: IFormInput[] = [    
    {
      tag: FormTagEnum.input,
      formControlName: 'nombre',
      label: 'Nombre',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Nombre',
      type: 'text',
      obligatorio: true,
      validadores:[],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'apellidos',
      label: 'Apellidos',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Apellidos',
      type: 'text',
      obligatorio: true,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },
  {
    tag: FormTagEnum.date,
    label: 'Fecha nacimiento',
    type: 'fecha',
    tipoDatePicker: TipoDatePickerEnum.Fecha,
    placeholder: 'dd/mm/aaaa',
    formControlName: 'fechaNacimiento',
    textoAyuda: '',
    min: new Date(1910, 8, 1),
    max: new Date(),
    obligatorio: false,
    validadores: [],
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'direccion',
      label: 'Dirección',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Dirección',
      type: 'text',
      obligatorio: false,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'cp',
      label: 'Código postal',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Código postal',
      type: 'text',
      obligatorio: true,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'municipio',
      label: 'Municipio',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Municipio',
      type: 'text',
      obligatorio: false,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'pais',
      label: 'País',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'País',
      type: 'text',
      obligatorio: false,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.telefono,
      formControlName: 'telefono',
      label: 'Telefono',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Telefono',
      type: '',
      obligatorio: false,
      validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.boolean,
      formControlName: 'teletrabajo',
      label: 'Teletrabajo',
      textoAyuda: '',
      placeholder: 'teletrabajo',
      type: '',
      obligatorio: false,
      validadores:[]
  },
  {
    tag: FormTagEnum.inputFilter,
    formControlName: 'alergia',
    label: 'Alergia',
    textoAyuda: '',
    placeholder: 'Alergia',
    type: '',
    obligatorio: false,
    datos:  [{id: '1', value: 'Cereales con gluten'}, {id: '2', value:'Crustáceos y productos a base de crustáceos'}, {id: '3', value: 'Huevos y productos derivados'} ],
    validadores:[]
},
  ];