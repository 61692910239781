// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { FormatosFicherosEnum } from "@appNeo/neoShared/helpers/enums/FormatosFicheros.enum";

export const environment = {
  production: true,
  // API
  base_url: 'https://zonafan.es/',
  api_base_url_node: 'http://localhost:8080',
  // TODO: Definir
  // api_base_url: 'http://localhost:5101/api',
  // DEVILBOX
  api_base_url: 'https://zonafan.es/backend/api',
  api_base_url_backoffice: 'https://zonafan.es/backend/api',
  base_url_pruebas: 'https://zonafan.es/',
  // api proyecto local contra api en preproduccion
  base_url_backoffice: 'https://zonafan.es/',
  close_alert_delay_ms: 3000,
  debug_interceptor_api: false,
  title_page: 'ZONA FAN',
  title_page_compose: 'Zona Fan',
  // Simulación api
  simulacion_datos_login: false,
  simulacion_datos_maquetacion: true,
  // TABLA
  numero_rows_celda_editable_inicial: 1,
  numero_rows_celda_editable_focus: 30,
  // PAGINADOR
  numero_elementos_paginador: 10,
  paginador_opciones_numero_elementos: [10, 50, 70],
  paginador_label_elementos: 'Elementos por página',
  paginador_label_anterior: 'Página anterior',
  paginador_label_siguiente: 'Página siguiente',
  paginador_label_primera: 'Primera página',
  paginador_label_ultima: 'Última página',
  // SPINNER
  spinner_delay_ms: 1000,
  delay_finalizacion_peticiones_auth_ms: 1200,
  delay_finalizacion_peticiones_admin_ms: 0,
  delay_finalizacion_peticiones_public_ms: 450,
  // MENU BARRA LATERAL IZQUIEDA
  barra_desplegada: true,
  // PUNTO DE CORTE
  MEDIA_MOBILE: 768,
  MEDIA_TABLETV: 1024,
  MEDIATOTABLETV: 1023,
  // FORMULARIOS
  formulario_busqueda_debounce: 200,
  formulario_captcha_debounce: 500,
  longuitud_truncar_textos_defecto: 30,
  numero_maximo_etiquetas_defecto: 3,
  // BOTÓN DESPLEGABLE
  boton_desplegable_debounce: 200,
  // FICHEROS
  fichero_formatos_permitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg, FormatosFicherosEnum.pdf],
  fichero_tamano_maximo: 2000000000000000000,
  fichero_numero_maximo: 20,
  ruta_images: 'assets/images/',
  ruta_padre_admin: 'admin/',
  ruta_padre_areaCliente: 'areaCliente/',
  pagina_aterrizaje_areaCliente: 'informacion-reserva',
  pagina_aterrizaje: 'dashboard',
  ruta_padre_admin_demo: 'adminDemo/',
  pagina_aterrizaje_demo: 'adminDemo/dashboard',
  pagina_aterrizaje_front: '',
  pagina_login: '/auth/login',
  adminDemo: false, // router aterrizaje a [aaaa]_demo
  // ROLES PERMISOS
  activo_store_auth: true,
  activo_cron_store_auth: false,
  time_interval_cron_store_auth: 300000,
  idioma_defecto: 1,
  codigo_idioma_defecto: 'es',
  color_defecto_colorPicker: '#00D385',
  MAPA_APIKEY_DESARROLO: 'AIzaSyCOOVBdj0Z0z098DfUO0ruqvlq3Va5z0YA',
  MAPA_APIKEY_PRODUCCION: '',
  stripePublicKey: 'pk_live_51Kw9Q1BXMIEejvgDQsLLnsGIASOaIso2owC0VAfZNng583fDUqoSwob7Pp7bleJrS9eHis7WlC8qpv2V9muakVgW00JKnGwPsj',
  pagina_login_front: '/authF/login',
  debug: true,
  formatFechaApi: 'dd/MM/yyyy',
  descripcionMetaDefecto: 'Zona fan',
  cookie_bot_id: '7c3051e7-d097-4b8c-938b-f264304e70ae',
  configuracionLogin: { registroLoginGoogle: false, opcionRegistro: false, opcionRecuperarContrasena: false },
  proximamente_reservas: true,
  errorServidorSinCodigo: 'Error respuesta servidor',
  errorServidorUnknown: 'Servicio api no configurado',
  validar401Logout: true,
  pruebasGeneracionInvitacion: { activo: false, codigoReserva: '' },
  keyIntentoAcceso: 'acceso-noLogin',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
