import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { AuthQuery } from '@appNeo/neoCore/authState/state/auth.query';
import {TablaComponent} from '@appNeo/neoShared/components/tabla/tabla/tabla.component';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import {TipoDatePickerEnum} from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import {Paginador} from '@appNeo/neoShared/models/Paginador/Paginador';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IColumnaTabla,
  TablaService,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';
import {LoremIpsum} from 'lorem-ipsum';
import {Subscription, take} from 'rxjs';

@Component({
  selector: 'neo-layout-dashboard',
  templateUrl: './layout-dashboard.component.html',
  styleUrls: ['./layout-dashboard.component.scss'],
  providers:[TablaService]
})
export class LayoutDashboardComponent implements OnInit {

  tablaSub: Subscription;
  subEntidadPrincipal: Subscription;
  /************************************************************************** */
  // configuracion necesaria para tabla genérica entidad
  /*************************************************************************** */
  @ViewChild('tablaTickets') tablaTickets: TablaComponent;

  paginador = new Paginador();
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.offline;
  tablaOnline: boolean = false;
  columnas: IColumnaTabla[] = [
    {key: 'icono', nombre: '', tipo: TipoColumnaTablaEnum.Icono, ordenacion: false},
    {key: 'nombre', nombre: 'Nombre', tipo: TipoColumnaTablaEnum.Texto, ordenacion: true},
    {key: 'fecha', nombre: 'Fecha', tipo: TipoColumnaTablaEnum.Fecha, tipoDatePicker: TipoDatePickerEnum.FechaHora, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeXs},
    {key: 'avatar', nombre: 'Avatar', tipo: TipoColumnaTablaEnum.Avatar, ordenacion: false, classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm},
  ];

  subscriptionPermisoVerFecha: Subscription;


  constructor(
    public tablaService: TablaService,
    private authQuery: AuthQuery
  ) {
    this.tablaOnline = this.tipoPaginador === TipoPaginadorEnum.online;
  }

  ngOnInit(): void {
    this.subscriptionEntidadPrincipal();
    this.authQuery.permisosCargados$.subscribe( cargados => {
      if (cargados) {
        this.establecerBotoneraTabla();
        this.establecerColumnasTabla();
        this.establecerFiltros();
      }
    })
  }

  ngAfterViewInit(): void {
  }
  ngOnDestroy() {
    if (this.subEntidadPrincipal) {
      this.subEntidadPrincipal.unsubscribe();
    }

    if (this.tablaSub) {
      this.tablaSub.unsubscribe();
    }
  }

  cargarTabla() {
    let data = [];
    const loremIpsum = new LoremIpsum();
    for (let i = 1; i < 8; i++) {
      const rutaImagen = (i % 2 === 0) ? `https://picsum.photos/40/40?random=1` : '';
      const color = (i % 2 === 0) ? '#DA1884' : '#00D385';
      data.push({
        icono: {
          nombre: 'Icono Arrastre Drag',
          class: 'mat-icon icon-20 icon-ion--information-circle',
          color
        },
        avatar: {nombre: 'Nombre Apellidos', src: rutaImagen},
        nombre: loremIpsum.generateWords(5) ,
        fecha: new Date()
      });
    }
    this.tablaService.data = new MatTableDataSource(this.mapearDataTabla(data));
    this.tablaService.total = data.length;
  }

  mapearDataTabla(data) {
    let dataMapeadas = data;
    if (data && data.length) {
      dataMapeadas = data.map( objeto => {
        // VALIDAR SI CADA REGISTRO ES EDITABLE O BORRABLE.
        let accionesColumnaTabla: IAccionRowTabla [] = [
          {
            idAccion: AccionesTablaEnum.Editar,
            tooltip: 'Editar producto',
            icono: true,
            iconClass: 'icon-20 icon-ibm--edit',
            disabled: false,
          },
          {
            idAccion: AccionesTablaEnum.Eliminar,
            tooltip: 'Eliminar producto',
            icono: true,
            iconClass: 'icon-20 icon-ibm--trash-can text-danger',
            disabled: false,
          },
        ];
        objeto['acciones'] = accionesColumnaTabla;
        return objeto;
      });
    }
    return dataMapeadas;
  }


  subscriptionEntidadPrincipal() {
    //  En caso de entidades releacionadas con una entidad principal ej: departamentosProductoSeleccionado
    // this.subEntidadPrincipal = this.productosService.producto$.subscribe( entidad =>  {
    //     if (entidad) {
    //         this.entidad = entidad;
    //         this.cargarTabla();
    //     }
    // });

    this.cargarTabla();
  }


  establecerBotoneraTabla() {
    // this.subscriptionPermisoCrear = this.authQuery.hasPermission(Permissions.OPORTUNIDADES_FRONT_CREAR).pipe(
    //   take(1)).subscribe( permiso => {
    //     if (!permiso) {
    //       this.botoneraAccionesCabecera = [];
    //     }else{
    //       this.botoneraAccionesCabecera = [this.botonCrearCabecera];
    //     }
    // });
  }
  establecerColumnasTabla() {
    // TABLA
    let columnasSinPermiso = [];
    // OPCION A
    if (environment.activo_store_auth) {
      this.authQuery.esAdministrador$.subscribe( permiso => {
        if (!permiso) {
          columnasSinPermiso.push('fecha');
        }
      });
  
      // this.authQuery.esDepartamentoMKV$.subscribe( permiso => {
      //   console.log('Pertecene al despartamento marketing ? ', permiso);
      //   if (!permiso) {
      //     columnasSinPermiso.push('nombre');
      //   }
      // })
    }
    this.tablaService.columnas = this.columnas.filter(columna => !columnasSinPermiso.includes(columna.key));    
  }
  establecerFiltros() {}

 

}
