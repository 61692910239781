import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import {AccionesDialogComponent} from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';

@Component({
  selector: 'neo-demo-notificaciones',
  templateUrl: './demo-notificaciones.component.html',
  styleUrls: ['./demo-notificaciones.component.scss']
})
export class DemoNotificacionesComponent implements OnInit, AfterViewInit {

  ttl = 'Ejemplo de mensaje de ';
  txt = 'Descripción del mensaje si es necesario. Puede incluír un link a otras páginas';

  @ViewChild('notificacionPag') notificacionPag: NotificacionComponent;
  @ViewChild('dialogoDemoNotificaciones') dialogoDemoNotificaciones: TemplateRef<any>;
  @ViewChild('accionesDialogoDemoNotificacion') accionesDialogoDemoNotificacion: AccionesDialogComponent;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // tipos principales
    this.notificacionPag.crearNotificacion(NotificacionEnum.Error, this.ttl + NotificacionEnum.Error, this.txt, true, IconEnum.Error);
    this.notificacionPag.crearNotificacion(NotificacionEnum.Exito, this.ttl + NotificacionEnum.Exito, this.txt, true, IconEnum.Exito);
    this.notificacionPag.crearNotificacion(NotificacionEnum.Advertencia, this.ttl + NotificacionEnum.Advertencia, this.txt, true, IconEnum.Advertencia);
    this.notificacionPag.crearNotificacion(NotificacionEnum.Informacion, this.ttl + NotificacionEnum.Informacion, this.txt, true, IconEnum.Informacion);

    // combinaciones:
    // icono personalizado
    this.notificacionPag.crearNotificacion(NotificacionEnum.Error, this.ttl + NotificacionEnum.Error, this.txt, true, IconEnum.Personalizado, 'icon-24 icon-ibm--letter--Aa');

    // sin icono
    this.notificacionPag.crearNotificacion(NotificacionEnum.Advertencia, this.ttl + NotificacionEnum.Advertencia, this.txt, true, IconEnum.SinIcono);

    // sin boton cierre
    this.notificacionPag.crearNotificacion(NotificacionEnum.Exito, this.ttl + NotificacionEnum.Exito, this.txt, false, IconEnum.Error);

    // cierre programado en caso de no permitir cierre manual ó si se incluye pero se programa cierre
    this.notificacionPag.crearNotificacion(NotificacionEnum.Informacion, this.ttl + NotificacionEnum.Informacion, this.txt, true, IconEnum.Error, '', true);

    // sin msg, sin titulo
    this.notificacionPag.crearNotificacion(NotificacionEnum.Error, this.ttl + NotificacionEnum.Error, '', true, IconEnum.Advertencia);
    this.notificacionPag.crearNotificacion(NotificacionEnum.Advertencia, '', 'Solo texto', true, IconEnum.Advertencia, '', true);
  }

  abrirDialogoDemoNotificaciones() {
    let dialogRef = this.dialog.open(this.dialogoDemoNotificaciones, {disableClose: true});
    dialogRef.afterOpened().subscribe(() => {
      this.accionesDialogoDemoNotificacion.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Ejemplo en dialogo', 'Sólo se debería mostrar en dialogo', true, IconEnum.Error);
    })
  }

}
