<neo-contenedor-cabecera
    [titulo]="'Categorías producto'"
    [botonera]="[
        {
            label: 'Guardar',
            id: 'btn-cancelar',
            color: 'primary',
            disabled: false,
            activo: true,
            iconoClase: 'icon-20 icon-ibm--save'
        }
    ]"
    (accionBoton)="onAccionContenedorCabecera($event)"
>
    <neo-notificacion #notificacionDragDropEtiquetas></neo-notificacion>
    <neo-drag-drop [atributoOrdenacion]="'id'" [mostrarIdItem]="true"></neo-drag-drop>
</neo-contenedor-cabecera>