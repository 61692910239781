import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
// TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
// import {WINDOW} from '@appNeo/neoShared/services/window/window.service';
import { AuthService } from '@appNeo/neoCore/services/auth/auth.service';
import {ConfigService} from '@appNeo/neoCore/services/config/config.service';
import { BarraDerechaService   } from '@appNeo/neoShared/services/barra-derecha/barra-derecha-service';
import { BarraIzquierdaService } from '@appNeo/neoShared/services/barra-izquierda/barra-izquierda.service';
import { BotonDesplegableComponent } from '@appNeo/neoShared/components/boton-desplegable/boton-desplegable.component';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { IUsuario } from '@appNeo/neoCore/interfaces/IUsuario';

const document: any = window.document;

export enum AccionesAvatarCuentaEnum {
  miCuenta = 'micuenta',
  cerrarSesion = 'cerrarSesion'
}



@Component({
  selector: 'neo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[BotonDesplegableService]
})
export class HeaderComponent implements OnInit, AfterViewInit {

  // demo
  usuarioNombre= 'Duacode';

  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow = false;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;

  subMenuDesplegableAccionesMultiplesAvatar: Subscription;

  menuCuentaAvatar:IAccionesBotonDesplegable[] = [
    { id: AccionesAvatarCuentaEnum.miCuenta, iconoClase: 'icon-24 icon-ibm--user--avatar', texto: 'Mi Cuenta' },
    {id:  AccionesAvatarCuentaEnum.cerrarSesion, iconoClase: 'icon-24 icon-ibm--logout', texto: 'Salir'},
  ];

  @ViewChild('btnAvatarDesplegableDialogo') btnAvatarDesplegableDialogo: BotonDesplegableComponent;
  @Input('accionesDuacode') accionesDuacode: boolean = false;
  usuario: IUsuario;
  constructor(
    private barraIzquierdaServicio: BarraIzquierdaService,
    @Inject(DOCUMENT) private document: Document,
    // TODO: HEREDADO DE LORAX TEMPLATE, EN UN PRINCIPIO NO APLICARÍA (DEJAR POR EL MOMENTO)
    // @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private barraDerachaServicio: BarraDerechaService,
    private configService: ConfigService,
    public authService: AuthService,
    private router: Router,
    private botonDesplegableService: BotonDesplegableService
  ) {}

  // en caso de desplazarse efecto sobre nav para que se resalte - no funcional
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      // this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (offset > 50) {
      this.isNavbarShow = true;
    } else {
      this.isNavbarShow = false;
    }
  }
  ngOnInit() {
    // util para establecer themas
    // this.config = this.configService.configData;
    this.subscripcionMenuDesplegableAccionesMultiplesAvatar();
    this.usuario = this.authService.user$.value;
    this.usuarioNombre = this.usuario.nombre;
  }
  ngAfterViewInit() {
    // set theme on startup
    // if (localStorage.getItem('theme')) {
    //   this.renderer.removeClass(this.document.body, this.config.layout.variant);
    //   this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    // } else {
    //   this.renderer.addClass(this.document.body, this.config.layout.variant);
    // }

    // if (localStorage.getItem('menuOption')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('menuOption')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'menu_' + this.config.layout.sidebar.backgroundColor
    //   );
    // }

    // if (localStorage.getItem('choose_logoheader')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('choose_logoheader')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'logo-' + this.config.layout.logo_bg_color
    //   );
    // }

    // if (localStorage.getItem('sidebar_status')) {
    //   if (localStorage.getItem('sidebar_status') === 'close') {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   } else {
    //     this.renderer.removeClass(this.document.body, 'side-closed');
    //     this.renderer.removeClass(this.document.body, 'submenu-closed');
    //   }
    // } else {
    //   if (this.config.layout.sidebar.collapsed === true) {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   }
    // }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subMenuDesplegableAccionesMultiplesAvatar) {
      this.subMenuDesplegableAccionesMultiplesAvatar.unsubscribe();
    }
  }
  public alternarPantallaCompleta():void  {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  public alternarBarraLateralIzquierda():void {
    this.barraIzquierdaServicio.alternarExpandido();
    if (this.barraIzquierdaServicio.expandido) {
      this.renderer.addClass(this.document.body, 'barra-desplegada');
      this.renderer.removeClass(this.document.body, 'barra-plegada');
    } else {
      this.renderer.removeClass(this.document.body, 'barra-desplegada');
      this.renderer.addClass(this.document.body, 'barra-plegada');
    }
  }

  public alternarBarraLateralDerecha(): void {
    this.barraDerachaServicio.alternarExpandido();
  }

  // setLanguage(text: string, lang: string, flag: string) {
    //   this.countryName = text;
    //   this.flagvalue = flag;
    //   this.langStoreValue = lang;
    //   // this.languageService.setLanguage(lang);
  // }
  // mobileMenuSidebarOpen(event: any, className: string) {
    //   const hasClass = event.target.classList.contains(className);
  //   if (hasClass) {
    //     this.renderer.removeClass(this.document.body, className);
    //   } else {
  //     this.renderer.addClass(this.document.body, className);
  //   }
  // }
  // callSidemenuCollapse() {
    //   const hasClass = this.document.body.classList.contains('side-closed');
    //   if (hasClass) {
  //     this.renderer.removeClass(this.document.body, 'side-closed');
  //     this.renderer.removeClass(this.document.body, 'submenu-closed');
  //   } else {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   }
    // }


  abrirDialogoBotonDesplegable() {
    this.btnAvatarDesplegableDialogo.abrirDialog();
  }

  desencadenarAccion(idAccion: string) {
    switch(idAccion) {
      case AccionesAvatarCuentaEnum.miCuenta :
        this.redireccionMiCuenta();
        break;
        case AccionesAvatarCuentaEnum.cerrarSesion:
        this.cerrarSesion();
        break;
        default:
        break;
      }
    }

    redireccionMiCuenta() {
      let ruta_padre = environment.adminDemo ? environment.ruta_padre_admin_demo : environment.ruta_padre_admin;
      this.router.navigate([ruta_padre + '/usuario/cuenta']);
    }

    public cerrarSesion ():void {
      this.authService.logout();
      this.router.navigate(['auth/login']);
    }

    subscripcionMenuDesplegableAccionesMultiplesAvatar() {
      if (this.subMenuDesplegableAccionesMultiplesAvatar) {
        this.subMenuDesplegableAccionesMultiplesAvatar.unsubscribe();
      }
      this.subMenuDesplegableAccionesMultiplesAvatar = this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
        this.desencadenarAccion(accionSeleccionada);
      });
    }

}
