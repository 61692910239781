import {AnchoColumnaTablaEnum, IClasesCeldas, IColumnaTabla, TipoColumnaTablaEnum} from '@appNeo/neoShared/services/tabla/tabla.service';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import {ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {IPerfilPropiedad} from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import {PerfilPropiedadTagEnum} from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';

//TODO: CAMBIAR NOMENCLATURA PARA ADAPTAR A UNIDADES en peso
export const columnasTablaDraggableFicheros: IColumnaTabla[] = [
  {
    key: 'iconDrag',
    nombre: '',
    tipo: TipoColumnaTablaEnum.Icono,
    ordenacion: false,
  },
  {
    key: 'select',
    nombre: '',
    tipo: TipoColumnaTablaEnum.Selector,
    ordenacion: false,
  },
  {
    key: 'imagen',
    nombre: 'IMAGEN',
    tipo: TipoColumnaTablaEnum.Imagen,
    ordenacion: false,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeXs,
    clasePersonalizada: [IClasesCeldas.cell200, IClasesCeldas.cellcenter]
  },
  {
    key: 'nombre',
    nombre: 'NOMBRE',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm,
  },
  // {
  //   key: 'peso',
  //   nombre: 'PESO',
  //   tipo: TipoColumnaTablaEnum.Importe,
  //   ordenacion: true,
  //   unidad: 'KB',
  //   classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd
  // },
  {
    key: 'tituloSeo',
    nombre: 'TÍTULO SEO',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg,
  },
  {
    key: 'textoAlternativoSeo',
    nombre: 'TEXTO ALTERNATIVO SEO',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg,
  },
  {
    key: 'acciones',
    nombre: 'Acciones',
    tipo: TipoColumnaTablaEnum.Acciones,
    ordenacion: false,
  }
];
export const columnasTablaGenericaFicheros: IColumnaTabla[] = [
  {
    key: 'select',
    nombre: '',
    tipo: TipoColumnaTablaEnum.Selector,
    ordenacion: false,
  },
  {
    key: 'imagen',
    nombre: 'IMAGEN',
    tipo: TipoColumnaTablaEnum.Imagen,
    ordenacion: false,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeXs,
    clasePersonalizada: [IClasesCeldas.cell200, IClasesCeldas.cellcenter]
  },
  {
    key: 'nombre',
    nombre: 'NOMBRE',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm,
  },
  // {
  //   key: 'peso',
  //   nombre: 'PESO',
  //   tipo: TipoColumnaTablaEnum.Importe,
  //   ordenacion: true,
  //   unidad: 'KB',
  //   classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd
  // },
  {
    key: 'tituloSeo',
    nombre: 'TÍTULO SEO',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg,
  },
  {
    key: 'textoAlternativoSeo',
    nombre: 'TEXTO ALTERNATIVO SEO',
    tipo: TipoColumnaTablaEnum.Texto,
    ordenacion: true,
    classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg,
  },
  {
    key: 'acciones',
    nombre: 'Acciones',
    tipo: TipoColumnaTablaEnum.Acciones,
    ordenacion: false,
  }
];


export const FORM_INPUT_EDICION: IFormInput[] = [
  {
    tag: FormTagEnum.input,
    formControlName: 'tituloSeo',
    label: 'Título(SEO)',
    maxLength: 250,
    textoAyuda: '',
    placeholder: 'Titulo SEO ejemplo',
    type: 'text',
    obligatorio: false,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  },
  {
    tag: FormTagEnum.input,
    formControlName: 'textoAlternativoSeo',
    label: 'Texto aleternativo(SEO)',
    maxLength: 250,
    textoAyuda: '',
    placeholder: 'Titulo SEO para imagen',
    type: 'text',
    obligatorio: false,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  }

];
export const FORM_INPUT_EDICION_TEXTO_ALT: IFormInput[] = [
  {
    tag: FormTagEnum.input,
    formControlName: 'textoAlternativoSeo',
    label: 'Texto aleternativo(SEO)',
    maxLength: 250,
    textoAyuda: '',
    placeholder: 'Titulo SEO para imagen',
    type: 'text',
    obligatorio: false,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  }

];

export const PROPIEDADES_MAS_INFO_FICHERO:  IPerfilPropiedad[] = [
  // {
  //   tag: PerfilPropiedadTagEnum.Basico,
  //   identificador: 'peso',
  //   label: 'Peso',
  //   ajustarTexto: true,
  //   // truncarTexto: 2,
  // },
  {
    tag: PerfilPropiedadTagEnum.Basico,
    identificador: 'tituloSeo',
    label: 'Título(SEO)',
  },
  {
    tag: PerfilPropiedadTagEnum.Basico,
    identificador: 'textoAlternativoSeo',
    label: 'Texto aleternativo(SEO)',
  }
];
