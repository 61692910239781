import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { ClassResponsiveEnum } from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import {
  AccionesTablaEnum,
  IColumnaTabla,
  ICeldaEditable,
  TablaService,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import { LoremIpsum } from 'lorem-ipsum';

@Component({
  selector: 'neo-tabla-editable-favoritos',
  templateUrl: './tabla-editable-favoritos.component.html',
  styleUrls: ['./tabla-editable-favoritos.component.scss'],
  providers: [TablaService]
})
export class TablaEditableFavoritosComponent implements OnInit {
  @ViewChild('notificacionTablaFavoritos') notificacionTablaFavoritos: NotificacionComponent;
  // tabla favoritos
  columnasTablaFavorito: IColumnaTabla[] = [
    {key: 'titulo', nombre: 'Nombre Favoritos', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, placeholder: 'Introducir Nombre', classResponsive: ClassResponsiveEnum.OcultarXs},
  ];

  constructor(
    private tablaService: TablaService,
  ) {
    this.tablaService.columnas = this.columnasTablaFavorito;
    this.mapearDataTablaEditable();
    this.subscripcionCambiosTablaEditable();
  }
  ngOnInit(): void {
  }


  //  tabla editable favoritos
  // TABLA EDITABLE
  mapearDataTablaEditable() {
    let data = [];
    const loremIpsum = new LoremIpsum();
    for (let i = 0; i < 2; i++) {
        data.push({
          id: String(Math.floor(Math.random() * 999999)),
          titulo: {
            nombre: `${loremIpsum.generateWords(5)}`,
          },
          acciones: AccionesTablaEnum.Eliminar
        });
    }
    this.tablaService.data =  new MatTableDataSource(data);
  }

  subscripcionCambiosTablaEditable() {
    this.tablaService.dataTablaEditable$.subscribe(dataModificada => {
      let _datos = dataModificada as unknown;
      let datos = _datos as Array<ICeldaEditable>;
      if (datos && datos.length) {
        this.showResult('', 'ok');
      } else {
        this.showResult('No se ha introducido favoritos', 'ko');
      }
    });
  }

  showResult(message: string, status: string) {
    if (this.notificacionTablaFavoritos) {
      if (status === 'ok') {
        this.notificacionTablaFavoritos.crearNotificacion(NotificacionEnum.Exito, `Operación correcta. Editados favoritos`, message, false, IconEnum.Exito, '', true);
      } else {
        this.notificacionTablaFavoritos.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la edición favoritos usuario', message, true, IconEnum.Error);
      }
    }

  }
}
