<div>
    <neo-notificacion #notificacion></neo-notificacion>
    <neo-tabla #tablaListado
        [identificador]="'tabla_usuarios'"
        [id]="'tabla_usuarios'"
        [tablaOnline]="tablaOnline"
        [responsiveDinamico] = false
        [titulo]="'Listado usuario'"
        [filtrado]="true"
        [keyFiltrado]="identificadorFiltro"
        [valoresInicialesFiltrado]="valoresInicialesFiltradoTabla"
        [accionesMultiplesDesplegable]="accionesMultiplesDesplegableTablaDemo"
        [paginacion]="true"
        [ordenacionColumnas]="true"
        [columnasFiltro]="columnasFiltro"
        [numeroFiltros]="inputsFiltros.length"
        [btnDescargarPdfCabecera]="true"
        [btnAccionesLote]="true"
        [mostrarColumnasOcultasEnDialogo]=false
        [visualizarTotales]="false"
        (accionCabeceraClick)="aplicarAccionCabecera($event)"
        (clickAccionRowTabla)="aplicarAccionRow($event)"
        (aplicarFiltros)="aplicarFiltrosTablaOnline($event)"
        (accionMultiple)="aplicarAccionMultipleSeleccion($event)"
        (accionDescargarPdf)="accionDescargarPdf($event)"
    ></neo-tabla>
</div>

<ng-template #dialogoFormulario>
    <neo-acciones-dialog #accionDialogoFormulario
    [dialogTittle]="'Nuevo usuario'"
    [botonera]="[
        {
            label: 'Cancelar',
            id: 'btn-cancelar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: 'primary',
            disabled: grabando,
            activo: true,
            flat: true
        },
        {
            label: 'Guardar',
            id: 'btn-confirmar',
            tipoAccion: 'accion',
            type: 'button',
            color: '',
            disabled: grabando,
            activo: true,
            flat: true
        }
    ]"
    (accionBoton)="submitAccionFormulario($event)"
    (cerrarDialogo)="accionesCerrarFormulario()"
    >
    <ng-container *ngIf="true">
        <neo-formulario #formulario
          [aplicarFlex]="aplicarFlex"
          [formularioAncho]="clasesFormularioAnchoEnum"
          [camposFormulario]="inputsCreacionEntrada"
        ></neo-formulario>
    </ng-container>
    </neo-acciones-dialog>
</ng-template>


<ng-template #dialogoConfirmarEliminar>
    <neo-acciones-dialog  #accionDialogoConfirmarEliminar
      [dialogTittle]="'Eliminar'"
      [botonera]="[
      {
        label: 'Sí, eliminar',
        id: 'btn-confirmar',
        tipoAccion: 'accion',
        type: 'button',
        color: '',
        disabled: false,
        activo: true,
        flat: true
      },
      {
        label: 'No, mantener',
        id: 'btn-cancelar',
        tipoAccion: 'cerrar',
        type: 'button',
        color: 'primary',
        disabled: false,
        activo: true,
        flat: true
      }
    ]"
      (accionBoton)="submitAccionDialogoConfirmarEliminar($event)"
      >
      ¿Seguro que quieres eliminar el usuario {{usuario.nombre}} {{usuario.apellidos}}?
  </neo-acciones-dialog>
  </ng-template>
