<div id="fichero">
  <div id="fichero-top">
    <h3 class="text-h3">{{titulo}}</h3>

    <!--Modo vista - Desktop, Tablet H y Tablet V  - Finalmente se usa siempre éste-->
    <neo-campo-boolean-palanca-model
      id="modoVista-lg"
      [iconoClase]="'modoVista toggle-icon toggle-list-table'"
      [color]="'primary'"
      [valor]="vista === 'card' ? true : false"
      (nuevoValor)="cambiarVista($event)">
    </neo-campo-boolean-palanca-model>
    <!--End Modo vista - Desktop, Tablet H y Tablet V-->

    <!--Botón búsqueda  - Tablet V-->
    <neo-button *ngIf="!visibilidadBuscador && busqueda" id="busqueda-fichero-btn" class="busqueda-fichero"
      [color]="'secondary'"
      [miniFab]="true"
      [type]="'button'"
      [clase]="'btn-outline btn-margin-left'"
      [iconClass]="'icon-20 icon-ibm--search'"
      (accion)="mostrarBuscador()"
    ></neo-button>
    <!--End Botón búsqueda - Tablet V-->

    <!--Componente búsqueda - Desktop, Tablet H y Tablet V-->
    <neo-busqueda #buscadorArchivos *ngIf="busqueda && visibilidadBuscador"  id="busqueda-fichero-input" class="busqueda-fichero"
        placeholder="Search"
        [keyFiltrado]="'archivos'"
        (changeBuscador)="filtroBusqueda.next($event);"
        (limpiarBuscador)="filtroLimpiar()"
        (pierdeFoco)="filtroPierdeFoco()"
    ></neo-busqueda>
     <!--End Componente búsqueda - Desktop y Tablet H-->


    <!--Btn subir fichero - Desktop y Tablet H-->
    <div id="btn-subir-fichero-lg" class="btn-subir-fichero">
      <neo-button class="mr-2"
                  [text]="'Subir ' + ((maximoElementosASubir>1) ? tipoFicheroPlural: tipoFichero).toString()"
                  [color]="'primary'"
                  [flat]="true"
                  [iconClass]="'icon-20 ' + iconoTipoFichero"
                  (accion)="abrirDialogoSubirArchivos()"
      ></neo-button>
    </div>
    <!--End Btn subir fichero - Desktop y Tablet H-->

    <!--Btn subir fichero - Tablet V y móvil-->
    <div id="btn-subir-fichero-xs" class="btn-subir-fichero">
      <neo-button class="mr-2"
        [color]="'primary'"
        [miniFab]="true"
        [type]="'button'"
        [iconClass]="'icon-20 icon-ibm--add'"
        (accion)="abrirDialogoSubirArchivos()"
      ></neo-button>
      <!-- <neo-boton-desplegable
          [disabled] = false
          [mostrarEnDialog]= true
          [tituloDialog]="'Cambiar foto de perfil'"
          [basicBotonPrincipal]=false
          [miniFabBotonPrincipal]=true
          [iconoClaseBotonPrincipal]="'icon-ibm--document'"
          [textoBotonPrincipal]="''"
          [botonCancelarDialog]=true
          [alineacionAccionesBotonDespegable]="'center'"
          [accionesBotonDespegable]="[
            {id: getAccionesMultiplesFicherosEnum().hacerFoto,  texto: 'Hacer foto', clase: ''},
            {id: getAccionesMultiplesFicherosEnum().elegirFichero,  texto: 'Elegir desde archivo', clase: ''},
            {id: getAccionesMultiplesFicherosEnum().eliminarFichero,  texto: 'Eliminar foto actual', clase: ''},
          ]"
      ></neo-boton-desplegable>-->
    </div>
    <!--End Btn subir fichero - Tablet V y móvil-->
  </div>

  <div id="fichero-mid">
    <div *ngIf="maximoElementosASubir && !numeroItemSeleccionados() && !valorBusqueda" class="num-archivos">
      <neo-icono [iconoClase]="'icon-20  ' + iconoTipoFichero + ' mr-5'"></neo-icono>
      {{arrayArchivos.length}}/{{maximoElementosASubir}}
      <span class="ml-5" *ngIf="arrayArchivos.length>=2">{{ tipoFicheroPlural | lowercase}}</span>
      <span class="ml-5" *ngIf="arrayArchivos.length<2">{{ tipoFichero | lowercase}}</span>
    </div>

    <div *ngIf="numeroItemSeleccionados()" class="num-archivos">
      <neo-icono [iconoClase]="'icon-20 icon-ibm--image mr-5'"></neo-icono>
      <span class="ml-5" *ngIf="numeroItemSeleccionados()>=2"> {{numeroItemSeleccionados()}} {{ tipoFicheroPlural | lowercase}} seleccionados</span>
      <span class="ml-5" *ngIf="numeroItemSeleccionados()<2"> {{numeroItemSeleccionados()}} {{ tipoFichero | lowercase}} seleccionado</span>
      <neo-button
        class="btn-limpiar-filtro"
        [icono]="true"
        [iconClass]="'icon-ibm--close icon-16'"
        (accion)="vaciarSeleccion(); $event.stopPropagation();"
      ></neo-button>
    </div>

    <div *ngIf="!numeroItemSeleccionados() && valorBusqueda" class="num-archivos">
      <neo-icono [iconoClase]="'icon-20 icon-ibm--image mr-5'"></neo-icono>
      <span class="ml-5" *ngIf="numeroFicherosBusqueda() >= 2 || numeroFicherosBusqueda() === 0; else numeroFicherosBusquedaSingular"> {{numeroFicherosBusqueda()}} {{ tipoFicheroPlural | lowercase}} encontrados</span>
      <ng-template #numeroFicherosBusquedaSingular>
        <span class="ml-5" *ngIf="numeroFicherosBusqueda() < 2"> {{numeroFicherosBusqueda()}} {{ tipoFichero | lowercase}} encontrado</span>
      </ng-template>
      <neo-button
        class="btn-limpiar-filtro"
        [icono]="true"
        [iconClass]="'icon-ibm--close icon-16'"
        (accion)="buscadorArchivos ? buscadorArchivos.filtroLimpiar() : filtroLimpiar();"
      ></neo-button>
    </div>

    <!--Modo vista - Móvil - Finalmente éste no se usa-->
    <!-- <neo-campo-boolean-palanca-model
      id="modoVista-xs"
      [iconoClase]="'modoVista toggle-icon toggle-list-table'"
      [color]="'primary'"
      [valor]="vista === 'card' ? true : false"
      (nuevoValor)="cambiarVista($event)">
    </neo-campo-boolean-palanca-model> -->

    <!--End Modo vista - Móvil-->

    <!--Acciones múltiples inline - Desktop-->
    <div id="acciones-multiples-inline">
      <div>
        <neo-button
                    [text]="'Invertir selección'"
                    [basic]="true"
                    [color]="'primary'"
                    [clase]="'btn-sm'"
                    [disabled]="!numeroItemSeleccionados()"
                    (accion)="numeroItemSeleccionados() ? invertirSeleccion(): null"
        ></neo-button>
        <neo-button
                    [text]="determinarAccionMultipleSeleccionarTodos()"
                    [basic]="true"
                    [color]="'primary'"
                    [iconClass]="'icon-20 icon-mdi--select-all'"
                    [clase]="'btn-sm'"
                    (accion)="convertirSeleccion()"
        ></neo-button>
        <neo-button
                    [text]="determinarAccionMultipleEliminacion()"
                    [basic]="true"
                    [color]="'warn'"
                    [iconClass]="'icon-20 icon-ibm--trash-can text-danger'"
                    [clase]="'btn-sm'"
                    (accion)="abrirDialogoConfirmarEliminarArchivos()"
        ></neo-button>
      </div>
    </div>
    <!--End Acciones múltiples inline - Desktop-->

    <!--Acciones múltiples select - Tablet V-->
    <neo-boton-desplegable #btnDepegableAccioneMultiples id="acciones-multiples-desplegables-lg"
        [referenciaElemento]="'fichero'"
        [mostrarEnDialog]=false
        [disabled]="false"
        [iconoClaseBotonPrincipal]="'icon-20 icon-mdi--arrow_drop_down'"
        [textoBotonPrincipal]="'Acciones múltiples'"
        [strokedBotonPrincipal]=true
        [claseBotonPrincipal]="'btn-sm'"
    ></neo-boton-desplegable>
    <!--End Acciones múltiples select - Tablet V-->

    <!--Acciones múltiples botón - Móvil-->
    <neo-boton-desplegable #btnDepegableAccioneMultiples id="acciones-multiples-desplegables-xs"
        [referenciaElemento]="'fichero'"
        [mostrarEnDialog]=true
        [disabled]="false"
        [iconoBotonPrincipal]="'icon-20 ibm--overflow-menu--vertical'"
        [textoBotonPrincipal]="''"
        [iconoTipoBotonPrincipal]="true"
    ></neo-boton-desplegable>
    <!--End Acciones múltiples botón - Móvil-->
  </div>

  <div id="fichero-bot">
    <ng-container *ngIf="vista == 'lista'">
      <neo-tabla-draggable #tablaDraggable *ngIf="!deshabilitarArrastre" [tablaOnline]="false" [filtrado]="false"
        [ordenacionColumnas]="true" [mostrarAccionesCabecera]="false" [mostrarTituloCabecera]="false"
        [botoneraAccionesCabecera]="false"
        [accionesMultiplesDesplegable]="false"
        (clickAccionRowTabla)="aplicarAccionRow($event)"
        (accionVerInformacionRow)="abrirDialogoMasInfo($event)"
      ></neo-tabla-draggable>
      <neo-tabla *ngIf="deshabilitarArrastre" [tablaOnline]="false" [filtrado]="false" [ordenacionColumnas]="true"
        [mostrarAccionesCabecera]="false" [mostrarTituloCabecera]="false" [botoneraAccionesCabecera]="false"
        [accionesMultiplesDesplegable]="false" (clickAccionRowTabla)="aplicarAccionRow($event)"
        (accionVerInformacionRow)="abrirDialogoMasInfo($event)"></neo-tabla>
      </ng-container>

<!--    TODO: Ajustar maquetación-->
    <div #contenedor class="listado-card" cdkDropListGroup *ngIf="vista == 'card'">
      <ng-container *ngFor="let item of arrayArchivos;let i=index">
        <div class="card" cdkDropList *ngIf="item.visibilidadFiltrado" cdkDropListOrientation="horizontal"
             [cdkDropListData]="{item:item,index:i}" [cdkDropListDisabled]="deshabilitarArrastre" (cdkDropListDropped)="dropCard($event)">
          <div class="inner" cdkDrag>
            <div class="placeholder" *cdkDragPlaceholder></div>
            <neo-fichero-card #appFicheroCard lass="card"
              [cdkDragDisabled]="!ordenable"
              [item]="item"
              [itemSeleccionable]="seleccionable"
              [itemMovible]="!deshabilitarArrastre"
              (itemSeleccionado)="seleccionarItem()"
              [externalizarProcesoEliminar]="configuracion.externalizarProcesoEliminar"
              (peticionEliminar)="borrarItem($event)"
              [externalizarProcesoEditar]="configuracion.externalizarProcesoEditar"
              (peticionEditar)="abrirDialogoEdicionArchivo($event)"
              [externalizarVerInformacion]="configuracion.externalizarVerInformacion"
              (verInformacionItem)="abrirDialogoMasInfo($event)"
              (eliminar)="eventoBorrarFichero($event)"
              >
            </neo-fichero-card>
          </div>
        </div>
      </ng-container>
    </div>

  <!--Diálogo Subir archivos-->
  <ng-template #dialogSubirArchivos>
    <neo-acciones-dialog #accionDialogSubirArchivos
                         [dialogTittle]="'Subir ' + ((maximoElementosASubir>1) ? tipoFicheroPlural: tipoFichero).toString()"
                         [notificarCierreDialogo]= true
                         (cerrarDialogo)="cierreDialogoSubirArchivos()"
                         [botonera]="[
                                      {
                                        label: 'Cancelar',
                                        id: 'btn-cancelar',
                                        tipoAccion: 'cerrar',
                                        type: 'button',
                                        color: '',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      },
                                      {
                                        label: 'Aceptar',
                                        id: 'btn-guardar',
                                        tipoAccion: 'accion',
                                        type: 'button',
                                        color: 'primary',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      }]"
                        [claseDialogActions]="''"
                         (accionBoton)="submitAccionDialogSubirArchivos($event)">
      <neo-fichero-subida #appFicheroSubida
                          [idObjeto]="idObjeto"
                          [camposExtra]="camposExtra"
                          [objetoRelacionado]="objetoRelacionado"
                          [urlArchivos]="urlArchivos"
                          [formatosPermitidos]="formatosPermitidos"
                          [subidaMultiple]="true"
                          [maximoElementosASubir]="calcularMaximosElementos()"
                          [tamanoMaximoTotal]="tamanoMaximoTotal"
                          [tamanoMaximoFichero]="tamanoMaximoFichero"
                          (preSubirArchivos)="preSubirArchivos($event)"
                          (submitFicheroSubida)="submitDatosFicheroSubida($event)"
                          (borrarFichero)="eventoBorrarFichero($event)"
      ></neo-fichero-subida>
    </neo-acciones-dialog>
  </ng-template>
  <!--End Diálogo Subir archivos-->

  <!--Diálogo Cerrar Subir archivos-->
  <ng-template #dialogConfirmarCerrarSubirArchivos>
    <neo-acciones-dialog #accionDialogConfirmarCerrarSubirArchivos
                         [dialogTittle]="'Cerrar subir ficheros'"
                         (cerrarDialogo)="cierreDialogoConfirmarCerrarSubirArchivos()"
                         [botonera]="[
                                      {
                                        label: 'Sí, cancelar la subida',
                                        id: 'btn-cancelar-subida',
                                        tipoAccion: 'accion',
                                        type: 'button',
                                        color: '',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      },
                                      {
                                        label: 'No, continuar la carga',
                                        id: 'btn-cancelar',
                                        tipoAccion: 'cerrar',
                                        type: 'button',
                                        color: 'primary',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      }
                                    ]"
                         [claseDialogActions]="'acciones-button-block-xs'"
                         (accionBoton)="submitAccionDialogoConfirmarCerrarSubirArchivos($event)">

      <p>¿Seguro que deseas cancelar la subida?</p>
    </neo-acciones-dialog>
  </ng-template>
  <!--End Diálogo Cerrar Subir archivos-->

  <!--Diálogo Eliminar archivos-->
  <ng-template #dialogConfirmarEliminarArchivos>
    <neo-acciones-dialog #accionDialogConfirmarEliminarArchivos
                         [dialogTittle]="'Eliminar'"
                         [botonera]="[
                                      {
                                        label: 'Sí, eliminar',
                                        id: 'btn-confirmar',
                                        tipoAccion: 'accion',
                                        type: 'button',
                                        color: '',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      },
                                      {
                                        label: 'No, mantener',
                                        id: 'btn-cancelar',
                                        tipoAccion: 'cerrar',
                                        type: 'button',
                                        color: 'primary',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      }
                                    ]"
                         (accionBoton)="submitAccionDialogConfirmarEliminarArchivos(archivosPendientesDeEliminar)">

      <p>¿Estás seguro de eliminar
        <span *ngIf="archivosPendientesDeEliminar.length>1 else avisoFicheroIndividual">los siguientes {{tipoFicheroPlural | lowercase}} </span>?
      </p>
      <ng-template #avisoFicheroIndividual>el siguiente {{tipoFichero | lowercase}} </ng-template>

      <ng-container *ngFor="let item of archivosPendientesDeEliminar">
        <p>
          {{item.nombre}}{{item.extension}}
        </p>
      </ng-container>
    </neo-acciones-dialog>
  </ng-template>
  <!--Diálogo Eliminar archivos-->

  <!--Diálogo Editar archivos-->
  <ng-template #dialogEditarArchivo>
    <neo-acciones-dialog #accionDialogEditarArchivo
                         dialogTittle="Editar  {{ tipoFichero | lowercase}}"
                         [botonera]="[
                                      {
                                        label: 'Cancelar',
                                        id: 'btn-cancelar',
                                        tipoAccion: 'cerrar',
                                        type: 'button',
                                        color: '',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      },
                                      {
                                        label: 'Aceptar',
                                        id: 'btn-guardar',
                                        tipoAccion: 'accion',
                                        type: 'button',
                                        color: 'primary',
                                        disabled: false,
                                        activo: true,
                                        flat: true
                                      }

                                    ]"
                         [claseDialogActions]="''"
                         (accionBoton)="submitAccionDialogEditarArchivo()">
      <neo-fichero-subida #appFicheroSubidaEdicion
                          [idObjeto]="idObjeto"
                          [camposExtra]="camposExtra"
                          [urlArchivos]="urlArchivos"
                          [objetoRelacionado]="objetoRelacionado"
                          [formatosPermitidos]="formatosPermitidos"
                          [subidaMultiple]="false"
                          [sobreescrituraFichero]=true
                          [maximoElementosASubir]="1"
                          [tamanoMaximoTotal]="calcularTamanoMaximoTotal()"
                          [ficherosIniciales]="[ficheroEdicion]"
                          [tamanoMaximoFichero]="tamanoMaximoFichero"
                          (preSubirArchivos)="preSubirArchivos($event)"
                          (submitFicheroSubida)="submitDatosFicheroSubida($event)"
                          (borrarFichero)="eventoBorrarFichero($event)"
      ></neo-fichero-subida>
      <neo-formulario class="mt-30 display-block" #formularioEdicion
                      [valoresIniciales]="ficheroEdicion"
                      [camposFormulario]="formInputEdicion"
      >
      </neo-formulario>
    </neo-acciones-dialog>
  </ng-template>
  <!--End Diálogo Editar archivos-->

  <!--Diálogo Más info-->
  <ng-template #dialogoMasInfo>
    <neo-acciones-dialog #accionDialogMasInfo
                         [dialogTittle]="'Información'"
                         [botonera]="[]">
      <neo-perfil #perfilMasInfoFichero
                  [propiedades]="propiedadesMasInfoFichero"
                  [aplicarFlex]=false
      >
      </neo-perfil>
    </neo-acciones-dialog>
  </ng-template>
  <!--End Diálogo Más info-->

</div>
