<neo-button *ngIf="!mostrarEnDialog; else btnDialog" class="btn-desplegable"
            data-testid="neo-button-principal"
            [ngClass]="{ 'btn-desplegable-disabled' : disabled }"
            [disabled]="disabled"
            [matMenuTriggerFor]="menu"
            [basic]="basicBotonPrincipal"
            [miniFab]="miniFabBotonPrincipal"
            [stroked]="strokedBotonPrincipal"
            [icono]="iconoTipoBotonPrincipal"
            [color]="colorBotonPrincipal"
            [text]="textoBotonPrincipal"
            [icon]="iconoBotonPrincipal"
            [iconClass]="iconoClaseBotonPrincipal"
            [clase]="claseBotonPrincipal"
            [matsuffix]=true>
</neo-button>
<mat-menu #menu="matMenu" class="mat-menu-duacode mat-menu-{{referenciaElemento}}">
  <ng-template [ngTemplateOutlet]="templateAcciones"></ng-template>
</mat-menu>

<ng-template #templateAcciones>
<!--  IMPORTANTE, no se puede usar <neo-BUTTON> como mat-menu-item ya que no permite tener 2 componentes en el mismo elemento-->
  <ng-container *ngFor="let accion of acciones">
    <div class="items-{{alineacionAccionesBotonDespegable}}">
      <button mat-menu-item  [class]="accion.clase" [disabled]="accion.disabled" (click)="desencadenarAccion(accion.id)">
        <neo-icono *ngIf="accion.icono || accion.iconoClase"
          [icono]="accion.icono"
          [iconoClase]="accion.iconoClase"
        ></neo-icono>
        <span *ngIf="accion.texto">{{accion.texto}}</span>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #btnDialog>
  <ng-content></ng-content>
  <neo-button class="flex-m" *ngIf="!proyeccionBotonPersonalizado"
              data-testid="template-btnDialog"
              [ngClass]="{ 'btn-desplegable-disabled' : disabled }"
              [disabled]="disabled"
              [basic]="basicBotonPrincipal"
              [miniFab]="miniFabBotonPrincipal"
              [stroked]="strokedBotonPrincipal"
              [icono]="iconoTipoBotonPrincipal"
              [color]="'primary'"
              [text]="textoBotonPrincipal"
              [icon]="iconoBotonPrincipal"
              [iconClass]="iconoClaseBotonPrincipal"
              [clase]="claseBotonPrincipal"
              [matsuffix]=true
              (accion)="abrirDialog()">
  </neo-button>
</ng-template>


 <ng-template #dialogBtnDesplegable>
   <neo-acciones-dialog
     [dialogTittle]="tituloDialog"
     [botonCerrarDialogoCabecera]="true"
     [botonera]="botoneraDialogo"
     >
     <ng-template [ngTemplateOutlet]="templateAcciones"></ng-template>
   </neo-acciones-dialog>
 </ng-template>
