import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, NavigationEnd, NavigationStart} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@appNeo/neoCore/services/auth/auth.service';
import { environment } from '@environments/environment';
import { StorageService } from '@appNeo/neoCore/services/storage/storage.service';
import { StorageTipo } from '@appNeo/neoCore/helpers/enums/storage-tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.user$.value) {
      return true;
    }
    if (state.url !== environment.pagina_aterrizaje) {
      const loginFallido = {url: state.url, fecha: new Date()};
      this.storage.set(environment.keyIntentoAcceso, JSON.stringify(loginFallido), StorageTipo.LocalStorage);
    }
    this.router.navigate([environment.pagina_login]);
    return false;
  }

}
