export class Paginator  {
  // private limit1: number;
  // private limit2: number;
  private pagina: number = 1;
  private numeroElementosPagina: number;



  // get _limit1(): number {
  //   return this.limit1;
  // }

  // set _limit1(value: number) {
  //   this.limit1 = value;
  // }

  // get _limit2(): number {
  //   return this.limit2;
  // }

  // set _limit2(value: number) {
  //   this.limit2 = value;
  // }

  get _numeroElementosPagina(): number {
    return this.numeroElementosPagina;
  }

  set _numeroElementosPagina(value: number) {
    this.numeroElementosPagina = value;
  }

  get _pagina(): number {
    return this.pagina;
  }

  set _pagina(value: number) {
    this.pagina = value;
  }
}
