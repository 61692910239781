import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FiltrosService } from '@appNeo/neoCore/services/filtros/filtros.service';
import { AccionesDialogComponent } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { CalendarioComponent, IConfiguracionMomento, IRangoTipoSeleccion, VistasCalendarioExtendidoEnum } from '@appNeo/neoShared/components/calendario/calendario/calendario.component';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';

import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { CalendarioService } from '@appNeo/neoShared/services/calendario/calendario.service';
import { environment } from '@environments/environment';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { addDays, format, setHours, setMinutes } from 'date-fns';
import { CodigoColorCalendarioHexEnum, COLORES, IEventoEntidad, INPUTS_FORMULARIO_EVENTO_ESPECIFICO } from './demo-calendario-data';


@Component({
  selector: 'neo-demo-calendario',
  templateUrl: './demo-calendario.component.html',
  styleUrls: ['./demo-calendario.component.scss']
})
export class DemoCalendarioComponent implements OnInit{
  grabando = false;
  // TODO hacer temas de configuracion que activen los flag

  vista: CalendarView = CalendarView.Month;

  diaVista: Date = new Date();

  eventoSeleccionado = null;

  colors = COLORES;

  // evento estatico
  eventos: CalendarEvent[] = [];

  clasesDias: IConfiguracionMomento[] = [];
  // [
  //   {
  //     dia: this.auxiliarService.ayer.getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOA,
  //     configuracion: 'Tarifa C',  //TODO objeto meta
  //     clase: 'bg-codigoA'
  //   },
  //   {
  //     dia: this.auxiliarService.hoy.getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOB,
  //     configuracion: 'Tarifa B',
  //     clase: 'bg-codigoB'
  //   },
  //   {
  //     dia: this.auxiliarService.manana.getTime(),
  //     bloqueo: true,
  //     color: CodigoColorCalendarioHexEnum.CODIGOC,
  //     configuracion: 'Tarifa C',
  //     clase: 'bg-codigoC'
  //   },
  //   {
  //     dia: this.auxiliarService.pasadomanana.getTime(),
  //     bloqueo: true,
  //     color: CodigoColorCalendarioHexEnum.CODIGOD,
  //     configuracion: 'Tarifa D',
  //     clase: 'bg-codigoD'
  //   },
  //   {
  //     dia: this.auxiliarService.anteayer.getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOC,
  //     configuracion: 'Tarifa C',
  //     clase: 'bg-codigoC'
  //   },
  //   {
  //     dia: this.auxiliarService.anteayer.getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOC,
  //     configuracion: 'Tarifa C',
  //     clase: 'bg-codigoC'
  //   },
  //   {
  //     dia: this.auxiliarService.fechaDentroDeDias(10).getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOA,
  //     configuracion: 'Tarifa A',
  //     clase: ''
  //   },
  //   {
  //     dia: this.auxiliarService.fechaDentroDeDias(15).getTime(),
  //     bloqueo: false,
  //     color: CodigoColorCalendarioHexEnum.CODIGOB,
  //     configuracion: 'Tarifa B',
  //     clase: ''
  //   },
  // ];


  accionesMultiples: IAccionesBotonDesplegable [] = [
    {id: 'bloquear', iconoClase: '', texto: 'Bloquear'},
    {id: 'aplicar_tarifaA', iconoClase: '', texto: 'Aplicar tarifa A'},
    {id: 'aplicar_tarifaB', iconoClase: '', texto: 'Aplicar tarifa B'},
    {id: 'aplicar_tarifaC', iconoClase: '', texto: 'Aplicar tarifa C'},
    {id: 'aplicar_descuento', iconoClase: '', texto: 'Aplicar descuento'},
    {id: 'no_laborable', iconoClase: '', texto: 'Eliminar tarifa'}

  ];
  tituloDialogo;
  cargarEventosApi: boolean = true;

  paginador = new Paginator();
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.offline;
  
  @ViewChild('calendario') calendario: CalendarioComponent;
  refDialogFormulario;
  @ViewChild('dialogoEvento') dialogoEvento: TemplateRef<any>;
  @ViewChild('accionDialogoEvento') accionDialogoEvento: AccionesDialogComponent;

  // para ejemplo extender template cell
  visibilidadChipsEventos = false;

  inputsFormulario = INPUTS_FORMULARIO_EVENTO_ESPECIFICO

  constructor(
    public calendarioService: CalendarioService,
    private auxiliarService: AuxiliarService,
    private dialog: MatDialog,
  ) {}
  ngOnInit(): void {    
    // cargar configuracion calendario
    if (true) {
      this.cargarConfiguracion();
    }
    if (this.cargarEventosApi){
      // evento dinamico
      // this.calendarioService.eventos$.subscribe(eventos => {
      //   this.eventos = eventos
      //   console.log('INformacion calendario ',this.eventos);
      // }); 
      
      this.cargarEventos() 
    }
  }

  private obtenerParametrosConsultaConfiguracion(diaVista?, vista?) {
    let formatFechaApi = this.calendarioService.formatFechaApi;
    let primerDiaMes = this.auxiliarService.primerDiaMes(diaVista);
    let filtrosDefault = {
      fechaDesde: format(<Date>primerDiaMes, formatFechaApi),
      fechaHasta: format(<Date>this.auxiliarService.ultimoDiaMes(<Date>primerDiaMes), formatFechaApi),
      bloqueo: '1',
    };
    let filtros = filtrosDefault;
    switch (vista) {
      case CalendarView.Month:
        filtros = {
          fechaDesde: format(<Date>primerDiaMes, formatFechaApi),
          fechaHasta: format(<Date>this.auxiliarService.ultimoDiaMes(<Date>primerDiaMes), formatFechaApi),
          bloqueo: '1',
        };
        break;
      case CalendarView.Week:
        break;
      case CalendarView.Day:
        filtros = {
          fechaDesde: format(new Date(diaVista), formatFechaApi),
          fechaHasta: format(new Date(diaVista), formatFechaApi),
          bloqueo: '1',
        };
        break;
      case VistasCalendarioExtendidoEnum.Agenda:
        break;
      default:
        filtros = filtrosDefault;
        break;
    }
    return filtros;
  }

  cargarConfiguracion(filtros?: any) {
    // filtros
    if (!filtros || Object.keys(filtros).length === 0) {
      filtros = this.obtenerParametrosConsultaConfiguracion(this.diaVista, this.vista);
    }
    this.calendarioService.getConfiguracionCalendario(filtros).subscribe(data => {
      // console.log('*************************************');
      // console.log('************CONFIGU*************************');
      // console.log(this.clasesDias, data);
      this.clasesDias = data;
      // console.log('*************************************');
      // console.log('*************************************');

    });
  }
  cargarEventos(filtros?: any) {
    let _filtros = { orden: 'nombre', tipoOrden: 'asc' , ...filtros};
    this.calendarioService.getAll( this.tipoPaginador, this.paginador, _filtros).subscribe(
      data => {
        this.eventos = data;
      }
    );
  }



  navegacionCalendario(data) {
    this.cargarConfiguracion(this.obtenerParametrosConsultaConfiguracion(this.diaVista, this.vista));
    console.log('Accion en controlador puente al hacer click en navegacion', data);

  }
  // 
  crearEvento(data) {
    console.log('Accion en controlador puente al hacer click en ', data);
    this.tituloDialogo = 'Crear evento';
    this.eventoSeleccionado = data;
    // this.refDialogFormulario = this.dialog.open(this.dialogoEvento, {disableClose: false});
  }

  clickEvento(data) {
    this.eventoSeleccionado = data;
    this.tituloDialogo = 'Editar evento';
    // this.refDialogFormulario = this.dialog.open(this.dialogoEvento, {disableClose: false});
    console.log('Accion en controlador puente al hacer click en evento ', data);
  }

  submitAccionDialogoEvento(evento){
    console.log(evento);
    this.dialog.closeAll();
    this.resfrescarCalendario();
  }

  resfrescarCalendario() {
    this.cargarEventos();
    this.calendario.refrescarVista();
  }

  clickAplicarRangoTipo(seleccion: IRangoTipoSeleccion){
    console.warn('Seleccionado ', seleccion);
  }

  clickElementosSeleccionados(seleccion) {
    console.log('Seleccion de las fechas ', seleccion);
    
    this.calendario.showResult('Tarifas aplicadas', 'ok');
  }

  clickAccionElementosSeleccionados(informacion) {
    // console.log("Accion sobre fechas seleccionadas ", informacion);
    let dias = [];
    switch(informacion.accion) {
        case 'bloquear' : 
        this.calendario.showResult('Existen eventos en las fechas seleccionadas', 'ko');
        break;
      case 'aplicar_tarifaA' : 
        this.mockAplicarTarifa(informacion, 'Tarifa A', 'bg-codigoA');
        this.calendario.vaciarSeleccion();
        this.calendario.showResult('Tarifa A aplicada', 'ok');
        break;
      case 'aplicar_tarifaB' : 
        this.mockAplicarTarifa(informacion, 'Tarifa B', 'bg-codigoB');
        this.calendario.vaciarSeleccion();
        this.calendario.showResult('Tarifa B aplicada', 'ok');
        break;
      case 'aplicar_tarifaC' : 
        this.mockAplicarTarifa(informacion, 'Tarifa C', 'bg-codigoC');
        this.calendario.vaciarSeleccion();
        this.calendario.showResult('Tarifa C aplicada', 'ok');
        break;
      case 'aplicar_descuento' : 
        this.mockAplicarTarifa(informacion, 'Tarifa D', 'bg-codigoD');
        this.calendario.vaciarSeleccion();
        this.calendario.showResult('Tarifa D aplicada', 'ok');
        break;
      case 'no_laborable' : 
        this.mockAplicarTarifa(informacion, 'Días no laboreables', '');
        this.calendario.vaciarSeleccion();
        this.calendario.showResult('Día no laborable', 'ok');
        break;
    }

    console.log( this.clasesDias );
  }

  gestionarEvento(evento) {
    if (evento?.meta?.entidad?.id) {
      this.calendarioService.update(evento?.meta?.entidad?.id, evento).subscribe({
        next: (v) => console.log('Respuesta update ', v),
        error: (e) => {
          console.log('Error ', e);
          this.calendario.showResult('Se ha producido un error!', 'ko', DialogActionEnum.Update)
        },
        complete: () => {
          this.calendario.showResult('Evento actualizado con éxito!', 'ok');
          this.cargarEventos();
        } 
      });
    }else {
      this.calendarioService.create(evento).subscribe({
        next: (v) => console.log(v),
        error: (e) => this.calendario.showResult('Se ha producido un error!', 'ko', DialogActionEnum.Create),
        complete: () => {
          this.calendario.showResult('Evento creado con éxito!', 'ok');
          this.cargarEventos();
        } 
      });
    }
  }
  eliminarEvento(evento) {
    if (evento?.meta?.entidad?.id) {
      this.calendarioService.delete(evento?.meta?.entidad?.id).subscribe({
        next: (v) => console.log('Respuesta delete ', v),
        error: (e) => {
          console.log('Error ', e);
          this.calendario.showResult('Se ha producido un error eliminando evento!', 'ko', DialogActionEnum.Delete)
        },
        complete: () => {
          this.calendario.showResult('Evento eliminado con éxito!', 'ok');
          this.cargarEventos();
        } 
      });
    } else {
      this.calendario.showResult('Se ha producido un error eliminando evento, id no encontrado!', 'ko', DialogActionEnum.Delete)
    }
    
  }
  mockAplicarTarifa(eventoSeleccion, configuracion, clase){
    let nuevosDiasPersonalizados = [];
    eventoSeleccion.seleccion.map(item => {
      let posicion = this.auxiliarService.buquedaPosicionPropiedad(this.clasesDias, 'dia', item.getTime());
      let confi = { dia: item.getTime(), configuracion, clase};
      if (posicion>=0) {
        this.clasesDias[posicion] = confi;
      }else {
        nuevosDiasPersonalizados.push(confi);
      }          
    });
    // si todo ok, lectura clasesDias y vaciarSeleccion
    this.clasesDias = [...this.clasesDias, ...nuevosDiasPersonalizados];
  }

  mockFiltrar(event) {
    console.log('Filrar ', event);
    this.cargarEventos(event);
  }
  

    // **************************************************** //
  // **************** RESPONSIVO************************* //
  // **************************************************** //

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.chequearEstadoPorTamanho();
  }
  
  get numeroEtiquetas() {
    return 3;
  }

  chequearEstadoPorTamanho() {
    this.visibilidadChipsEventos = (window.innerWidth > environment.MEDIA_MOBILE);
  }
}
