import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BarraDerechaService } from '@appNeo/neoShared/services/barra-derecha/barra-derecha-service';
import { BarraIzquierdaService } from '@appNeo/neoShared/services/barra-izquierda/barra-izquierda.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'neo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  // https://meganrook.medium.com/how-to-create-a-mini-nav-with-angular-materials-sidenav-dbc46ce4abf5
  subAlternarBarraIzquierda: Subscription;
  subAlternarBarraDerecha: Subscription;
  barraIzquierdaClass = 'desplegada';
  barraDerechaClass = 'desplegada';
  expandidoBI: boolean;
  expandidoBD: boolean;
  @ViewChild('sidenavBI') sidenavBI: MatSidenav;
  @ViewChild('sidenavBD') sidenavBD: MatSidenav;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private barraIzquierdaService: BarraIzquierdaService,
    private barraDerechaService: BarraDerechaService
  ) {
    this.incializarEstado();
    this.chequearEstadoPorTamanho();
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.chequearEstadoPorTamanho();
  }

  ngOnInit(): void {
    this.subscripcionAlternarBarraIzquierda();
    this.subscripcionAlternarBarraDerecha();
  }

  ngOnDestroy(): void {
    this.subAlternarBarraIzquierda.unsubscribe();
    this.subAlternarBarraDerecha.unsubscribe();
  }

  subscripcionAlternarBarraIzquierda() {

    this.subAlternarBarraIzquierda = this.barraIzquierdaService.expandido$.subscribe( valor => {
      this.barraIzquierdaClass = (valor) ? 'desplegada': 'plegada';
      this.expandidoBI = valor;
      if (valor) {
        this.sidenavBI?.toggle();
      }
    })
  }

  subscripcionAlternarBarraDerecha() {
    this.subAlternarBarraDerecha = this.barraDerechaService.expandido$.subscribe( valor => {
      this.barraDerechaClass = (valor) ? 'desplegada': 'plegada';
      this.expandidoBD  =valor;
      if (this.sidenavBD ) {
        this.sidenavBD.toggle();
     }
    })
  }


  chequearEstadoPorTamanho() {
    if (window.innerWidth < environment.MEDIA_MOBILE || window.innerWidth < environment.MEDIA_TABLETV) {
      this.renderer.addClass(this.document.body, 'menu-mobile');
      if (this.esBarraDesplegadaFija()) {
        this.barraIzquierdaService.establecerExpandido(false);
      }

    } else {
      if (this.esBarraDesplegadaFija()) {
        this.barraIzquierdaService.establecerExpandido(true);
      }
      this.renderer.removeClass(this.document.body, 'menu-mobile');
      this.renderer.removeClass(this.document.body, 'barra-desplegada-movil');
    }
  }

  incializarEstado() {
    this.renderer.addClass(this.document.body, 'barra-desplegada');
  }

  esBarraDesplegadaFija() {
    const barraDesplegada = this.document.body.classList.contains('barra-desplegada');
    return barraDesplegada;
  }

  esBarraDesplegadaMovil() {
    const barraDesplegada = this.document.body.classList.contains('barra-desplegada-movil');
    return barraDesplegada;
  }

}

