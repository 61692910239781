import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() linkInterno: string;
  @Input() linkExterno: string;
  @Input() src = 'assets/images/icons/logo.svg';
  @Input() alt = 'logo';
  constructor() { }

}
