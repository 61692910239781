import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { StepperComponent } from '@appNeo/neoShared/components/stepper/stepper.component';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { FORM_INPUT_STEP_1, FORM_INPUT_STEP_2, FORM_INPUT_STEP_3, FORM_INPUT_STEP_4, IDemoEntidadWizard } from '@appNeo/neoShared/pages/demo-wizard/demo-wizard-data';

@Component({
  selector: 'neo-demo-wizard',
  templateUrl: './demo-wizard.component.html',
  styleUrls: ['./demo-wizard.component.scss'],
  providers:[FormularioService]
})
export class DemoWizardComponent implements OnInit, AfterViewInit {

  configuracionFormStep1: IFormInput[] = FORM_INPUT_STEP_2;
  configuracionFormStep2: IFormInput[] = FORM_INPUT_STEP_1;
  configuracionFormStep3: IFormInput[] = FORM_INPUT_STEP_3;
  configuracionFormStep4: IFormInput[] = FORM_INPUT_STEP_4;


  stepControl1: FormGroup;
  stepControl2: FormGroup;
  stepControl3: FormGroup;
  stepControl4: FormGroup;

  entidad: IDemoEntidadWizard;

  @ViewChild('stepper')  stepper: StepperComponent;
  @ViewChild('formStepControl1')  formStepControl1: FormularioComponent;
  @ViewChild('formStepControl2')  formStepControl2: FormularioComponent;
  @ViewChild('formStepControl3')  formStepControl3: FormularioComponent;
  @ViewChild('formStepControl4')  formStepControl4: FormularioComponent;

  constructor(
    private formulario: FormularioService
  ) { }


  ngOnInit(): void {

  }

  siguiente() {
    this.stepper.next();
  }
  anterior() {
    this.stepper.previous();
  }

  cambioPaso(evento){
    const pasos = this.stepper['htmlSteps'];
    if (evento.previouslySelectedIndex < evento.selectedIndex) {
      for (let i = 0; i < evento.selectedIndex; i ++) {
        pasos[i].classList.add('previous');
      }
    }
    else {
      for (let i = pasos.length - 1; i > evento.selectedIndex; i--) {
        pasos[i].classList.remove('previous');
      }
    }
    if (evento.selectedIndex === 4) {
      this.entidad = {...this.stepControl1.value, ...this.stepControl2.value, ...this.stepControl3.value, ...this.stepControl4.value};
    }
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.stepControl1 = this.formStepControl1.formValidators;
      this.stepControl2 = this.formStepControl2.formValidators;
      this.stepControl3 = this.formStepControl3.formValidators;
      this.stepControl4 = this.formStepControl4.formValidators;

    });
  }
  calcularEdad(){
    let objeto = this.formStepControl2.validaCampos( false );
    if (objeto && objeto.formulario && objeto.formulario.valid) {
      let fecha = objeto.formulario.value;
    }
    this.stepper.next();
  }
}
