import { Component, Input, EventEmitter, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {ILabelLink} from '@appNeo/neoShared/helpers/interfaces/IForm-input';


@Component({
  selector: 'neo-campo-checkbox [controlCampo]',
  templateUrl: './campo-checkbox.component.html',
  styleUrls: ['./campo-checkbox.component.scss']
})
export class CampoCheckboxComponent  {
  @Input() clase: string;
  @Input() controlCampo: FormControl;
  @Input() label: string;
  @Input() labelLink: ILabelLink;
  @Input() color: string = 'primary';
  @Output('labelLinkClick') labelLinkClick = new EventEmitter<boolean>();


  constructor(private dialog: MatDialog) { }

  clickLabelLink() {
    this.dialog.closeAll();
    if (!this.labelLink.rutaInterna && !this.labelLink.rutaExterna) {
      this.labelLinkClick.emit(true);
    }
  }

}
