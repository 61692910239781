import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { FicheroSubidaService } from '@appNeo/neoShared/services/fichero-subida/fichero-subida.service';
import { environment } from '@environments/environment';
import { CirculoProgresoComponent } from '@appNeo/neoShared/components/circulo-progreso/circulo-progreso.component';
import { FicheroComponent } from '@appNeo/neoShared/components/fichero/fichero.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { FICHEROS_ADJUNTOS, FICHEROS_ERROR_CARGA, FICHEROS_NO_PERMITIDOS } from './fichero-subida-data';
import { AliasFicherosEnum, AliasPluralFicherosEnum } from '@appNeo/neoShared/helpers/enums/AliasFIcheros.enum';
import { CirculoProgresoIconoCompletadoComponent } from '@appNeo/neoShared/components/circulo-progreso-icono-completado/circulo-progreso-icono-completado.component';


export interface IFileEnvuelto {
  file: File,
  fichero?: any,
  hash:string,
  idFichero?: string,
  edicion?: boolean,
  id?: string,
  nuevaImagen?: boolean,
}
@Component({
  selector: 'neo-fichero-subida',
  templateUrl: './fichero-subida.component.html',
  styleUrls: ['./fichero-subida.component.scss'],
  providers: [FicheroSubidaService]
})
// Todo: Si sobreescritura únicamente puede haber 1 fichero máximo a subir
// Todo: Si errorCarga, no debería de desaparecer la img que había preSubirFichero()
export class FicheroSubidaComponent implements OnInit {

  // TODO BORRAR, url a traves de INPUT y ficheroServicio
  url: string = environment.base_url;

  // TODO si se configura desde servicio, eliminiar INPUT
  // configuracion servicio interno
  @Input('idObjeto') idObjeto: string = '1';
  @Input('camposExtra') camposExtra: object;
  @Input('objetoRelacionado') objetoRelacionado;
  @Input('urlArchivos') urlArchivos;
  @Input('presubidaFichero') presubidaFichero: boolean = true;

  @Input('identificador') identificador: string = 'fileDropRef';
  @Input('zonaDraggable') zonaDraggable: boolean = true;
  @Input('subidaMultiple') subidaMultiple;
  @Input('sobreescrituraFichero') sobreescrituraFichero: boolean = false; // solo para casos de  maximoElementosASubir 1 y sin subida multiple
  @Input('maximoElementosASubir') maximoElementosASubir;
  @Input('formatosPermitidos') formatosPermitidos;
  @Input('tamanoMaximoTotal') tamanoMaximoTotal; // Tamaño en bits
  @Input('tamanoMaximoFichero') tamanoMaximoFichero; // Tamaño en bits
  @Input('ficherosIniciales') ficherosIniciales;
  @Input('desactivado') desactivado: boolean = false;
  @Input('botonHacerFotoResponsivo') botonHacerFotoResponsivo: boolean = true;
  
  @Input('iconoBotonSubirFicheroResponsivo') iconoBotonSubirFicheroResponsivo: string = '';
  @Input('textoBotonSubirFicheroResponsivo') textoBotonSubirFicheroResponsivo: string = '';
  
  @Output() submitFicheroSubida = new EventEmitter ();
  @Output() preSubirArchivos = new EventEmitter ();
  @Output() borrarFichero = new EventEmitter ();
  @ViewChildren(CirculoProgresoIconoCompletadoComponent) listadoCirculosProgreso: QueryList<CirculoProgresoIconoCompletadoComponent>;
  @ViewChild('inputHacerFoto') inputHacerFoto:ElementRef;
  @ViewChild('inputSubirFichero') inputSubirFichero:ElementRef;

  @Input('tipoFichero') tipoFichero: AliasFicherosEnum = AliasFicherosEnum.generico;
  @Input('tipoFicheroPlural') tipoFicheroPlural: AliasPluralFicherosEnum = AliasPluralFicherosEnum.generico;

  // presubidos en formato IFichero
  // TODO aplicar IFIchero BORRAR
  archivosAdjuntos: any[] = [];
  // presubidos en formato File
  fileAdjuntos: IFileEnvuelto[] = [];
  filesAdjuntosPendientesBorrado: IFileEnvuelto[] = [];
  // TODO MAPEO ENTRE FILES Y FICHEROS

  // TODO valorar si llevar a servicio
  filesNoPermitidos: File[] = [];
  filesErrorCarga: File[] = [];
  hashFileSobreescribir;

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @ViewChild('notificacion') notificacion: NotificacionComponent;

  alertaMaximosElementos: string;
  mensajeCabecera: string;
  tamanoMaximoExcedido: boolean = false;
  mensajeFormatosPermitidos;
  progreso = 0;

  constructor(
    public auxiliarService: AuxiliarService,
    // public progressService: ProgressService,
    private ficheroSubidaService: FicheroSubidaService,
    @Optional() public padre: FicheroComponent,
  ) {
  }

  ngOnInit(): void {
    // console.log('Inicializando FicheroSubidaComponent');
    this.configurarAlertaMaximosElementos();
    this.cargarFicherosIniciales();
    // if (environment.simulacion_datos_maquetacion && !this.sobreescrituraFichero) {
    //   this.filesNoPermitidos = FICHEROS_NO_PERMITIDOS;
    //   this.filesErrorCarga = FICHEROS_ERROR_CARGA;
    //   this.fileAdjuntos = FICHEROS_ADJUNTOS;
    // }
  }

  getAliasFicherosEnum(){
    if (this.padre) {
      return this.padre.getAliasFicherosEnum();
    } else {
      return AliasFicherosEnum;
    }
  }

  arrastarArchivo(event) {
    this.addArchivo(event);
  }

  seleccionarArchivo(event) {
    console.log('Seleccion archivo', event);
    let archivos = event.target.files;
    this.addArchivo(archivos);
  }

  addArchivo(archivos){
    console.log('Archivo a subir ', archivos);
    if (this.sobreescrituraFichero) {
      this.hashFileSobreescribir = (this.fileAdjuntos.length > 0 ) ? this.fileAdjuntos[0].hash:null;
    }
    this.notificacion.eliminarNotificaciones();
    // Comprobamos nº de archivos
    if(this.comprobarNumeroDeArchivos(archivos)){
      console.log('EXCEDIDO NUM ELEMENTOS');
      this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', this.alertaMaximosElementos, true, IconEnum.Error);
      return;
    }

    for(let item of archivos){
      console.log('Fichero a subir ', item);
      // Comprobamos si el archivo es válido
      if(this.comprobarArchivoValido(item)){
        // Comprobamos si tamaño del archivo es válido
        if(this.comprobarTamanoValido(item)){
          //this.archivosAdjuntos.push(item);
          //this.preSubirArchivos.emit(item);
          console.log('[Presubir el fichero]');
          this.preSubirFichero(item);
        } else if(this.tamanoMaximoExcedido){
          this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', `Has supera el límite permitido (${this.auxiliarService.formatBytes(this.tamanoMaximoTotal, undefined)}) .`, true, IconEnum.Error);
        } else {
          this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', `El archivo ${item.name} (${this.auxiliarService.formatBytes(item?.size, undefined)}) supera el tamaño máximo permitido (${this.auxiliarService.formatBytes(this.tamanoMaximoFichero, undefined)}).`, true, IconEnum.Error);
        }
      } else {
        this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', `El archivo ${item.name} no es válido.`, true, IconEnum.Error);
      }
    }
  }

  // 
  preSubirFichero(item){
    console.log(`[PRESUBIR FICHERO 1] ${item}`);
    // pendiente ver subida multiple en una misma petición ( cosa de back primero)
    let fileACargar = {hash: generateId(), file: item, id: null};
    console.log(`[PRESUBIR FILEENVUELTO 1] ${fileACargar}`);
    if ( this.sobreescrituraFichero ) {
      console.log(`[PRESUBIR SOBREESCRITURA] ${this.sobreescrituraFichero}`);
      // this.borrarArchivo(0, 'fileAdjuntos');
    }
    this.fileAdjuntos.push(fileACargar);


    // this.archivosAdjuntos.push(item);
    // console.log('Subiendo el fichero ', item);
    let indiceFile = this.obtenerIndiceFichero(item);
    let hashFile = fileACargar.hash;
    // TODO borrar cuando este configurado el servicio
    this.ficheroSubidaService.urlArchivos = this.urlArchivos;
    if (this.presubidaFichero) {
      this.ficheroSubidaService.subirFichero([item], this.idObjeto, this.camposExtra, this.objetoRelacionado
      ).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              // console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              console.log('Buqueda del ciruclo indice ', indiceFile, hashFile);
              let progresoComponente = this.listadoCirculosProgreso.find((progresoComponente: CirculoProgresoIconoCompletadoComponent, index: number, array) => {
                return progresoComponente.identificador == hashFile

              });
              if (progresoComponente) {
                progresoComponente.setProgreso(Math.round(event.loaded / event.total * 100));
                // console.log(`Uploaded! ${Math.round(event.loaded / event.total * 100)}%`);
              }
              break;
            case HttpEventType.Response:
              // console.log('Created!', event.body);
              setTimeout(() => this.mapearFichero(event.body.data, item, hashFile), 1000);

          }
        },
        (error) => {
          // console.warn(`Ha ocurrido un error al subir fichero: ${error}`);
          let aviso = 'El fichero seleccionado no se ha podido subir';
          if (error?.data) {
            if (Array.isArray(error.data)) {
              aviso = (error.data.length) ? error.data[0]?.mensaje : '';
            } else {
              aviso = error.data
            }
          }
          this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', aviso, true, IconEnum.Error);
          this.filesErrorCarga.push(item);
          // borrar file de filesSubidos
          this.borrarArchivo(this.obtenerIndiceFicheroPorHash(hashFile), 'fileAdjuntos');
        },
        () => {
          console.log('Nuevos ficheros subidos')
          if (this.sobreescrituraFichero && this.hashFileSobreescribir) {
            this.borrarArchivo(this.obtenerIndiceFicheroPorHash(this.hashFileSobreescribir), 'fileAdjuntos', true);
          }
        }

      )
    }

  }

  mapearFichero_old(ficherosRespositorio, file: File){
    let ficheroSubido = ficherosRespositorio.filter( x =>  x.nombre.toLowerCase() == file.name.replace(/\s+/g, '-').toLowerCase());
    if (ficheroSubido) {
      ficheroSubido = ficherosRespositorio[0];
    }
    // console.log('file ', file.name, 'fichero subir ', ficheroSubido.nombre);

    const indice = this.obtenerIndiceFichero(file);
    this.fileAdjuntos[indice].fichero = ficheroSubido;
    this.fileAdjuntos[indice].idFichero = ficheroSubido.id;
  }

  mapearFichero(ficherosRespositorio, file: File, hashFile: string) {
    let ficheroSubido = ficherosRespositorio
    if (ficheroSubido) {
      ficheroSubido = ficherosRespositorio.pop();
      let arrRuta = ficheroSubido.ruta.split('/');
      console.log('Ruta ', arrRuta, arrRuta[arrRuta.length - 1]);
      ficheroSubido.nombre = arrRuta[arrRuta.length - 1];
    }

    const indice = this.obtenerIndiceFicheroPorHash(hashFile);
    // console.log('file ', file.name, 'fichero subir ', ficheroSubido.nombre);
    // console.log('indice', indice);
    console.log("************************************************************");
    console.log("************************************************************");
    console.log("Fichero subido es ", ficheroSubido);
    console.log("************************************************************");
    console.log("************************************************************");

    this.fileAdjuntos[indice].fichero = ficheroSubido;
    this.fileAdjuntos[indice].idFichero = ficheroSubido?.id;
    this.fileAdjuntos[indice].nuevaImagen = true;
  }

  obtenerIndiceFichero(file) {
    const indice = this.fileAdjuntos.findIndex((elemento, indice) => {
      if (elemento?.file?.name === file?.name) {
        return true;
      }
    });
    return indice;
  }
  obtenerIndiceFicheroPorHash(hashFile:string) {
    const indice = this.fileAdjuntos.findIndex((elemento, indice) => {
      if (elemento.hash === hashFile) {
        return true;
      }
    });
    return indice;
  }


  borrarArchivo(index: number, coleccion: string = 'fileAdjuntos', conSubmit = false) {

    switch(coleccion) {
      case 'fileAdjuntos' :
        console.log('Borramos el fichero en coleccion ', coleccion, this.fileAdjuntos[index]);
        if (!conSubmit) {
          this.borrarFichero.emit(this.fileAdjuntos[index].fichero);
        } else {
          // comprobamos que sea el inicial
          if (this.sobreescrituraFichero && this.filesAdjuntosPendientesBorrado.length <= 0) {
            this.filesAdjuntosPendientesBorrado = [this.fileAdjuntos[index].fichero];
            console.log('Sobreescritura ', this.filesAdjuntosPendientesBorrado);
          } else {
            this.filesAdjuntosPendientesBorrado.push(this.fileAdjuntos[index].fichero);
          }
        }
        this.fileAdjuntos.splice(index, 1);
        break;

      case 'filesNoPermitidos' :
        this.filesNoPermitidos.splice(index, 1);
        break;

      case 'filesErrorCarga' :
        this.filesErrorCarga.splice(index, 1);
        break;

      default:
        console.warn(`Intento de borrado fichero en coleccion no identificada ${coleccion} `);
        break;
    }

  }

  cargarFicherosIniciales() {
    // console.log('Ficheros iniciales', this.ficherosIniciales);
    // TODO generalizar
    if (this.ficherosIniciales && this.ficherosIniciales.length ){
      this.ficherosIniciales.forEach((item) => {
        let fileACargar = {hash: generateId(), file: null, idFichero: item?.id, fichero: item, edicion: true, nuevaImagen: false};
        this.fileAdjuntos.push(fileACargar);

      })
    }
    if (this.sobreescrituraFichero) {
      this.hashFileSobreescribir = (this.fileAdjuntos.length>0) ? this.fileAdjuntos[0].hash: null;
    }
    // console.log(this.fileAdjuntos);
  }

  submit() {
    // console.log('submit');
    this.submitFicheroSubida.emit(this.obtenerFicherosASubir());
  }

  // alternativa a submit para acceder a ficheros
  obtenerFicherosASubir(): IFileEnvuelto[] {
    // console.log('  FICHEROS A SUBIR ', this.fileAdjuntos);
    // let fichero = this.fileAdjuntos.map(item=> item.fichero);
    // console.log('  FICHEROS A SUBIR ', fichero, this.fileAdjuntos);
    console.log('Ficheros a subir ', this.fileAdjuntos);
    console.log('Ficheros momento subida con error carga  ', this.filesErrorCarga);
    return  this.fileAdjuntos;
  }


  obtenerFicherosPendientesBorrado() {
    return this.filesAdjuntosPendientesBorrado;
  }

  comprobarNumeroDeArchivos(archivosPendientesDeAdjuntar){
    console.log('DEBUG SOBREESCRITURA: ', this.sobreescrituraFichero);
    if ( this.sobreescrituraFichero && this.maximoElementosASubir == 1) {
      console.log('DEBUG ARCHIVOS PENDIENTES ADJUNTAR: ', archivosPendientesDeAdjuntar);
      return (archivosPendientesDeAdjuntar.length > this.maximoElementosASubir) || (archivosPendientesDeAdjuntar.length + this.fileAdjuntos.length > this.maximoElementosASubir + 1);
    } else {
      return (archivosPendientesDeAdjuntar.length > this.maximoElementosASubir) || (this.fileAdjuntos.length == this.maximoElementosASubir) || (archivosPendientesDeAdjuntar.length + this.fileAdjuntos.length > this.maximoElementosASubir);
    }

  }

  comprobarArchivoValido(archivoPendienteDeValidar){
    let valido = true;
    // console.log('Comprobamos si tiene formato correcto ', archivoPendienteDeValidar);
    if(this.formatosPermitidos){
      let extensionFichero = '.'+archivoPendienteDeValidar.type.split('/')[1];
      valido = this.formatosPermitidos.find(x=> x == extensionFichero);
      // console.log(' --> Comprobamos si tiene formato correcto? ', valido);
      if (valido === undefined) this.filesNoPermitidos.push(archivoPendienteDeValidar);
      return valido;
    }
    return valido;
  }

  comprobarTamanoValido(fichero){
    let size = fichero.size;
    // console.log(size);
    // console.log(this.tamanoMaximoFichero);
    let valido = true;
    if (this.tamanoMaximoFichero || this.tamanoMaximoTotal > -1) {
      let pesoArchivosAdjuntos = 0;
      for(let item of this.archivosAdjuntos){
        pesoArchivosAdjuntos = item.size + pesoArchivosAdjuntos;
      }

      if (this.tamanoMaximoTotal > -1) {
        if((pesoArchivosAdjuntos + size) > this.tamanoMaximoTotal){
          this.tamanoMaximoExcedido = true;
        } else {
          this.tamanoMaximoExcedido = false;
        }
      }


      // Cuando existen valores de Tamaño total y Tamaño de cada fichero
      if (this.tamanoMaximoTotal > -1 && this.tamanoMaximoFichero) {
        valido =  (size < this.tamanoMaximoFichero) && (size < this.tamanoMaximoTotal) && ((pesoArchivosAdjuntos + size) < this.tamanoMaximoTotal);
      }
      // Cuando existe solo valor de Tamaño total
      if (this.tamanoMaximoTotal > -1 && !this.tamanoMaximoFichero) {
        valido = (size < this.tamanoMaximoTotal) && ((pesoArchivosAdjuntos + size) < this.tamanoMaximoTotal);
      }
      // Cuando existe solo valor de Tamaño de cada fichero
      if (!this.tamanoMaximoTotal || this.tamanoMaximoTotal === -1 && this.tamanoMaximoFichero) {
        valido = (size < this.tamanoMaximoFichero);
      }

      if (!valido) {
        this.filesErrorCarga.push(fichero);
      }
    }
    return valido;
  }


  configurarAlertaMaximosElementos(){
    if(this.maximoElementosASubir === 1){
      this.alertaMaximosElementos = `No puedes subir más de 1 archivo!`;
      this.mensajeCabecera = 'Máximo 1 archivo.';
    } else {
      this.alertaMaximosElementos = `No puedes subir más de ${this.maximoElementosASubir} archivos!`;
      this.mensajeCabecera = `Máximo ${this.maximoElementosASubir} archivos.`;
    }
    let re = /\./gi;
    console.log('FORMATOS PERMITIDOS: ', this.mensajeFormatosPermitidos);
    this.mensajeFormatosPermitidos = this.formatosPermitidos?.toString().replace(re, " ");
  }

  getRuta(ruta){
    return this.ficheroSubidaService.getRuta(ruta);
  }


  cancelarSubida(){
    this.filesNoPermitidos = [];
    this.filesErrorCarga = [];
    this.fileAdjuntos.forEach((item, i) => {
      this.borrarArchivo(i, 'fileAdjuntos');
    });
  }

  existenFicheroCargados() {
    return this.fileAdjuntos.length;
  }

  eventoBtnHacerFoto() {

    this.inputHacerFoto.nativeElement.click();
    this.inputHacerFoto.nativeElement.onchange = () => {
      let files = this.inputHacerFoto.nativeElement?.files;
      if (files) {
        // console.log('Cambio input file', this.inputHacerFoto.nativeElement.files);
        this.addArchivo(files);
      }

    }
  }

  eventoBtnSubirFichero() {
    this.inputSubirFichero.nativeElement.click();
    this.inputSubirFichero.nativeElement.onchange = () => {
      // console.log('Cambio input file');
      let files = this.inputSubirFichero.nativeElement?.files;
      if (files) {
        // console.log('Cambio input file', files);
        this.addArchivo(files);
      }
    }
  }

 obtenerTipoFichero() {
    return (this.padre?.tipoFichero) ? this.padre?.tipoFichero:  this.tipoFichero;
  }

  obtenerTipoFicheroPlural() {
    return (this.padre?.tipoFicheroPlural) ? this.padre?.tipoFicheroPlural:  this.tipoFicheroPlural;
  }
}

const generateId = () => Math.random().toString(36).substr(2, 18);
