import { IUsuario, IUsuarioEstadoActual } from "@appNeo/neoCore/interfaces/IUsuario";
import { IArbolData } from "../../demo-componentes/demo-componentes.component";
import { IFichero, IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { IEtiquetas, IProducto } from "./productos-configuracion";
import {AccionesTablaEnum} from '@appNeo/neoShared/services/tabla/tabla.service';

// export const USUARIOS_ESTADOS_DEMO: IUsuarioEstadoActual[] = [{iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Activo'},
// {iconoClase: 'icon-20 icon-ibm--restart', color: '#00D400', nombre: 'Nuevo'}]

export const PRODUCTOS_ETIQUETAS_DEMO: IEtiquetas[] = [
  {
    id: '1',
    nombre: 'Etiqueta A',
    descripcion: 'Descripcion etiqueta A'
  },
  {
    id: '2',
    nombre: 'Etiqueta B',
    descripcion: 'Descripcion etiqueta B'
  },
  {
    id: '3',
    nombre: 'Etiqueta C',
    descripcion: 'Descripcion etiqueta C'
  },
  {
    id: '4',
    nombre: 'Etiqueta A',
    descripcion: 'Descripcion etiqueta D'
  },
  {
    id: '5',
    nombre: 'Etiqueta E',
    descripcion: 'Descripcion etiqueta E'
  }
]
export const PRODUCTOS_DEMO : IProducto[]= [
    {
      id: '1',
      identificador: '1',
      activoEnTienda: true,
      imagenPrincipal: 'https://picsum.photos/40/40?random=4',
      imagenes: [],
      nombre: 'Producto A',
      descripcion: 'Descripcion producto',
      precio: 100,
      cantidadStock: 10,
      fechaEntradaAlmacen: new Date(2021, 11, 1, 10, 20),
      ubicacion: 'Almacen A',
      idEtiqueta: "'1', '2'",
      etiquetas: [PRODUCTOS_ETIQUETAS_DEMO[0], PRODUCTOS_ETIQUETAS_DEMO[1]]
    },
    {
      id: '2',
      identificador: '2',
      activoEnTienda: false,
      imagenPrincipal: 'https://picsum.photos/40/40?random=4',
      imagenes: [],
      nombre: 'Producto B',
      descripcion: 'Descripcion producto',
      precio: 200,
      cantidadStock: 5,
      fechaEntradaAlmacen: new Date(2021, 11, 1, 10, 20),
      ubicacion: 'Almacen A',
      idEtiqueta: "'3', '4'",
      etiquetas: [PRODUCTOS_ETIQUETAS_DEMO[2], PRODUCTOS_ETIQUETAS_DEMO[3]]
    }
]



export const DEMOARCHIVOS: IFicheroExtendido[] = [
    {
      id: '1',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '2',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen2.jpg',
      extension: '.jpg',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '3',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen3.pdf',
      extension: '.pdf',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '4',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    },
    {
      id: '5',
      iconDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500'},
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ],
    }
  ];

  export const DEMOARCHIVOSSUBIDA: IFichero[] = [
    {
      id: '1',
      nombre: 'imagen1.jpg',
      extension: '.jpg',
      peso: 1500,
      posicion: 1,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg'
      },
    },
    {
      id: '2',
      nombre: 'imagen2.pdf',
      extension: '.pdf',
      peso: 200,
      posicion: 3,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: ''
      }
    },
    {
      id: '3',
      nombre: 'imagen3.jpg',
      extension: '.jpg',
      peso: 1300,
      posicion: 2,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://tinypng.com/images/social/website.jpg'
      }
    },
    {
      id: '4',
      nombre: 'imagen4.jpg',
      extension: '.jpg',
      peso: 2300,
      posicion: 0,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://s03.s3c.es/imag/_v0/770x420/1/4/f/El-plnaeta-Tierra-de-cristal-iStock.jpg'
      }
    },
    {
      id: '5',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '6',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    },
    {
      id: '7',
      nombre: 'imagen5.jpg',
      extension: '.jpg',
      peso: 3300,
      posicion: 4,
      tituloSeo: 'Titulo seo ejemplo',
      textoAlternativoSeo: 'Texto alternativo ejemplo',
      imagen: {
        src: 'https://kinsta.com/es/wp-content/uploads/sites/8/2019/09/jpg-vs-jpeg.jpg'
      }
    }
  ];
