import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IBarraIzquierdaMenuItems } from '@appNeo/neoShared/helpers/interfaces/IBarraIzquierdaMenuItems';
import { NavegacionService } from '@appNeo/neoShared/services/navegacion/navegacion.service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'neo-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuItemComponent implements OnInit {

  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input('item') item: IBarraIzquierdaMenuItems;
  @Input('minivariante') minivariante: boolean;
  @Input() depth: number;
  @Output() seleccionMenuItem = new EventEmitter();

  constructor(
    public router: Router,
    private navegacionService: NavegacionService
    ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }

  }

  ngOnInit() {
    this.navegacionService.actualUrl.subscribe((url: string) => {
      if (this.item.path && url) {
        if (this.item?.submenu && this.item?.submenu.length) {
          // si alguno de los hijos coincide
          let hijo = this.item.submenu.find(hijo => url.indexOf(`/${hijo.path}`) === 0);
          this.expanded = (hijo != undefined);
        } else {
          this.expanded = url.indexOf(`/${this.item.path}`) === 0;
        }
        this.ariaExpanded = this.expanded;
      }
    });
  }

  seleccionItem(item: IBarraIzquierdaMenuItems) {
    if (!item.submenu || !item.submenu.length) {
      if ( item.path){
        this.onSeleccionMenuItem();
        this.router.navigate([item.path]);
      }
    }
    if (item.submenu && item.submenu.length) {
      this.expanded = !this.expanded;
    }
  }

  // si es url o es padre de hijo activo
  esActivo(item) {
    return item.path ? this.router.isActive(item.path, true): false;
  }

  onSeleccionMenuItem() {
    this.seleccionMenuItem.emit();
  }

}
