<div id="producto">
    <neo-notificacion #notificacion></neo-notificacion>
  
    <div class="botonera-acciones">
      <h3 class="text-h2">{{producto?.nombre}}</h3>
      <!-- botonera ver mi-cuenta -->
    </div>
    
    <div id="producto-header">
        <mat-tab-group class="no-border">
            <mat-tab>
                <ng-template mat-tab-label>
                    Información
                </ng-template>   
                
                  <!-- tab 1 -->
                <div class="row">
                    <div class="col-lg-8 col-md-7">
                        <div class="blq-card">
                            <neo-contenedor-cabecera [titulo]="'Datos generales'" [botonera]="[
                                                                {
                                                                    label: 'Cancelar',
                                                                    id: 'btn-cancelar-edicion',
                                                                    basic: true,
                                                                    color: 'primary',
                                                                    disabled: false,
                                                                    iconoClase: 'icon-20 icon-ibm--close',
                                                                    activo: (productoService.editandoProducto$ |async)
                                                                },
                                                                {
                                                                    label: 'Guardar',
                                                                    id: 'btn-guardar',
                                                                    flat: true,
                                                                    color: 'primary',
                                                                    disabled: false,
                                                                    iconoClase: 'icon-20 icon-ibm--save',
                                                                    activo: (productoService.editandoProducto$ |async)
                                                                },
                                                                {
                                                                    label: 'Editar',
                                                                    id: 'btn-editar',
                                                                    flat: true,
                                                                    color: 'primary',
                                                                    disabled: false,
                                                                    iconoClase: 'icon-20 icon-ibm--edit',
                                                                    activo: !(productoService.editandoProducto$ |async)
                                                                }
                                                            ]" (accionBoton)="onAccionContenedorCabeceraEntidad($event)">
                                <neo-notificacion #notificacionPerfilFormularioEntidad></neo-notificacion>
                                <neo-perfil #perfilUsuario [propiedades]="propiedadesPerfilUsuario" [perfilFormulario]=true
                                    [perfilAncho]="'perfil-ancho-medio'" *ngIf="!(productoService.editandoProducto$ |async)">
                                </neo-perfil>
                        
                                <neo-formulario #formulario [valoresIniciales]="producto" [camposFormulario]="camposFormulario"
                                    [formularioAncho]="'formulario-ancho-medio'" *ngIf="(productoService.editandoProducto$ |async)">
                                </neo-formulario>
                            </neo-contenedor-cabecera>
                        </div>
                        <div class="blq-card">
                            <neo-historial #historialProducto (crearItem)="accionCrearItem($event)">
                            </neo-historial>
                        </div>
                        </div>
                        <div class="col-lg-4 col-md-5">
                            <div class="blq-card">
                                <neo-imagen-principal-perfil #imagenPrincipalProducto
                                (editar)="eventoEditarFichero($event)"
                                (eliminar)="eliminarFichero($event)"
                                ></neo-imagen-principal-perfil>
                            </div>

                        <div class="blq-card">
                            <neo-drag-drop-etiquetas #etiquetasProducto></neo-drag-drop-etiquetas>
                        </div>

                    </div>
                    </div>
            </mat-tab>


            <mat-tab>
                <ng-template mat-tab-label>
                    Imágenes
                </ng-template>        
                <div class="blq-card">
                  <neo-fichero
                  [formatosPermitidos]="formatosPermitidos"
                  [subidaMultiple]="subidaMultiple"
                  [maximoElementosASubir]="maximoElementosASubir"
                  [tamanoMaximoTotal]="tamanoMaximoTotal"
                  [tamanoMaximoFichero]="tamanoMaximoFichero"
                  [vista]="'card'"
                  [visualizarDetalleItems]="true"
                  [arrayArchivos]="arrayArchivos"
                  [ordenable] = "true"
                  (borrarItems)="eliminarFichero($event)"
                  (cambiarPosicion)="peticionCambiarPosicionEjemplo($event)"
                  (preSubirArchivos)="preSubirFicheros($event)"
                  (subirArchivos)="subirFicheros($event)"
                  (editarArchivo)="eventoEditarFichero($event)"
              ></neo-fichero>
                </div>
                


            </mat-tab>
        </mat-tab-group>      
    </div>
  
    
  </div>

  
  
 