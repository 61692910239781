<mat-toolbar class="navbar" [class.active]="isNavbarShow">
  <neo-button class="mr-auto"
              [icono]="true"
              [type]="'button'"
              [iconClass]="'icon-20 icon-ibm--menu'"
              (click)="alternarBarraLateralIzquierda()"
  ></neo-button>

  <neo-button
    [color]="'primary'"
    [miniFab]="true"
    [type]="'button'"
    [clase]="'btn-outline btn-margin-left'"
    [icon]="'fullscreen'"
    (click)="alternarPantallaCompleta()"
  ></neo-button>

  <!-- <ng-container *ngIf="authService.user$ | async as usuario"> -->
  <ng-container>
    <div class="acciones-avatar" [ngClass]="{'acciones-avatar-menu-open': trigger.menuOpen}">
      <button class="lnk-avatar btn-margin-left" aria-label="Menu desplegable acciones usuario" #trigger="matMenuTrigger" [matMenuTriggerFor]="menu">

        <!--TODO:  los colores fuente y background pueden venir de preseleccion de usuario y por tanto con info obj usuario con las propiedades fgColor bgColor-->
        <ngx-avatar name="{{usuarioNombre}}" initialsSize="1" src="{{usuario?.avatar?.rutaAbsoluta}}" size="40"
          class="ngx-mi-avatar"></ngx-avatar>
        <span class="ml-5">{{usuarioNombre}}</span>
      </button>
      <mat-menu #menu="matMenu" class="mat-menu-duacode mat-menu-header-mi-cuenta">
        <button *ngFor="let accion of menuCuentaAvatar" mat-menu-item  [class]="accion.clase" [disabled]="accion.disabled" (click)="desencadenarAccion(accion.id)">
          <neo-icono
            [icono]="accion.icono"
            [iconoClase]="accion.iconoClase"
          ></neo-icono>
          <span class="ml-10">{{accion.texto}}</span>
        </button>
      </mat-menu>
    </div>

    <neo-boton-desplegable #btnAvatarDesplegableDialogo class="acciones-avatar-desplegables"
                           [referenciaElemento]="'header'"
                           [disabled] = false
                           [mostrarEnDialog]="true"
                           [proyeccionBotonPersonalizado]=true
                           [tituloDialog]="usuarioNombre"
                           [accionesBotonDesplegable]="menuCuentaAvatar">
      <button class="lnk-avatar btn-margin-left" aria-label="Menu desplegable acciones usuario" (click)="abrirDialogoBotonDesplegable()">
        <ngx-avatar name="{{usuarioNombre}}" src="{{usuario?.avatar?.rutaAbsoluta}}" size="40" class="ngx-mi-avatar">
        </ngx-avatar>
        <span class="ml-5">{{usuarioNombre}}</span>
      </button>
    </neo-boton-desplegable>
  </ng-container>

  <neo-button *ngIf="accionesDuacode"
              [color]="'accent'"
              [miniFab]="true"
              [type]="'submit'"
              [clase]="'btn-light btn-margin-left'"
              [iconClass]="'icon-20 icon-ibm--operations--record'"
              (accion)="alternarBarraLateralDerecha()"
  ></neo-button>
</mat-toolbar>
<!-- <nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header flex">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a> -->
      <!-- mobile menu bars -->
      <!-- <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="/inicio">

      </a>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="pull-left collapse-menu-icon">
        <li class="btn-header">
          <mat-icon class="sidemenu-collapse flex" (click)="callSidemenuCollapse()">menu</mat-icon>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">

        <li class="fullscreen btn-header">
          <mat-icon (click)="alternarPantallaCompleta()" class="flex nav-notification-icons">fullscreen</mat-icon>
        </li>

        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="assets/images/icons/user_default.svg" class="rounded-circle" width="32" height="32" alt="User Image">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;">
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="usuario/cuenta" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Perfil
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="cerrarSesion()">
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>
                        Cerrar Sesión
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li class="pull-right">
          <mat-icon (click)="alternarBarraLateralDerecha()" class="flex" id="btnConfiguracion">settings</mat-icon>
        </li>
      </ul>
    </div>
  </div>
</nav> -->
