import { FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { PerfilPropiedadTagEnum } from "@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum";
import { TagCampoEnum } from "@appNeo/neoShared/helpers/enums/TagCampo.enum";
import { TipoDatePickerEnum } from "@appNeo/neoShared/helpers/enums/TipoDatePicker.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";
import { IPerfilPropiedad } from "@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad";

//entidades
export interface IProducto {
    id: string;
    identificador: string;
    imagenPrincipal: any,
    activoEnTienda: boolean,
    imagenes: [],
    nombre: string;
    descripcion: string;
    precio: number;
    cantidadStock: number;
    fechaEntradaAlmacen: Date;
    ubicacion: string;
    idEtiqueta: string,
    // mapeado
    etiquetas: IEtiquetas[];
}

export interface IEtiquetas{
    id: string;
    nombre: string;
    descripcion: string;
}

export const INPUTSCREACIONENTRADA : IFormInput[] = [

  {
    tag: FormTagEnum.input,
    formControlName: 'nombre',
    label: 'Nombre',
    // maxLength: 5000,
    textoAyuda: '',
    placeholder: 'Nombre',
    type: 'text',
    obligatorio: true,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
  },
  {
    tag: FormTagEnum.boolean,
    formControlName: 'activoEnTienda',
    label: 'Visible en tienda online',
    textoAyuda: '',
    placeholder: 'Visible en tienda online',
    type: '',
    obligatorio: false,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
    tag: FormTagEnum.date,
    label: 'Fecha entrada',
    type: TipoDatePickerEnum.FechaHora,
    tipoDatePicker: TipoDatePickerEnum.FechaHora,
    placeholder: 'dd/mm/aaaa hh:mm',
    formControlName: 'fechaEntradaAlmacen',
    textoAyuda: '',
    min: new Date(1910, 8, 1),
    max: new Date(),
    obligatorio: false,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },

{
    tag: FormTagEnum.cantidad,
    label: 'Stock',
    type: '',
    min: 0,
    max: 50,
    placeholder: 'Cantidad',
    formControlName: 'cantidadStock',
    textoAyuda: 'Texto opcional de ayuda',
    obligatorio: true,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x]
},
{
  tag: FormTagEnum.importe,
  formControlName: 'precio',
  label: 'Precio',
  type: '',
  textoAyuda: 'Texto opcional de ayuda',
  obligatorio: true,
  validadores: [],
  clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
},

{
  tag: FormTagEnum.fichero,
  label: 'Imagen principal',
  type: 'file',
  placeholder: 'Imagen principal',
  formControlName: 'imagenPrincipal',
  textoAyuda: '',
  obligatorio: true,
  disabled: false,
  validadores: [],
  clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
},
{
  tag: FormTagEnum.editor,
  formControlName: 'descripcion',
  label: 'Descripcion',
  maxLength: 5000,
  textoAyuda: '',
  placeholder: 'Descripcion',
  type: 'text',
  obligatorio: true,
  validadores:[],
clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
},
];

export const PROPIEDADESPERFILPRODUCTO: IPerfilPropiedad[] = [
  {
      tagCampo: TagCampoEnum.Input,
      identificador: 'nombre',
      label: 'Nombre',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
    },
    {
      tagCampo: TagCampoEnum.Boolean,
      identificador: 'activoEnTienda',
      label: 'Visible en tienda online',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
    },
    {
      tagCampo: TagCampoEnum.Date,
      tipoDatePicker: TipoDatePickerEnum.FechaHora,
      label: 'Fecha entrada',
      identificador: 'fechaEntradaAlmacen',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
    },

  {
      tagCampo: TagCampoEnum.Cantidad,
      label: 'Stock',
      identificador: 'cantidadStock',
      unidad: 'unidades',
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x]
  },
  {
    tagCampo: TagCampoEnum.Importe,
    identificador: 'precio',
    label: 'Precio',
    unidad: '€',
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },

  // {
  //   tagCampo: TagCampoEnum.Fichero,
  //   label: 'Imagen principal',
  //   identificador: 'imagenPrincipal',
  //   clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  // },
  {
    tagCampo: TagCampoEnum.Editor,
    identificador: 'descripcion',
    label: 'Descripcion',
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  },



  ];

export const CAMPOSFORMULARIO: IFormInput[] = [    
  {
    tag: FormTagEnum.input,
    formControlName: 'nombre',
    label: 'Nombre',
    // maxLength: 5000,
    textoAyuda: '',
    placeholder: 'Nombre',
    type: 'text',
    obligatorio: true,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
  },
  {
    tag: FormTagEnum.boolean,
    formControlName: 'activoEnTienda',
    label: 'Visible en tienda online',
    textoAyuda: '',
    placeholder: 'Visible en tienda online',
    type: '',
    obligatorio: false,
    validadores:[],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
    tag: FormTagEnum.date,
    label: 'Fecha entrada',
    type: TipoDatePickerEnum.FechaHora,
    tipoDatePicker: TipoDatePickerEnum.FechaHora,
    placeholder: 'dd/mm/aaaa hh:mm',
    formControlName: 'fechaEntradaAlmacen',
    textoAyuda: '',
    min: new Date(1910, 8, 1),
    max: new Date(),
    obligatorio: false,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },

{
    tag: FormTagEnum.cantidad,
    label: 'Stock',
    type: '',
    min: 0,
    max: 50,
    placeholder: 'Cantidad',
    formControlName: 'cantidadStock',
    textoAyuda: 'Texto opcional de ayuda',
    obligatorio: true,
    validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent1x]
},
{
  tag: FormTagEnum.importe,
  formControlName: 'precio',
  label: 'Precio',
  type: '',
  textoAyuda: 'Texto opcional de ayuda',
  obligatorio: true,
  validadores: [],
  clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
},

// {
//   tag: FormTagEnum.fichero,
//   label: 'Imagen principal',
//   type: 'file',
//   placeholder: 'Imagen principal',
//   formControlName: 'imagenPrincipal',
//   textoAyuda: '',
//   obligatorio: true,
//   disabled: false,
//   validadores: [],
//   clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
// },
{
  tag: FormTagEnum.editor,
  formControlName: 'descripcion',
  label: 'Descripcion',
  maxLength: 5000,
  textoAyuda: '',
  placeholder: 'Descripcion',
  type: 'text',
  obligatorio: true,
  validadores:[],
clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
},
  ];