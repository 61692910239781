import { DOCUMENT } from '@angular/common';
import {Component, ElementRef, HostListener, Inject, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EdicionSeccionesService } from '@appNeo/neoShared/modules/edicion-secciones/edicion-secciones.service';
import { BarraIzquierdaService } from '@appNeo/neoShared/services/barra-izquierda/barra-izquierda.service';
import { NavegacionService } from '@appNeo/neoShared/services/navegacion/navegacion.service';
import { environment } from '@environments/environment';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'neo-barra-izquierda',
  templateUrl: './barra-izquierda.component.html',
  styleUrls: ['./barra-izquierda.component.scss']
})
export class BarraIzquierdaComponent implements OnInit {
  itemExpandido: boolean = false;
  subAlternarBarraIzquierda: Subscription;
  minivariante: boolean;
  menuBarra;
  menuBarraEdicionSeccion;
  precargaSeccionesEditablesMenu;
  precargaHayModulo;

  constructor(
    public elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public barraIzquierdaService: BarraIzquierdaService,
    public router: Router,
    private route: ActivatedRoute,
    private edicionSeccionesService: EdicionSeccionesService,
    private navegacionService: NavegacionService // es necesario pasar servicio a constructor para que funcione en hijo menu-item Importante
  ) {
    this.route.data.subscribe(
      response => {
        // console.log('RESPONSE BARRA IZQUIERDA COMPONENT', response);
        this.precargaSeccionesEditablesMenu = response.seccionEditables.listadoEntidad;
        this.precargaHayModulo =
          (response?.helpersService?.listadoEntidad && response?.helpersService?.listadoEntidad.length > 0) ? response?.helpersService?.listadoEntidad : [];
      }
    );
  }

  @HostListener('mouseenter', ['$event'])
  onBarraEnter(event): void {
       // si barra plegada entonces toggle
    let minivariante = !this.esBarraDesplegadaFija() && !this.esMobile();
    if (minivariante) this.barraIzquierdaService.establecerExpandido(true);
  }
  @HostListener('mouseleave', ['$event'])
  onBarraLeave(event): void {

    // si barra plegada entonces toggle
    let minivariante = !this.esBarraDesplegadaFija() && !this.esMobile();
    if (minivariante) this.barraIzquierdaService.establecerExpandido(false);
  }


  ngOnInit(): void {
    let peticiones = [
      this.barraIzquierdaService.menu()
    ];

    let hayModuloEdicion = false;
    if (this.precargaHayModulo.length) {
      let moduloEdicion = this.precargaHayModulo.find(item => item.nombre == 'edicion');
      if (moduloEdicion) {
        hayModuloEdicion = moduloEdicion.valor;
      }
    }

    if (hayModuloEdicion) {
      if (this.precargaSeccionesEditablesMenu) {
        this.menuBarraEdicionSeccion = this.barraIzquierdaService.mapearMenu(this.precargaSeccionesEditablesMenu)
      } else {
        this.edicionSeccionesService.getEdicionSeccionMenu().subscribe(menu => this.menuBarraEdicionSeccion = this.barraIzquierdaService.mapearMenu(menu));
      }     
    }
    forkJoin(peticiones).subscribe(data => {
      if (data[0]) {
        this.menuBarra = data[0];
      }
      if (data[1]){
        this.menuBarraEdicionSeccion = data[1];
      }
    })
    this.subscripcionAlternarBarraIzquierda();
  }


  subscripcionAlternarBarraIzquierda() {
    this.subAlternarBarraIzquierda = this.barraIzquierdaService.expandido$.subscribe( valor => {
      this.minivariante = !valor;
    });
  }

  cerrarBarra(): void {
    this.barraIzquierdaService.establecerExpandido(false);
  }


  esBarraDesplegadaFija() {
    const barraDesplegada = this.document.body.classList.contains('barra-desplegada');
    return barraDesplegada;
  }
  esMobile() {
    let esMobile = this.document.body.classList.contains('menu-mobile');
    return esMobile;
  }

  seleccionMenuItem() {
    if (this.esMobile()) {
      this.cerrarBarra();
    }
  }
}
