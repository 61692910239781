<div class="blq-card">
  <neo-stepper
    [orientation]="'horizontal'"
    [linear]="false"
    [iconoEdicion]="'icon-24 icon-ibm--checkmark'"
    (selectionChange)="cambioPaso($event)"
    #stepper>
    <mat-step [stepControl]="stepControl1" label="ITEM">
      <neo-formulario #formStepControl1 [camposFormulario]="configuracionFormStep1"></neo-formulario>
      <div>
        <!-- <button mat-flat-button color="primary" matStepperNext>Siguiente</button> -->
        <neo-button 
         [text]="'Siguiente'"
         [flat]="true"
         [color]="'primary'"
         (click)="siguiente()"
        ></neo-button>
      </div>
    </mat-step>
    <mat-step [stepControl]="stepControl2"  label="ITEM">
      <neo-formulario #formStepControl2 [camposFormulario]="configuracionFormStep2"></neo-formulario>
      <div>
        <neo-button 
         [text]="'Anterior'"
         [basic]="true"
         [color]="'primary'"
         (click)="anterior()"
        ></neo-button>
        <!-- <button mat-button color="primary" matStepperPrevious>Anterior</button> -->
        <!-- <button mat-button matStepperNext>Siguiente</button> -->
        <neo-button  class="mr-2" 
         [text]="'Siguiente'"
         [flat]="true"
         [color]="'primary'"
         (click)="calcularEdad()"
        ></neo-button>
        <!-- <button class="mr-2" type="button"  mat-flat-button color="primary" [disabled]="false" (click)="calcularEdad()" >Siguiente</button> -->
      </div>
    </mat-step>
    <mat-step [stepControl]="stepControl3" label="ITEM">
      <neo-formulario #formStepControl3 [camposFormulario]="configuracionFormStep3"></neo-formulario>
      <div>
        <neo-button 
        [text]="'Anterior'"
        [basic]="true"
        [color]="'primary'"
        (click)="anterior()"
       ></neo-button>
        <!-- <button mat-button color="primary" matStepperPrevious>Anterior</button> -->
        <!-- <button mat-flat-button color="primary" matStepperNext>Siguiente</button> -->
        <neo-button  class="mr-2" 
         [text]="'Siguiente'"
         [flat]="true"
         [color]="'primary'"
         (click)="siguiente()"
        ></neo-button>
      </div>
    </mat-step>
    <mat-step [stepControl]="stepControl4" label="ITEM">
      <neo-formulario #formStepControl4 [camposFormulario]="configuracionFormStep4"></neo-formulario>
      <div>
        <neo-button 
        [text]="'Anterior'"
        [basic]="true"
        [color]="'primary'"
        (click)="anterior()"
       ></neo-button>
        <!-- <button mat-button color="primary" matStepperPrevious>Anterior</button> -->
        <!-- <button mat-flat-button color="primary" matStepperNext>Siguiente</button> -->
        <neo-button  class="mr-2" 
         [text]="'Siguiente'"
         [flat]="true"
         [color]="'primary'"
         (click)="siguiente()"
        ></neo-button>
      </div>
    </mat-step>
    <mat-step label="ITEM">
      <p>Resultados: </p>
      <pre>
            {{entidad | json}}
         </pre>
      <div>
        <neo-button 
        [text]="'Anterior'"
        [basic]="true"
        [color]="'primary'"
        (click)="anterior()"
       ></neo-button>
        <!-- <button mat-button color="primary" matStepperPrevious>Anterior</button> -->
        <!-- <button mat-flat-button color="primary" (click)="stepper.reset()">Reset</button> -->
        <!-- <neo-button  class="mr-2" 
         [text]="'Reset'"
         [flat]="true"
         [color]="'primary'"
         (click)="stepper.reset()"
        ></neo-button> -->
      </div>
    </mat-step>
  </neo-stepper>
</div>
