import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  showSpinner(): void {
    this.showSpinner$.next(true);
  }

  hideSpinner(): void {
    this.showSpinner$.next(false);
  }

}
