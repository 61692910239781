import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'neo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // throw new Error('Sentry Test Error');
  }
}
