import {Injectable} from '@angular/core';
import {StorageService} from '@appNeo/neoCore/services/storage/storage.service';
import {StorageTipo} from '@appNeo/neoCore/helpers/enums/storage-tipo.enum';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';

@Injectable()
export class FiltrosService {


  constructor(
    private storageService: StorageService,
    private auxiliarService: AuxiliarService,
  ) { }

  setFiltros(key: string, filtros: any) {
    this.storageService.set(key, JSON.stringify(filtros), StorageTipo.LocalStorage);
  }

  clearFiltros(key: string) {
    this.storageService.remove(key, StorageTipo.LocalStorage);
  }

  getFiltros(key: string, filtrosDefecto: object | string = {tipoOrden: 'asc', orden: 'nombre'}): any {
    let filtros;
    if (this.storageService.get( key , StorageTipo.LocalStorage) ){
      filtros = JSON.parse(this.storageService.get( key , StorageTipo.LocalStorage));
    } else {
      filtros = (filtrosDefecto) ? filtrosDefecto : this.filtrosDefecto();
    }
    return filtros;
  }

  getFiltroBusqueda(key: string): string {
    return JSON.parse(this.storageService.get( key , StorageTipo.LocalStorage))?.['busqueda'];
  }

  filtrosDefecto() {
    return this.auxiliarService.filtrosDefecto();
  }
}
