<mat-form-field class="form-field field-select" appearance="outline">
    <mat-label>{{placeholder}}</mat-label>
    <div class="mat-select-container">
      <mat-select [(ngModel)]="opcion" (selectionChange)="nuevaOpcion.emit(opcion)">
        <mat-option *ngFor="let opcion of opciones" 
        [value]="opcion?.id">
          {{opcion?.nombre}}
        </mat-option>
      </mat-select>
      <neo-button class="btn-limpiar-filtro" *ngIf="opcion != '' && opcion != null &&  !disabled" [icono]="true"
        [iconClass]="'icon-ibm--close icon-18'" [disabled]="disabled"
        (accion)="opcion = null; nuevaOpcion.emit(opcion); $event.stopPropagation();"></neo-button>
    </div>
    <mat-icon *ngIf="icon" matSuffix>{{icon}}</mat-icon>
  </mat-form-field>
  
