import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {InputTypeEnum} from '@appNeo/neoShared/components/input/input.component';

@Component({
  selector: 'neo-input-importe [controlCampo]',
  templateUrl: './input-importe.component.html',
  styleUrls: ['./input-importe.component.scss']
})
export class InputImporteComponent {

  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() mostrarMatError = true;
  @Input() tipo = InputTypeEnum.text;
  @Input() placeholder = '000.00';


  constructor() { }

  public formatearImporte(valor) {
      this.controlCampo.setValue(parseFloat(valor).toFixed(2));
  }

}
