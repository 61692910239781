import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, forkJoin, from, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { environment } from 'src/environments/environment';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { IUsuario, IUsuarioEstadoActual } from '@appNeo/neoCore/interfaces/IUsuario';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { USUARIOS_DEMO } from '@appNeo/neoShared/pages/layoutUsuarios/usuarios/layout-usuario-data';
import { AuthService } from '../auth/auth.service';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  url: string = environment.api_base_url_backoffice;
  public editandoUsuario$ = new BehaviorSubject<boolean>(false);

  // CONFIG
  urlUsuarios = environment.api_base_url + '/usuarios';
  urlUsuariosEstado = environment.api_base_url + '/usuariosEstado';
  public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
  public usuario$: BehaviorSubject<IUsuario> = new BehaviorSubject<IUsuario>(null);
  public usuarios$: BehaviorSubject<IUsuario> = new BehaviorSubject<IUsuario>(null);
  public totalUsuarios$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService,
    private authService: AuthService,
    private router: Router
  ) { }

  set editandoUsuario(flag: boolean) {
    this.editandoUsuario$.next(flag);
  }

  set usuario(usuario: IUsuario) {
    this.usuario$.next(usuario);
  }

  editarPerfilUsuario(params): Observable<boolean> {

    return this.http.patch<IRespApi>(`${this.url}/usuario`, params).pipe(
      tap((resp) => Object.assign([], { ...resp.data, ...params, token: resp.token })),
      tap((resp) => this.authService.user$.next(Object.assign([], { ...this.authService.user$.value, ...params }))),
      map((resp: IRespApi) => {
        if (!resp.error) {
          const user = { ...resp.data, token: resp.token };
          console.log(resp);
          return user;
        }
        return EMPTY;
      })
    );
  }

  cambiarPasswordUsuario(params): Observable<boolean> {
    return this.http.post<IRespApi>(`${this.url}/usuarios/password`, params).pipe(
      map((resp: IRespApi) => {
        if (!resp.error) {
          const user = { ...resp.data, token: resp.token };
          console.log(resp);
          return user;
        }
        return EMPTY;
      })
    );
  }

  subirFicheros(request: any) {
    return this.http.post<IRespApi>(`${this.url}/usuarios/fichero`, request).pipe(
      map((resp: IRespApi) => resp.data)
      // catchError( (err: IRespApi) => {
      //   return err;
      // })
    );
  }

  eliminarFotoUsuario() {
    return this.http.delete<IRespApi>(`${this.url}/usuarios/fichero`).pipe(
      map((resp: IRespApi) => {
        return of(true);
      })
    );
  }

  getPerfilUsuario(): Observable<any> {
    return this.http.get<IRespApi>(`${this.url}/perfil`).pipe(
      map((resp: IRespApi) => {
        if (!resp.error) {
          const user = { ...resp.data, token: resp.token };
          console.log(resp);
          return resp;
        }
        return EMPTY;
      })
    );
  }

  // CRUD
  getAll(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IUsuario[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'nombre', tipoOrden: 'asc' };
    }
    let request = `${this.urlUsuarios}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    console.log('URL USUARIOS ', request);
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => { this.usuarios$.next(data.data); }),
      tap((data: IRespApi) => { this.totalUsuarios$.next(data.total); }),
      map((data: IRespApi) => this.mapearEntidades(data['data'])));
  }

  get(id): Observable<IUsuario> {
    return this.http.get(`${this.urlUsuarios}/${id}`).pipe(
      map((data: IRespApi) => data['data'])
    );
  }

  rolesUsuario() {
    return this.http.get(`${this.urlUsuarios}/rolesUsuario`).pipe(
      map( (data: IRespApi) => data['data']
      )
    );
  }

  permisosUsuario() {
    return this.http.get(`${this.urlUsuarios}/permisosUsuario`).pipe(
      tap(data => console.log(data)), 
      map( (data: IRespApi) => data['data']
      )
    );
  }

  mapearEntidades(listadoEntidad: IUsuario[]) {
    if (listadoEntidad.length) {
    }
    return listadoEntidad;
  }

  getEstados(): Observable<IUsuarioEstadoActual[]> {
    return this.http.get(`${this.urlUsuariosEstado}`).pipe(
      map((data: IRespApi) => data['data'])
    );
  }

  create(request: any): Observable<any> {
    return this.http.post<IRespApi>(`${this.urlUsuarios}`, request).pipe(
      tap(() => USUARIOS_DEMO.push({ ...request, idUsuario: USUARIOS_DEMO.length + 1 })),
      tap(() => console.log(USUARIOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateUsuario');
      })
    );
  }

  update(id: string, request: any): Observable<any> {
    let idUsuario = USUARIOS_DEMO.findIndex(usuario => usuario.id === id);
    USUARIOS_DEMO[idUsuario] = Object.assign([], { ...request, id });
    return this.http.patch<IRespApi>(`${this.urlUsuarios}/${id}`, request).pipe(
      tap(() => this.usuario$.next(USUARIOS_DEMO[idUsuario])),
      tap(() => console.log(USUARIOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateUsuario');
      })
    );
  }

  delete(id: string): Observable<any> {
    let indiceUsuarioEliminar = USUARIOS_DEMO.findIndex(usuario => usuario.id === id);
    console.log(id, USUARIOS_DEMO, indiceUsuarioEliminar);

    if (indiceUsuarioEliminar >= 0) {
      return this.http.delete(`${this.urlUsuarios}/${id}`).pipe(
        tap(() => USUARIOS_DEMO.splice(1, indiceUsuarioEliminar)),
        tap(() => console.log(USUARIOS_DEMO)),
        map((resp: IRespApi) => {
          return resp.data;
        }),
        catchError((err: IRespApi) => {
          console.log(err);
          return this.handleError(err);
        })
      );
    } else {
      return throwError(() => new Error('No existe elemento'));
    }
  }

  /****************************/
  /* FICHEROS                */
  /*****************************/
  subirFichero(ficheros: File[], idObjeto = 1): Observable<any> {
    const formData: FormData = new FormData();
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++)
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
    }
    return this.http.post(`${this.urlUsuarios}/${idObjeto}/ficheros`, formData).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return throwError(() => new Error(err.mensaje));
      })
    );
  }

  getFicheros(id: string) {
    return this.http.get(`${this.urlUsuarios}/${id}/ficheros`).pipe(
      map((data: IRespApi) => data));
  }

  getFichero(id: string) {
    return this.http.get(`${environment.api_base_url}/ficheros/12`);
  }

  eliminarFichero(id: string) {
    return this.http.delete(`${environment.api_base_url}/ficheros/${id}`).pipe(
      map((data: IRespApi) => data));
  }


  /************************************ 
   * Funciones apoyo                  *
  *************************************/
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUsuario> {
    const id = route.params.id;
    return this.get(id).pipe(
      tap((data: IRespApi) => console),
      catchError((err: IRespApi) => {
        let ruta_padre = environment.adminDemo ? environment.ruta_padre_admin_demo : environment.ruta_padre_admin;
        this.router.navigate([ruta_padre + '/usuarios'])
        console.log('Capturamos error resolve Usuario ', err);
        return this.handleError(err, 'Resolve UsuarioService');
      })
    )
  }
  /********************************/
  /* ROLES Y PERMISOS            */
  /********************************/
  lecturaPermisosRolCron(): Observable<void> {
    return timer(0, environment.time_interval_cron_store_auth).pipe(
      // restart counter on every click
      switchMap(
        () =>{ 
          if (environment.activo_store_auth && environment.activo_cron_store_auth) {
            // console.log('--------------------------');
            // console.log('**************************');
            // console.log('CRON lectura roles y permisos para actualizar auth store del usuario en sesion');
            // console.log('**************************');
            // console.log('**************************');
            return forkJoin([
              this.permisosUsuario(),
              this.rolesUsuario()
            ]).pipe(
              map(res => {
                // console.log('Respuesta lectura Enpoint permisos/rol');
                // console.log(res);
                // let permisos = new Object();
                // let roles = [];
                this.authService.actualizarStoreAuth(res[0], res[1]);
  
                // console.log('**************************');
                // console.log('**************************');
                // console.log(' CRON lectura roles y permisos para actualizar auth store del usuario en sesion');
                // console.log('**************************');
                // console.log('--------------------------');
                })
            );
          } else {
            return ;
          }
          
        }
      )
    )
  }

  /********************************/
  /* MANEJADOR ERRORES            */
  /********************************/
  private handleError(err: IRespApi, idRequest = '') {
    // dialog específico
    if (err.codigoError === 422) {
      err.request = idRequest;
      this.errorApi$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if (err.codigoError === 403) {
      mensaje = `${err.mensaje}: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404) {
      let avisos = '';
      if (err.data) {
        if (typeof err.data === 'string') {
          avisos = ' ' + err.data + ' ';
        } else {
          err.data.forEach(element => {
            if (element.id === 999) {
              avisos = ' ' + element.mensaje + ' ';
            }
          });
        }
      }
      mensaje = err?.mensaje + avisos;
    }

    if (err.codigoError === 500) {
      let avisos = '';
      if (err.data) {
        err.data.forEach(element => {
          if (element.id === 999) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = err?.mensaje + avisos;
    }
    return throwError({ 'error': true, 'data': err.data, mensaje });
  }
}
