import { Injectable } from '@angular/core';
import { Query, coerceArray } from '@datorama/akita';
import { AuthStore, AuthState, Permissions, RolesEnum } from './auth.store';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {

  // SOLUCION PARA EVITAR CONSULTA DATOS PERMISOS ANTES DE QUE ESTEN CARGADOS, subs a update store
  public permisosCargados$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!environment.activo_store_auth) ;

  // QUERYS personalizadas
  esAdministrador$ = this.select(state => {
    return !!state.permissions.includes(Permissions.ADMINISTRADOR);
  });

  // esCocinero$ = this.select(state => {
  //  return !![RolesEnum.MKV_C, RolesEnum.MKV_D, RolesEnum.MKV_T].some((current) => {
  //     return !!state.roles.includes(current);
  //   });
  // });

  constructor(protected store: AuthStore) {
    super(store);
  }

  hasPermission(
    permissions: Permissions[] | Permissions,
    user?: any
  ): Observable<boolean> {
    const asArray = coerceArray(permissions);
    // console.log('Directiva tiene este permiso? ', permissions, asArray);
    return this.select((state) => state.permissions).pipe(
      map((userPermissions) => {
        // console.log('Usuario tiene el permiso de ', userPermissions);
        return asArray.every((current) => {
          return userPermissions.includes(current);
        });
      })
    );
  }

  hasAnyPermission(
    permissions: Permissions[] | Permissions,
    user?: any
  ): Observable<boolean> {
    const asArray = coerceArray(permissions);
    // console.log('Directiva tiene este permiso? ', permissions, asArray);

    return this.select((state) => state.permissions).pipe(
      map((userPermissions) => {
        // console.log('Usuario tiene el permiso de ', userPermissions);
        return asArray.some((current) => {
          return userPermissions.includes(current);
        });
      })
    );
  }

  hasRol(
    roles: RolesEnum[] | RolesEnum,
    user?: any
  ): Observable<boolean> {
    const asArray = coerceArray(roles);
    // console.log('Directiva tiene este rol? ', roles, asArray);

    return this.select((state) => state.roles).pipe(
      map((userRoles) => {
        // console.log('Usuario tiene el rol de ', userRoles);
        return asArray.some((current) => {
          return userRoles.includes(current);
        });
      })
    );
  }


  get _RolesEnum() {
    return RolesEnum;
   }
  
  get Permissions() {
    return Permissions;
  }
}
