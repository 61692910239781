import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import { Filtros } from '@appNeo/neoCore/helpers/enums/filtros.enum';
import {AccionesDialogComponent} from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import {FormularioComponent} from '@appNeo/neoShared/components/formulario/formulario.component';
import {NotificacionComponent} from '@appNeo/neoShared/components/notificacion/notificacion.component';
import {TablaComponent} from '@appNeo/neoShared/components/tabla/tabla/tabla.component';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import {DialogActionEnum} from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import {IconEnum} from '@appNeo/neoShared/helpers/enums/Icon.enum';
import {NotificacionEnum} from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import {ClasesFormularioAnchoEnum, IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {Paginador} from '@appNeo/neoShared/models/Paginador/Paginador';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {BotonDesplegableService, IAccionesBotonDesplegable} from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import {FormularioFiltrosTablaService} from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IColumnaTabla,
  TablaService,
  TipoColumnaEditableEnum,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';
import {BehaviorSubject, Subscription, tap} from 'rxjs';
import {ProductoService} from '../producto.service';
import {INPUTSCREACIONENTRADA, IProducto} from './productos-configuracion';

@Component({
  selector: 'neo-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss'],
  providers: [TablaService, FormularioService, FormularioFiltrosTablaService, BotonDesplegableService]
})
export class ProductosComponent implements OnInit, OnDestroy, AfterViewInit {

  producto: IProducto;
  idProducto: string;
  tablaSub: Subscription;
  subEntidadPrincipal: Subscription;
  subRequestFormulario: Subscription;
  subErrorApi: Subscription;
  errorApi$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  grabando:boolean = false;
  formularioConCambios: boolean = false;
  @ViewChild('notificacion') notificacion: NotificacionComponent;
  /************************************************************************** */
  // configuracion necesaria para tabla genérica entidad
  /*************************************************************************** */
  @ViewChild('tablaProductos') tablaProductos: TablaComponent;
  identificadorFiltro = Filtros.Productos;
  columnasFiltro = ['nombre', 'descripcion'];
  valoresInicialesFiltradoTabla = {};
  // valoresInicialesFiltradoTabla = {
  //   idCategoria: ['1'],
  //   idSubCategoria: '1',
  //   fechaPublicacion: '2021-09-22T22:00:10.000Z',
  //   fechaCortaPublicacion: '',
  //   horaPublicacion: '',
  //   idIdioma: ['1' , '2', '3'],
  //   idEstado: ['1', '2'],
  // };
  accionesMultiplesDesplegableTablaDemo: IAccionesBotonDesplegable[] =  [
    {id: 'vaciar_vaciarAlamacen', iconoClase: '', texto: 'Vaciar almacen'},
    {id: 'rellenar_rellenarAlmacen', iconoClase: '', texto: 'Rellenar almacen'},
    {id: 'activar_productos', iconoClase: '', texto: 'Publicar en tienda'},
    {id: 'desactivar_productos', iconoClase: '', texto: 'Ocultar en tienda'}

  ];
  // , classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg
  paginator = new Paginator();
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.online;
  
  tablaOnline: boolean = false;
  columnas: IColumnaTabla[] = [
    {key: 'select', nombre: '', tipo: TipoColumnaTablaEnum.Selector, ordenacion: false},
    {key: 'imagenPrincipal', nombre: 'Imagen', tipo: TipoColumnaTablaEnum.Imagen, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd},
    {key: 'nombre', nombre: 'Nombre', tipo: TipoColumnaTablaEnum.Texto, ordenacion: true},
    {key: 'activoEnTienda', nombre: 'Activo tienda', tipo: TipoColumnaTablaEnum.Boolean, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm},
    {key: 'precioEditable', nombre: 'Precio', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, unidad: '€', classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm},
    {key: 'cantidadStockEditable', nombre: 'stock', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd},
    // {key: 'fechaEntradaAlmacen', nombre: 'Fecha recep.', tipo: TipoColumnaTablaEnum.FechaHora, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'acciones', nombre: 'Acciones', tipo: TipoColumnaTablaEnum.Acciones, ordenacion: false}
  ];

  inputsFiltros: IFormInput[] = [];


  /***************************************************************** */
  // configuracion necesaria para edicion
  /***************************************************************** */
  refDialogFormulario;
  @ViewChild('dialogoFormulario') dialogoFormulario: TemplateRef<any>;
  @ViewChild('accionDialogFormulario') accionDialogoFormulario: AccionesDialogComponent;
  @ViewChild('formulario') formulario: FormularioComponent;
  // congiguracion layout formulario
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  inputsCreacionEntrada: IFormInput[] = INPUTSCREACIONENTRADA;

  // Eliminacion
  @ViewChild('dialogoConfirmarEliminar') dialogoConfirmarEliminar: TemplateRef<any>;
  @ViewChild('accionDialogoConfirmarEliminar') accionDialogoConfirmarEliminar: AccionesDialogComponent;

  constructor(
    public tablaService: TablaService,
    // private filtrosService: FiltrosService,
    private filtrosTablaService: FormularioFiltrosTablaService,
    private formularioService: FormularioService,
    private productoService: ProductoService,
    public auxiliarService: AuxiliarService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.tablaOnline = this.tipoPaginador === TipoPaginadorEnum.online;
  }

  ngOnInit(): void {
    this.subscriptionEntidadPrincipal();
    this.establecerColumnasTabla();
  }

  ngAfterViewInit(): void {
    this.subscriptionErrorApi();
  }
  ngOnDestroy() {
    if (this.subEntidadPrincipal) {
      this.subEntidadPrincipal.unsubscribe();
    }

    if (this.tablaSub) {
      this.tablaSub.unsubscribe();
    }

    if (this.subRequestFormulario) {
      this.subRequestFormulario.unsubscribe();
    }

    if (this.subErrorApi) {
      this.subErrorApi.unsubscribe();
    }

    if (this.errorApi){
      this.errorApi.unsubscribe();
    }


  }

  cargarTabla() {
    // let filtros = this.filtrosService.getFiltros(this.identificadorFiltro, {});
    // this.paginator._numeroElementosPagina = (this.paginator._numeroElementosPagina && this.paginator._numeroElementosPagina!== null)
    //   ? this.paginator._numeroElementosPagina
    //   : environment.numeroElementosPaginadorDefault;
    // this.paginator._pagina = 0;
    this.tablaSub = this.productoService.getAll( this.tipoPaginador, this.paginator, {} ).subscribe( data => {
      console.log('Refresh data ',data);
      this.tablaService.data = new MatTableDataSource(this.mapearDataTabla(data));
      this.tablaService.total = data.length;
    })
  }

  mapearDataTabla(data) {
    let dataMapeadas = data;
    if (data && data.length) {
      dataMapeadas = data.map( objeto => {
        // VALIDAR SI CADA REGISTRO ES EDITABLE O BORRABLE.
        let accionesColumnaTabla: IAccionRowTabla [] = [
          {
            idAccion: AccionesTablaEnum.Editar,
            tooltip: 'Editar producto',
            icono: true,
            iconClass: 'icon-20 icon-ibm--edit',
            disabled: false,
          },
          {
            idAccion: AccionesTablaEnum.Eliminar,
            tooltip: 'Eliminar producto',
            icono: true,
            iconClass: 'icon-20 icon-ibm--trash-can text-danger',
            disabled: false,
          },
        ];
        objeto['acciones'] = accionesColumnaTabla;
        objeto['cantidadStockEditable'] =  {
          //nombre: 'Lorem ipsum este es el texto muy lago que no es asi ',
          nombre: objeto.cantidadStock,
          tipo: TipoColumnaEditableEnum.Cantidad,
        };
        console.log('------> Precio ', objeto.precio);
        objeto['precioEditable'] =  {
          nombre: Math.floor( objeto.precio ),
          // nombre: `${loremIpsum.generateWords(300)}`,
          // tipo: TipoColumnaEditableEnum.Input,
          // tipo: TipoColumnaEditableEnum.Cantidad,
          tipo: TipoColumnaEditableEnum.Importe,
          // tipo: TipoColumnaEditableEnum.TextArea,
        };
        // objeto['cantidadStock'] =  {
        //   nombre: Math.floor( objeto.cantidadStock ),
        //   // nombre: `${loremIpsum.generateWords(300)}`,
        //   // tipo: TipoColumnaEditableEnum.Input,
        //   tipo: TipoColumnaEditableEnum.Cantidad,
        //   // tipo: TipoColumnaEditableEnum.Importe,
        //   // tipo: TipoColumnaEditableEnum.TextArea,
        // };
        return objeto;
      });
    }
    return dataMapeadas;
  }

  //TODO IMPLEMENTAR TRAS INTEGRACIÓN CON API
  aplicarFiltrosTablaOnline(entidadFiltrado: object) {
    this.paginator._pagina = 0;
    console.log('OBJETO A FILTRAR: ', entidadFiltrado);
  }

  aplicarAccionMultipleSeleccion(dataAccionSeleccion: any){
    console.log('Accion multiple y elementos seleccionados: ', dataAccionSeleccion);
      switch(dataAccionSeleccion?.accion) {
        case 'vaciar_vaciarAlamacen':
          this.vaciarProductoEnAlmacen(dataAccionSeleccion?.seleccion)
        break;
        case 'rellenar_rellenarAlmacen':
          this.rellenarProductoEnAlmacen(dataAccionSeleccion?.seleccion);
        break;
        case 'activar_productos':
          this.activarProductoTienda(dataAccionSeleccion?.seleccion);
        case 'desactivar_productos':
          this.desactivar_productos(dataAccionSeleccion?.seleccion);
        break;
      }
  }

  aplicarAccionRow(accionRow: [object, IAccionRowTabla]) {
    // let accion = [];
    // accionRow.length === 2 ? accion = accionRow [1] : accion = accionRow;
    // this.accionEditar(accionRow[1]);
    //
    switch (accionRow[1].idAccion) {
      case AccionesTablaEnum.Editar:
        this.accionEditar(accionRow[0]);
        break;
      case AccionesTablaEnum.Eliminar:
        this.accionEliminar(accionRow[0]);
        break;
    }
  }


  abrirDialogo(dialog: DialogActionEnum, entity?): void {
    switch (dialog) {
      case DialogActionEnum.Create:
       // cargamos en servicio formulario los inputs correspondientes
       this.formularioService.inputs = this.inputsCreacionEntrada;
       //  this.formulario.formValueReset = true;
       this.refDialogFormulario = this.dialog.open(this.dialogoFormulario, {disableClose: true});
       this.formularioConCambios = this.formulario?.formValueChanges;
       this.formulario?.reset({});
       break;

       case DialogActionEnum.Delete:
       this.refDialogFormulario = this.dialog.open(this.dialogoConfirmarEliminar, {disableClose: true});
       break;
    }
 }

  accionEditar(entidad) {
    console.log(entidad);
    this.router.navigate([environment.ruta_padre_admin_demo + '/productos', '1']);
  }

  accionEliminar(entidad) {
    this.idProducto = entidad.id;
    this.producto = entidad;
    this.abrirDialogo(DialogActionEnum.Delete);
    console.log('Eliminar fila ', entidad);

  }

  accionDescargarPdf(event){
    console.log('Descargar pdf');
  }

  subscriptionEntidadPrincipal() {
    //  En caso de entidades releacionadas con una entidad principal ej: departamentosProductoSeleccionado
    // this.subEntidadPrincipal = this.productosService.producto$.subscribe( entidad =>  {
    //     if (entidad) {
    //         this.entidad = entidad;
    //         this.cargarTabla();
    //     }
    // });

    this.cargarTabla();
  }


  establecerColumnasTabla() {
    // TABLA
    let columnasSinPermiso = [];
    this.tablaService.columnas = this.columnas.filter(columna => !columnasSinPermiso.includes(columna.key));

    // FILTROS
    // valores combos filtros
    // if (this.auxiliarService.busquedaPropiedad(this.inputsFiltros, 'formControlName', 'idEstado')) {
    //   this.auxiliarService.busquedaPropiedad(this.inputsFiltros, 'formControlName', 'idEstado').datos = USUARIOS_ESTADOS_DEMO;
    // }
    this.filtrosTablaService.inputsForm = this.inputsFiltros;
  }

  vaciarProductoEnAlmacen(seleccion: IProducto[] | IProducto){
    console.log(seleccion, 'vaciar')
    this.showResult('Productos creado con éxito!', 'ok', DialogActionEnum.Create)
  }
  rellenarProductoEnAlmacen(seleccion: IProducto[] | IProducto){
    console.log(seleccion, 'rellenar')
  }
  activarProductoTienda(seleccion: IProducto[] | IProducto){
    console.log(seleccion, 'activar')
  }
  desactivar_productos(seleccion: IProducto[] | IProducto){
    console.log(seleccion, 'desactivar')
  }

  submitAccionFormulario(event){
    console.log('Crear', event);
    this.grabando =  true;
    let objeto = this.formulario.validaCampos( false );
    if (objeto &&  objeto.formulario && objeto.formulario.valid) {
      this.formulario.camposFichero.forEach(campo => {
        this.formulario.ficheros.push({id: campo.nombreCampo, ficheros: campo.storeFicheros});
      });
      console.log('Valido');
      this.crear(objeto.valores);
    } else {
      this.grabando = false;
      this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
      return;
    }

  }

  submitAccionDialogoConfirmarEliminar(event) {
    if (event.id == 'btn-confirmar') {
      this.eliminar(this.idProducto);
    }else{
      this.idProducto = null;
      this.producto = null;
    }
    this.grabando =  true;

  }
  accionesCerrarFormulario() {}

  // Enlace APi
  crear(parametrosRequest) {
    //  let parEntidad = [-- keys request a filtrar --- en caso de diferentes peticiones---];
    //  let parametrosEntidad: any = this.auxiliarService.filtrarParametros(parametrosRequest, parEntidad);
    console.log('Creando...');
    this.subRequestFormulario = this.productoService.create(parametrosRequest).pipe(
      tap(x=> this.idProducto = x)
    ).subscribe({
      next: (v) => console.log(v),
      error: (e) => this.manejarErrorFormularioCreacion(e, (this.idProducto) ? 'ficheros' : 'crear'),
      complete: () => this.showResult('Producto creado con éxito!', 'ok', DialogActionEnum.Create)
    });
  }

  eliminar(parametrosRequest) {
    console.log('Eliminando...', this.idProducto);
    this.subRequestFormulario = this.productoService.delete(parametrosRequest).subscribe({
      next: (v) => console.log(v),
      error: (e) =>  this.showResult(`Se ha producido un error en el proceso de eliminación producto. ${e}`, 'ko', DialogActionEnum.Delete),
      complete: () => this.showResult('Producto eliminado con éxito!', 'ok', DialogActionEnum.Delete)
    });
  }

  manejarErrorFormularioCreacion(errorData, peticion?: 'crear' | 'ficheros' ) {
    console.log('Error ', errorData);
    this.grabando = false;
     let avisoApi = '';

     if ( errorData?.data ) {
       if (typeof errorData.data === 'string') {
         avisoApi = ': ' + errorData.data + ' ';
       }else{
         errorData?.data.forEach(element => {
           if ( element.id === 999 ) {
             avisoApi = ': ' + element.mensaje;
             return;
           }
         });
       }
     }
     // console.log(`Aviso API  [${ avisoApi }]`, errorData?.err?.request);
     if (this.idProducto) {
       this.refDialogFormulario.close();
       this.showResult(`Se ha producido un error en el proceso de subida de ficheros en la creación de producto ${ avisoApi }.`, 'ko');
       this.showResult('producto creado con éxito!', 'ok', DialogActionEnum.Create);
     } else {
       this.showResult(`Se ha producido un error en el proceso de creación producto ${ avisoApi }.`, 'ko', DialogActionEnum.Create);
     }
   }


   showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    if (status === 'ok') {
      this.idProducto= null;
      this.dialog.closeAll();
      this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
    } else {
      switch (dialogAction) {
        case DialogActionEnum.Create:
         this.accionDialogoFormulario?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        case DialogActionEnum.Delete:
          this.accionDialogoConfirmarEliminar?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        default:
            this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la subída de los ficheros', message, true, IconEnum.Error);
      }
    }
    this.cargarTabla();
  }



  // errorApi
  set errorApi(data) {
    this.errorApi$.next(data);
  }
  subscriptionErrorApi() {
    // TODO sub a errorApi entidad princiipal
    this.subErrorApi = this.productoService.errorApi$.subscribe(data => {
      // this.errorApi = data;
      // console.log('ERROR API ES ', data);
      // if(this.requisitosProyecto)
      //   this.requisitosProyecto.errorApi = data;
      // if(this.conceptosProyecto)
      //   this.conceptosProyecto.errorApi = data;
      // if(this.analisisFuncionalProyecto)
      //   this.analisisFuncionalProyecto.errorApi = data;
    } );
  }


}


