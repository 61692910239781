import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { HistorialComponent } from '@appNeo/neoShared/components/historial/historial/historial.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { FormatosFicherosEnum } from '@appNeo/neoShared/helpers/enums/FormatosFicheros.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { ITEMS_HISTORIAL } from '../../demo-componentes/demo-componentes-data';
import { DragDropEtiquetasComponent } from '../components/drag-drop-etiquetas/drag-drop-etiquetas.component';
import { ImagenPrincipalPerfilComponent } from '../../../components/imagen-principal-perfil/imagen-principal-perfil.component';
import { ProductoService } from '../producto.service';
import { DEMOARCHIVOS, PRODUCTOS_ETIQUETAS_DEMO } from '../productos/layout-productos-data';
import { CAMPOSFORMULARIO, IProducto, PROPIEDADESPERFILPRODUCTO } from '../productos/productos-configuracion';

@Component({
  selector: 'neo-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
  providers: [FormularioService]
})
export class ProductoComponent implements OnInit, AfterViewInit, OnDestroy {

  urlPadrePrincipal: string = '/productos';
  codigo: string;
  producto: IProducto;
  subErrorApi: Subscription;
  subscriptionPermisoEdicion: Subscription;
  subEntidad: Subscription;
  subRequestFormulario: Subscription;
  cargando: boolean = true;
  grabando: boolean = false;
  /***************************************************************** */
  // configuracion necesaria para perfiles
  /***************************************************************** */
  propiedadesPerfilUsuario: IPerfilPropiedad[] = PROPIEDADESPERFILPRODUCTO;
  
  /***************************************************************** */
  // configuracion necesaria para edicion
  /***************************************************************** */
  @ViewChild('notificacionPerfilFormularioEntidad') notificacionPerfilFormularioEntidad: NotificacionComponent;
  @ViewChild('formulario') formulario: FormularioComponent;
  camposFormulario: IFormInput[] = CAMPOSFORMULARIO;
    
  // COMPONENTESTE SECUNDARIOS
  @ViewChild('etiquetasProducto') etiquetasProducto: DragDropEtiquetasComponent;

  @ViewChild('historialProducto') historialProducto: HistorialComponent;
  items_historial: any;

  @ViewChild('imagenPrincipalProducto') imagenPrincipalProducto: ImagenPrincipalPerfilComponent;
  

  formatosPermitidos = [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg, FormatosFicherosEnum.pdf];;
  subidaMultiple= false;
  maximoElementosASubir= 10;
  tamanoMaximoTotal= 1000000000000000000;
  tamanoMaximoFichero = 200000000000000000;
  arrayArchivos: IFicheroExtendido[] = DEMOARCHIVOS;



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public productoService: ProductoService
  ) { 
    
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe ( ( paramMap ) => {
      if ( paramMap.has('id') && paramMap.get('id') ) {
        this.codigo = paramMap.get('id');
        this.route.data.subscribe(
          response => {
            this.producto = response.producto;
            // mapeo del objeto con la carga de todas las entidades relacionadas get(id)
            this.productoService.producto = this.producto;
            this.cargando = false;            
            this.subscriptionEntidad();
          }
        );
      } else {
        this.router.navigate([ environment.ruta_padre_admin_demo + this.urlPadrePrincipal ] );
      }
    });
  }

  ngOnDestroy(): void {

    if (this.subRequestFormulario) {
      this.subRequestFormulario.unsubscribe();
    }
    if (this.subEntidad){
      this.subEntidad.unsubscribe();
    }
    if (this.subErrorApi) {
      this.subErrorApi.unsubscribe();
    }
    // if (this.subscriptionPermisoEdicion) {
    //   this.subscriptionPermisoEdicion.unsubscribe();
    // }
  }

  ngAfterViewInit(): void {  
    this.cargarDatos();
    this.subscriptionErrorApi();        
  }

  subscriptionEntidad() {
    // documentacion propuesta activa
    this.subEntidad = this.productoService.producto$.subscribe( producto => {
      console.log(' SUB ENTIDAD SERVICIO ', producto);
      if ( producto ) {
        this.producto = producto;           
        // cargando datos del perfil producto
        this.propiedadesPerfilUsuario.map(propiedad => propiedad.valor = (this.producto.hasOwnProperty(propiedad.identificador)) ? this.producto[propiedad.identificador] : '');
        // let propiedad_idProyectoTipo = this.auxiliarService.busquedaPropiedad(this.propiedadesPerfilUsuario, 'identificador', 'idProyectoTipo');
        // if (propiedad_idProyectoTipo) {
        //   propiedad_idProyectoTipo.valor = `${this.producto.proyectoTipo.nombre}`;
        // }    
        this.cargarDatos();
      }
    });
  }

  cargarDatos() {
    setTimeout(()=>{
      if (this.etiquetasProducto) {
        let etiquetasNoAplicadas = PRODUCTOS_ETIQUETAS_DEMO.filter(item => !this.producto.etiquetas.includes(item))
        this.etiquetasProducto.establecer(etiquetasNoAplicadas, this.producto.etiquetas);
      }  
  
      if (this.historialProducto) {
        this.historialProducto.establecerHistorial(ITEMS_HISTORIAL);
      }
  
      if (this.imagenPrincipalProducto) {
        this.imagenPrincipalProducto.establecer(this.arrayArchivos[0]);
        
      }
    })
  }

  // edicion principal
  onAccionContenedorCabeceraEntidad(evento) {
    if (evento.id === 'btn-editar') {
      this.editar();
    }

    if( evento.id === 'btn-cancelar-edicion') {
      this.notificacionPerfilFormularioEntidad.eliminarNotificaciones();
      this.productoService.editandoProducto = false;
    }

    if (evento.id === 'btn-guardar') {
      this.grabando = true;
      let objeto = this.formulario.validaCampos( false );
      if (objeto &&  objeto.formulario && objeto.formulario.valid) {
        this.actualizar(objeto.valores);
      } else {
        this.grabando = false;
        this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
        return;
      }
    }
  }

  editar() {
    this.productoService.editandoProducto = true;
  }

  // Enlace APi
  actualizar(parametrosRequest) {
    //  let parEntidad = [-- keys request a filtrar --- en caso de diferentes peticiones---];
    //  let parametrosEntidad: any = this.auxiliarService.filtrarParametros(parametrosRequest, parEntidad);
    console.log('Actualizando...', parametrosRequest);
    this.subRequestFormulario = this.productoService.update(this.producto.id,parametrosRequest).subscribe({
      next: (v) => console.log(v),
      error: (e) =>  this.showResult(`Se ha producido un error en el proceso de actualiación producto. ${e}`, 'ko', DialogActionEnum.Update),
      complete: () => {this.showResult('Usuario actualizado con éxito!', 'ok', DialogActionEnum.Update); this.productoService.editandoProducto = false;}
    });
  }

  showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    this.notificacionPerfilFormularioEntidad.eliminarNotificaciones();
    if (status === 'ok') {
      this.notificacionPerfilFormularioEntidad.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
    } else {
      this.notificacionPerfilFormularioEntidad.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la edición del producto', message, true, IconEnum.Error);
    }
  }


    // HISTORIAL
    accionCrearItem(event){
      console.log('Crear el comentario ', event);
      let items = this.items_historial;
      items.push(event);
  
      this.historialProducto.establecerHistorial(items);
      this.historialProducto.mostrarResultado('ok');
    }

    // FICHEROS

  obtenerFicheros() {}


  eliminarFichero(ids){
    /*
    LA IDEA ES QUE A LA API PODREMOS MANDARLE UN ARRAY DE IDS PARA ELIMINAR VARIOS,
    MIENTRAS RECORRO EL ARRAY Y HAGO PETICIONES INDIVIDUALES, Y AL REALIZARLAS HAGO EL GET DE FICHEROS
    */
    for(let id of ids){
      console.log(id);
      this.productoService.eliminarFichero(id).subscribe(()=>{
        this.obtenerFicheros();
      });
    }
  }

  peticionCambiarPosicionEjemplo(nuevoArray){
    // AQUÍ HARÍAMOS PETICIÓN A LA API PARA EDITAR LOS ELEMENTOS
    console.log(nuevoArray);
  }

  subirFicheros(ficheros){
     // AQUÍ HARÍAMOS PETICIÓN A LA API PARA SUBIR NUEVOS ARCHIVOS
    //this.demoService.subirFichero(ficheros).subscribe( data => console.log('Respuesta subida ficheros ', data));
    this.obtenerFicheros();
   // this.testArrayArchivos = this.testArrayArchivosSubida;
  }

  preSubirFicheros(ficheros){
    // AQUÍ HARÍAMOS PETICIÓN A LA API PARA SUBIR NUEVOS ARCHIVOS
   this.productoService.subirFichero(ficheros).subscribe( data => console.log('Respuesta subida ficheros ', data));

  // this.testArrayArchivos = this.testArrayArchivosSubida;
 }

 eventoEditarFichero(datos){
   console.log('Edicion datos fichero ', datos);
 }


  /******************** */
  // subErrorProyecto
  subscriptionErrorApi() {
    this.subErrorApi = this.productoService.errorApi$.subscribe(data => {
      // if(this.requisitosProyecto)
      //   this.requisitosProyecto.errorApi = data;
    } );
  }

}
