<neo-fichero-card  class="card"
    [id]="'test'"
    [idObjeto]="idObjeto"
    [objetoRelacionado]="objetoRelacionado"
    [camposExtra]="camposExtra"
    [urlArchivos]="urlArchivos"
    [item]="item"
    [itemMovible]="false"
    [itemSeleccionable]="false"
    [formatosPermitidos]="formatosPermitidos"
    [tamanoMaximoFichero]="tamanoMaximoFichero"
    [externalizarVerInformacion]="false"
    [externalizarProcesoEliminar]="false"
    [externalizarProcesoEditar]="false"
    (eliminar)="eliminarImagenPrincipal($event)"
    (editar)="editarImagenPrincipal($event)" 
    (subir)="subirImagenPrincipal($event)" 
    >
</neo-fichero-card>