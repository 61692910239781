import { Injectable, ErrorHandler} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

@Injectable()
export class SentryService implements ErrorHandler {

  constructor() {
    Sentry.init({
      environment: 'production',
      dsn: 'https://df956158223343d78e1cd4d494bf71d3@o1303155.ingest.sentry.io/6541745',
      integrations: [
        new BrowserTracing({
          tracingOrigins: ['localhost', 'https://yourserver.io/api'],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })

//     enableProdMode();
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(success => console.log(`Bootstrap success`))
//   .catch(err => console.error(err));
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error)
  }
}
