
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfiguracionMomento, VistasCalendarioExtendidoEnum } from '@appNeo/neoShared/components/calendario/calendario/calendario.component';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';

import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { CodigoColorCalendarioHexEnum, COLORES, EVENTOS_DEMO, IEventoEntidad, IFilm } from '@appNeo/neoShared/pages/demo-calendario/demo-calendario-data';
import { environment } from '@environments/environment';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventColor, WeekDay, WeekViewHourColumn } from 'calendar-utils';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
  sub,
  isPast,
  isToday,
  subDays,
  addDays,
} from 'date-fns';
import { addMinutes } from 'date-fns/esm';
import { BehaviorSubject, catchError, map, Observable, Subject, tap, throwError } from 'rxjs';
import { AuxiliarService } from '../auxiliar/auxiliar.service';

export interface EventColorNeo extends EventColor {
  dia?: string;
}

export interface WeekDayNeo extends WeekDay {
  color?: string;
  locked?: boolean;
}

export interface CalendarEventNeo<MetaType = any> extends CalendarEvent<any> {
  color?: EventColorNeo,
  locked?: boolean,
  mostrarIcono?: boolean,
  icono?: string,
}


export interface WeekViewHourColumnNeo extends WeekViewHourColumn {
  color?: EventColorNeo,
  locked?: boolean,
  mostrarIcono?: boolean,
  icono?: string,
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  // const hoursOffset = String(
  //   '16'
  // ).padStart(2, '0');
 const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {
  urlCalendario = environment.api_base_url + '/eventos';
  // subject
  vista: CalendarView;
  diaVista: Date;
  eventos$: Observable<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IFilm
  }>[]>;

  url: string = environment.api_base_url;
  urlEntidad = environment.api_base_url_backoffice + '/calendario';
  formatFechaApi = 'dd/MM/yyyy';

  public editandoEventos$ = new BehaviorSubject<boolean>(false);
  // CONFIG
  urlEventos = environment.api_base_url + '/eventos';
  public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
  public evento$: BehaviorSubject<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IFilm
  }>> = new BehaviorSubject<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IFilm
  }>>(null);
  public _eventos$: BehaviorSubject<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IFilm
  }>[]> = new BehaviorSubject<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IFilm
  }>[]>([]);
  public totalEventos$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  colores = COLORES;
  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService,
  ) {
    // this.fetchEvents();
  }

  // EJEMPLO ADAPTACION ENTIDAD A CALENDARIO


  // CRUD
  getAll(tipoPaginador?: TipoPaginadorEnum, paginador?: Paginator, filtros?: any): Observable<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean,
    totalInvitados: any[],
    entidad: IEventoEntidad
  }>[]> {
    let eventosOld: IEventoEntidad[] = [
      {
          id: '1',
          titulo: 'Cumpleaños María',
          fechaInicio: format(new Date(this.auxiliar.ayer.getTime()), 'yyyy-MM-dd'),
          horaInicio: "17:00",
          horaFin: "20:00",
          numeroNinhos: 25,
          menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '2',
        titulo: 'Commemoración dia de la Mujer',
        fechaInicio: format(new Date(this.auxiliar.ayer.getTime()), 'yyyy-MM-dd'),
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '3',
        titulo: 'Cumpleaños Teresa',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "00:00",
        horaFin: "00:15",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.ROSA,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '50',
        titulo: 'Cumpleaños Ge',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "08:30",
        horaFin: "9:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.ROSA,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '4',
        titulo: 'Cumpleaños Gemma',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.LILA,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '20',
        titulo: 'Cumpleaños Dami',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '21',
        titulo: 'Cumpleaños Sergio',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '100',
        titulo: 'Cumpleaños Concha',
        fechaInicio: format(new Date(this.auxiliar.hoy.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:30",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '5',
        titulo: 'Cumpleaños Mery',
        fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
          id: '6',
          titulo: 'Cumpleaños Bernardo',
          fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:10",
          horaFin: "20:00",
          numeroNinhos: 25,
          menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
          id: '7',
          titulo: 'Cumpleaños Mía',
          fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:25",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '33',
        titulo: 'Cumpleaños Sara',
        fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:30",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '34',
        titulo: 'Cumpleaños Sara 2',
        fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:45",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '8',
        titulo: 'Cumpleaños India',
        fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "11:15",
        horaFin: "12:15",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.LILA,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
      {
        id: '9',
        titulo: 'Cumpleaños Matty',
        fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.AMARILLO,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
    },
    {
      id: '10',
      titulo: 'Cumpleaños Alberto',
      fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
      allDay: true,
      horaInicio: "00:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: CodigoColorCalendarioHexEnum.ROSA,
      colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
    },
    {
      id: '11',
      titulo: 'Cumpleaños Gala',
      fechaInicio: format(new Date(this.auxiliar.manana.getTime()), 'yyyy-MM-dd'),
      horaInicio: "17:00",
      allDay: true,
      horaFin: "20:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: CodigoColorCalendarioHexEnum.VERDE,
      colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
    },
      {
        id: '30',
        titulo: 'Cumpleaños Alfredo',
        fechaInicio: format(sub(new Date(), {

          days: 1,

        }).getTime(), 'yyyy-MM-dd'),
        horaInicio: "22:00",
        allDay: false,
        horaFin: "23:15",
        numeroNinhos: 25,
        menusPadres: 10,
        email: 'lucia.rodriguez@duacode.com',
        color: CodigoColorCalendarioHexEnum.VERDE,
        colorPaquete: CodigoColorCalendarioHexEnum.VERDE,
      },
  ];

    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'nombre', tipoOrden: 'asc' };
    }
    let request = `${this.urlEventos}?${this.auxiliar.extraerFiltrosPaginacion(paginador)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      tap((data:IRespApi) => console.log('::DEBUG DATOS EVENTOS ', data)),
      tap((data: IRespApi) => { this.totalEventos$.next(data.total); }),
      map(({data}) => this.mapearEntidades(eventosOld)),
      tap(data => { this._eventos$.next(data); }),
    );
  }



  get(id): Observable<CalendarEventNeo<{
    isSameDay: boolean,
    isPast: boolean;
    isToday: boolean,
    isFuture: boolean,
    isWeekend: boolean, entidad: IEventoEntidad
  }>> {
    return this.http.get(`${this.urlEventos}/${id}`).pipe(
      map((data: IRespApi) => data['data'])
    );
  }
  mapearAApi(eventoCalendario) {
    console.log(eventoCalendario);
  }

  mapearEntidades(listadoEntidad:any) {
    return listadoEntidad.map( (entidad: any) => {
      let fechaInicio = new Date(entidad.fechaInicio);
      if (!entidad?.fechaFin) {
        entidad.fechaFin = entidad.fechaInicio;
      }
      let fechaFin = new Date(entidad.fechaFin);
      // let allDay = false;
      if (fechaInicio.getHours()<= 1 && !entidad.horaInicio) {
        fechaInicio = new Date(
          entidad.fechaInicio + getTimezoneOffsetString(new Date())
        );
        fechaFin = entidad?.fechaFin ? new Date(entidad.fechaFin): fechaInicio;
        // allDay = true;
      }else{
        fechaInicio = new Date(entidad.fechaInicio + ' ' + entidad.horaInicio);
        if (!entidad.horaFin) {
          fechaFin = addMinutes(fechaInicio, 30);
        }else {
          fechaFin = new Date(entidad.fechaFin + ' ' + entidad.horaFin);
        }
      }

      // let allDay = isSameDay(fechaInicio, fechaFin);
      let colorEvento: EventColorNeo = { primary: entidad.color, secondary: entidad.colorPaquete, dia: '' }
      let evento: CalendarEventNeo<{
        isSameDay: boolean,
        isPast: boolean;
        isToday: boolean,
        isFuture: boolean,
        isWeekend: boolean,
        entidad: any
      }> = {
        title: entidad.titulo,
        start: fechaInicio,
        end: fechaFin,
        color: colorEvento,
        allDay: entidad?.allDay,
        cssClass: '',
        meta: {
          isSameDay: isSameDay(fechaInicio, fechaFin),
          isPast: isPast(fechaFin),
          isToday: isToday(fechaInicio),
          entidad,
        },

      };
      return evento;
    })
  }
  create(request: any): Observable<any> {


    let evento: IEventoEntidad = {
      ...request,
      id: '',
      titulo: request.title,
      fechaInicio: format(new Date(request.start), 'yyyy-MM-dd'),
      fechaFin: format(new Date(request.end), 'yyyy-MM-dd'),
    };

    return this.http.post<IRespApi>(`${this.urlEventos}`, evento).pipe(
      tap(() => EVENTOS_DEMO.push({ ...evento, id: (EVENTOS_DEMO.length + 1).toString() })),
      tap(() => console.log(EVENTOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        // console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateEvento');
      })
    );
  }


  update(id: string, request: any): Observable<any> {
    let horaFin = '';
    let horaInicio = '';
    if (!request.allDay) {
      horaInicio= format(new Date(request.horaInicio), 'HH:mm');
      horaFin= format(new Date(request.horaFin), 'HH:mm');
    }
    let evento: IEventoEntidad = {
      ...request,
      id,
      titulo: request.title,
      fechaInicio: format(new Date(request.start), 'yyyy-MM-dd'),
      fechaFin: format(new Date(request.end), 'yyyy-MM-dd'),
      horaFin,
      horaInicio
    };

    let _evento = this.auxiliar.busquedaPropiedad(EVENTOS_DEMO, 'id', id);
    let pos = this.auxiliar.buquedaPosicionPropiedad(EVENTOS_DEMO, 'id', id);
    return this.http.patch<IRespApi>(`${this.urlEventos}/${id}`, evento).pipe(
      tap(() => console.log('RESPUESTA EDICION ')),
      tap(() => EVENTOS_DEMO[pos] = {..._evento, ...evento} ),
      tap(() => console.log(EVENTOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        // console.log('Capturamos error ', err);
        return this.handleError(err, 'UpdateEvento');
      })
    );
  }

  delete(id: string): Observable<any> {
    let indiceEliminar = EVENTOS_DEMO.findIndex(item => item.id === id);
    console.log(id, EVENTOS_DEMO, indiceEliminar);

    if (indiceEliminar >= 0) {

      return this.http.delete(`${this.urlEventos}/${id}`).pipe(
        tap(() => EVENTOS_DEMO.splice(indiceEliminar, 1)),
        tap(() => console.log(EVENTOS_DEMO)),
        map((resp: IRespApi) => {
          return resp.data;
        }),
        catchError((err: IRespApi) => {
          // console.log(err);
          return this.handleError(err);
        })
      );
    } else {
      return throwError(() => new Error('No existe elemento'));
    }
  }


  /************************************
  * Gestion calendario                *
  *************************************/
  //  Posibles filtros
  /**
   idTarifas:2,1
    idTipoTurnos:2100001,2100002
    fechaDesde:01/06/2022
    fechaHasta:02/08/2022
    bloqueo:0
  */
  // en caso de no recibir fechaDesde y fechaHasta pete
  getConfiguracionCalendario(filtros?: any): Observable<IConfiguracionMomento[]> {
    // console.log('Configuracion filtros ', filtros);
    let request = `${this.urlEntidad}/configuracionCalendario?${this.auxiliar.extraerFiltros(filtros)}`;
    return this.http.get(request, {}).pipe(
      // tap((data: IRespApi) => console.log('CONFIGURACION', this.mapearConfiguracionCalendario(data.data?.configuracionDias))),
      map((data: IRespApi) => this.mapearConfiguracionCalendario(data.data?.configuracionDias)),
      // catchError((err: IRespApi) => {
      //   // console.log('Capturamos error ', err);
      //   return this.handleError(err, 'ConfiguracionCalendario');
      // })
    );
  }

  // TARIFAS
  existeCalendarioTarifas(peticion?: any, filtros?: any): Observable<IRespApi> {
    // asignacion estatica de turnos
    // peticion = { tarifas: peticion.tarifasTurnos };
    // const options = { params: new HttpParams().append('tarifas', JSON.stringify(peticion.tarifasTurnos)) };
    // filtros.concat('&tarifas=' + JSON.stringify(peticion.tarifasTurnos));
    let _filtros = this.auxiliar.extraerFiltros(filtros);
    _filtros += `&tarifas=${JSON.stringify(peticion.tarifasTurnos)}`
    console.log('Peticion existeCalendarioTarifas', filtros);
    let request = `${this.urlEntidad}/existeCalendarioTarifas?${_filtros}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IResquestExisteCalendarioTarifas');
      })
    );
  }

  establecerCalendarioTarifas(request: any): Observable<any> {
    // asignacion estatica de turnos
    let turnos = [
      {
        "idTipoTurno": 2100001,
        "horaInicio": "09:00",
        "horaFin": "14:00"
      },
      {
        "idTipoTurno": 2100002,
        "horaInicio": "16:00",
        "horaFin": "22:30"
      }
    ]
    request?.tarifasTurnos.map(configuracionRango => configuracionRango.turnos = (configuracionRango.turnos.length > 0) ? configuracionRango.turnos : [])
    return this.http.post<IRespApi>(`${this.urlEntidad}/establecerCalendarioTarifas`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestEstablecerCalendarioTarifas');
      })
    );
  }
  eliminarCalendarioTarifas(request: any): Observable<any> {
    const options = { body: request };
    console.log('Eliminar bloqueos ', options);
    return this.http.delete(`${this.urlEntidad}/eliminarCalendarioTarifas`, options).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestEliminarCalendarioTarifas');
      })
    );
  }
  // BLOQUEOS
  establecerCalendarioBloqueos(request: any): Observable<any> {
    return this.http.post<IRespApi>(`${this.urlEntidad}/establecerCalendarioBloqueos`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestEstablecerCalendarioBloqueos');
      })
    );
  }
  eliminarCalendarioBloqueos(request: any): Observable<any> {
    const options = { body: request };
    console.log('Eliminar bloqueos ', options);
    return this.http.delete(`${this.urlEntidad}/eliminarCalendarioBloqueos`, options).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestEliminarCalendarioBloqueos');
      })
    );
  }


  /************************************
  * Gestion calendario Auxiliares     *
  *************************************/
  convertirDateEs(fecha: string): Date {
    let dateParts: number[] = fecha.split("/").map(item => parseInt(item));
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return new Date(dateObject);
  }

  convertirItemConfiguracionATarifa(item) {
    return { id: item?.id, nombre: item?.nombre, iconoClase: 'icon-20  icon-ibm--circle--solid', color: item.color };
  }
  private mapearConfiguracionCalendario(configuracion): IConfiguracionMomento[] {
    if (configuracion.length) {
      return configuracion.map((entidad: any) => {
        // console.log(entidad.dia, this.convertirDateEs(entidad.dia));
        return {
          dia: this.convertirDateEs(entidad.dia).getTime(),
          bloqueo: entidad.bloqueo,
          color: (entidad?.tarifa?.color) ? entidad?.tarifa?.color : '',
          clase: '',
          configuracion: { entidad }
        }
      });

    } else {
      return [];
    }
  }

  public obtenerParametrosConsultaConfiguracion(diaVista?, vista?, diasMargen = 0) {
    let formatFechaApi = this.formatFechaApi;
    let primerDiaMes = this.auxiliar.primerDiaMes(diaVista);
    let filtrosDefault = {
      fechaDesde: format(<Date>primerDiaMes, formatFechaApi),
      fechaHasta: format(<Date>this.auxiliar.ultimoDiaMes(<Date>primerDiaMes), formatFechaApi),
      bloqueo: '1',
    };
    let filtros = filtrosDefault;
    switch (vista) {
      case CalendarView.Month:
        filtros = {
          fechaDesde: format(subDays(<Date>primerDiaMes, diasMargen), formatFechaApi),
          fechaHasta: format(addDays(<Date>this.auxiliar.ultimoDiaMes(<Date>primerDiaMes), diasMargen), formatFechaApi),
          bloqueo: '1',
        };
        break;
      case CalendarView.Week:
        break;
      case CalendarView.Day:
        filtros = {
          fechaDesde: format(new Date(diaVista), formatFechaApi),
          fechaHasta: format(new Date(diaVista), formatFechaApi),
          bloqueo: '1',
        };
        break;
      case VistasCalendarioExtendidoEnum.Agenda:
        break;
      default:
        filtros = filtrosDefault;
        break;
    }
    return filtros;
  }

  private handleError(err: IRespApi, idRequest: string = ''): any {
    // dialog específico
    err.request = idRequest;
    if ( err.codigoError === 422) {
      this.errorApi$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if ( err.codigoError === 403) {
      mensaje = `${ err.mensaje }: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404  || err.codigoError === 422) {
      let avisos = '';
      if ( err.data ) {
        err.data.forEach(element => {
          if ( element.id === 999 ) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = (avisos.length)  ? avisos : err?.mensaje;
    }

    if (err.codigoError === 500) {
      let avisos = '';
      if ( err.data ) {
        err.data.forEach(element => {
          if ( element.id === 999 ) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = err?.mensaje +  avisos;
    }
    // console.log(` Error Operacion ${err.data} ${mensaje} ${err}`);
    err.mensaje = mensaje;
    // {'error' : true, 'data': err.data, mensaje, 'err': err }
    return throwError(() => err);
 }

}
