import {Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BusquedaComponent} from '@appNeo/neoShared/components/busqueda/busqueda.component';
import {
  AccionesTablaEnum,
  ICeldaEditable,
  TablaService,
  TipoColumnaEditableEnum,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import {environment} from '@environments/environment';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {FiltrosService} from '@appNeo/neoCore/services/filtros/filtros.service';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {CdkTextareaAutosize} from '@node_modules/@angular/cdk/text-field';
import {InputTypeEnum} from '@appNeo/neoShared/components/input/input.component';


@Component({
  selector: 'neo-tabla-editable',
  templateUrl: './tabla-editable.component.html',
  styleUrls: ['./tabla-editable.component.scss'],
  providers: [FormularioService, FiltrosService],
})

export class TablaEditableComponent implements OnInit {

  // Tabla Online => Paginación, filtrado y ordenación delegado en api
  @Input() tablaOnline = true;
  @Input() identificador = 'tabla-editable-1';
  @Input() ordenAnhadirCola = false;

  //FILTRADO
  //Visualizar filtrado
  @Input() filtrado: boolean;
  @Input() keyFiltrado: string;

  // cabecera tabla
  @Input() mostrarTituloCabecera = true;
  @Input() mostrarTotalResultados = true;
  @Input() titulo = 'Título';
  @Input() editable = true;
  //SOLO APLICA A TABLA OFFLINE
  @Input() columnasFiltro: string[];
  @Output() accionClickGuardar = new EventEmitter<object>();
  @Output() accionClickCancelar = new EventEmitter<boolean>();

  //TODO: AÑADIR POSIBILIDAD DE ENVIAR ICONCLASS ??
  @Input() placeholderNuevaRow = 'Añadir nueva fila';
  @Input() placeholderRow = 'Introducir texto';
  @Input() btnGuardar = false;
  @Input() textoBtnGuardar = 'Guardar cambios';
  @Input() btnCancelar = false;
  @Input() textoBtnCancelar = 'Cancelar';
  dataInicial: object [];

  //ACCIONES TABLA
  @Output() accionClickRow = new EventEmitter<object>();

  //SOLO APLICA A TABLA ONLINE
  @Output() aplicarFiltros = new EventEmitter<object>();

  valoresFiltrado = {};
  columnas: any[];
  dataSource: MatTableDataSource<any>;
  totalResultados = 0;
  totalRowsCreacion = 1;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('window') window: TemplateRef<any>;
  @ViewChild('tabla') table: MatTable<any>;
  @ViewChildren('textAreaColumnaEditable') textAreaColumnaEditable: QueryList<CdkTextareaAutosize>;

  // basico termino busqueda
  @ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;

  // Campos editables
  campoEditableFoco = null;
  posicionCampoEditableFoco = null;
  textAreaOpen = [];

  constructor(
    public dialog: MatDialog,
    public tablaService: TablaService,
    private router: Router,
    private formularioService: FormularioService,
    private filtrosService: FiltrosService,
    private auxiliarService: AuxiliarService
  ) {}

  ngOnInit() {
    this.subscripcioncolumnasTabla();
    this.subscripcionDataSourceTabla();
    this.subscripcionTotalDataSourceTabla();
  }


  get keysColumnas(): string[] {
    if (!this.columnas) {
      return [];
    }
    return this.columnas.map((column: object) => column['key']);
  }

  subscripcioncolumnasTabla(): void {
    this.tablaService.columnas$.subscribe(columnas => {
      this.columnas = columnas;
      this.columnas.push(
        {
          key: TipoColumnaTablaEnum.Acciones,
          nombre: '',
          tipo: TipoColumnaTablaEnum.Acciones,
          ordenacion: false,
          accion: AccionesTablaEnum.Eliminar,
        }
      );
    });
  }

  subscripcionDataSourceTabla(): void {
    this.tablaService.data$.subscribe( data => {
      this.dataSource = data;
      // if (!this.dataInicial || this.dataSource.data.length === 0)  {
        this.mapearPrimeraFilaCreacionRow();
      // }
      if (!this.dataInicial && this.dataSource?.data) {
        this.dataInicial = this.auxiliarService.copiarArrayObjetosSinReferencia(this.dataSource.data);
      }
      this.filtrar();
    });
  }

  mapearPrimeraFilaCreacionRow() {
    const keysColumnasEditables = [];
    this.columnas.forEach(columna => {
      if (columna.key !== TipoColumnaTablaEnum.Acciones) {
        keysColumnasEditables.push(columna.key);
      }
    });

    const primeraFilaMap = {
      id: String(Math.floor(Math.random() * 999999)),
      acciones: AccionesTablaEnum.Crear,
      filaCreacion: true,
    };

    keysColumnasEditables.forEach(keyColumnaEditable => {
      primeraFilaMap[`${keyColumnaEditable}`] = {nombre: ''};
    });

    // asegurarno que existe una sola fila creacion
    let numCreacion = 0;
    this.dataSource?.data.forEach(fila => {
      if (fila.filaCreacion) {
        numCreacion++;
      }
    })
    if (numCreacion <= 0) {
      if (this.ordenAnhadirCola) {
        this.dataSource?.data.push(primeraFilaMap);
      } else {
        this.dataSource?.data.unshift(primeraFilaMap);
      }
    }
  }

  actualizarDataSource() {
    this.tablaService.data = this.dataSource;
  }

  aplicarFiltrosOffline() {
    const inputsFiltro = this.valoresFiltrado['busqueda'];
    if  (inputsFiltro ) {
      this.dataSource.filter = inputsFiltro;
      this.dataSource.filterPredicate = this.buscarCoincidenciaRowTablaOffline.bind(this);
      this.tablaService.total = this.dataSource.filteredData.length - this.totalRowsCreacion;
    }
  }

  subscripcionTotalDataSourceTabla() {
    this.tablaService.total$.subscribe(totalResultados => {
      this.totalResultados = totalResultados;
    });
  }

  buscarCoincidenciaRowTablaOffline(row, filtroBusqueda): boolean {
    if (filtroBusqueda) {
      let coincidencias = [];

      if (!row.filaCreacion) {
        for (let i = 0; i < this.columnasFiltro.length; i++) {
          let columnaFiltro = this.columnasFiltro[i];
          let valorFiltro = filtroBusqueda.trim().toLowerCase();
          let valorTabla = '';

          if (Date.parse(row[columnaFiltro])) {
            valorTabla = row[columnaFiltro];
          } else if (typeof row[columnaFiltro] === 'object') {
            valorTabla = row[columnaFiltro].nombre;
          } else {
            valorTabla = row[columnaFiltro];
          }


          if (valorTabla) {
            let coincidencia;
            if (Number(valorTabla) && Number(valorFiltro)) {
              Number(valorTabla) === Number(valorFiltro) ? coincidencia = true : coincidencia = false;
            } else {
              valorTabla = String(valorTabla).trim().toLowerCase();
              coincidencia = valorTabla.includes(valorFiltro);
            }
            coincidencias.push(coincidencia);
          }
        }
      } else {
        coincidencias.push(true);
      }

      if (coincidencias.includes(true)) {
        return true;
      }  else {
        return  false;
      }
    }

    return true;
  }

  eventoCambioBuscador(filterValue: string) {
    // TODO: REVISAR
    this.valoresFiltrado['busqueda'] = filterValue;
    // this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }

  filtrar() {
    if (this.tablaOnline) {
      this.aplicarFiltros.emit(this.valoresFiltrado);
    } else {
      this.aplicarFiltrosOffline();
    }
  }

  clearBuscador() {
    this.filtrosBusqueda.clearBuscador();
  }

  clickRow( event ) {
    // console.log('Click row');
    this.accionClickRow.emit(event);
  }

  focusInputMascaraTextareaEdicion(row, posicionRow: number) {

    if (this.posicionCampoEditableFoco >= 0) {
      this.focusOutTextAreaEdicion(this.posicionCampoEditableFoco);
    }
    this.campoEditableFoco = row;
    this.textAreaColumnaEditable['_results'][posicionRow];
    setTimeout(()=> {
      this.focusTextAreaEdicion(posicionRow);
    },100);
  }


  focusTextAreaEdicion(posicionRow: number) {
    this.posicionCampoEditableFoco = posicionRow;
    this.textAreaOpen[posicionRow] = true;
    if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {
      this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableFocus;
    }
  }

  focusOutTextAreaEdicion(posicionRow: number) {

    this.posicionCampoEditableFoco = null;
    this.textAreaOpen[posicionRow] = false;
    if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {

      this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableInicial;
    }
    this.actualizarDataSource();
    this.campoEditableFoco = undefined;
  }

  get numeroRowsCeldaEditableFocus(): number {
    return environment.numero_rows_celda_editable_focus;
  }


  get numeroRowsCeldaEditableInicial(): number {
    return environment.numero_rows_celda_editable_inicial;
  }

  getInputType(columna) {
    if (columna.tipoColumnaEditable === TipoColumnaEditableEnum.Importe || columna.tipoColumnaEditable === TipoColumnaEditableEnum.Cantidad) {
      return InputTypeEnum.number;
    }
    return InputTypeEnum.text;
  }



  celdaEliminada(row): boolean {
    return row[TipoColumnaTablaEnum.Acciones] === AccionesTablaEnum.Restaurar;
  }


  insertarRow(row: ICeldaEditable) {
    if(!this.rowVacio(row)) {
      this.dataSource.data.map(dataRow => {
        if (dataRow?.id === row?.id) {
          dataRow.nueva = true;
          dataRow.filaCreacion = false;
          dataRow.acciones = AccionesTablaEnum.Eliminar;
        }
      });
      if (this.ordenAnhadirCola) {
        this.dataSource.data.push(row);
      } else {
        this.dataSource.data.unshift(row);
      }
      this.mapearPrimeraFilaCreacionRow();
      this.dataSource.data = [...new Set(this.dataSource.data)];
    }
  }

  rowVacio(row) {
    let rowVacio;
    Object.keys(row).filter(rowElement => row[rowElement].nombre).length > 0 ? rowVacio = false : rowVacio = true;
    return rowVacio;
  }

  clickRowIcon(row: ICeldaEditable): object {
    this.celdaEliminada(row) ? row[TipoColumnaTablaEnum.Acciones] = AccionesTablaEnum.Eliminar : row[TipoColumnaTablaEnum.Acciones] = AccionesTablaEnum.Restaurar;
    return row;
  }

  guardarCambios() {
    // if (this.detectarCambios()) {
      this.dataSource.data = this.mapearDataEditada(this.dataSource.data);
      this.tablaService.dataTablaEditable = this.mapearDataEditada(this.dataSource.data);
      this.accionClickGuardar.emit(this.mapearDataEditada(this.dataSource.data));
      this.mapearPrimeraFilaCreacionRow();
      this.dataSource.data = [...new Set(this.dataSource.data)];
      this.dataInicial = this.auxiliarService.copiarArrayObjetosSinReferencia(this.dataSource.data);
      this.actualizarDataSource();
    // }
  }

  mapearDataEditada(data: ICeldaEditable []): ICeldaEditable [] {
    data = data.map(({nueva, ...row}) => row);
    data = data.filter( row => {
      return !this.celdaEliminada(row);
    });
    data = data.filter( row => {
      return !row?.filaCreacion;
    });

    return  data;
  }

  cancelarCambios() {
    // if (this.detectarCambios()) {
      this.dataSource.data = this.auxiliarService.copiarArrayObjetosSinReferencia(this.dataInicial);
    // }
    this.accionClickCancelar.emit(true);
  }

  detectarCambios() {
    const dataSourceAux = this.auxiliarService.copiarArrayObjetosSinReferencia(this.dataSource.data);
    const dataInicialAux = this.auxiliarService.copiarArrayObjetosSinReferencia(this.dataInicial);
    dataSourceAux.splice(0, 1);
    dataInicialAux.splice(0, 1);
    if (JSON.stringify(dataSourceAux) !== JSON.stringify(dataInicialAux)) {
      return true;
    }
    return false;
  }
}
