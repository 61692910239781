import {Directive, HostListener, Input, Optional} from '@angular/core';
import {RouterLink} from '@angular/router';

// Directiva para combinar el uso de routerLink con redireccionamiento a url externas
// Ejemplo en logo.component
// Si recibe linkExterno y routerLink tiene preferencia el linkExterno.
// En el siguiente ejemplo nos redireccionaría a la url externa
// <button [routerLink]="'tabla-draggable'" [linkExterno]="'https://github.com/molily/threshold-warning-directive'">TEST</button>

@Directive({
  selector: '[linkExterno]'
})
export class LinkExternoDirective {

  @Input() linkExterno: string;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.linkExterno) {
      window.open(this.linkExterno, '_blank');
    }
  }
  constructor(
    @Optional() public link: RouterLink
  ) {
    if (!link) {
      return;
    }
    const onClick = link.onClick;

    link.onClick = (...args: any[]) => {
      if (this.linkExterno) {
        window.open(this.linkExterno, '_blank');
        return false;
      } else {
        return onClick.apply(link, args);
      }
    }
  }
}
