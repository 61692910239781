import { Component, Input, OnInit } from '@angular/core';
import { NotificacionService } from '@appNeo/neoCore/services/notificacion/notificacion.service';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { INotificacion } from '@appNeo/neoShared/helpers/interfaces/INotificacion';
import { environment } from '@environments/environment';

@Component({
  selector: 'neo-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss'],
  providers: [NotificacionService]
})
export class NotificacionComponent implements OnInit {

  notificaciones: INotificacion[] = [];
  @Input() identificador: string;

  constructor(
    public notificacionService: NotificacionService
  ) { }

  ngOnInit(): void {
    this.notificacionService.obtenerNotificaciones().subscribe((notificacion: INotificacion) => {
      this.tratarNotificacionRecibida(notificacion);
    });
  }

  tratarNotificacionRecibida(notificacion: INotificacion){
    if (!notificacion) {
      this.notificaciones = [];
      return;
    }
    this.notificaciones.push(notificacion);
    if (!notificacion.btnCerrar || notificacion.cierreProgramado) {
      this.programarCierreNotificacion(notificacion);
    }
  }

  programarCierreNotificacion(notificacion: INotificacion) {
    setTimeout(() =>{
      this.eliminarNotificacion(notificacion);
    },environment.close_alert_delay_ms);
  }

  eliminarNotificacion(notificacion: INotificacion) {
    this.notificaciones = this.notificaciones.filter(x => x !== notificacion);
  }

  crearNotificacion(tipo: NotificacionEnum, titulo:string, mensaje: string, btnCerrar: boolean, icono: IconEnum, iconoClase: string = '', cierreProgramado = false, mantenerEnNavegacion = false) {
    this.notificacionService.crearNotificacion(tipo, titulo, mensaje, btnCerrar, icono, iconoClase, mantenerEnNavegacion, cierreProgramado);
  }

  eliminarNotificaciones() {
    // this.notificaciones = [];
    this.notificacionService.eliminarNotificaciones();
  }

  get IconoEnum() {
    return IconEnum;
  }
}
