import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Filtros } from '@appNeo/neoCore/helpers/enums/filtros.enum';
import { IUsuario } from '@appNeo/neoCore/interfaces/IUsuario';
import { SpinnerService } from '@appNeo/neoCore/services/spinner/spinner.service';
import { UsuarioService } from '@appNeo/neoCore/services/usuario/usuario.service';
import { AccionesDialogComponent } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { TablaComponent } from '@appNeo/neoShared/components/tabla/tabla/tabla.component';
import { ClassResponsiveEnum } from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { FormatosFicherosEnum } from '@appNeo/neoShared/helpers/enums/FormatosFicheros.enum';
import { FormTagEnum } from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { TipoDatePickerEnum } from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, ClasesFormularioAnchoEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { FormularioFiltrosTablaService } from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IBotonAccionCabeceraTabla,
  IClasesCeldas,
  IColumnaTabla,
  TablaService,
  TipoColumnaEditableEnum,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { USUARIOS_ESTADOS_DEMO } from './layout-usuario-data';



@Component({
  selector: 'neo-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
  providers: [TablaService, FormularioService, FormularioFiltrosTablaService, BotonDesplegableService]
})
export class UsuariosComponent implements OnInit, OnDestroy, AfterViewInit {

  usuario: IUsuario;
  idUsuario: string;
  tablaSub: Subscription;
  subEntidadPrincipal: Subscription;
  subRequestFormulario: Subscription;
  subErrorApi: Subscription;
  errorApi$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  grabando:boolean = false;
  formularioConCambios: boolean = false;
  @ViewChild('notificacion') notificacion: NotificacionComponent;
  /************************************************************************** */
  // configuracion necesaria para tabla genérica entidad
  /*************************************************************************** */
  @ViewChild('tablaUsuarios') tablaUsuarios: TablaComponent;
  identificadorFiltro = Filtros.Usuarios;
  columnasFiltro = ['nombreMapping', 'email', 'direccion', 'fechaNacimiento'];
  valoresInicialesFiltradoTabla = {};
  // valoresInicialesFiltradoTabla = {
  //   idCategoria: ['1'],
  //   idSubCategoria: '1',
  //   fechaPublicacion: '2021-09-22T22:00:10.000Z',
  //   fechaCortaPublicacion: '',
  //   horaPublicacion: '',
  //   idIdioma: ['1' , '2', '3'],
  //   idEstado: ['1', '2'],
  // };
  accionesMultiplesDesplegableTablaDemo: IAccionesBotonDesplegable[] =  [
    {id: 'activar_teletrabajo', iconoClase: '', texto: 'Activar teletrabajo'},
    {id: 'eliminar_seleccionados', iconoClase: '', texto: 'Eliminar seleccionados'}
  ];
  // , classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg
  paginador = new Paginador();
  paginator = new Paginator();
  // tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.offline;
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.offline;
  tablaOnline: boolean = false;
  columnas: IColumnaTabla[] = [
    {key: 'select', nombre: '', tipo: TipoColumnaTablaEnum.Selector, ordenacion: false},
    {key: 'nombreMapping', nombre: 'Nombre', tipo: TipoColumnaTablaEnum.EnlacePrincipal, ordenacion: true},
    {key: 'avatar', nombre: 'Avatar', tipo: TipoColumnaTablaEnum.Avatar, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm},
    {key: 'teletrabajo', nombre: 'Teletrabajo', tipo: TipoColumnaTablaEnum.Boolean, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'email', nombre: 'Email', tipo: TipoColumnaTablaEnum.Texto, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'usuarioEstadoActual', nombre: 'Estado', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'fechaNacimiento', nombre: 'Fecha Nacimiento', tipo: TipoColumnaTablaEnum.Fecha, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'direccion', nombre: 'Direccion', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'acciones', nombre: 'Acciones', tipo: TipoColumnaTablaEnum.Acciones, ordenacion: false}
  ];

  inputsFiltros: IFormInput[] = [

    // {
    //   tag: FormTagEnum.boolean,
    //   label: 'Teletrabajo',
    //   type: '',
    //   placeholder: 'Teletrabajo',
    //   formControlName: 'teletrabajo',
    //   validadores: [],
    // },
    {
      tag: FormTagEnum.date,
      label: 'Fecha Nacimiento',
      type: 'fecha',
      placeholder: 'Fecha Nacimiento',
      formControlName: 'fechaNacimiento',
      obligatorio: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.estado,
      label: 'Estado',
      type: '',
      placeholder: 'Estado',
      formControlName: 'idEstado',
      multiple: true,
      obligatorio: false,
      validadores: [],
    }
  ];

 /***************************************************************** */
  // configuracion necesaria para edicion
  /***************************************************************** */
  refDialogFormulario;
  @ViewChild('dialogoFormulario') dialogoFormulario: TemplateRef<any>;
  @ViewChild('accionDialogFormulario') accionDialogoFormulario: AccionesDialogComponent;
  @ViewChild('formulario') formulario: FormularioComponent;
  // congiguracion layout formulario
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  inputsCreacionEntrada: IFormInput[] = [

    {
      tag: FormTagEnum.input,
      formControlName: 'nombre',
      label: 'Nombre',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Nombre',
      type: 'text',
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'apellidos',
      label: 'Apellidos',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Apellidos',
      type: 'text',
      obligatorio: true,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x]
  },
  {
    tag: FormTagEnum.date,
    label: 'Fecha nacimiento',
    type: 'fecha',
    tipoDatePicker: TipoDatePickerEnum.Fecha,
    placeholder: 'dd/mm/aaaa',
    formControlName: 'fechaNacimiento',
    textoAyuda: '',
    min: new Date(1910, 8, 1),
    max: new Date(),
    obligatorio: false,
    validadores: [],
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'direccion',
      label: 'Dirección',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Dirección',
      type: 'text',
      obligatorio: false,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'cp',
      label: 'Código postal',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Código postal',
      type: 'text',
      obligatorio: true,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'municipio',
      label: 'Municipio',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Municipio',
      type: 'text',
      obligatorio: false,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.input,
      formControlName: 'pais',
      label: 'País',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'País',
      type: 'text',
      obligatorio: false,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.telefono,
      formControlName: 'telefono',
      label: 'Telefono',
      // maxLength: 5000,
      textoAyuda: '',
      placeholder: 'Telefono',
      type: '',
      obligatorio: false,
      validadores: [],
    clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x]
  },
  {
      tag: FormTagEnum.boolean,
      formControlName: 'teletrabajo',
      label: 'Teletrabajo',
      textoAyuda: '',
      placeholder: 'teletrabajo',
      type: '',
      obligatorio: false,
      validadores: []
  },
  {
      tag: FormTagEnum.ficheroZonaArrastre,
      label: 'Documentación asociada',
      type: '',
      placeholder: 'Documentación asociada',
      formControlName: 'ficheros',
      // textoAyuda: 'Texto opcional de ayuda',
      obligatorio: false,
      disabled: false,
      validadores: [],
      formatosPermitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg],
      subidaMultiple: true,
      maximoElementosASubir: 10,
      tamanoMaximoTotal: 1000000000000000000,
      tamanoMaximoFichero: 200000000000000000,
  },
  ];

  // Eliminacion
  @ViewChild('dialogoConfirmarEliminar') dialogoConfirmarEliminar: TemplateRef<any>;
  @ViewChild('accionDialogoConfirmarEliminar') accionDialogoConfirmarEliminar: AccionesDialogComponent;

  constructor(
    public tablaService: TablaService,
    // private filtrosService: FiltrosService,
    private filtrosTablaService: FormularioFiltrosTablaService,
    private formularioService: FormularioService,
    private usuarioService: UsuarioService,
    private botonDesplegableService: BotonDesplegableService,
    public auxiliarService: AuxiliarService,
    private dialog: MatDialog,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    this.tablaOnline = this.tipoPaginador === TipoPaginadorEnum.online;
  }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.subscriptionEntidadPrincipal();
    this.establecerFiltrosTabla();
    this.establecerColumnasTabla();
  }

  ngAfterViewInit(): void {
    this.subscriptionErrorApi();
  }
  ngOnDestroy() {
    if (this.subEntidadPrincipal) {
      this.subEntidadPrincipal.unsubscribe();
    }

    if (this.tablaSub) {
      this.tablaSub.unsubscribe();
    }

    if (this.subRequestFormulario) {
      this.subRequestFormulario.unsubscribe();
    }

    if (this.subErrorApi) {
      this.subErrorApi.unsubscribe();
    }

    if (this.errorApi){
      this.errorApi.unsubscribe();
    }

  }

  cargarTabla() {
    // let filtros = this.filtrosService.getFiltros(this.identificadorFiltro, {});
    // this.paginator._numeroElementosPagina = (this.paginator._numeroElementosPagina && this.paginator._numeroElementosPagina!== null)
    //   ? this.paginator._numeroElementosPagina
    //   : environment.numeroElementosPaginadorDefault;
    // this.paginator._pagina = 0;
    this.tablaSub = this.usuarioService.getAll( this.tipoPaginador, this.paginator, {} ).subscribe( data => {
      this.tablaService.data = new MatTableDataSource(this.mapearDataTabla(data));
      this.tablaService.total = data.length;
      this.spinnerService.hideSpinner();
    })
  }

  mapearDataTabla(data) {
    let dataMapeadas = data;
    if (data && data.length) {
      dataMapeadas = data.map( objeto => {
        // VALIDAR SI CADA REGISTRO ES EDITABLE O BORRABLE.
        let accionesColumnaTabla: IAccionRowTabla [] = [
          {
            idAccion: AccionesTablaEnum.Editar,
            tooltip: 'Editar usuario',
            icono: true,
            iconClass: 'icon-20 icon-ibm--edit',
            disabled: false,
          },
          {
            idAccion: 'eliminar',
            tooltip: 'Eliminar usuario',
            icono: true,
            iconClass: 'icon-20 icon-ibm--trash-can text-danger',
            disabled: false,
          },
        ];
        objeto['acciones'] = accionesColumnaTabla;
        objeto['nombreMapping'] = `${objeto.nombre} ${objeto.apellidos}`;
        objeto['fechaNacimientoMapping'] = `${objeto.nombre} ${objeto.apellidos}`;
        objeto['direccion'] =  {
          //nombre: 'Lorem ipsum este es el texto muy lago que no es asi ',
          nombre: `${this.auxiliarService.textoAleatorio(100)}`,
          tipo: TipoColumnaEditableEnum.TextArea,
        };
        return objeto;
      });
    }
    return dataMapeadas;
  }

  //TODO IMPLEMENTAR TRAS INTEGRACIÓN CON API
  aplicarFiltrosTablaOnline(entidadFiltrado: object) {
    this.paginator._pagina = 0;
    console.log('OBJETO A FILTRAR: ', entidadFiltrado);
  }

  aplicarAccionMultipleSeleccion(dataAccionSeleccion: object){
    console.log('Accion multiple y elementos seleccionados: ', dataAccionSeleccion);
  }

  aplicarAccionRow(accionRow: [object, IAccionRowTabla]) {
    switch (accionRow[1].idAccion) {
      case AccionesTablaEnum.Editar:
        this.accionEditar(accionRow[0]);
        break;
      case AccionesTablaEnum.Eliminar:
        this.accionEliminar(accionRow[0]);
        break;
    }
  }

  aplicarAccionCabecera(accionCabecera: IBotonAccionCabeceraTabla) {
    switch (accionCabecera.id) {
      case AccionesTablaEnum.Crear:
        this.abrirDialogo(this.auxiliarService._DialoDialogActionEnum.Create);
        break;
    }
  }
  abrirDialogo(dialog: DialogActionEnum, entity?): void {
    switch (dialog) {
      case DialogActionEnum.Create:
       // cargamos en servicio formulario los inputs correspondientes
       this.formularioService.inputs = this.inputsCreacionEntrada;
       //  this.formulario.formValueReset = true;
       this.refDialogFormulario = this.dialog.open(this.dialogoFormulario, {disableClose: true});
       this.formularioConCambios = this.formulario?.formValueChanges;
       this.formulario?.reset({});
       break;

       case DialogActionEnum.Delete:
       this.refDialogFormulario = this.dialog.open(this.dialogoConfirmarEliminar, {disableClose: true});
       break;
    }
 }

  accionEditar(entidad) {
    console.log(entidad);
    this.router.navigate([environment.ruta_padre_admin_demo + '/usuarios', '1']);
  }

  accionEliminar(entidad) {
    this.idUsuario = entidad.id;
    this.usuario = entidad;
    this.abrirDialogo(DialogActionEnum.Delete);
    console.log('Eliminar fila ', entidad);

  }

  accionDescargarPdf(event){
    console.log('Descargar pdf');
  }

  subscriptionEntidadPrincipal() {
    //  En caso de entidades releacionadas con una entidad principal ej: departamentosUsuarioSeleccionado
    // this.subEntidadPrincipal = this.usuariosService.usuario$.subscribe( entidad =>  {
    //     if (entidad) {
    //         this.entidad = entidad;
    //         this.cargarTabla();
    //     }
    // });

    this.cargarTabla();
  }


  establecerFiltrosTabla() {
    // FILTROS
    // valores combos filtros
    if (this.auxiliarService.busquedaPropiedad(this.inputsFiltros, 'formControlName', 'idEstado')) {
      this.auxiliarService.busquedaPropiedad(this.inputsFiltros, 'formControlName', 'idEstado').datos = USUARIOS_ESTADOS_DEMO;
    }
    this.filtrosTablaService.inputsForm = this.inputsFiltros;
  }
  establecerColumnasTabla() {
    // TABLA
    let columnasSinPermiso = [];
    this.tablaService.columnas = this.columnas.filter(columna => !columnasSinPermiso.includes(columna.key));

    
  }


  submitAccionFormulario(event){
    console.log('Crear', event);
    this.grabando =  true;
    let objeto = this.formulario.validaCampos( false );
    if (objeto &&  objeto.formulario && objeto.formulario.valid) {
      this.formulario.camposFichero.forEach(campo => {
        this.formulario.ficheros.push({id: campo.nombreCampo, ficheros: campo.storeFicheros});
      });
      console.log('Valido');
      this.crear(objeto.valores);
    } else {
      this.grabando = false;
      this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
      return;
    }

  }

  submitAccionDialogoConfirmarEliminar(event) {
    if (event.id == 'btn-confirmar') {
      this.eliminar(this.idUsuario);
    }else{
      this.idUsuario = null;
      this.usuario = null;
    }
    this.grabando =  true;

  }
  accionesCerrarFormulario() {}

  // Enlace APi
  crear(parametrosRequest) {
    //  let parEntidad = [-- keys request a filtrar --- en caso de diferentes peticiones---];
    //  let parametrosEntidad: any = this.auxiliarService.filtrarParametros(parametrosRequest, parEntidad);
    console.log('Creando...');
    this.subRequestFormulario = this.usuarioService.create(parametrosRequest).pipe(
      tap(x => this.idUsuario = x)
    ).subscribe({
      next: (v) => console.log(v),
      error: (e) => this.manejarErrorFormularioCreacion(e, (this.idUsuario) ? 'ficheros' : 'crear'),
      complete: () => this.showResult('Usuario creado con éxito!', 'ok', DialogActionEnum.Create)
    });
  }

  eliminar(parametrosRequest) {
    console.log('Eliminando...', this.idUsuario);
    this.subRequestFormulario = this.usuarioService.delete(parametrosRequest).subscribe({
      next: (v) => console.log(v),
      error: (e) =>  this.showResult(`Se ha producido un error en el proceso de eliminación usuario. ${e}`, 'ko', DialogActionEnum.Delete),
      complete: () => this.showResult('Usuario creado con éxito!', 'ok', DialogActionEnum.Delete)
    });
  }

  manejarErrorFormularioCreacion(errorData, peticion?: 'crear' | 'ficheros' ) {
    console.log('Error ', errorData);
    this.grabando = false;
     let avisoApi = '';

     if ( errorData?.data ) {
       if (typeof errorData.data === 'string') {
         avisoApi = ': ' + errorData.data + ' ';
       }else{
         errorData?.data.forEach(element => {
           if ( element.id === 999 ) {
             avisoApi = ': ' + element.mensaje;
             return;
           }
         });
       }
     }
     // console.log(`Aviso API  [${ avisoApi }]`, errorData?.err?.request);
     if (this.idUsuario) {
       this.refDialogFormulario.close();
       this.showResult(`Se ha producido un error en el proceso de subida de ficheros en la creación de usuario ${ avisoApi }.`, 'ko');
       this.showResult('usuario creado con éxito!', 'ok', DialogActionEnum.Create);
     } else {
       this.showResult(`Se ha producido un error en el proceso de creación usuario ${ avisoApi }.`, 'ko', DialogActionEnum.Create);
     }
   }


   showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    if (status === 'ok') {
      this.idUsuario= null;
      this.dialog.closeAll();
      this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
    } else {
      switch (dialogAction) {
        case DialogActionEnum.Create:
         this.accionDialogoFormulario?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        case DialogActionEnum.Delete:
          this.accionDialogoConfirmarEliminar?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        default:
            this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la subída de los ficheros', message, true, IconEnum.Error);
      }
    }
    this.cargarTabla();
  }



  // errorApi
  set errorApi(data) {
    this.errorApi$.next(data);
  }
  subscriptionErrorApi() {
    // TODO sub a errorApi entidad princiipal
    this.subErrorApi = this.usuarioService.errorApi$.subscribe(data => {
      // this.errorApi = data;
      // console.log('ERROR API ES ', data);
      // if(this.requisitosProyecto)
      //   this.requisitosProyecto.errorApi = data;
      // if(this.conceptosProyecto)
      //   this.conceptosProyecto.errorApi = data;
      // if(this.analisisFuncionalProyecto)
      //   this.analisisFuncionalProyecto.errorApi = data;
    } );
  }


}


