
<div id="kanbanTickets">
    <neo-notificacion #notificacion></neo-notificacion>  
    <div class="botonera-acciones">
      <h3 class="text-h2">Tickets</h3>
      <div class="botonera">
        <neo-button class="ml-10"
            [flat]="true"
            [text]="'Nueva Entrada'"
            [color]="'primary'"
            [iconClass]="'icon-20 icon-ibm--add'"
            (click)="abrirDialogo(auxiliarService._DialoDialogActionEnum.Create)"
        ></neo-button>
      </div>
  
      <div class="botonera-minifab">
        <neo-button class=""
          [color]="'primary'"
          [miniFab]="true"
          [type]="'button'"
          [iconClass]="'icon-20 icon-ibm--add'"
          (click)="abrirDialogo(auxiliarService._DialoDialogActionEnum.Create)"
        ></neo-button>
      </div>
    </div>
    <div>
        <neo-kanban
        [desactivarMovimientos]="false"
        (clickMovimientoItemColumna)="onClickMovimientoItemColumna($event)"
        (clickMovimientoItemEntreColumnas)="onClickMovimientoItemEntreColumnas($event)"
        ></neo-kanban>
    </div>
    
</div>
  

<ng-template #dialogoFormulario>
  <neo-acciones-dialog #accionDialogoFormulario
  [dialogTittle]="'Nuevo ticket'"
  [botonera]="[
      {
          label: 'Cancelar',
          id: 'btn-cancelar',
          tipoAccion: 'cerrar',
          type: 'button',
          color: 'primary',
          disabled: grabando,
          activo: true,
          flat: true
      },
      {
          label: 'Guardar',
          id: 'btn-confirmar',
          tipoAccion: 'accion',
          type: 'button',
          color: '',
          disabled: grabando,
          activo: true,
          flat: true
      }
  ]"
  (accionBoton)="submitAccionFormulario($event)"
  (cerrarDialogo)="accionesCerrarFormulario()"
  >
  <ng-container *ngIf="true">
    <neo-formulario-wizard-ticket #formularioWizard></neo-formulario-wizard-ticket>
      <!-- <neo-formulario #formulario
        [aplicarFlex]="aplicarFlex"
        [formularioAncho]="clasesFormularioAnchoEnum"
        [camposFormulario]="inputsCreacionEntrada"
      ></neo-formulario> -->
  </ng-container>
  </neo-acciones-dialog>
</ng-template>