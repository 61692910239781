import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { ICamposExtra } from '@appNeo/neoShared/modules/edicion-secciones/components/imagen-principal-edicion-item/imagen-principal-edicion-item.component';

@Component({
  selector: 'neo-imagen-principal-perfil',
  templateUrl: './imagen-principal-perfil.component.html',
  styleUrls: ['./imagen-principal-perfil.component.scss']
})
export class ImagenPrincipalPerfilComponent implements OnInit {
   // configuracion servicio interno
   @Input('identificador') identificador = 'ficheroIndividual';
   @Input('idObjeto') idObjeto: string = '1';
   @Input('camposExtra') camposExtra: ICamposExtra;
   @Input('objetoRelacionado') objetoRelacionado;
   @Input('urlArchivos') urlArchivos;
   @Input('formatosPermitidos') formatosPermitidos;
   @Input('tamanoMaximoFichero') tamanoMaximoFichero;
   // item;
   @Input('item') item: IFicheroExtendido ;
   @Output() subir = new EventEmitter<IFicheroExtendido>();
   @Output() editar = new EventEmitter<IFicheroExtendido>();
   @Output() eliminar = new EventEmitter<IFicheroExtendido>();
   constructor() { }
 
   ngOnInit(): void {
   }
 
   establecer(fichero) {
     this.item = fichero;
    }
    
    // imagen principal
   eliminarImagenPrincipal(item: IFicheroExtendido){
     this.eliminar.emit(item);
   }
   subirImagenPrincipal(item: IFicheroExtendido) {}
   
   editarImagenPrincipal(item: IFicheroExtendido){

    //  Revisar flag si nuevaImagen y camposEditados
    if(item.camposEditados == false && item.nuevaImagen == false) {
      console.log('Sin cambios');
    } else if (item.nuevaImagen == true) {
      // Alta del archivo 
      this.subir.emit(item);
    } else {
      // Edicion de parametros asociados
      this.editar.emit(item);
    }
    
   }

   guardarArchivo($event) {
    console.log('Debug archivo a guardar imagen principal producto', $event);
   }

}
