import { Component, OnInit, ViewChild } from '@angular/core';
import { ArbolAnidadoComponent } from '@appNeo/neoShared/components/arbol-anidado/arbol-anidado.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { IArbolData } from '@appNeo/neoShared/pages/demo-componentes/demo-componentes.component';
import { ArbolAnidadoService } from '@appNeo/neoShared/services/arbol-anidado/arbol-anidado.service';



@Component({
  selector: 'neo-arbol-permisos',
  templateUrl: './arbol-permisos.component.html',
  styleUrls: ['./arbol-permisos.component.scss'],
  providers: [ArbolAnidadoService]
})
export class ArbolPermisosComponent implements OnInit {
  @ViewChild('notificacionArbolPermisos') notificacionArbolPermisos: NotificacionComponent;
  @ViewChild('arbolPermisos') arbolPermisos: ArbolAnidadoComponent;
  constructor( 
    private arbolAnidadoService: ArbolAnidadoService
  ) { }

  ngOnInit(): void {
  }
  
  onAccionContenedorCabecera(event){
    console.log('Guardar arbol desde contenedor cabecera => Seleccion: ', this.arbolPermisos.obetenerSeleccion());
    if (this.tienePermisosSeleccionados) {
      this.showResult(this.arbolPermisos.obetenerSeleccion().toString(), 'ok');
    } else {
      this.showResult('Ningún permiso seleccionado', 'ko');
    }
  }

  get tienePermisosSeleccionados() {
    let contadorPermisos = 0;
    if (this.arbolPermisos) {
      contadorPermisos = this.arbolPermisos.obetenerSeleccion().length;
    }
    return contadorPermisos;
  }


  establecer(permisos) {
    setTimeout(()=>{
      this.arbolAnidadoService.setDatos(permisos);
    })
  }

  showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    this.notificacionArbolPermisos.eliminarNotificaciones();
    if (status === 'ok') {
      this.notificacionArbolPermisos.crearNotificacion(NotificacionEnum.Exito, `Operación correcta.`, message, false, IconEnum.Exito, '', true);
    } else {
      this.notificacionArbolPermisos.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la edición permisos del usuario', message, true, IconEnum.Error);
    }
  }

}
