import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@appNeo/neoCore/services/auth/auth.service';
import { FormTagEnum } from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import { PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { AccionesTablaEnum } from '@appNeo/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, tap, throwError } from 'rxjs';
import { DEMO_SECCIONES } from './demo-edicion-secciones-data';
import { EdicionTipoEnum, EdicionTipoItemEnum } from './helpers/enums/EdicionTipoEnum';
import { IContenidoBloqueSubseccion, IContenidoFichero, IEdicionSeccion } from './helpers/interfaces/IEdicionSeccion';
import { IFormItem } from './helpers/interfaces/IFormInput';

export interface ItemsInicialesItemsNeo {
  itemsIniciales: IFormItem[],
  itemsNeo: IFormInput[];
}
@Injectable({
  providedIn: 'root'
})
export class EdicionSeccionesService {

  public editandoSeccion$ = new BehaviorSubject<string>(null);

  // urlEdicionSeccion = environment.api_base_url + '/edicionSeccion';
  urlEdicionSeccion = environment.api_base_url_backoffice + '/edicionSeccion';
  urlEdicionSeccionMenu = environment.api_base_url_backoffice + '/edicionSeccionMenu';
  urlEdicionTipoItem = environment.api_base_url_backoffice + '/edicionTipoItem';
  urlEdicionSeccionGrupo = environment.api_base_url_backoffice + '/edicionSeccionGrupo';
  urlEdicionSeccionItems = environment.api_base_url_backoffice + '/edicionSeccionItems';
  urlCambiarBloqueSeccionGrupo = environment.api_base_url_backoffice + '/edicionSeccion' + '/cambiarBloqueSeccionGrupo';
  urlEdicionImagenItems = environment.api_base_url_backoffice + '/edicionItemImagen';
  urlEdicionContenidoBloquesSubseccion = environment.api_base_url_backoffice + '/edicionSeccion' + '/contenidoBloquesSubseccion';
  public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
  public edicionSecciones$: BehaviorSubject<IEdicionSeccion[]> = new BehaviorSubject<IEdicionSeccion[]>(null);
  public edicionSeccion$: BehaviorSubject<IEdicionSeccion> = new BehaviorSubject<IEdicionSeccion>(null);
  public totalEdicionSecciones$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService,
    private authService: AuthService,
    private router: Router
  ) { }

  set editandoSeccion(flag: string) {
    this.editandoSeccion$.next(flag);
  }

  set edicionSeccion(edicionSeccion: IEdicionSeccion) {
    this.edicionSeccion$.next(edicionSeccion);
  }

  // CRUD
  getAll(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IEdicionSeccion[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'id', tipoOrden: 'asc' };
    }
    let request = `${this.urlEdicionSeccion}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => { this.edicionSecciones$.next(data.data); }),
      tap((data: IRespApi) => { this.totalEdicionSecciones$.next(data.total); }),
      map((data: IRespApi) => this.mapearEntidades(data['data'])),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGetAllSeccionesEditables');
      })
    );
  }

  getEdicionSeccionMenu(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IEdicionSeccion[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'id', tipoOrden: 'asc' };
    }
    let request = `${this.urlEdicionSeccionMenu}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => { this.edicionSecciones$.next(data.data); }),
      tap((data: IRespApi) => { this.totalEdicionSecciones$.next(data.total); }),
      map((data: IRespApi) => this.mapearEntidades(data['data'])),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestgetEdicionSeccionMenu');
      })
    );
  }

  get(id): Observable<IEdicionSeccion> {
    return this.http.get(`${this.urlEdicionSeccion}/${id}`).pipe(
      tap(data=>console.log('datos', data)),
      map((data: IRespApi) => this.mapearEntidad(data['data'])),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGetSeccionesEditables');
      })
    );
  }

  mapearEntidades(listadoEntidad: IEdicionSeccion[]) {
    if (listadoEntidad.length) {
      // listadoEntidad.forEach((element, index) => {
      //   listadoEntidad[index] = this.mapearEntidad(element);
      // });
    }
    return listadoEntidad;
  }


  mapearEntidad(entidad: IEdicionSeccion): IEdicionSeccion {
    let item = Object.assign({}, entidad);
    if (item?.grupos) {
      item.grupos.map(seccionGrupo => seccionGrupo.multiple = this.auxiliar.getBoolean(seccionGrupo.multiple));

    }
    let _item = { ...item };
    return _item;
  }

  /************************************
   * EDICION TIPO ITEM                  *
  *************************************/
  getEdicionTipoItem(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IFormItem[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'id', tipoOrden: 'asc' };
    }
    let request = `${this.urlEdicionTipoItem}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']));
  }


  // configuracion parametros tam, numero, formatos
  getEdicionConfiguracionCampo(idEdicionTipo): Observable<any[]> {
    let filtros = { idEdicionTipo };
    let request = `${this.urlEdicionSeccion}/configuracionFicheros?${this.auxiliar.extraerFiltros(filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']));
  }

  obtenerConfiguracionCamposMultimedia() {
    let peticion = {
      campo_matrix_edicionTipo_6_: this.getEdicionConfiguracionCampo(EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA),
      campo_matrix_edicionTipo_7_: this.getEdicionConfiguracionCampo(EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_UNICO),
      campo_matrix_edicionTipo_8_: this.getEdicionConfiguracionCampo(EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE),
      campo_matrix_edicionTipo_9_: this.getEdicionConfiguracionCampo(EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE),
    };
    return forkJoin(peticion);
  }

  /************************************
   * ALTA ELEMENTO MULTIPLE            *
  *************************************/
  // sus para multiples itemsIniciales, itemsNeo
  getEdicionSeccionGrupo_ItemsInicialesItemsNeo(idEdicionSeccionGrupo, tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<ItemsInicialesItemsNeo> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'id', tipoOrden: 'asc' };
    }
    let request = `${this.urlEdicionSeccionGrupo}/${idEdicionSeccionGrupo}/itemsIniciales?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => this.obtenerCamposMatrixInicialYNeo(data['data'])));
  }


  obtenerCamposMatrixInicialYNeo(datos: any): ItemsInicialesItemsNeo {

    let itemsNeo: IFormInput[] = [];
    let itemsIniciales: IFormItem[] = [];
    if (datos?.grupo?.items && datos?.grupo?.items.length) {
      itemsIniciales = datos?.grupo?.items;
      datos?.grupo?.items.map(item => {
        itemsNeo.push(this.conversionNeoCampoFormulario(item))
      })
    }
    console.log('CAMPOS INICILAES', { itemsIniciales, itemsNeo });
    return { itemsIniciales, itemsNeo };
  }

  // ALTA pero valdra para todo
  convertirValorFormularioAMatrix(estructuraMatrix: IFormItem[], valoresIdioma: any): IFormItem[] {
    console.log('[GRABACION SECCION EDITABLE ] LOS VALORE [Creacion elemento convertirCamposMatrix desde camposNeo] ', estructuraMatrix, valoresIdioma);
    let itemsMatrix = Object.assign([], estructuraMatrix);
    itemsMatrix.map((item, key) => {
      item.idiomas = valoresIdioma[item.nombre];
      // console.log(key, item.nombre, item.idiomas, valoresIdioma[item.nombre]);
      // if (!item.idiomas) {
      //   itemsMatrix.splice(key, 1);
      // }
    })
    itemsMatrix.map(item => item.obligatorio = this.auxiliar.getBoolean(item.obligatorio))
    // console.log('Campos a enviar ', itemsMatrix);
    return itemsMatrix;
  }


  createElemento(idSeccionGrupo, request: any): Observable<any> {
    console.log(' -----> Enviamos ', request, request);
    return this.http.post<IRespApi>(`${this.urlEdicionSeccionItems}/${idSeccionGrupo}`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPostCreateElemento');
      })
    );
  }

  deleteElemento(idSeccionGrupo, request: any): Observable<any> {
    console.log('PETICION DELETE ', `${this.urlEdicionSeccionGrupo}/${idSeccionGrupo}/items`,);
    return this.http.delete(`${this.urlEdicionSeccionGrupo}/${idSeccionGrupo}/items`, { body: request }).pipe(
      map((valor: string) => this.auxiliar.getBoolean(valor)),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestDeleteElementoMultiple');
      })
    );
  }

  actualizarCamposNoFichero(idSeccionGrupo, request: any): Observable<any> {
    console.log(' -----> Enviamos ', request, request);
    return this.http.patch<IRespApi>(`${this.urlEdicionSeccionItems}/${idSeccionGrupo}`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPatchCreateElemento');
      })
    );
  }

  /************************************
    * Ficheros: imagenes y adjuntos    *
  *************************************/
  deleteFicherosEdicionSeccionItem(idEdicionItem, request: any): Observable<any> {
    return this.http.delete(`${this.urlEdicionSeccionItems}/${idEdicionItem}/ficheros`, { body: { ids: [request.ids.id] } }).pipe(
      map((valor: string) => this.auxiliar.getBoolean(valor)),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestDeleteFicherosEdicionSeccionItem');
      })
    );
  }

  postFicherosEdicionSeccionItem(idSeccionGrupo, request: any): Observable<any> {
    // console.log('Peticion ', request);
    return this.http.post<IRespApi>(`${this.urlEdicionSeccionItems}/${idSeccionGrupo}/ficheros`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPostFicherosEdicionSeccionItem');
      })
    );
  }

  postFicherosTempEdicionSeccionItem(idSeccionGrupo, ficheros: File[], camposExtra?: object): Observable<any> {
    const formData: FormData = new FormData();
    // console.log('fichero a subir', ficheros, camposExtra);
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++) {
        // console.log('fichero a subir', ficheros, ficheros[i], ficheros[i].name, camposExtra);
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
      }
      if (camposExtra && Object.keys(camposExtra)?.length > 0) {
        Object.entries(camposExtra).forEach(([key, value]) => {
          formData.append(key, value.toString());
        });
      }
    }
    let peticion = `${this.urlEdicionSeccionItems}/${idSeccionGrupo}/ficherosTemp`
    return this.http.post<IRespApi>(peticion, formData, {
      reportProgress: true, observe: "events"
    }).pipe(
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPostFicherosTempEdicionSeccionItem');
      })
    );
  }

  patchImagenEdicionSeccionItem(idImagen, request) {
    return this.http.patch<IRespApi>(`${this.urlEdicionImagenItems}/${idImagen}`, request).pipe(
      map((resp: IRespApi) => resp.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestPostFicherosEdicionSeccionItem');
      })
    );
  }

  getImagenDefecto() {
    let request = `${this.urlEdicionSeccion}/imagenDefecto`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETImagenDefecto');
      })
    );
  }

  getImagenesEdicionSeccionGrupo(idSeccionGrupo, filtros?: any) {
    let _filtros = filtros;

    let request = `${this.urlEdicionSeccionGrupo}/${idSeccionGrupo}/imagenes?${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETImagenesEdicionSeccionGrupo');
      })
    );
  }

  getAdjuntosEdicionSeccionGrupo(idSeccionGrupo, filtros?: any) {
    let _filtros = filtros;
    // if (!_filtros || _filtros && !filtros?.orden) {
    //   _filtros = { orden: 'id', tipoOrden: 'asc' };
    // }
    let request = `${this.urlEdicionSeccionGrupo}/${idSeccionGrupo}/adjuntos?${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETAdjuntosEdicionSeccionGrupo');
      })
    );
  }

  // contenido campos tipo ficheros, rutas
  getFicherosEdicionSeccionItems(idSeccionGrupo, filtros?: any): Observable<IContenidoFichero[]> {
    let _filtros = filtros;
    // if (!_filtros || _filtros && !filtros?.orden) {
    //   _filtros = { orden: 'id', tipoOrden: 'asc' };
    // }
    // let request = `${this.urlEdicionSeccionGrupo}/${idSeccionGrupo}/ficheros?${this.auxiliar.extraerFiltros(_filtros)}`;
    let request = environment.api_base_url_backoffice + '/edicionSeccionItems/8/ficheros?edicionSeccionGrupo/8/ficheros?&idEdicionItems=21,24'
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETFicherosEdicionSeccionItem');
      })
    );
  }

  /************************************
    * Contenido completo seccion grupo por idioma y por clave seccion grupo    *
  *************************************/
  getContenidoBloquesSubseccion(nombreSeccionGrupo, tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IContenidoBloqueSubseccion> {
    let _filtros = filtros;
    // vendra el idioma, si no se envia devuelve el de por defecto
    // if (!_filtros || _filtros && !filtros?.orden) {
    //   _filtros = { idIdioma: '1'};
    //   _filtros = { orden: 'id', tipoOrden: 'asc' };
    // }
    let request = `${this.urlEdicionContenidoBloquesSubseccion}/${nombreSeccionGrupo}?${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETContenidoBloquesSubseccion');
      })
    );
  }

  getContenidoElementoBloquesSubseccion(nombreSeccionGrupo, numeroBloque, tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IContenidoBloqueSubseccion> {
    let _filtros = filtros;
    // vendra el idioma, si no se envia devuelve el de por defecto
    // if (!_filtros || _filtros && !filtros?.orden) {
    //   _filtros = { idIdioma: '1'};
    //   _filtros = { orden: 'id', tipoOrden: 'asc' };
    // }
    let request = `${this.urlEdicionContenidoBloquesSubseccion}/${nombreSeccionGrupo}/${numeroBloque}?${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      map((data: IRespApi) => data['data']),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestGETContenidoBloquesSubseccion');
      })
    );
  }

  getCamposEdicionItem(elemento, clave, indice) {
    let result: any = clave;
    let claveIncide = '';
    if (indice >= 0) {
      claveIncide = '_' + (indice + 1);
    }
    let claveCampo = clave + claveIncide;
    let campoMatrix = this.auxiliar.busquedaPropiedad(elemento?.campos, 'nombre', claveCampo);
    if (!campoMatrix) {
      throw new Error(`[ERROR DE CONFIGURACION COMPONENTE NEO ][SECCIONES EDITABLES] No existe el campo ${claveCampo} en la seccion consultada`);
    }
    switch (campoMatrix?.idTipoItem) {
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA:
        let _result = this.auxiliar.busquedaPropiedad(elemento?.campos, 'nombre', claveCampo);
        let textoAlternativo = this.auxiliar.obtenerContenidoPorIdioma(_result?.idiomas)['alt'];
       
        if (_result?.valor['nombre'] != 'default.png') {
          result = { 'ruta': _result.valor['ruta'], 'miniaturas': _result.valor['miniaturas'], 'nombre':_result.valor['nombre'], 'alt': textoAlternativo && textoAlternativo != "" ? textoAlternativo : _result?.valor['nombre'] };
        } else {
          result = null;
        }
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE:
        console.log(claveCampo)
        let _resultMultiple = this.auxiliar.busquedaPropiedad(elemento.campos, 'nombre', claveCampo).valor;
        let textoAlternativoMultiple;
        let resultMultiple = [];
        _resultMultiple.forEach( imagen => {
          textoAlternativoMultiple = this.auxiliar.obtenerContenidoPorIdioma(imagen.idiomas)['alt'];
          if (imagen?.nombre != 'default.png') {
            resultMultiple.push({ 'ruta': imagen['ruta'], 'miniaturas': imagen['miniaturas'], 'nombre':imagen['nombre'], 'alt': textoAlternativoMultiple ?? imagen['nombre'] });
          } else {
            resultMultiple.push(null);
          }
        });
        result = resultMultiple;
        break;
      default:
        result = this.auxiliar.busquedaPropiedad(elemento.campos, 'nombre', claveCampo).valor;
    }
    if (result == null) {
      result = clave;
    }
    return result;
  }

  postCambiarBloqueSeccionGrupo(nombreGrupo, request) {
    return this.http.post(`${this.urlCambiarBloqueSeccionGrupo}/${nombreGrupo}`, request).pipe(
      map((data: IRespApi) => {data['data']})
    );
  }

  /************************************
   * Funciones apoyo                  *
  *************************************/
  conversionFicheroExtendido(file): IFicheroExtendido {
    let idFichero = (file?.id) ? file.id : 0;
    let idEdicionItem = file.idEdicionItem ? file.idEdicionItem : idFichero;
    let idTipoItem = file.idTipoItem ? file.idTipoItem : null;
    let tituloAlternativo = file?.idiomas ? this.auxiliar.obtenerContenidoPorIdioma(file?.idiomas)['alt'] : null;
    return {
      id: idFichero,
      idExterno: idEdicionItem,
      datosExternos: { idEdicionItem, idTipoItem },
      iconDrag: { nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable text-grey500' },
      nombre: file.nombre,
      extension: '.' + this.auxiliar.getFicheroExtension(file.nombre),
      peso: 1500,
      posicion: file.posicion,
      tituloSeo: file.nombre,
      textoAlternativoSeo: tituloAlternativo ?? file.nombre,
      imagen: {
        src: file.ruta
      },
      acciones: [
        {
          idAccion: AccionesTablaEnum.Editar,
          tooltip: 'Editar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--edit',
          disabled: false,
        },
        {
          idAccion: AccionesTablaEnum.Eliminar,
          tooltip: 'Eliminar',
          icono: true,
          iconClass: 'icon-20 icon-ibm--trash-can text-danger',
          disabled: false,
        },
      ]
    };
  }
  conversionNeoCampoFormulario(item: IFormItem, codigoIdioma: string = 'es'): IFormInput {
    // console.log('[Conversion campos] ', item);
    let ele: IFormInput;
    // let tipo = <unknown>item.nombreTipoItem.toLocaleLowerCase();
    // if (tipo === 'imagen') {
    //   tipo = 'fichero';
    // }
    let tag = this.tipoNeoCampoEquivalente(item.idTipoItem);
    let nombreTipoCampo = (item.nombreTipoItem) ? ' [' + item.nombreTipoItem + ']' : '';
    let id = item.id;
    let idTipoItem = item.idTipoItem ? item.idTipoItem : null;
    // campos ficheros
    let camposFicheros = {};
    if (this.esFichero(item.idTipoItem)) {
      // formatos y presubida ficheros
      camposFicheros['presubidaFichero'] = false;
      camposFicheros['formatosPermitidos'] = environment.fichero_formatos_permitidos;
      camposFicheros['maximoElementosASubir'] = 10;
      camposFicheros['tamanoMaximoFichero'] = environment.fichero_tamano_maximo;
    }
    return {
      idExterno: item.id,
      datosExternos: { idEdicionItem: item.id, idTipoItem },
      tag,
      maxLength: item.logintud, // longitud
      // formControlName: item.id, //nombre
      formControlName: item.nombre, //item.nombre
      obligatorio: this.auxiliar.getBoolean(item.obligatorio), //obligatorio
      // label:  (item.label) ? item.label : item.id,
      label: ((item['idiomas'] && item['idiomas'][codigoIdioma]?.label) ? item['idiomas'][codigoIdioma].label : item.nombre) + nombreTipoCampo,
      valor: (item['idiomas'] && item['idiomas'][codigoIdioma]?.texto) ? item['idiomas'][codigoIdioma].texto : '',
      type: '',
      placeholder: '',
      textoAyuda: item?.tooltip, //tooltip
      validadores: [],
      ...camposFicheros
    }
  }



  conversionNeoPerfilPropiedad(item: IFormItem, codigoIdioma: string = 'es'): IPerfilPropiedad {
    let tipo = <unknown>item.tipo;

    let tag = (item?.idTipoItem) ? this.tipoNeoPerfilPropiedadEquivalente(item.idTipoItem):  <PerfilPropiedadTagEnum>tipo;
    let tagCampo = (item?.idTipoItem) ? this.tipoNeoPerfilPropiedadTagCampoEquivalente(item.idTipoItem):   <TagCampoEnum> tipo;
    let nombreTipoCampo = (item.nombreTipoItem) ? ' [' + item.nombreTipoItem + ']' : '';
    return {
      tag,
      tagCampo,
      identificador: item.nombre,
      label: ((item['idiomas'] && item['idiomas'][codigoIdioma]?.label) ? item['idiomas'][codigoIdioma].label : item.nombre) + nombreTipoCampo,
      valor: (item['idiomas'] && item['idiomas'][codigoIdioma]?.texto) ? item['idiomas'][codigoIdioma].texto : '',
    };
  }

  tipoNeoCampoEquivalente(idCampoMatrix) {
    let tipo: FormTagEnum = FormTagEnum.input;
    switch (idCampoMatrix.toString()) {
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_INPUT :
        tipo = FormTagEnum.input;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TEXTAREA :
        tipo = FormTagEnum.textarea;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO :
        tipo = FormTagEnum.editor;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_MINIMO :
        tipo = FormTagEnum.editor;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_VIDEO :
        tipo = FormTagEnum.fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA :
        tipo = FormTagEnum.fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_UNICO :
        tipo = FormTagEnum.fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE :
        tipo = FormTagEnum.ficheroZonaArrastre;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE :
        tipo = FormTagEnum.ficheroZonaArrastre
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_COLORPICKER :
        tipo = FormTagEnum.input
        break;
    }
    return tipo;
  }


  tipoNeoPerfilPropiedadEquivalente(idCampoMatrix) {
    let tipo: PerfilPropiedadTagEnum = PerfilPropiedadTagEnum.Basico;
    switch(idCampoMatrix){
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_INPUT :
        tipo = PerfilPropiedadTagEnum.Basico;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TEXTAREA :
        tipo = PerfilPropiedadTagEnum.Texto;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO :
        tipo = PerfilPropiedadTagEnum.Texto;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_MINIMO :
        tipo = PerfilPropiedadTagEnum.Texto;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_VIDEO :
        tipo = PerfilPropiedadTagEnum.Basico;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA :
        tipo = PerfilPropiedadTagEnum.Imagen;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_UNICO :
        tipo = PerfilPropiedadTagEnum.Imagen;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE :
        tipo = PerfilPropiedadTagEnum.Imagen;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE :
        tipo = PerfilPropiedadTagEnum.Imagen;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_COLORPICKER :
        tipo = PerfilPropiedadTagEnum.Basico;
        break;
    }
    return tipo;
  }

  tipoNeoPerfilPropiedadTagCampoEquivalente(idCampoMatrix) {
    let tipo: TagCampoEnum = TagCampoEnum.Input;
    switch(idCampoMatrix){
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_INPUT :
        tipo = TagCampoEnum.Input;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TEXTAREA :
        tipo = TagCampoEnum.Textarea;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO :
        tipo = TagCampoEnum.Editor;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_MINIMO :
        tipo = TagCampoEnum.Editor;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_VIDEO :
        tipo = TagCampoEnum.Fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA :
        tipo = TagCampoEnum.Fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_UNICO :
        tipo = TagCampoEnum.Fichero;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE :
        tipo = TagCampoEnum.FicheroZonaArrastre;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE :
        tipo = TagCampoEnum.FicheroZonaArrastre;
        break;
      case EdicionTipoItemEnum.EDICION_TIPO_ITEM_COLORPICKER :
        tipo = TagCampoEnum.Input;
        break;
    }
    return tipo;
  }

  esFichero (idTipoItem) {
    return (this.esFicheroImagen(idTipoItem) || this.esFicheroAdjunto(idTipoItem))
  }

  esFicheroImagen(idTipoItem) {
    return ([EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE, EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_UNICA].includes(idTipoItem))
  }

  esFicheroAdjunto(idTipoItem) {
    return ([EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE, EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_UNICO].includes(idTipoItem))
  }

  esFicheroMultiple(idTipoItem) {
    return ([EdicionTipoItemEnum.EDICION_TIPO_ITEM_ADJUNTO_MULTIPLE, EdicionTipoItemEnum.EDICION_TIPO_ITEM_IMAGEN_MULTIPLE].includes(idTipoItem))
  }

  esTexto (idTipoItem) {
    return ([EdicionTipoItemEnum.EDICION_TIPO_ITEM_TEXTAREA, EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_COMPLETO, EdicionTipoItemEnum.EDICION_TIPO_ITEM_TINYMCE_MINIMO].includes(idTipoItem))
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let peticion = {
      listadoEntidad: this.getEdicionSeccionMenu().pipe(
        catchError(err => of(null))
      )     
    };
    const id = route.params.id;
    if (id) {
      peticion['configuracionFormatosTamCamposFicheros'] = this.obtenerConfiguracionCamposMultimedia().pipe(
        catchError(err => of(null))
      );
      peticion['imagenDefecto'] = this.getImagenDefecto().pipe(
        catchError(err => of(null))
      );
      peticion['entidad'] = this.get(id).pipe(
        catchError(err => of(null))
      );
    }
    return forkJoin(peticion);
  }

  /********************************/
  /* MANEJADOR ERRORES            */
  /********************************/
  private handleError(err: IRespApi, idRequest = '') {
    // control error server con un html ok
    if (err == 'OK') {
      let mensaje = environment.errorServidorSinCodigo;
      return throwError({ 'error': true, 'data': mensaje, mensaje, 'err': err });
    }
    // dialog específico
    err.request = idRequest;
    if ( err.codigoError === 422) {
      this.errorApi$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if ( err.codigoError === 403) {
      mensaje = `${ err.mensaje }: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404  || err.codigoError === 422) {
      let avisos = '';
      if ( err.data ) {
        err.data.forEach(element => {
          if ( element.id === 999 ) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = (avisos.length)  ? avisos : err?.mensaje;
    }

    if (err.codigoError === 500) {
      let avisos = '';
      if ( err.data ) {
        err.data.forEach(element => {
          if ( element.id === 999 ) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = err?.mensaje +  avisos;
    }
    console.log(` Error Operacion ${err.data} ${mensaje} ${err}`);
    return throwError({'error' : true, 'data': err.data, mensaje, 'err': err });
 }
}
