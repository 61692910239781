import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, Subject, tap, throwError } from 'rxjs';
export interface IModulos {
  nombre: string;
  valor: boolean;
}

export const MODULOS_ACTIVOS: IModulos[] =
  [
    {
      nombre: 'EDICION_ACTIVO',
      valor: true,
    }
  ];

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  modulos$: BehaviorSubject<IModulos[]> = new BehaviorSubject<IModulos[]>(null);
  urlEntidad = environment.api_base_url_backoffice + '/hayModulo';
  public error422Api$: Subject<IRespApi> = new Subject<IRespApi>();
  constructor(
    private auxiliar: AuxiliarService,
    private http: HttpClient,
  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let peticion = {
      listadoEntidad: this.getAllModulos().pipe(
        catchError(err => of(null))
      )
    };
    return forkJoin(peticion);
  }

  getAllModulos(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IModulos[]> {
    let request = (tipoPaginador === TipoPaginadorEnum.offline)
      ? this.urlEntidad
      : `${this.urlEntidad}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(filtros)}`;
    // this.modulos$.next(MODULOS_ACTIVOS);
    // return of(MODULOS_ACTIVOS);
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => this.modulos$.next(data.data)),
      map((data: IRespApi) => data.data),
      catchError((err: IRespApi) => {
        return this.handleError(err, 'IRequestMenuFront');
      })

    )
  }

  hayModulo(modulo: string): boolean {
    // let clave = modulo.toLocaleUpperCase() + '_ACTIVO';
    let valor = this.modulos$.value[this.auxiliar.buquedaPosicionPropiedad(this.modulos$.value, 'nombre', modulo)].valor;
    if (valor) {
      return valor;
    } else {
      return false
    }
  }
  private handleError(err: IRespApi, idRequest = '') {

    // dialog específico
    if (err.codigoError === 422) {
      err.request = idRequest;
      this.error422Api$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if (err.codigoError === 403) {
      mensaje = `${err.mensaje}: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404) {
      let avisos = '';
      if (err.data) {
        if (typeof err.data === 'string') {
          avisos = err.data;
        } else {
          err.data.forEach(element => {
            if (element.id === 999) {
              avisos = ' ' + element.mensaje + ' ';
            }
          });
        }
      }
      mensaje = err?.mensaje + avisos;
    }
    return throwError({ 'error': true, 'data': err.data, mensaje });
  }
}
