import { Component, Input } from '@angular/core';
import { ClasesPerfilAnchoEnum, LayoutPerfiloEnum, PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import { ClasesCampoLayoutCamposAnchoFijoFormularioEnum, ClasesCampoLayoutCamposPorcentajeFormularioEnum } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';

// PerfilPropiedad
@Component({
  selector: 'neo-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent {

  @Input() propiedades: IPerfilPropiedad[];
  @Input('aplicarFlex') aplicarFlex: boolean = true;
  @Input('perfilAncho') perfilAncho: ClasesPerfilAnchoEnum = ClasesPerfilAnchoEnum.perfilAnchoCompleto;   
  @Input('layout') layout: LayoutPerfiloEnum = LayoutPerfiloEnum.layoutCamposPorcentaje;
  @Input('perfilFormulario') perfilFormulario: boolean = false;

  // TODO unificar y llevarlo a un sitio comun tanto para perfil como propieadad
  clasesCampoDefectoLayoutCamposPorcentaje  = {
    input: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    inputFilter: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    textarea: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    select: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    chipsAutocomplete: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    date: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    boolean: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    importe: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    cantidad: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    telefono: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    radio: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    checkbox: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    contrasena: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    captcha: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    fichero: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    estado: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent3x],
    editor: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    clipboard: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent4x]
  }


  clasesCampoDefectoLayoutCamposAnchoFijo  = {
    input: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    inputFilter: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    textarea: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    select: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    chipsAutocomplete: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    date: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    boolean: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    importe: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    cantidad: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    telefono: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    radio: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    checkbox: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    contrasena: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x],
    captcha: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    fichero: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx2x],
    ficheroZonaArrastre: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    estado: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx3x],
    editor: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100],
    clipboard: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPx1x]
  }
  
  esTexto( propiedad: IPerfilPropiedad): boolean {
    return PerfilPropiedadTagEnum.Texto === propiedad.tag;
  }

  get _LayoutPerfilEnum() {
    return LayoutPerfiloEnum;
  }

  get _TagCampoEnum() {
    return TagCampoEnum;
  }
}
