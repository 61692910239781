import { MenuItemClassEnum } from "@appNeo/neoShared/helpers/enums/MenuItemClass.enum";
import { IBarraIzquierdaMenuItems, ISeccionMenu } from "@appNeo/neoShared/helpers/interfaces/IBarraIzquierdaMenuItems";
import { environment } from "@environments/environment";


export const  LAYOUTS_SECCION: IBarraIzquierdaMenuItems[] = [
  // {
  //   path: 'usuario',
  //   label: 'Usuario',
  //   class: MenuItemClassEnum.noDesplegable,
  //   ultimoNivelPath: 'usuario',
  //   iconoClase: 'icon-ibm--letter--Aa',
  //   submenu: [
  //     {
  //       path: 'usuario/cuenta',
  //       label: 'Mi Cuenta',
  //       class: MenuItemClassEnum.noDesplegable,
  //       ultimoNivelPath: 'usuario',
  //     },
  //     {
  //       path: 'usuarios',
  //       label: 'Listado usuarios',
  //       class: MenuItemClassEnum.subItemDesplegable,
  //       ultimoNivelPath: 'test-item-active',

  //     }
  //   ],
  // },
  {
    path: environment.ruta_padre_admin_demo +  'dashboard',
    label: 'Dashboard',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo +  'usuarios',
    label: 'Listado usuarios',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo +  'productos',
    label: 'Listado productos',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo +  'tickets',
    label: 'Tickets',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: 'resEvento',
    label: 'Reserva evento',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  }
];

export const PAGES_SECCION: IBarraIzquierdaMenuItems[] = [
  {
    path: 'auth',
    label: 'Autenticación',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: 'auth',
    iconoClase: 'icon-ibm--letter--Aa',
    submenu: [
      {
        path: 'auth/login',
        label: 'Login',
        class: MenuItemClassEnum.noDesplegable,
        ultimoNivelPath: 'login',
      },
      {
        path: 'auth/recuperar-contrasena',
        label: 'Recuperar contraseña',
        class: MenuItemClassEnum.noDesplegable,
        ultimoNivelPath: 'recuperar-contrasena',
      },
      {
        path: 'auth/restablecer-contrasena',
        label: 'Reestablecer contraseña',
        class: MenuItemClassEnum.noDesplegable,
        ultimoNivelPath: 'restablecer-contrasena',
      },
      {
        path: 'auth/registro',
        label: 'Registro',
        class: MenuItemClassEnum.noDesplegable,
        ultimoNivelPath: 'registro',
      },      
    ],    
  },
  {
    path: 'error/404',
    label: '404',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: 'error/500',
    label: '500',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  }
]

export const  BARRA_IZQUIERDA_MENU: ISeccionMenu[] = [
    {
        seccion: 'Layouts',
        menu: LAYOUTS_SECCION
    },
    {
      seccion: 'Páginas',
       menu: PAGES_SECCION
    },
];
