import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { DragDropService, IColumnaDragDrop } from '@appNeo/neoShared/services/drag-drop/drag-drop.service';
import { LoremIpsum } from 'lorem-ipsum';
import { IEtiquetas } from '../../productos/productos-configuracion';

@Component({
  selector: 'neo-drag-drop-etiquetas',
  templateUrl: './drag-drop-etiquetas.component.html',
  styleUrls: ['./drag-drop-etiquetas.component.scss'],
  providers: [DragDropService]
})
export class DragDropEtiquetasComponent implements OnInit {

  etiquetas : IEtiquetas[] = [];
  etiquetasAplicadas : IEtiquetas[] = [];

  etiquetasAplicadasIniciales : IEtiquetas[] = [];
  cambios:boolean = false;
  @ViewChild('notificacionDragDropEtiquetas') notificacionDragDropEtiquetas: NotificacionComponent;
  constructor(
    private dragDropService: DragDropService,
  ) { }

  ngOnInit(): void {}

  cargarDatos(){
    this.setItemsDragDrop();
    this.subscripcionCambiosDragDrop();
  }

   // DRAG DROP
  private setItemsDragDrop() {
    const columnaIzqDrag: IColumnaDragDrop[] = [];
    const columnaDerDrag: IColumnaDragDrop[] = [];
    this.etiquetas.forEach( item => {
      columnaIzqDrag.push({
        visibilidadFiltrado: true,
        id: item.id,
        nombre: item.nombre,
      })
    })
    this.etiquetasAplicadas.forEach( item => {
      columnaDerDrag.push({
        visibilidadFiltrado: true,
        id: item.id,
        nombre: item.nombre,
      })
    })
    this.dragDropService.columnaIzquierda = columnaIzqDrag;
    this.dragDropService.columnaDerecha = columnaDerDrag;
  }

  private subscripcionCambiosDragDrop() {
    this.dragDropService.columnaIzquierda$.subscribe(items => {
      console.log('Cambio items columna izq drag: ', items);
    });
    this.dragDropService.columnaDerecha$.subscribe(items => {
      console.log('Cambio items columna dcha drag: ', items);
      this.cambios = true;
      this.etiquetasAplicadas = items;
    });
  }

  onAccionContenedorCabecera(event){
    if (this.cambios) {
      this.showResult('', 'ok');
    } else {
      this.showResult('Ningún permiso seleccionado', 'ko');
    }
  }

  establecer(etiquetas, etiquetasSeleccionadas) {
    this.etiquetas = etiquetas;
    this.etiquetasAplicadas = etiquetasSeleccionadas;
    this.etiquetasAplicadasIniciales = etiquetasSeleccionadas;
    this.cargarDatos();
    
  }

  showResult(message: string, status: string) {
    this.notificacionDragDropEtiquetas.eliminarNotificaciones();
    if (status === 'ok') {
      this.cambios = false;
      this.notificacionDragDropEtiquetas.crearNotificacion(NotificacionEnum.Exito, `Operación correcta.`, message, false, IconEnum.Exito, '', true);
    } else {
      this.notificacionDragDropEtiquetas.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la edición etiquetas del usuario', message, true, IconEnum.Error);
    }
  }

}
