import { CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input,  Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FiltrosService } from '@appNeo/neoCore/services/filtros/filtros.service';
import { IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { IKanbanItem, KanbanService } from '@appNeo/neoShared/services/kanban/kanban.service';
import { AccionesTablaEnum, IBotonAccionCabeceraTabla } from '@appNeo/neoShared/services/tabla/tabla.service';
import { BusquedaComponent } from '../busqueda/busqueda.component';
import { FiltrosTablaComponent } from '../tabla/filtros-tabla/filtros-tabla.component';

@Component({
  selector: 'neo-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss'],
  providers: [FiltrosService, FormularioService]
})
export class KanbanComponent {

  // CUSTOMIZADO CARD 
  @Input() customKanbanCard: TemplateRef<any>;
  @Input() desactivarMovimientos: boolean = false;
  @Input() iconoCabecera: string = 'icon-ibm--copy';
  @Input() mostrarContadorCabecera: boolean = true;
  @Output() accionCabeceraClick = new EventEmitter<[IBotonAccionCabeceraTabla]>();  
  //FILTRADO
  //Visualizar filtrado
  @Input() filtrado: boolean = false;
  kanbanOnline = true;
  //Filtrado en caliente
  @Input() filtradoSinBotonSubmit = false;
  @Input() keyFiltrado: string;
  @Input() valoresInicialesFiltrado: object;
  @Input() numeroFiltros = 0;
  // TODO verle el sentido
  //Inputs formulario filtrado
  @Input() inputsFormularioFiltrado: IFormInput[];
 
  @Output() clickItem = new EventEmitter<object>();
  @Output() clickMovimientoItemColumna = new EventEmitter<{id: string, items: IKanbanItem[]}>();
  @Output() clickMovimientoItemEntreColumnas = new EventEmitter<{idOrigen: string, itemsOrigen: IKanbanItem[], idDestino: string, itemsDestino: IKanbanItem[]}>();

  //SOLO APLICA A KABAN ONLINE
  @Output() aplicarFiltros = new EventEmitter<object>();
  @Output() cambioPaginador = new EventEmitter<object>();
  // basico termino busqueda
  @ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;
  @ViewChildren(FiltrosTablaComponent) filtrosTabla: QueryList<FiltrosTablaComponent>;
  @ViewChild('dialogFiltrosResponsivos') dialogFiltrosResponsivos: TemplateRef<any>;
  botoneraDialogFiltrosResponsivos = [{
      label: 'Limpiar filtros',
      id: 'btn-limpiar',
      tipoAccion: 'accion',
      type: 'button',
      color: 'primary',
      disabled: this.deshabilitarLimpiarFiltros,
      activo: true,
      basic: true,
      iconoClase:'icon-20 icon-ibm--clean',
      matprefix: true
    },
    {
      label: 'Aplicar filtros',
      id: 'btn-filtrar',
      tipoAccion: 'accion',
      type: 'button',
      color: 'primary',
      disabled: false,
      activo: true,
      flat: true
    }];
  valoresFiltrado: object;
  cambioValoresFiltrado = false;
  cambioValorBuscador = false;
  @Input() mostrarAccionesCabecera = true;
  @Input() mostrarTotalElementos = true;
  @Input() btnAccionesLote = false;
  @Input() botoneraAccionesCabecera: IBotonAccionCabeceraTabla[] = [
    {
      id: AccionesTablaEnum.Crear,
      color: 'primary',
      bloqueClass: 'btn-add',
      mostrarBtnLg: true,
      neoButtonClassLg: 'btn-crear-lg',
      iconClassLg: 'icon-16 icon-ibm--add',
      neoButtonInputClassLg: 'btn-sm',
      mostrarBtnXs: true,
      textoLg: 'Nueva Entrada',
      neoButtonClassXs: 'btn-crear-xs',
      iconClassXs: 'icon-16 icon-ibm--add',
      neoButtonInputClassXs: 'btn-sm',
      textoXs: '',
    }
  ];
  constructor(
    public kanbanService: KanbanService,  
    private filtrosService: FiltrosService,
    private formularioService: FormularioService,
    private dialog: MatDialog

  ) { }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.clickMovimientoItemColumna.emit(
      {
        'id': event.container.id,
        'items': event.container.data
      });
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      this.clickMovimientoItemEntreColumnas.emit(
        {
          'idOrigen': event.previousContainer.id, 
          'itemsOrigen': event.previousContainer.data, 
          'idDestino': event.container.id, 
          'itemsDestino': event.container.data,
        });
    }
  }

  onClickItem(event, item) {
    this.clickItem.emit(item);
  }

  ngOnInit() {
    if (this.filtrado) this.setInputsValidadoresFiltrado();
    this.numeroFiltros = (this.filtrado) ? this.numeroFiltros + 1 : 0;
    this.valoresFiltrado = Object.assign({}, this.getValoresFiltrado());
    if (JSON.stringify(this.valoresFiltrado) !== JSON.stringify(this.valoresInicialesFiltrado)) this.cambioValoresFiltrado = true;
    
  }

   setInputsValidadoresFiltrado() {
    this.formularioService.inputs = this.inputsFormularioFiltrado;
  }

  getValoresFiltrado() {
    return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
  }


  // filtrado
  eventoCambioFormularioFiltros(entidadFormulario: object) {
    this.determinarCambioValoresFiltradoIniciales(entidadFormulario);
    this.guardarFiltrosStorage(entidadFormulario);
    this.valoresFiltrado = Object.assign({}, entidadFormulario);
    if (this.filtradoSinBotonSubmit) {
      if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
      this.filtrar();
    }
  }

  determinarCambioValoresFiltradoIniciales(entidadFormulario) {
    JSON.stringify(entidadFormulario) !== JSON.stringify(this.valoresInicialesFiltrado) ? this.cambioValoresFiltrado = true : this.cambioValoresFiltrado = false;
  }

  clearBuscador(filterValue: string) {
    filterValue ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
    this.valoresFiltrado['busqueda'] = filterValue;
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }
  

  eventoCambioBuscador(filterValue: string) {
    filterValue ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
    this.valoresFiltrado['busqueda'] = filterValue;
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }


  submitFiltrado() {
    if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }

  //TODO: AÑADIR FLAG PARA GUARDAR INPUT BÚSQUEDA
  guardarFiltrosStorage(valoresFiltrado: object) {
    let filtradoSinBusqueda = Object.assign({}, valoresFiltrado);
    delete filtradoSinBusqueda['busqueda'];
    this.filtrosService.setFiltros(this.keyFiltrado, filtradoSinBusqueda);
  }


  clearFilter() {
    this.filtrosService.clearFiltros(this.keyFiltrado);
    this.filtrosBusqueda.clearBuscador();
    this.filtrosTabla.forEach(filtroTabla => {
      filtroTabla.clearFilter();
    });
    this.cambioValoresFiltrado = false;
  }

  filtrar(resetPaginador = true) {
    setTimeout(() => {
      this.filtrosTabla.forEach(filtroTabla => {
        filtroTabla.validar();
        if (!filtroTabla.determinarErroresValidacion()) {
          console.log('Filtrar kanban');
          this.aplicarFiltros.emit(this.valoresFiltrado);          
        }
      });
    });
  }

  submitAccionDialogFiltrosResponsivos(accionSeleccionada){
    switch(accionSeleccionada.id) {
      case 'btn-filtrar':
        this.filtrar();
        // this.dialogoMasInfoRef.close();
        if (JSON.stringify(this.filtrosTabla.first.valoresFiltrado) !== JSON.stringify(this.filtrosTabla.last.valoresFiltrado)) {
          this.filtrosTabla.first.setValores(this.filtrosTabla.last.valoresFiltrado);
        }
        if (!this.filtrosTabla.last.determinarErroresValidacion()) {
          this.dialog.closeAll();
          this.submitFiltrado();
        };
        break;
      case 'btn-limpiar':
        this.clearFilter();
        break;
    }
  }

   // FILTROS RESPONSIVOS
   abrirFiltros(){
    this.dialog.open(this.dialogFiltrosResponsivos, {disableClose: true});
  }


  get deshabilitarLimpiarFiltros() {
    if (!this.cambioValoresFiltrado && !this.cambioValorBuscador) {
      if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = true;
      return true;
    }
    if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = false;
    return false;
  }
}
