import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RouterModule} from '@angular/router';
import {BlockSpinnerComponent} from './components/block-spinner/block-spinner.component';
import {ListadoEjemploComponent} from './pages/listado-ejemplo/listado-ejemplo.component';
import {CaptchaComponent} from './components/captcha/captcha.component';
import { LogoComponent } from './components/logo/logo.component';
import {ButtonComponent} from './components/button/button.component';
import {BusquedaComponent} from './components/busqueda/busqueda.component';
import {EstadoLabelComponent} from './components/estado-label/estado-label.component';
import {FormularioComponent} from './components/formulario/formulario.component';
import {ChipsAutocompleteComponent} from './components/chips-autocomplete/chips-autocomplete.component';
import {NeoMaterialModule} from '@appNeo/neoMaterial/neoMaterial.module';
import { PerfilComponent } from './components/perfil/perfil.component';
import { ArbolComponent } from './components/arbol/arbol.component';
import { DemoComponentesComponent } from './pages/demo-componentes/demo-componentes.component';
import {FormularioFiltrosTablaComponent} from '@appNeo/neoShared/components/tabla/formulario-filtros-tabla/formulario-filtros-tabla.component';
import {TablaComponent} from '@appNeo/neoShared/components/tabla/tabla/tabla.component';
import {FiltrosTablaComponent} from '@appNeo/neoShared/components/tabla/filtros-tabla/filtros-tabla.component';
import {SelectNgxSingleSelectionComponent} from '@appNeo/neoShared/components/select-ngx-single-selection/select-ngx-single-selection.component';
import { BotonDesplegableComponent } from './components/boton-desplegable/boton-desplegable.component';
import { FicheroComponent } from './components/fichero/fichero.component';
import { FicheroSubidaComponent } from './components/fichero-subida/fichero-subida.component';
import { FicheroListaComponent } from './components/fichero-lista/fichero-lista.component';
import { FicheroCardComponent } from './components/fichero-card/fichero-card.component';
import { DndDirective } from './directives/dnd/dnd.directive';
import {OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from '@busacca/ng-pick-datetime';
import { AvatarModule } from 'ngx-avatar';
import { ResaltadoDirective } from './directives/resaltado/resaltado.directive';
import { ResaltadoTerminoPipe } from './pipes/resaltadoTermino/resaltado-termino.pipe';
import {DragDropComponent} from '@appNeo/neoShared/components/drag-drop/drag-drop.component';
import {TruncarTextoPipe} from '@appNeo/neoShared/pipes/truncarTexto/truncar-texto.pipe';
import { ArbolAnidadoComponent } from './components/arbol-anidado/arbol-anidado.component';
import { AccionesDialogComponent } from './components/acciones-dialog/acciones-dialog.component';
import { IconoComponent } from './components/icono/icono.component';
import { IconoFormComponent } from './components/icono-form/icono-form.component';
import {TablaDraggableComponent} from '@appNeo/neoShared/components/tabla/tabla-draggable/tabla-draggable.component';
import {ListadoEjemploDraggableComponent} from '@appNeo/neoShared/pages/listado-ejemplo-draggable/listado-ejemplo-draggable.component';
import { FormularioContrasenaComponent } from './components/formulario-contrasena/formulario-contrasena.component';
import { DemoInputsComponent } from './pages/demo-inputs/demo-inputs.component';
import { FortalezaContresenaComponent } from './components/fortaleza-contresena/fortaleza-contresena.component';
import { BarraProgresoColorDirective } from './directives/barra-progreso-color/barra-progreso-color.directive';
import { InputContrasenaComponent } from './components/input-contrasena/input-contrasena.component';
import { CampoBooleanPalancaComponent } from './components/campo-boolean-palanca/campo-boolean-palanca.component';
import { TextareaContadorComponent } from './components/textarea-contador/textarea-contador.component';
import {EtiquetaInputComponent} from '@appNeo/neoShared/components/etiqueta-input/etiqueta-input.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { DemoFormularioComponent } from './pages/demo-formulario/demo-formulario.component';
import { InputComponent } from './components/input/input.component';
import {TablaEditableComponent} from '@appNeo/neoShared/components/tabla/tabla-editable/tabla-editable.component';
import {CampoSelectComponent} from '@appNeo/neoShared/components/campo-select/campo-select.component';
import { EditorComponent } from './components/editor/editor.component';
import {LinkExternoDirective} from '@appNeo/neoShared/directives/linkExterno/linkExterno.directive';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CirculoProgresoComponent } from './components/circulo-progreso/circulo-progreso.component';
import { CiterioValidezContrasenaComponent } from './components/criterio-validez-contrasena/criterio-validez-contrasena.component';
import { NotificacionComponent } from './components/notificacion/notificacion.component';
import { DemoNotificacionesComponent } from './pages/demo-notificaciones/demo-notificaciones.component';
import { ContenedorCabeceraComponent } from './components/contenedor-cabecera/contenedor-cabecera.component';
import { PerfilVerticalComponent } from './components/perfil-vertical/perfil-vertical.component';
import { ContenedorMasinfoTablaComponent } from './components/tabla/contenedor-masinfo-tabla/contenedor-masinfo-tabla.component';
import { DemoWizardComponent } from './pages/demo-wizard/demo-wizard.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { PerfilPropiedadComponent } from './components/perfil-propiedad/perfil-propiedad.component';
import { CirculoProgresoIconoCompletadoComponent } from './components/circulo-progreso-icono-completado/circulo-progreso-icono-completado.component';
import { InputClipboardComponent } from './components/input-clipboard/input-clipboard.component';
import { IconoDespliegueComponent } from './components/icono-despliegue/icono-despliegue.component';

import { HistorialComentarioComponent } from './components/historial/historial-comentario/historial-comentario.component';
import { HistorialEstadoComponent } from './components/historial/historial-estado/historial-estado.component';
import { HistorialComponent } from './components/historial/historial/historial.component';
import {MatErrorFormComponent} from '@appNeo/neoShared/components/mat-error-form/mat-error-form.component';
import { CustomizadoPaginadorDirective } from './directives/tabla/customizado-paginador.directive';
import { ListadoEjemplo2Component } from './pages/listado-ejemplo2/listado-ejemplo2.component';
import { CustomizadoFechaDirective } from './directives/seleccionFecha/customizado-fecha.directive';
import { KanbanComponent } from './components/kanban/kanban.component';
import { KanbanCardComponent } from './components/kanban-card/kanban-card.component';
import {NgxMatTimepickerModule} from '@node_modules/@angular-material-components/datetime-picker';
import {ScrollingModule} from '@node_modules/@angular/cdk/scrolling';
import {TableVirtualScrollModule} from '@node_modules/ng-table-virtual-scroll';
import { CampoMatdatapickerCustomizadoComponent } from './components/campo-matdatapicker-customizado/campo-matdatapicker-customizado.component';
import { CampoDatatimepickerComponent } from './components/campo-datatimepicker/campo-datatimepicker.component';
import {InputFilterComponent} from '@appNeo/neoShared/components/input-filter/input-filter.component';
import { TotalesComponent } from './components/totales/totales.component';
import { InputCantidadComponent } from './components/input-cantidad/input-cantidad.component';
import { InputImporteComponent } from './components/input-importe/input-importe.component';
import {InputImporteModelComponent} from '@appNeo/neoShared/components/input-importe/input-importe-model/input-importe-model.component';
import {InputCantidadModelComponent} from '@appNeo/neoShared/components/input-cantidad/input-cantidad-model/input-cantidad-model.component';
import {
  CampoBooleanPalancaModelComponent
} from '@appNeo/neoShared/components/campo-boolean-palanca/campo-boolean-palanca-model/campo-boolean-palanca-model.component';
import {CampoCheckboxComponent} from '@appNeo/neoShared/components/campo-checkbox/campo-checkbox.component';
import {CampoRadioComponent} from '@appNeo/neoShared/components/campo-radio/campo-radio.component';
import {CampoTelefonoComponent} from '@appNeo/neoShared/components/campo-telefono/campo-telefono.component';
import {CampoFicheroComponent} from '@appNeo/neoShared/components/campo-fichero/campo-fichero.component';
import { SinElementosComponent } from './sin-elementos/sin-elementos.component';
import {ClickOutsideDirective} from '@appNeo/neoShared/directives/clickOutside/click-outside.directive';
import { SpanInnerHtmlComponent } from './components/span-inner-html/span-inner-html.component';
import { UsuariosComponent } from './pages/layoutUsuarios/usuarios/usuarios.component';
import { UsuarioComponent } from './pages/layoutUsuarios/usuario/usuario.component';
import { ArbolPermisosComponent } from './pages/layoutUsuarios/components/arbol-permisos/arbol-permisos.component';
import { TablaEditableFavoritosComponent } from './pages/layoutUsuarios/components/tabla-editable-favoritos/tabla-editable-favoritos.component';
import { ProductosComponent } from './pages/layoutProductos/productos/productos.component';
import { ProductoComponent } from './pages/layoutProductos/producto/producto.component';
import { DragDropEtiquetasComponent } from './pages/layoutProductos/components/drag-drop-etiquetas/drag-drop-etiquetas.component';
import { ImagenPrincipalPerfilComponent } from './components/imagen-principal-perfil/imagen-principal-perfil.component';
import { TicketsComponent } from './pages/layoutTikects/tickets/tickets.component';
import { TicketComponent } from './pages/layoutTikects/ticket/ticket.component';
import { FormularioWizardTicketComponent } from './pages/layoutTikects/components/formulario-wizard-ticket/formulario-wizard-ticket.component';
import { PerfilValoresMultiplesComponent } from './components/perfil-valores-multiples/perfil-valores-multiples.component';
import { LayoutDashboardComponent } from './pages/layout-dashboard/layout-dashboard.component';
import {TituloSeccionComponent} from '@appNeo/neoShared/components/titulo-seccion/titulo-seccion.component';
import { HasPermissionDirective } from './directives/auth/has-permission.directive';
import { ContenidoImagenComponent } from './components/contenido-imagen/contenido-imagen.component';
import { ContenidoTituloComponent } from './components/contenido-titulo/contenido-titulo.component';
import { ContenidoBotonComponent } from './components/contenido-boton/contenido-boton.component';
import { ContenidoTextoPlanoComponent } from './components/contenido-texto-plano/contenido-texto-plano.component';
import { ContenedorCabeceraTabsComponent } from './components/contenedor-cabecera-tabs/contenedor-cabecera-tabs.component';
import { ColapsarComponent } from './components/colapsar/colapsar.component';
import { CampoColorPickerComponent } from './components/campo-color-picker/campo-color-picker.component';
import { VisorColorPickerComponent } from './components/campo-color-picker/visor-color/visor-color-picker.component';
import { DemoCalendarioComponent } from './pages/demo-calendario/demo-calendario.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarioComponent } from './components/calendario/calendario/calendario.component';
import { CalendarioCabeceraComponent } from './components/calendario/calendario-cabecera/calendario-cabecera.component';
import { CalendarioAgendaVistaComponent } from './components/calendario/calendario-agenda-vista/calendario-agenda-vista.component';
import { CalendarioFechaPipe } from './pipes/calendario/calendario-fecha.pipe';
import { ReservaEventoComponent } from './pages/layoutReservaEvento/reserva-evento/reserva-evento.component';
import { GestionCalendarioComponent } from './pages/gestion-calendario/gestion-calendario.component';
import { FormularioRangosComponent } from './pages/gestion-calendario/components/formulario-rangos/formulario-rangos.component';
import { FormularioArrayComponent } from './components/formulario-array/formulario-array.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { FiltrosNavegacionDiaComponent } from './components/filtros-navegacion-dia/filtros-navegacion-dia.component';
import { FormularioContrasenaCambioComponent } from './components/formulario-contrasena-cambio/formulario-contrasena-cambio.component';
import { FormularioTipoDiaComponent } from './pages/gestion-calendario/components/formulario-tipo-dia/formulario-tipo-dia.component';
import { FormularioRangos2Component } from './pages/gestion-calendario/components/formulario-rangos2/formulario-rangos2.component';
import { AutofillValueAccessorDirective } from './directives/autofill-value-accessor.directive';
import { CampoSelectModelComponent } from './components/campo-select/campo-select-model/campo-select-model.component';
import { IconoPuntuacionComponent } from './components/icono-puntuacion/icono-puntuacion.component';
import { CampoVideoComponent } from './components/campo-video/campo-video.component';
import { LayoutLpeListadoComponent } from './components/layouts/layout-lpe-listado/layout-lpe-listado.component';
import { LayoutLpePerfilBasicoMultimediaComponent } from './components/layouts/layout-lpe-perfil-basico-multimedia/layout-lpe-perfil-basico-multimedia.component';
import { LayoutLpePerfilComponent } from './components/layouts/layout-lpe-perfil/layout-lpe-perfil.component';
import { EstadoBooleanLabelComponent } from './components/estado-boolean-label/estado-boolean-label.component';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};



export class OwlDateTimeIntlCustom extends OwlDateTimeIntl {
  cancelBtnLabel = '';
  setBtnLabel = 'Aceptar';
}

@NgModule({
  declarations: [
    ButtonComponent,
    BlockSpinnerComponent,
    ListadoEjemploComponent,
    ListadoEjemploDraggableComponent,
    TablaComponent,
    TablaDraggableComponent,
    TablaEditableComponent,
    FiltrosTablaComponent,
    FormularioFiltrosTablaComponent,
    CaptchaComponent,
    LogoComponent,
    BusquedaComponent,
    EstadoLabelComponent,
    FormularioComponent,
    ChipsAutocompleteComponent,
    SelectNgxSingleSelectionComponent,
    FormularioContrasenaComponent,
    PerfilComponent,
    ArbolComponent,
    DemoComponentesComponent,
    BotonDesplegableComponent,
    FicheroComponent,
    FicheroSubidaComponent,
    FicheroListaComponent,
    FicheroCardComponent,
    DndDirective,
    ResaltadoDirective,
    LinkExternoDirective,
    ResaltadoTerminoPipe,
    TruncarTextoPipe,
    DragDropComponent,
    ArbolAnidadoComponent,
    AccionesDialogComponent,
    IconoComponent,
    IconoFormComponent,
    IconoPuntuacionComponent,
    DemoInputsComponent,
    FortalezaContresenaComponent,
    BarraProgresoColorDirective,
    InputContrasenaComponent,
    CampoBooleanPalancaComponent,
    TextareaContadorComponent,
    EtiquetaInputComponent,
    DemoFormularioComponent,
    InputComponent,
    CampoSelectComponent,
    EditorComponent,
    CirculoProgresoComponent,
    CiterioValidezContrasenaComponent,
    NotificacionComponent,
    DemoNotificacionesComponent,
    ContenedorCabeceraComponent,
    PerfilVerticalComponent,
    ContenedorMasinfoTablaComponent,
    DemoWizardComponent,
    StepperComponent,
    PerfilPropiedadComponent,
    CirculoProgresoIconoCompletadoComponent,
    InputClipboardComponent,
    IconoDespliegueComponent,
    HistorialComponent,
    HistorialComentarioComponent,
    HistorialEstadoComponent,
    MatErrorFormComponent,
    CustomizadoPaginadorDirective,
    ListadoEjemplo2Component,
    CustomizadoFechaDirective,
    KanbanComponent,
    KanbanCardComponent,
    CampoMatdatapickerCustomizadoComponent,
    CampoDatatimepickerComponent,
    InputFilterComponent,
    TotalesComponent,
    InputCantidadComponent,
    InputImporteComponent,
    InputCantidadModelComponent,
    InputImporteModelComponent,
    CampoBooleanPalancaModelComponent,
    CampoCheckboxComponent,
    CampoRadioComponent,
    CampoTelefonoComponent,
    CampoFicheroComponent,
    CampoColorPickerComponent,
    VisorColorPickerComponent,
    SinElementosComponent,
    ClickOutsideDirective,
    SpanInnerHtmlComponent,
    HasPermissionDirective,
    TituloSeccionComponent,
    TituloSeccionComponent,
    ContenidoImagenComponent,
    ContenidoTituloComponent,
    ContenidoBotonComponent,
    ContenidoTextoPlanoComponent,
    ContenedorCabeceraTabsComponent,
    // LAYOUTS
    UsuariosComponent,
    UsuarioComponent,
    ArbolPermisosComponent,
    TablaEditableFavoritosComponent,
    ProductosComponent,
    ProductoComponent,
    DragDropEtiquetasComponent,
    ImagenPrincipalPerfilComponent,
    TicketsComponent,
    TicketComponent,
    FormularioWizardTicketComponent,
    PerfilValoresMultiplesComponent,
    LayoutDashboardComponent,
    ColapsarComponent,
    DemoCalendarioComponent,
    CalendarioComponent,
    CalendarioCabeceraComponent,
    CalendarioAgendaVistaComponent,
    CalendarioFechaPipe,
    ReservaEventoComponent,
    GestionCalendarioComponent,
    FormularioRangosComponent,
    FormularioArrayComponent,
    FiltrosNavegacionDiaComponent,
    FormularioContrasenaCambioComponent,
    FormularioTipoDiaComponent,
    FormularioRangos2Component,
    AutofillValueAccessorDirective,
    CampoSelectModelComponent,
    CampoVideoComponent,
    LayoutLpePerfilComponent,
    LayoutLpeListadoComponent,
    LayoutLpePerfilBasicoMultimediaComponent,
    EstadoBooleanLabelComponent
  ],
  imports: [
    NeoMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    DragDropModule,
    FormsModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AvatarModule,
    NgCircleProgressModule.forRoot(),
    NgxMatIntlTelInputModule,
    CKEditorModule,
    NgxDocViewerModule,
    NgxMatTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatTimepickerModule,
    NgxDocViewerModule,
    TableVirtualScrollModule,
    ScrollingModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: OwlDateTimeIntlCustom },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig }
  ],
  exports: [
    NeoMaterialModule,
    ReactiveFormsModule,
    ButtonComponent,
    FormsModule,
    RouterModule,
    DragDropModule,
    BlockSpinnerComponent,
    TablaComponent,
    TablaDraggableComponent,
    TablaEditableComponent,
    FiltrosTablaComponent,
    FormularioFiltrosTablaComponent,
    CaptchaComponent,
    LogoComponent,
    BusquedaComponent,
    EstadoLabelComponent,
    FormularioComponent,
    ChipsAutocompleteComponent,
    SelectNgxSingleSelectionComponent,
    PerfilComponent,
    FormularioContrasenaComponent,
    FicheroComponent,
    FicheroCardComponent,
    FicheroListaComponent,
    FicheroSubidaComponent,
    AvatarModule,
    ResaltadoTerminoPipe,
    AvatarModule,
    TruncarTextoPipe,
    DragDropComponent,
    BotonDesplegableComponent,
    ArbolAnidadoComponent,
    AccionesDialogComponent,
    IconoComponent,
    IconoFormComponent,
    IconoPuntuacionComponent,
    FortalezaContresenaComponent,
    BarraProgresoColorDirective,
    LinkExternoDirective,
    InputContrasenaComponent,
    EtiquetaInputComponent,
    CampoBooleanPalancaComponent,
    TextareaContadorComponent,
    NgxMatIntlTelInputModule,
    InputComponent,
    CampoSelectComponent,
    CKEditorModule,
    NgxDocViewerModule,
    CirculoProgresoComponent,
    CiterioValidezContrasenaComponent,
    NotificacionComponent,
    DemoNotificacionesComponent,
    ContenedorCabeceraComponent,
    PerfilVerticalComponent,
    DemoWizardComponent,
    StepperComponent,
    PerfilPropiedadComponent,
    InputClipboardComponent,
    IconoDespliegueComponent,
    HistorialComponent,
    HistorialComentarioComponent,
    HistorialEstadoComponent,
    KanbanComponent,
    KanbanCardComponent,
    MatErrorFormComponent,
    CustomizadoPaginadorDirective,
    TableVirtualScrollModule,
    ScrollingModule,
    CampoDatatimepickerComponent,
    InputFilterComponent,
    InputCantidadModelComponent,
    InputImporteModelComponent,
    CampoBooleanPalancaModelComponent,
    CampoCheckboxComponent,
    CampoRadioComponent,
    CampoTelefonoComponent,
    CampoFicheroComponent,
    CampoColorPickerComponent,
    VisorColorPickerComponent,
    SinElementosComponent,
    ClickOutsideDirective,
    SpanInnerHtmlComponent,
    HasPermissionDirective,
    TituloSeccionComponent,
    ContenidoImagenComponent,
    ContenidoTituloComponent,
    ContenidoBotonComponent,
    ContenidoTextoPlanoComponent,
    ContenedorCabeceraTabsComponent,
    ColapsarComponent,
    // LAYOUTS
    UsuariosComponent,
    UsuarioComponent,
    ImagenPrincipalPerfilComponent,
    CalendarioFechaPipe,
    FormularioRangosComponent,
    FormularioArrayComponent,
    GestionCalendarioComponent,
    ReservaEventoComponent,
    CalendarioComponent,
    FiltrosNavegacionDiaComponent,
    FormularioContrasenaCambioComponent,
    CampoSelectModelComponent,
    CampoVideoComponent,
    LayoutLpePerfilComponent,
    LayoutLpeListadoComponent,
    LayoutLpePerfilBasicoMultimediaComponent,
    EstadoBooleanLabelComponent
  ]
})
export class NeoSharedModule { }
