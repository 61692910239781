import { environment } from '@environments/environment';
import { LayoutComponent } from './neoCore/layout/layout.component';
import { HelpersService } from './neoCore/services/helpers/helpers.service';
import { UsuarioService } from './neoCore/services/usuario/usuario.service';
import { EdicionSeccionesService } from './neoShared/modules/edicion-secciones/edicion-secciones.service';
import { DemoCalendarioComponent } from './neoShared/pages/demo-calendario/demo-calendario.component';
import { DemoComponentesComponent } from './neoShared/pages/demo-componentes/demo-componentes.component';
import { DemoFormularioComponent } from './neoShared/pages/demo-formulario/demo-formulario.component';
import { DemoNotificacionesComponent } from './neoShared/pages/demo-notificaciones/demo-notificaciones.component';
import { DemoWizardComponent } from './neoShared/pages/demo-wizard/demo-wizard.component';
import { LayoutDashboardComponent } from './neoShared/pages/layout-dashboard/layout-dashboard.component';
import { ProductoService } from './neoShared/pages/layoutProductos/producto.service';
import { ProductoComponent } from './neoShared/pages/layoutProductos/producto/producto.component';
import { ProductosComponent } from './neoShared/pages/layoutProductos/productos/productos.component';
import { ReservaEventoComponent } from './neoShared/pages/layoutReservaEvento/reserva-evento/reserva-evento.component';
import { TicketsComponent } from './neoShared/pages/layoutTikects/tickets/tickets.component';
import { UsuarioComponent } from './neoShared/pages/layoutUsuarios/usuario/usuario.component';
import { UsuariosComponent } from './neoShared/pages/layoutUsuarios/usuarios/usuarios.component';
import { ListadoEjemploDraggableComponent } from './neoShared/pages/listado-ejemplo-draggable/listado-ejemplo-draggable.component';
import { ListadoEjemploComponent } from './neoShared/pages/listado-ejemplo/listado-ejemplo.component';
import { ListadoEjemplo2Component } from './neoShared/pages/listado-ejemplo2/listado-ejemplo2.component';

export const ADMIN_DEMO = (environment.adminDemo) ?
    {
        path: 'adminDemo',
        component: LayoutComponent,
        data: {
            migasDePan: {
                icono: 'home',
                label: 'Inicio',
                link: environment.pagina_aterrizaje
            },
            title: 'Inicio'
        },
        resolve: {
            // preferencias: PreferenciasService,
            helpersService: HelpersService,
            seccionEditables: EdicionSeccionesService
        },
        // canActivate: [AuthGuard],
        children: [
            {
                path: 'adminDemo',
                // path: '',
                redirectTo: environment.pagina_aterrizaje,
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: LayoutDashboardComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Dashboard',
                        link: '/dashboard'
                    },
                    title: 'Dashboard'
                }
            },
            {
                path: 'inicio',
                component: ListadoEjemploComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Tabla genérica ejemplo1',
                        link: 'inicio'
                    },
                    title: 'Tabla genérica ejemplo1'
                },
            },
            {
                path: 'tabla',
                component: ListadoEjemplo2Component,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Tabla genérica ejemplo2',
                        link: 'tabla'
                    },
                    title: 'Tabla genérica ejemplo2'
                },
            },
            {
                path: 'tdraggable',
                component: ListadoEjemploDraggableComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Tabla draggable',
                        link: 'tdraggable'
                    },
                    title: 'Tabla draggable'
                },
            },
            {
                path: 'componentes',
                component: DemoComponentesComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Componentes',
                        link: 'componentes'
                    },
                    title: 'Componentes'
                },
            },
            {
                path: 'formulario',
                component: DemoFormularioComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Formulario',
                        link: 'formulario'
                    },
                    title: 'Formulario'
                },
            },
            {
                path: 'notificaciones',
                component: DemoNotificacionesComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Notificaciones',
                        link: 'notificaciones'
                    },
                    title: 'Notificaciones'
                },
            },
            {
                path: 'wizard',
                component: DemoWizardComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Wizard',
                        link: 'wizard'
                    },
                    title: 'Wizard'
                },
            },
            {
                path: 'calendario',
                component: DemoCalendarioComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Wizard',
                        link: 'wizard'
                    },
                    title: 'Wizard'
                },
            },
            {
                path: 'usuarios',
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Usuarios',
                        link: '/usuarios'
                    },
                    title: 'Usuarios'
                },
                children: [
                    {
                        path: '',
                        component: UsuariosComponent,
                        data: {
                            migasDePan: null,
                            title: 'Usuarios'
                        },
                    },
                    {
                        path: ':id',
                        component: UsuarioComponent,
                        data: {
                            migasDePan: (data: any) => {
                                return {
                                    label: data.usuario.nombre,
                                    link: `usuarios/${data.usuario.id}`,
                                    tituloPagina: `${data.usuario.nombre} ${data.usuario.apellidos}`
                                };
                            }
                        },
                        resolve: { usuario: UsuarioService }
                    }
                ]
            },
            {
                path: 'productos',
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Productos',
                        link: '/productos'
                    },
                    title: 'Productos'
                },
                children: [
                    {
                        path: '',
                        component: ProductosComponent,
                        data: {
                            migasDePan: null
                        },
                    },
                    {
                        path: ':id',
                        component: ProductoComponent,
                        data: {
                            migasDePan: (data: any) => {
                                return {
                                    label: data.producto.nombre,
                                    link: `Productos/${data.producto.id}`,
                                    tituloPagina: `${data.producto.nombre}`
                                };
                            }
                        },
                        resolve: { producto: ProductoService }
                    }
                ]
            },
            {
                path: 'tickets',
                component: TicketsComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Tickets',
                        link: '/tickets'
                    },
                    title: 'Tickets'
                },
            },
            {
                path: 'resEvento',
                component: ReservaEventoComponent,
                data: {
                    migasDePan: {
                        icono: '',
                        label: 'Reserva evento',
                        link: '/resEvento'
                    },
                    title: 'Reserva evento'
                },
            },
            {
                path: 'usuario',
                loadChildren: () => import('./neoShared/modules/usuario/usuario.module').then(m => m.UsuarioModule),
                data: {
                    migasDePan: null
                },
            },
            {
                path: 'edicionSeccion', loadChildren: () => import('@appNeo/neoShared/modules/edicion-secciones/edicion-secciones.module').then(m => m.EdicionSeccionesModule),
                data: {
                    migasDePan: null
                },
            },

        ]
    }
    : { path: 'adminDemo', redirectTo: '/error/404', pathMatch: 'full' };