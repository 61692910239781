import {ActivatedRouteSnapshot, Data, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { IMigasDePan } from '@appNeo/neoShared/helpers/interfaces/IMigasDePan';

@Injectable({
  providedIn: 'root',
})
export class MigasDePanService {
  private readonly _migasDePan$ = new BehaviorSubject<IMigasDePan[]>([]);

  readonly migasDePan$ = this._migasDePan$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        const root = this.router.routerState.snapshot.root;
        const migas: IMigasDePan[] = [];
        this.addMigasDePan(root, migas);
        this._migasDePan$.next(migas);
      });
  }

  private addMigasDePan(
    route: ActivatedRouteSnapshot,
    migas: IMigasDePan[]
  ) {
    if (route) {
      if (route.data.migasDePan) {
        // console.log('Add migas de pan ', route.data);
        const data = this.getData(route.data);
        const migaDePan = {
          icono: data['icono'],
          label: data['label'],
          link: data['link'],
          tituloPagina: data?.tituloPagina ? data.tituloPagina: '',
        };
        migas.push(migaDePan);
      }
      this.addMigasDePan(route.firstChild, migas);
    }
  }

  private getData(data: Data) {
    return typeof data.migasDePan === 'function'
      ? data.migasDePan(data)
      : data.migasDePan;
  }
}
