import { MenuItemClassEnum } from '@appNeo/neoShared/helpers/enums/MenuItemClass.enum';
import { IBarraIzquierdaMenuItems, ISeccionMenu } from '@appNeo/neoShared/helpers/interfaces/IBarraIzquierdaMenuItems';
import { environment } from '@environments/environment';

export const  PRIMERA_SECCION: IBarraIzquierdaMenuItems[] = [
  {
    path: environment.ruta_padre_admin_demo + 'inicio',
    label: 'Tabla genérica ejemplo1',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo +'tabla',
    label: 'Tabla genérica ejemplo2',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'tdraggable',
    label: 'Tabla draggable',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'componentes',
    label: 'Componentes',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'formulario',
    label: 'Formulario',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'notificaciones',
    label: 'Notificaciones',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'wizard',
    label: 'Wizard',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: 'inicio',
    label: 'Inicio Matrix',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: 'contacto',
    label: 'Contacto Matrix',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  },
  {
    path: environment.ruta_padre_admin_demo + 'calendario',
    label: 'Calendario',
    class: MenuItemClassEnum.noDesplegable,
    ultimoNivelPath: '',
    iconoClase: 'icon-ibm--letter--Aa',
  }
];

export const  BARRA_DERECHA_MENU: ISeccionMenu[] = [
  {
    seccion: 'Control bar',
    menu: PRIMERA_SECCION
  }

];
