<mat-sidenav-container role="main" class="sidenav-container-layout">

  <mat-sidenav #sidenavBI
    opened="{{ expandidoBI }}"
    mode="side"
    position="start"
    class="sidenav-barraizquierda"
    [class]="barraIzquierdaClass"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"  >  <!--plegada - desplegada-->
      <neo-barra-izquierda ></neo-barra-izquierda>
  </mat-sidenav>

  <mat-sidenav-content
  class="sidenav-content"
  [ngClass]="{'desplegada-bi': expandidoBI, 'desplegada-bd': expandidoBD}">
  <!-- <mat-sidenav-content> -->
    <neo-header></neo-header>

    <div class="main-content">
      <div class="container-fluid">
        <neo-migas-de-pan></neo-migas-de-pan>
        <router-outlet></router-outlet>
      </div>
    </div>

  </mat-sidenav-content>

  <mat-sidenav #sidenavBD
    position="end"
    mode="side"
    class="sidenav-barraderecha"
    [class]="barraDerechaClass"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"  >
      <neo-barra-derecha></neo-barra-derecha>
  </mat-sidenav>

</mat-sidenav-container>
