<div class="blq-card">
    <neo-stepper
      [orientation]="'horizontal'"
      [linear]="true"
      [iconoEdicion]="'icon-24 icon-ibm--checkmark'"
      (selectionChange)="cambioPaso($event)"
      #stepper>
      <mat-step [stepControl]="stepControl1" label="ITEM">
        <neo-formulario #formStepControl1 
        [aplicarFlex]="aplicarFlex"
        [formularioAncho]="clasesFormularioAnchoEnum"
        [camposFormulario]="configuracionFormStep1"></neo-formulario>
        <div>
          <!-- <button  mat-button matStepperNext >
            Siguiente
          </button> -->
          <neo-button  
            [text]="'Siguiente'"
            [flat]="true"
            [color]="'primary'"
            (click)="siguiente()"
          ></neo-button>
        </div>
      </mat-step>
      <mat-step [stepControl]="stepControl2"  label="ITEM">
        <neo-formulario #formStepControl2 
        [aplicarFlex]="aplicarFlex"
        [formularioAncho]="clasesFormularioAnchoEnum"
        [camposFormulario]="configuracionFormStep2"></neo-formulario>
        <div>
          <neo-button  
          [text]="'Anterior'"
          [basic]="true"
          [color]="'primary'"
          (click)="anterior()"
         ></neo-button>
          <!-- <button mat-button matStepperPrevious>Anterior</button> -->
          <!-- <button mat-button matStepperNext>Siguiente</button> -->
          <!-- <button class="mr-2" type="button" mat-raised-button color="primary" [disabled]="false" >Siguiente</button> -->
          <neo-button  
            [text]="'Siguiente'"
            [flat]="true"
            [color]="'primary'"
            (click)="siguiente()"
          ></neo-button>
        </div>
      </mat-step>
      <mat-step label="ITEM">
        <p>Resultados: </p>
        <pre>
              {{entidad | json}}
           </pre>
        <div>
          <neo-button  
          [text]="'Anterior'"
          [basic]="true"
          [color]="'primary'"
          (click)="anterior()"
         ></neo-button>
          <!-- <button mat-button matStepperPrevious>Anterior</button> -->
          <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
          <!-- <neo-button  
            [text]="'Reset'"
            [flat]="true"
            [color]="'primary'"
            (click)="stepper.reset()"
          ></neo-button> -->
       
        </div>
      </mat-step>
    </neo-stepper>
  </div>
  