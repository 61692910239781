import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';

import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, EMPTY, map, Observable, Subject, tap, throwError } from 'rxjs';
import { PRODUCTOS_DEMO } from './productos/layout-productos-data';
import { IProducto } from './productos/productos-configuracion';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {


  url: string = environment.api_base_url;
  public editandoProducto$ = new BehaviorSubject<boolean>(false);

  // CONFIG
  urlProductos = environment.api_base_url + '/productos';
  urlProductosEstado = environment.api_base_url + '/productosEstado';
  public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
  public producto$: BehaviorSubject<IProducto> = new BehaviorSubject<IProducto>(null);
  public productos$: BehaviorSubject<IProducto> = new BehaviorSubject<IProducto>(null);
  public totalProductos$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService,
  ) { }

  set editandoProducto(flag: boolean) {
    console.log(flag);
    this.editandoProducto$.next(flag);
  }

  set producto(producto: IProducto) {
    this.producto$.next(producto);
  }

  // CRUD
  getAll(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<IProducto[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'nombre', tipoOrden: 'asc' };
    }
    let request = `${this.urlProductos}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    console.log('URL PRODUCTOS ', request);
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => { this.productos$.next(data.data); }),
      tap((data: IRespApi) => { this.totalProductos$.next(data.total); }),
      map((data: IRespApi) => this.mapearEntidades(data['data'])));
  }

  get(id): Observable<IProducto> {
    return this.http.get(`${this.urlProductos}/${id}`).pipe(
      map((data: IRespApi) => data['data'])
    );
  }


  mapearEntidades(listadoEntidad: IProducto[]) {
    if (listadoEntidad.length) {
    }
    return listadoEntidad;
  }

  // getEstados(): Observable<IProductoEstadoActual[]>  {
  //   return this.http.get(`${this.urlProductosEstado}`).pipe(
  //     map((data: IRespApi) => data['data'])
  //   );
  // }

  create(request: any): Observable<any> {
    return this.http.post<IRespApi>(`${this.urlProductos}`, request).pipe(
      tap(() => PRODUCTOS_DEMO.push({ ...request, idUsuario: PRODUCTOS_DEMO.length + 1 })),
      tap(() => console.log(PRODUCTOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateUsuario');
      })
    );
  }

  update(id: string, request: any): Observable<any> {
    return this.http.patch<IRespApi>(`${this.urlProductos}/${id}`, request).pipe(
      tap(() => PRODUCTOS_DEMO.push({ ...request })),
      tap(() => console.log(PRODUCTOS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateUsuario');
      })
    );
  }

  delete(id: string): Observable<any> {
    let indiceUsuarioEliminar = PRODUCTOS_DEMO.findIndex(producto => producto.id === id);
    console.log(id, PRODUCTOS_DEMO, indiceUsuarioEliminar);

    if (indiceUsuarioEliminar >= 0) {
      return this.http.delete(`${this.urlProductos}/${id}`).pipe(
        tap(() => PRODUCTOS_DEMO.splice(1, indiceUsuarioEliminar)),
        tap(() => console.log(PRODUCTOS_DEMO)),
        map((resp: IRespApi) => {
          return resp.data;
        }),
        catchError((err: IRespApi) => {
          console.log(err);
          return this.handleError(err);
        })
      );
    } else {
      return throwError(() => new Error('No existe elemento'));
    }
  }

  /****************************/
  /* FICHEROS                */
  /*****************************/
  subirFichero(ficheros: File[], idObjeto = 1): Observable<any> {
    const formData: FormData = new FormData();
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++)
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
    }
    return this.http.post(`${this.urlProductos}/${idObjeto}/ficheros`, formData).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return throwError(() => new Error(err.mensaje));
      })
    );
  }

  getFicheros(id: string) {
    return this.http.get(`${this.urlProductos}/${id}/ficheros`).pipe(
      map((data: IRespApi) => data));
  }

  getFichero(id: string) {
    return this.http.get(`${environment.api_base_url}/ficheros/12`);
  }

  eliminarFichero(id: string) {
    return this.http.delete(`${environment.api_base_url}/ficheros/${id}`).pipe(
      map((data: IRespApi) => data));
  }




  /************************************ 
   * Funciones apoyo                  *
  *************************************/
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProducto> {
    const id = route.params.id;
    return this.get(id);
  }

  /********************************/
  /* MANEJADOR ERRORES            */
  /********************************/
  private handleError(err: IRespApi, idRequest = '') {
    // dialog específico
    if (err.codigoError === 422) {
      err.request = idRequest;
      this.errorApi$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if (err.codigoError === 403) {
      mensaje = `${err.mensaje}: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404) {
      let avisos = '';
      if (err.data) {
        if (typeof err.data === 'string') {
          avisos = ' ' + err.data + ' ';
        } else {
          err.data.forEach(element => {
            if (element.id === 999) {
              avisos = ' ' + element.mensaje + ' ';
            }
          });
        }
      }
      mensaje = err?.mensaje + avisos;
    }

    if (err.codigoError === 500) {
      let avisos = '';
      if (err.data) {
        err.data.forEach(element => {
          if (element.id === 999) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = err?.mensaje + avisos;
    }
    return throwError({ 'error': true, 'data': err.data, mensaje });
  }
}
