<section>
  <div *ngIf="mostrarTituloCabecera" class="section-header">
    <div>
      <h2 class="text-h2">{{ titulo }}</h2>
    </div>

    <ng-container *ngIf="btnDescargarPdfCabecera">
      <div class="flex justify-content-end">
        <neo-button
          [basic]="tipoBotonDescarga == modoBotonEnum.basic"
          [stroked]="tipoBotonDescarga == modoBotonEnum.stroked"
          [text]="textoDescargarPdfCabeceraIcono"
          [color]="'primary'"
          [clase]="'btn-outline btn-margin-left'"
          [iconClass]="btnDescargarPdfCabeceraIcono"
          (click)="descargarPdf()"
        ></neo-button>
        <ng-content></ng-content>
      </div>
    </ng-container>
  </div>

  <!-- filtros entidad-->
  <div *ngIf="filtrado" class="tabla-filtros blq-card" [ngClass]="{'contenedorFiltrosOcultarM': numeroFiltros>1, 'contenedorFiltrosOcultarTV': numeroFiltros>2}">
      <neo-filtros-tabla
          [keyFiltrado]="keyFiltrado"
          [valoresIniciales]="valoresInicialesFiltrado"
          (cambioFormulario)="eventoCambioFormularioFiltros($event)"
      >

      <div class="campo-busqueda">
        <neo-busqueda #filtrosBusqueda
          class="mr-4 ml-4"
          placeholder="Busqueda"
          [valorDefault]="guardarValorBuscadorEnSesion"
          [keyFiltrado]="keyFiltrado"
          [botonLimpiarBuscador]="true"
          (limpiarBuscador)="clearBuscador()"
          (changeBuscador)="eventoCambioBuscador($event)"
        ></neo-busqueda>
      </div>

      <div class="btns-filtrado" *ngIf="!filtradoSinBotonSubmit && numeroFiltros > 1">
        <neo-button
                      [disabled]="deshabilitarLimpiarFiltros"
                      [text]="'Limpiar filtros'"
                      [basic]="true"
                      [color]="'primary'"
                      [iconClass]="'icon-20 icon-ibm--clean'"
                      (accion)="!deshabilitarLimpiarFiltros ? clearFilter(): null;"
        ></neo-button>
        <neo-button
                    [text]="'Aplicar filtros'"
                    [color]="'primary'"
                    [flat]="true"
                    (accion)="submitFiltrado()"
        ></neo-button>
      </div>
    </neo-filtros-tabla>
  </div>

  <div class="blq-cont" *ngIf="filtrado">
    <!-- boton acceso filtros en caso de estar ocultos por temas responsivos -->
    <neo-button class="botonAccesoFiltros btn-block"
      [ngClass]="{'botonAccesoFiltrosOcultarM': numeroFiltros==1, 'botonAccesoFiltrosOcultarTV': numeroFiltros==2 || numeroFiltros==1}"
    [text]="'Filtrar'"
    [flat]="true"
    [color]="'secondary'"
    [iconClass]="'icon-20 icon-ibm--filter'"
    (accion)="abrirFiltros()"
    ><span *ngIf="cambioValoresFiltrado" class="filtro-activo"></span>
    </neo-button>
    <!--fin filtros entidad-->
  </div>

  <div id="container-cards" class="mt-15">

    <div id="blq-totales" class="blq-card" *ngIf="visualizarTotales">
      <neo-totales #totales
        [data]="dataTotales"
        (cambioOpcionSeleccionada)="cambioSelectTotales($event)"
      ></neo-totales>
    </div>

    <div id="blq-tabla" class="blq-card">
      <div class="blq-header" *ngIf="mostrarTotalElementos">
        <h3 class="text-p6">
          <span *ngIf="selection.selected.length>1">{{selection.selected.length}} elementos seleccionados</span>
          <span *ngIf="selection.selected.length == 1">{{selection.selected.length}} elemento seleccionado</span>
          <span *ngIf="selection.selected.length<=0">{{ totalResultados }} elementos</span>
        </h3>
        <div *ngIf="btnAccionesLote || botoneraAccionesCabecera" class="blq-acciones">

          <div *ngIf="btnAccionesLote" class="mr-2">
            <neo-boton-desplegable #btnDepegableAccioneMultiples
                                  [referenciaElemento]="'tabla'"
                                  [disabled]="selection.selected.length < 1"
                                  [iconoClaseBotonPrincipal]="'icon-20 icon-mdi--arrow_drop_down'"
                                  [textoBotonPrincipal]="'Acciones múltiples'"
                                  [strokedBotonPrincipal]=true
                                  [claseBotonPrincipal]="'btn-sm'"
            ></neo-boton-desplegable>
          </div>

          <ng-container *ngIf="botoneraAccionesCabecera">
            <div [ngClass]="accionCabecera.bloqueClass" *ngFor="let accionCabecera of botoneraAccionesCabecera">
              <neo-button  *ngIf="accionCabecera.mostrarBtnLg"
                          [ngClass]="accionCabecera.neoButtonClassLg"
                          [flat]="true"
                          [color]="accionCabecera.color"
                          (accion)="accionCabeceraClick.emit(accionCabecera)"
                          [iconClass]="accionCabecera.iconClassLg"
                          [text]="accionCabecera.textoLg"
                          [clase]="accionCabecera.neoButtonInputClassLg"
              ></neo-button>
              <neo-button *ngIf="accionCabecera.mostrarBtnXs"
                          [ngClass]="accionCabecera.neoButtonClassXs"
                          [flat]="true"
                          [color]="accionCabecera.color"
                          (accion)="accionCabeceraClick.emit(accionCabecera)"
                          [iconClass]="accionCabecera.iconClassXs"
                          [text]="accionCabecera.textoXs"
                          [clase]="accionCabecera.neoButtonInputClassXs"
              ></neo-button>
            </div>
          </ng-container>
          </div>
      </div>

      <div #content class="table-container " [ngClass]="{'scroll-lateral': !responsiveDinamico}">
        <table #tabla multiTemplateDataRows matSort mat-table [dataSource]="dataSource"
          (matSortChange)="ordenarDataSourceByColumna($event)">
          <ng-container *ngFor="let columna of columnas" matColumnDef="{{columna.key}}">
            <ng-container [ngSwitch]="columna.tipo">

              <ng-container *ngIf="columna.tipo === 'selector'">
                <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') 
                                                                                                                  + ' ' +  ((columna?.classResponsive) ? columna.classResponsive : '')  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-40 cell-center' : '')"
                >
                  <mat-checkbox #toogleCheckbox
                                [ngClass]="'tbl-checkbox'"
                                [color]="'primary'"
                                (click)="toogleMasterClick($event)"
                                [checked]="toogleMasterChecked()"
                                [indeterminate]="toogleMasterIndeterminate()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; index as i;"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' +  ((columna?.classResponsive) ? columna.classResponsive : '')  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-40 cell-center' : '')"
                  [attr.key]="columna.key">
                  <mat-checkbox #childCheckbox (click)="$event.stopPropagation()"  [ngClass]="'tbl-checkbox'"
                                [color]="'primary'"
                                (change)="$event ? selection.toggle(row) : null"
                                (change) = "seleccionarRow(row)"
                                [checked]="row['seleccionado']">
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container *ngIf="columna.tipo === columnaDespligueMasInfoKey">

                <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' +  ((columna?.classResponsive) ? columna.classResponsive : '')  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')">
                  <!-- columna vacia -->
                </th>
                <td mat-cell *matCellDef="let row;"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive"
                  [attr.key]="columna.key">
                  <neo-icono-despliegue [identificador]="'fila_' + dataSource.filteredData.indexOf(row) +'_'"></neo-icono-despliegue>
                </td>

              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Editable">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' +  ((columna?.classResponsive) ? columna.classResponsive : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' +  ((columna?.classResponsive) ? columna.classResponsive : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell  *matCellDef="let row; let posicion = index" [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive" [attr.key]="columna.key"  (click)="$event.stopPropagation()">
                <ng-container *ngIf="(columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.TextArea ||
                columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Cantidad ||
                columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Importe ||  columna.tipoColumnaEditable === tablaService._TipoColumnaEditable.Select); else inputEdicion">
                <container-element [ngSwitch]="columna.tipoColumnaEditable">
                  <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Importe">
                    <neo-input-importe-model
                      [importe]="row[columna.key].nombre"
                      [placeholder]="columna.placeholder"
                      (nuevoImporte)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})"
                    ></neo-input-importe-model>
                  </some-element>

                  <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Cantidad">
                    <neo-input-cantidad-model
                      [cantidad]="row[columna.key].nombre"
                      [placeholder]="columna.placeholder"
                      (nuevaCantidad)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})"
               ></neo-input-cantidad-model>
                  </some-element>

                  <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Select">
                    <neo-campo-select-model
                      [opciones] = "row[columna.key]?.opciones"
                      [opcion]="row[columna.key]?.nombre"
                      [placeholder]="columna.placeholder"
                      (nuevaOpcion)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: row[columna.key].nombre, valorActualizado: $event})"
                    ></neo-campo-select-model>
                  </some-element>

                  <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.TextArea">
                    <mat-form-field appearance="outline" floatLabel="never" class="campo-editable-textarea" [ngClass]="{'textarea-abierto': textAreaOpen[dataSource.filteredData.indexOf(row)]}">
                      <!-- TODO buscar solucion para aplicar clase  campo-editable-ellipsis, mientras usamos pipe-->
                      <input matInput #inputTextAreaEditable
                             [ngClass]="{ 'campo-editable-oculto': campoEditableFoco == row}"  placeholder="Introducir texto"
                             [value]="row[columna.key].nombre | truncarTexto : 30"
                             (focus)="focusInputMascaraTextareaEdicion(row, dataSource.filteredData.indexOf(row))">
                      <textarea matInput
                                [ngClass]="{ 'campo-editable-oculto': campoEditableFoco != row}"
                                cdkTextareaAutosize
                                #textAreaColumnaEditable = "cdkTextareaAutosize"
                                cdkAutosizeMinRows = "{{numeroRowsCeldaEditableInicial}}"
                                cdkAutosizeMaxRows = "{{numeroRowsCeldaEditableInicial}}"
                                [value]="row[columna.key].nombre"
                                (click)="$event.stopPropagation()"
                                (focusout)="actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: valorTextAreaEdicion(row[columna.key].nombre), valorActualizado: $event.target['value']}); focusOutTextAreaEdicion(dataSource.filteredData.indexOf(row));"
                      >
                    </textarea>
                    </mat-form-field>
                  </some-element>
                </container-element>
                </ng-container>
                  <ng-template #inputEdicion>
                    <mat-form-field appearance="outline" floatLabel="never">
                      <input class="campo-editable-ellipsis" matInput placeholder="Introducir texto" [value]="row[columna.key].nombre" [(ngModel)]="row[columna.key].nombre">
                    </mat-form-field>
                  </ng-template>
                </td>

              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Imagen">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-60 cell-center' : '')"
                  [attr.key]="columna.key">
                  <img [src]="row[columna.key]?.src ? row[columna.key]?.src : imgDefault"  alt="{{row[columna.key]?.alt}}" />
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Avatar">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-220 cell-left' : '')"
                  [attr.key]="columna.key">

                  <div class="avatar-txt">
                    <ngx-avatar name="{{row[columna.key]?.nombre}}"  initialsSize="1"  src="{{row[columna.key]?.src}}" size="40" class="ngx-mi-avatar"></ngx-avatar>
                    <span>{{row[columna.key]?.nombre}}</span>
                  </div>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Icono">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row; index as i;"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'hidden-xs cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' hidden-xs cell-40 cell-center ' : '')"
                  [attr.key]="columna.key">
                  <neo-icono [color]="row[columna.key]?.color"  [iconoClase]="row[columna.key]?.class"></neo-icono>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Estado">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="" [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  <neo-estado-label [estado]="row[columna.key]"></neo-estado-label>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.EstadoBoolean">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                    {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                    {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="" [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  <neo-estado-boolean-label [valor]="row[columna.key]"
                    [configuracion]="(row[columna.key + '_configuracion'])? row[columna.key + '_configuracion']: undefined"></neo-estado-boolean-label>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Boolean">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class=""
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-80 cell-center' : '')"
                  [attr.key]="columna.key">
                  <neo-campo-boolean-palanca-model
                      [valor]="row[columna.key]"
                      (nuevoValor)="this.actualizarDataSource({row: row, columnaKey: columna.key, valorAnterior: getValorAnteriorCampoBoolean(row, columna.key) , valorActualizado: $event});"
                      [color]="'primary'"
                  ></neo-campo-boolean-palanca-model>
                </td>

              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Texto">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="limite-lineas campo-texto" [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')">
                  <span>{{ row[columna.key] }} {{columna.unidad}}</span>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlacePrincipal" class="">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="limite-lineas campo-enlace" [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-320 cell-left' : '')">
                  <a class="link linkPrincipal" (click)="$event.stopPropagation()"
                  [routerLink]="(row[columna.key + 'Mapping']) ? obtenerRouterLink(row[columna.key + 'Mapping']): undefined">
                    {{ row[columna.key] }} {{columna.unidad}}
                  </a>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.EnlaceSecundario" class="">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="limite-lineas campo-enlace"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left')  + ' ' + columna.classResponsive  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left' : '')"
                  [attr.key]="columna.key">
                  <ng-container *ngIf="row[columna.key + 'Mapping'].url; else urlInterna">
                    <a class="link linkSecundario"
                      (click)="(row[columna.key + 'Mapping']) ? gestionarEnlace(row[columna.key + 'Mapping']) : $event.stopPropagation()">
                      {{ row[columna.key] }} {{columna.unidad}}
                    </a>
                  </ng-container>
                  <ng-template #urlInterna>
                    <a class="link linkSecundario" (click)="$event.stopPropagation()"
                      [routerLink]="(row[columna.key + 'Mapping']) ? obtenerRouterLink(row[columna.key + 'Mapping']): undefined">
                      {{ row[columna.key] }} {{columna.unidad}}
                    </a>
                  </ng-template>
                </td>

              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Importe">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + columna.classResponsive  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')">
                  {{columna.nombre}}
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-right') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-right ' : '')"
                  [attr.key]="columna.key">
                  {{ row[columna.key] }} {{columna.unidad}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="[tablaService._TipoColumna.Fecha, tablaService._TipoColumna.FechaHora, tablaService._TipoColumna.Hora].includes(columna.tipo) ? columna.tipo : '' ">
                  <ng-container *ngIf="columna.ordenacion">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')">
                      {{columna.nombre}}
                    </th>
                  </ng-container>
                  <ng-container *ngIf="!columna.ordenacion">
                    <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')">
                     {{columna.nombre}}
                    </th>
                  </ng-container>

                  <td mat-cell *matCellDef="let element" class="limite-lineas campo-fecha"
                    [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : '  cell-left ') + ' ' + columna.classResponsive  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-120 cell-left ' : '')" [attr.key]="columna.key">
                    <div *ngIf="!esString(element[columna.key])">
                      <ng-container *ngIf="tablaService._TipoColumna.Fecha == columna.tipoDatePicker ">
                        {{element[columna.key] | date: 'd/M/y'}}
                      </ng-container>
                      <ng-container *ngIf="tablaService._TipoColumna.FechaHora == columna.tipoDatePicker ">
                        {{element[columna.key] | date: 'd/M/y, h:mm'}}
                      </ng-container>
                      <ng-container *ngIf="tablaService._TipoColumna.Hora == columna.tipoDatePicker ">
                        {{element[columna.key] | date: 'HH:mm'}}
                      </ng-container>
                    </div>
                    <div *ngIf="esString(element[columna.key])">
                      {{element[columna.key]}}
                    </div>
                  </td>

              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Otro">
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')">                    <div>{{columna.nombre}}</div>
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                      [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')">
                    <div>{{columna.nombre}}</div>
                  </th>
                </ng-container>
                <td mat-cell *matCellDef="let row" class="campo-otro" [attr.key]="columna.key"
                  [ngClass]="columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-left'  + ((aplicarClasesPersonalizadaDefecto) ? ' cell-160 cell-left ' : '')">
                  <div>{{ row[columna.key] }}</div>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="tablaService._TipoColumna.Acciones">
                <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key"
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-center ' : '')">
                  <span [ngClass]="{'botonera-acciones': columna.tipo == tablaService._TipoColumna.Acciones}"> {{columna.nombre}}</span>
                </th>
                <td mat-cell *matCellDef="let row" class=""
                  [ngClass]="(columna?.clasePersonalizada ? columna?.clasePersonalizada?.join(' ') : 'cell-center') + ' ' + columna.classResponsive + ((aplicarClasesPersonalizadaDefecto) ? ' cell-140 cell-center ' : '')">
                  <div class="botonera-acciones"
                    [ngClass]="{'ocultar-botonera-acciones-responsive': ocultarBotoneraAccionesRowResponsive}">
                    <!--  boton fijo para mostrar columnas ocultas por responsivo-->
                    <neo-button *ngIf="columnasOcultas?.length && mostrarColumnasOcultasEnDialogo"  [disabled]="deshabilitarAcciones"
                                class="btn-accion"
                                [icono]="true"
                                [iconClass]="'icon-20 icon-ibm--information'"
                                matTooltip="Ver información"
                                (accion)="$event.stopPropagation()" (accion)="verInformacionRowClick(row)">
                    </neo-button>

                    <!--  botonera configurable-->
                    <neo-button *ngFor="let accion of row[columna.key]" [disabled]="deshabilitarAccionRow(accion)"
                                class="btn-accion"
                                [icono]="accion.icono"
                                [iconClass]="accion.iconClass"
                                matTooltip="{{accion.tooltip}}"
                                (accion)="$event.stopPropagation()" (accion)="!deshabilitarAccionRow(accion) && clickAccionRowTabla.emit([row, accion])">
                    </neo-button>

                  </div>
                  <div class="botonera-acciones-desplegable" *ngIf="ocultarBotoneraAccionesRowResponsive">
                    <neo-boton-desplegable
                      [referenciaElemento]="'tabla'"
                      [iconoBotonPrincipal]="'more_vert'"
                      [colorBotonPrincipal]="'black'"
                      [iconoTipoBotonPrincipal]=true
                      [textoBotonPrincipal]="''"
                      [item]="row"
                      [disabled]="deshabilitarAcciones"
                      [accionesBotonDesplegable]="obtenerAccionesBotonDesplegable(row)"
                      (click)="rowSeleccionadaBotonDesplegable = row; $event.stopPropagation()"
                    ></neo-boton-desplegable>
                  </div>
                </td>

                <!-- <td mat-cell *matCellDef="let row" class="" [attr.key]="columna.key">

                </td> -->
              </ng-container>

              <ng-container *ngSwitchDefault>
                <!--TODO: Codigo duplicado. Se puede hacer depender la directiva mat-sort-top de withSorting en default y para cada columna tipada?-->
                <ng-container *ngIf="columna.ordenacion">
                  <th mat-header-cell mat-sort-header  *matHeaderCellDef [attr.key]="columna.key" [ngClass]="(columna?.classResponsive + ' ' + columna?.clasePersonalizada?.join(' ')) + + ' ' + columna.classResponsive">
                    {{columna.nombre}}
                  </th>
                </ng-container>
                <ng-container *ngIf="!columna.ordenacion">
                  <th mat-header-cell *matHeaderCellDef [attr.key]="columna.key" [ngClass]="(columna?.classResponsive + '  ' + columna?.clasePersonalizada?.join(' ')) + ' ' + columna.classResponsive">
                    {{columna.nombre}}
                  </th>
                </ng-container>

                <td mat-cell  *matCellDef="let element" class="align-left"  [ngClass]="'  ' + columna?.clasePersonalizada?.join(' ') + ' ' + columna.classResponsive" [attr.key]="columna.key"> {{element[columna.key]}} {{columna?.unidad}} </td>

              </ng-container>

            </ng-container>


          </ng-container>


          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row;" [attr.colspan]="columnas?.length">
              <div class="contenedor-mas-info" [@detailExpand]="row == filaExpandida ? 'expanded' : 'collapsed'">
                <neo-contenedor-masinfo [propiedades]="propiedadesMasInfo" (cambioCeldaEditable)="$event.row = row; actualizarDataSource($event)"></neo-contenedor-masinfo>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="keysColumnas; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: keysColumnas;let i = index;"
                  (click)="clickRow(row, dataSource.filteredData.indexOf(row))"
                  #tableRow [ngClass]="{'seleccionado' : selection.isSelected(row)}">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="contenedor-mas-info-fila" [ngClass]="{'expandida' : row == filaExpandida, 'colapsada': row == null || row != filaExpandida}"></tr>
          <!-- <tr mat-header-row *matHeaderRowDef="keysColumnas"></tr>
          <tr mat-row  *matRowDef="let row; columns: keysColumnas;let i = index;" (click)="clickRow(row, i)" class="filaTabla"  #tableRow></tr> -->
        </table>
        <ng-container *ngIf="!totalResultados">
          <div class="sin-resultados text-center">
        
            <p class="text-h3 mt-10">
              <neo-icono [iconoClase]="'icon-60 icon-ibm--information'"></neo-icono> No se han encontrado registros.
            </p>
          </div>
        </ng-container>
      </div>
      <ng-template #tableEmpty>
        <div class="sin-resultados">
          <neo-icono [iconoClase]="'icon-60 icon-ibm--information'"></neo-icono>
          <p class="text-h3 mt-10">No se han encontrado registros.</p>
        </div>
      </ng-template>
      <mat-paginator [iconosClasesPaginador]="{navigationFirst : 'icon-ibm--page--first', navigationPrevious : 'icon-ibm--chevron--left', navigationNext: 'icon-ibm--chevron--right',navigationLast: 'icon-ibm--page--last'}"
        *ngIf="paginacion && totalResultados > 0"
        [length]="totalResultados"
        [pageSizeOptions]="opcionesPaginacion"
        [showFirstLastButtons]=true
        (page)="eventoCambioPaginador($event)">
      </mat-paginator>
    </div>
  </div>
</section>

<ng-template #dialogoMasInfo>
  <neo-acciones-dialog #accionDialogMasInfo
    [dialogTittle]="'Información'"
    [botonera]="[]">
     <neo-perfil #perfilMasInfo
      [propiedades]="propiedadesMasInfo">
     </neo-perfil>
  </neo-acciones-dialog>
</ng-template>

<!-- filtros responsivos ocultos -->
<ng-template #dialogFiltrosResponsivos>
  <neo-acciones-dialog
  [dialogTittle]="'Filtros'"
  [botonCerrarDialogoCabecera]=true
  [botonera]="botoneraDialogFiltrosResponsivos"
  (accionBoton)="submitAccionDialogFiltrosResponsivos($event)"
    >
    <neo-busqueda #filtrosBusquedaDialog
      placeholder="Busqueda"
      [botonLimpiarBuscador]="true"
      [valorDefault]="true"
      [keyFiltrado]="keyFiltrado"
      (limpiarBuscador)="clearBuscador()"
      (changeBuscador)="eventoCambioBuscador($event)"
    ></neo-busqueda>
    <neo-filtros-tabla
       [keyFiltrado]="keyFiltrado"
       [valoresIniciales]="valoresInicialesFiltrado"
       (cambioFormulario)="eventoCambioFormularioFiltros($event)"
    ></neo-filtros-tabla>
</neo-acciones-dialog>
</ng-template>

<!--
<pre>{{ columnasOcultas | json}}</pre> -->
