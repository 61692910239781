// {iconoClase: 'icon-20 icon-ibm--restart', color: '#00D400', nombre: 'Nuevo'}]

import { LoremIpsum } from "lorem-ipsum";
import { USUARIOS_DEMO } from "../layoutUsuarios/usuarios/layout-usuario-data";
import { ICliente, ITicket, ITicketEstado } from "./tickets-configuracion"

const loremIpsum = new LoremIpsum();

export const TICKETS_ESTADOS_DEMO: ITicketEstado[] = [
  {id: '1', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Nuevo'},
  {id: '2', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Abierto'},
  {id: '3', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Pendiente'},
  {id: '4', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'En Espera'},
  {id: '5', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Resuelto'},
  {id: '6', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Perdida'},
  {id: '7', iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Cerrado'}
];

export const CLIENTE_DEMO: ICliente[] = [
  {
    id:                                 '1',
    nombre:                            'Alarco SL'  
  },
  {
    id:                                 '2',
    nombre:                            'Greta SA'  
  },
  {
    id:                                 '3',
    nombre:                            'Coarco SL' 
  }
]


export const TICKETS_DEMO: ITicket[] = [ 
  {
    id: '1',
    idUsuario: '2',
    importante: false,
    asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 1, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[0].id,
    estado: TICKETS_ESTADOS_DEMO[0],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  {
    id: '2',
    idUsuario: '2',
    importante: false,
     asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 10, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[1].id,
    estado: TICKETS_ESTADOS_DEMO[1],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  {
    id: '3',
    idUsuario: '2',
    importante: true,
     asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 1, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[2].id,
    estado: TICKETS_ESTADOS_DEMO[2],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  {
    id: '4',
    idUsuario: '2',
    importante: false,
     asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 1, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[3].id,
    estado: TICKETS_ESTADOS_DEMO[3],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  {
    id: '5',
    idUsuario: '2',
    importante: false,
     asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 1, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[4].id,
    estado: TICKETS_ESTADOS_DEMO[4],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  {
    id: '6',
    idUsuario: '2',
    importante: false,
     asunto: `${loremIpsum.generateWords(4)}`,
    descripcion: `${loremIpsum.generateWords(20)}`,
    fecha:  new Date(2022, 1, 1, 10, 20),
    idCliente: '1',
    idEstado: TICKETS_ESTADOS_DEMO[5].id,
    estado: TICKETS_ESTADOS_DEMO[5],
    cliente : CLIENTE_DEMO[0],
    usuario: USUARIOS_DEMO[0] 
  },
  { id: '7',
  idUsuario: '2',
  importante: false,
  asunto: 'asdfasdfasdf',
  descripcion: 'asdfasdfasdf',
  fecha:  new Date(2022, 1, 1, 10, 20),
  idCliente: '1',
  idEstado: TICKETS_ESTADOS_DEMO[6].id,
  estado: TICKETS_ESTADOS_DEMO[6],
  cliente : CLIENTE_DEMO[0],
  usuario: USUARIOS_DEMO[0] }
]