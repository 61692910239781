import { SelectionModel } from '@angular/cdk/collections';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { FormTagEnum } from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { ITodoNodoItem } from '@appNeo/neoShared/helpers/interfaces/ITodo-nodo-item';
import { ArbolAnidadoService } from '@appNeo/neoShared/services/arbol-anidado/arbol-anidado.service';
import { ArbolService } from '@appNeo/neoShared/services/arbol/arbol.service';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { environment } from '@environments/environment';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { IBotonAccionesDialog } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { BotonDesplegableComponent } from '@appNeo/neoShared/components/boton-desplegable/boton-desplegable.component';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';

/**
 * @title Tree with nested nodes
 */
 @Component({
  selector: 'neo-arbol-anidado',
  templateUrl: './arbol-anidado.component.html',
  styleUrls: ['./arbol-anidado.component.scss'],
  providers: [BotonDesplegableService, FormularioService]
})
export class ArbolAnidadoComponent implements OnInit{
  @Input('btnAccion') btnAccion: boolean = true;
  @Input('busqueda') busqueda: boolean = true;

  /** La selección para la lista de verificación */
  seleccionChecklist = new SelectionModel<ITodoNodoItem>(true /* multiple */);
  treeControl = new NestedTreeControl<ITodoNodoItem>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ITodoNodoItem>();

  /** subscripcion al filtro de busqueda */
  filtroBusqueda: Subject<string> = new Subject<string>();
  /** Control de existencia de elementos visualizados a la vista de usuario */
  elementosVisualizados: boolean = true;
  /** término de búsqueda actual*/
  termino: string = '';

  @Output('submitArbol') submitArbol = new EventEmitter<any[]>();
  numeroNodosSeleccionados = 0;
  numeroNodos = 0;

  // acciones nodo
  nodoEdicion: ITodoNodoItem;
  nodoSeleccionado: ITodoNodoItem;
  // accionesBotonDesplegable: IAccionesBotonDesplegable[] = [
  //   {id: '1', iconoClase: 'icon-ibm--document icon-16', texto: 'Editar sss'},
  //   {id: '2', iconoClase: 'icon-ibm--document icon-16', texto: 'Eliminar'},
  // ];

  formInputEdicion: IFormInput[] = [
    {
      tag: FormTagEnum.input,
      formControlName: 'item',
      type: 'text',
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
    }
  ];


  subMenuDespegableItem: Subscription;
  subMenuDespegableAccionesMultiples: Subscription;

  @ViewChild('dialogConfirmarEliminarNodo') dialogConfirmarEliminarNodo: TemplateRef<any>;
  @ViewChild('dialogEditarNodo') dialogEditarNodo: TemplateRef<any>;
  @ViewChild('formularioEdicion') formularioEdicion: FormularioComponent;
  @ViewChild('btnDepegableAccioneMultiples') btnDepegableAccioneMultiples: BotonDesplegableComponent;


  constructor(
    private dialog: MatDialog,
    private arbolServicio: ArbolAnidadoService,
    private botonDesplegableService: BotonDesplegableService,
    private element: ElementRef,
    private formularioService: FormularioService,
  ) {
    // this.dataSource.data = TREE_DATA;
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.chequearEstadoPorTamanho();
  }

  chequearEstadoPorTamanho() {
    if (window.innerWidth > environment.MEDIA_MOBILE) {
      // cerramos dialogo menu desplegable al aumentar size dispositivo
     this.btnDepegableAccioneMultiples.cerrarDialogo();
    }
  }

  hasChild = (_: number, node: ITodoNodoItem) => !!node.children && node.children.length > 0;

  getLevel(data, node: ITodoNodoItem) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => n.item === node.item);
    });

    return path ? this.getLevel(path.children, node) + 1 : 0 ;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.botonDesplegableService.acciones = [
      {id: '3', iconoClase: 'icon-20 icon-mdi--select-all', texto: 'Seleccionar todo'},
      {id: '4', iconoClase: 'icon-20 icon-ibm--collapse-all', texto: 'Expandir todo'},
      {id: '5', iconoClase: 'icon-20 icon-ibm--layers', texto: 'Colapsar todo'},
    ];
    this.subscripcionMenuDesplegableAccionesMultiples();
    // this.iniciarVistaArbol();
    this.arbolServicio.getDatos().subscribe(data => {
      this.dataSource.data = data;
      this.iniciarVistaArbol();
      this.subscripcionMenuDesplegableItem();

   });

  }

  iniciarVistaArbol() {
    // console.log('*******************************************');
    // console.log('*******************************************');
    // console.log('*******************************************');
    this.expandaAll();
    this.filtroBusqueda.pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(termino => {
      if (termino && termino.length >= 3) {
        this.termino = termino;
        this.treeControl.collapseAll();
        this.filtrarPorNombre(termino);
      } else {
        this.expandaAll();
        this.filtroLimpiar();
      }

    });
    // preseleccionados
    // this.preseleccion();
    // this.subscripcionMenuDesplegableItem();
  }

  expandaAll() {
    this.loopExpandAll(this.dataSource.data);
  }
  loopExpandAll(data) {
    data.forEach(d => {
      this.treeControl.expand(d);
      if (d.children && d.children.length > 0) {
        this.loopExpandAll(d.children);
      }
    });
  }


  /** Alternar una selección de elementos de tareas pendientes de hoja. Verifique a todos los padres para ver si cambiaron */
  todoLeafItemSelectionToggle(nodo: ITodoNodoItem): void {
    this.seleccionChecklist.toggle(nodo);
    this.checkAllParentsSelection(nodo);
    this.treeControl.expand(nodo);
  }

  /* Comprueba todos los partodoLeafItemSelectionToggleents cuando un nodo hoja está seleccionado / deseleccionado */
  checkAllParentsSelection(nodo: ITodoNodoItem): void {
    let padre: ITodoNodoItem | null = this.obtenerNodoPadre(nodo);
    while (padre) {
      this.checkRootNodeSelection(padre);
      padre = this.obtenerNodoPadre(padre);
    }
  }

   /** Verifique el estado verificado del nodo raíz y cámbielo en consecuencia */
   checkRootNodeSelection(node: ITodoNodoItem): void {
    const nodeSelected = this.seleccionChecklist.isSelected(node);
    const descendientes = this.treeControl.getDescendants(node);
    const descAllSelected = descendientes.length > 0 && descendientes.every(child => {
      return this.seleccionChecklist.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.seleccionChecklist.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.seleccionChecklist.select(node);
    }
  }


  descendientesTodosSeleccionados(nodo: ITodoNodoItem): boolean {
    const descendientes = this.treeControl.getDescendants(nodo);
    const descendientesTodoSeleccionados = descendientes.length > 0 && descendientes.every(child => {
      return this.seleccionChecklist.isSelected(child);
    });
    return descendientesTodoSeleccionados;
  }

  descendientesParcialmenteSeleccionados(nodo: ITodoNodoItem): boolean {
    const descendientes = this.treeControl.getDescendants(nodo);
    const resultado = descendientes.some(child => this.seleccionChecklist.isSelected(child));
    return resultado && !this.descendientesTodosSeleccionados(nodo);
  }

  alternarSeleccionDesdencientes(nodo: ITodoNodoItem): void {
    this.seleccionChecklist.toggle(nodo);
    const descendientes = this.treeControl.getDescendants(nodo);
    this.seleccionChecklist.isSelected(nodo)
      ? this.seleccionChecklist.select(...descendientes)
      : this.seleccionChecklist.deselect(...descendientes);

    // Force update for the parent
    descendientes.forEach(child => this.seleccionChecklist.isSelected(child));
    this.checkAllParentsSelection(nodo);

  }


  /* Obtener el nodo padre de un nodo */
  obtenerNodoPadre(node: ITodoNodoItem): ITodoNodoItem | null {
    const ancestors = this.getAncestors(this.dataSource.data, node.item);
    if (ancestors[ancestors.length - 2]) {
      return ancestors[ancestors.length - 2];
    } else {
      return  null;
    }

  }

  getAncestors(array, item) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].item === item) {
          return [array[i]];
        }
        const a = this.getAncestors(array[i].children, item);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  onLeafNodeClick(node: ITodoNodoItem): void {
    const ancestors = this.getAncestors(this.dataSource.data, node.item);
    let breadcrumbs = "";
    ancestors.forEach(ancestor => {
      breadcrumbs += `${ancestor.item}/`;
    });
    // console.log("breadcrumbs ", breadcrumbs);
  }

   //  Funciones desarrolldas para cubrir nuevas funcionalidades del componente mat-tree
  /*******************************************************
  * Filtrado
  *******************************************************/
   filtroCambiado(filtro: string): void {
    // console.log(filtro);
    this.filtroBusqueda.next(filtro);
  }

  filtrarPorNombre(termino: string ): void {
    this.busquedaRecursiva(this.dataSource.data, termino);
    this.expandirRamaFiltrada();
  }

  filtroLimpiar() {
    this.termino = '';
    this.elementosVisualizados = true;
    this.dataSource.data.forEach(x =>  {
      x.coincidenciaBusqueda = false;
      x.visible = true
    });
  }

  esBusqueda() {
    return !this.termino || (this.termino && this.termino.length==0);
  }

  focusPrimeraCoincidencia() {
    const span = this.element.nativeElement.querySelector('[class="nodo-resaltado"]');
    if (span) {
      span.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }

  /** Hacer visibles todos los nodos ascendientes de un nodo incluídos sus hermanos*/
  hacerVisibleAscendientes(nodo: ITodoNodoItem) {
    let padre: ITodoNodoItem | null = this.obtenerNodoPadre(nodo);
    while (padre) {
      padre.visible = true;

      // visibles hijos sin profundizar en siguiente nivel descendientes
      padre.children.forEach( nodoItemHijo => {
        nodoItemHijo.visible = true;
      });

      padre = this.obtenerNodoPadre(padre);
    }
  }

  /** Hacer visibles todos los nodos descendientes de un nodo incluídos sus hermanos*/
  hacerVisibleDescendientes(nodo: ITodoNodoItem) {
    if (nodo && nodo.children && nodo.children.length) {
      let hijosItem = nodo.children;

      hijosItem.forEach( nodoItemHijo => {
        nodoItemHijo.visible = true;
        this.hacerVisibleDescendientes( nodoItemHijo );
      });
    }
  }


  /*******************************************************
   * Expansión
  ********************************************************/
  // Expande solo los nodos ascendentes hasta su padre, no expande hermanos ni propio nodo.
  expandirRamaFiltrada() {
    this.loopExpandirRamaFiltrada(this.dataSource.data);
  }
  loopExpandirRamaFiltrada(data) {
    data.forEach(d => {
      // accion
      if( d.coincidenciaBusqueda ) {
        this.expandirPadre(d);
      }
      if (d.children && d.children.length > 0) {
        this.loopExpandirRamaFiltrada(d.children);
      }
    });
  }

  expandirPadre( nodo: ITodoNodoItem ) {
    let nodoPadre = this.obtenerNodoPadre(nodo);
    if (nodoPadre) {
      this.treeControl.expand(nodoPadre);
      if ( this.getLevel(this.dataSource.data, nodoPadre) >  0 ) {
        this.expandirPadre(nodoPadre)
      }
    }
  }


  /*******************************************************
  * Selección
  ********************************************************/
  obetenerSeleccion() {
    return this.seleccionChecklist.selected.map( nodo => nodo.id );
  }

  convertirSeleccion():void {
    if ( this.estanTodosSeleccionados() ) {
      this.deseleccionarTodos();
    } else {
      this.seleccionarTodos();
    }
  }

  estanTodosSeleccionados(){
    this.numeroNodos = 0; // resetting count
    this.numeroNodosSeleccionados = 0; // resetting count
    this.loopContabilizarSeleccion(this.dataSource.data);
    return this.numeroNodos > 0 && this.numeroNodos == this.numeroNodosSeleccionados;
  }
  loopContabilizarSeleccion(data) {
    data.forEach(d => {
      this.numeroNodos++;
      if (this.seleccionChecklist.isSelected(d)) {
        this.numeroNodosSeleccionados += 1;
      }
      if (d.children && d.children.length > 0) {
        this.loopContabilizarSeleccion(d.children);
      }
    });
  }

  seleccionarTodos():void {
    // this.dataSource.data.map( (nodo:ITodoNodoItem) =>  this.seleccionChecklist.select(nodo));
    this.loopSeleccionarTodo(this.dataSource.data);
    this.expandaAll();
  }
  loopSeleccionarTodo(data) {
    data.forEach(d => {
      this.seleccionChecklist.select(d);
      if (d.children && d.children.length > 0) {
        this.loopSeleccionarTodo(d.children);
      }
    });
  }

  deseleccionarTodos():void {
    // this.dataSource.data.map( (nodo:ITodoNodoItem) =>  this.seleccionChecklist.select(nodo));
    this.loopDeseleccionarTodo(this.dataSource.data);
  }
  loopDeseleccionarTodo(data) {
    data.forEach(d => {
      this.seleccionChecklist.deselect(d);
      if (d.children && d.children.length > 0) {
        this.loopDeseleccionarTodo(d.children);
      }
    });
  }


  // SUBMIT
  submit() {
    let seleccion = this.obetenerSeleccion().filter( id => id);
    this.submitArbol.emit( seleccion );
  }

  subscripcionMenuDesplegableItem() {
    if (this.subMenuDespegableItem) {
      this.subMenuDespegableItem.unsubscribe();
    }
    this.subMenuDespegableItem = this.botonDesplegableService.accionItemSeleccionados$.subscribe(accionItemSeleccionados => {
      this.nodoSeleccionado = accionItemSeleccionados.item;
      switch(accionItemSeleccionados.idAccion) {
        case '1':
          this.formularioService.inputs = this.formInputEdicion;
          this.dialog.open(this.dialogEditarNodo, {disableClose: true, panelClass: ['dialogoEditarNodo', 'dialogoFormulario']});
        break;
        case '2':
          this.dialog.open(this.dialogConfirmarEliminarNodo, {panelClass: 'dialogoConfirmarEliminarNodo'});
        break;
      }
    });
  }

  subscripcionMenuDesplegableAccionesMultiples() {
    if (this.subMenuDespegableAccionesMultiples) {
      this.subMenuDespegableAccionesMultiples.unsubscribe();
    }
    this.subMenuDespegableAccionesMultiples = this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
      switch(accionSeleccionada) {
        case '3':
          this.convertirSeleccion()
        break;
        case '4':
          this.expandaAll();
        break;
        case '5':
          this.treeControl.collapseAll();
        break;
      }
    });
  }

  submitAccionDialogConfirmarEliminar(button: IBotonAccionesDialog) {
    if (button.id = 'btn-confirmar') {
      this.cerrarDialogo();
    }
  }

  submitAccionDialogEditar(button: IBotonAccionesDialog) {
    if (button.id = 'btn-guardar') {
      let objeto = this.formularioEdicion.validaCampos( false );
      if (objeto && objeto.formulario && objeto.formulario.valid) {
        this.arbolServicio.actualizarNodo(this.nodoSeleccionado,objeto.formulario.value.item);
        this.cerrarDialogo();
      }
    }
  }
  // prototipos
  cerrarDialogo() {
    this.dialog.closeAll();
  }

  busquedaRecursiva(obj: ITodoNodoItem[], termino) {
    obj.forEach((item, index, array )=> {
      const value = item.children;
      let coincidenciaBusqueda = item.item.toLowerCase().indexOf(termino.toLowerCase()) !== -1;
      array[index].coincidenciaBusqueda  = coincidenciaBusqueda;
      array[index].visible = coincidenciaBusqueda;
      if( value && value.length ) {
        this.busquedaRecursiva(value, termino);
     }
    })
  }

  obtenerNumeroNodos(obj: ITodoNodoItem[],numeroNodos=0) {
    obj.forEach((item, index, array )=> {
      const value = item.children;
      numeroNodos++;
      if( value && value.length ) {
        this.obtenerNumeroNodos(value, numeroNodos);
     }
    });
    return numeroNodos;
  }

  preseleccion() {
    this.loopPreseleccion(this.dataSource.data);
  }
  loopPreseleccion(data) {
    data.forEach(d => {
      if(d.seleccionado) {
        this.seleccionChecklist.select(d);
        if (d.children && d.children.length > 0) {
            this.loopPreseleccion(d.children);
        }
      }
    })
  }


  desactivarEfectoCheckbox(checkbox){
    // meter clase accion
    checkbox._elementRef.add
    document.getElementById(checkbox.id).classList.add('accionButon');
  }

  activarEfectoCheckbox(checkbox){
    // meter calse accionButton
    document.getElementById(checkbox.id).classList.remove('accionButon');
  }
}


