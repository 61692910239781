import { Component, EventEmitter, Input, Output } from '@angular/core';

// Esta Variante aplica cuando necesitamos controlar el valor mediante ngModel (Caso tabla)
@Component({
  selector: 'neo-campo-select-model',
  templateUrl: './campo-select-model.component.html',
  styleUrls: ['../campo-select.component.scss']
})
export class CampoSelectModelComponent {

  @Input() opciones: [];
  @Input() opcion: string;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '';
  @Input() min: any;
  @Input() max: any;
  @Input() disabled = false;
  @Input() icon: string;

  @Output() nuevaOpcion = new EventEmitter<string>();

}
