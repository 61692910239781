<neo-contenedor-cabecera
[titulo]="'Favoritos'">
<neo-notificacion #notificacionTablaFavoritos></neo-notificacion>
<neo-tabla-editable
  [tablaOnline]="false"
  [titulo]="''"
  [filtrado]="true"
  [keyFiltrado]="'filter_usuriosfavoritos'"
  [columnasFiltro]="['titulo']"
  [btnGuardar]="true"
  [btnCancelar]="true">
</neo-tabla-editable>
</neo-contenedor-cabecera>
