import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFicheroExtendido } from '@appNeo/neoShared/helpers/interfaces/IFichero';
import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { environment } from '@environments/environment';
import { Observable, catchError, map, throwError, BehaviorSubject, tap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FicheroSubidaService {

  // TODO configurar los parametros objetoRelaciondo, urlArchivos, y campos extras a la hora de meter neo-formulario archivo similar al como se hace con un arbol
  objetoRelacionado = '/edicionSeccionItems';
  urlArchivos: string = environment.base_url;
  urlItem = environment.api_base_url_backoffice + this.objetoRelacionado;
  api_base_url_backoffice = environment.api_base_url_backoffice;

  private filesACargar$ = new BehaviorSubject<File[]>([]);

  constructor(
    protected http: HttpClient
  ) { }

  getfilesACargar():BehaviorSubject<File[]>{
    return this.filesACargar$;
  }

  setfilesACargar(datos: File[]) {
    this.filesACargar$.next(datos);
  }

  // a
  presubirFichero(ficheros: File[], idObjeto:string = '1'): Observable<any> {
    const formData: FormData = new FormData();
    if(ficheros.length){
      for(let i=0 ; i < ficheros.length ; i++)
      formData.append('fichero[]', ficheros[i],ficheros[i].name);
    }


    return this.http.post(`${this.urlItem}/${idObjeto}/ficherosTemp`, formData, {
      responseType: "blob", reportProgress: true, observe: "events"
    })
    .pipe(
      tap( (data:any) => console.log('La data recibida es', data))
    );
  }

  subirFichero(ficheros: File[], idObjeto: string = '1', camposExtra?: object, objetoRelacionado?: string): Observable<any> {
    const formData: FormData = new FormData();
    if(ficheros.length){
      for(let i=0 ; i < ficheros.length ; i++)
      formData.append('fichero[]', ficheros[i],ficheros[i].name);
      if (camposExtra && Object.keys(camposExtra)?.length > 0) {
        Object.entries(camposExtra).forEach(([key, value]) => {
          formData.append(key, value.toString());
          console.log(key, 'camps extra subida fichero formdata:::: ', formData.getAll(key));
        });
      }
    }

    if (environment.debug) {
      console.log("SUBIDA FICHERO TEMPORAL A ", objetoRelacionado, this.objetoRelacionado);
    }
    if (objetoRelacionado == undefined && this.objetoRelacionado == '/edicionSeccionItems') {
      if (environment.debug) {
        console.log("SUBIDA FICHERO TEMPORAL A ", "MODO ID");
      }

      let peticion = (!objetoRelacionado) ? `${this.urlItem}/${idObjeto}/ficherosTemp` : `${this.api_base_url_backoffice}/${objetoRelacionado}/${idObjeto}/ficherosTemp`
      return this.http.post(peticion, formData, {
        reportProgress: true, observe: "events"
      });
    } else {
      if (environment.debug) {
        console.log("SUBIDA FICHERO TEMPORAL A ", "MODO CARPETA TEMPORAL TODO");
      }
      let endpoint = (objetoRelacionado == 'usuarios') ? 'ficheroTemp' : 'ficherosTemp';
      let peticion = (!objetoRelacionado) ? `${this.urlItem}/ficherosTemp` : `${this.api_base_url_backoffice}/${objetoRelacionado}/${endpoint}`
      return this.http.post(peticion, formData, {
        reportProgress: true, observe: "events"
      });
    }
  }

  getRuta(ruta){
    let _ruta = this.urlArchivos + ruta;
    return _ruta;
  }
}
