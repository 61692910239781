import { Injectable } from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {

  public actualUrl = new BehaviorSubject<string>(undefined);
  constructor(
    private router: Router
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currenturl = event.url.split('?')[0];
        this.actualUrl.next(currenturl);

      }
    });    
  }
}
