<a mat-list-item (click)="seleccionItem(item)"
   [ngClass]="{'active': esActivo(item) || expanded, 'expanded': expanded}"
   class="menu-list-item">

    <span *ngIf="depth < 1;else iconMenuItemHijo">
      <neo-icono
        [iconoClase]="'icon-24 ' + item.iconoClase"
      ></neo-icono>
    </span>
    <ng-template #iconMenuItemHijo>
       <neo-icono
        [iconoClase]="'icon-16 icon-ibm--dot-mark'"
       ></neo-icono>
    </ng-template>

  <span *ngIf="!minivariante">
    {{item.label}}
  </span>
  <span class="toggle-item-content" fxFlex *ngIf="item.submenu && item.submenu.length && !minivariante">
    <span fxFlex></span>
    <i class="icon-20 icon-mdi--arrow_drop_down" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'"></i>
  </span>
</a>
<div class="submenu" *ngIf="expanded && !minivariante">
  <neo-menu-item *ngFor="let hijo of item.submenu" [item]="hijo" [depth]="depth+1"
  (seleccionMenuItem)="onSeleccionMenuItem()">
  </neo-menu-item>
</div>
