import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { StepperComponent } from '@appNeo/neoShared/components/stepper/stepper.component';
import { ClasesFormularioAnchoEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { INPUTSCREACIONENTIDAD1, INPUTSCREACIONENTIDAD2, ITicket } from '../../tickets-configuracion';

@Component({
  selector: 'neo-formulario-wizard-ticket',
  templateUrl: './formulario-wizard-ticket.component.html',
  styleUrls: ['./formulario-wizard-ticket.component.scss'],
  providers: [FormularioService]
})
export class FormularioWizardTicketComponent implements OnInit, AfterViewInit {

  // congiguracion layout formulario
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  
  configuracionFormStep1: IFormInput[] = INPUTSCREACIONENTIDAD1;
  configuracionFormStep2: IFormInput[] = INPUTSCREACIONENTIDAD2;


  stepControl1: FormGroup;
  stepControl2: FormGroup;

  entidad: ITicket;

  @ViewChild('stepper')  stepper: StepperComponent;
  @ViewChild('formStepControl1')  formStepControl1: FormularioComponent;
  @ViewChild('formStepControl2')  formStepControl2: FormularioComponent;

  constructor(
    private formulario: FormularioService
  ) { }


  ngOnInit(): void {

  }

  cambioPaso(evento){
    const pasos = this.stepper['htmlSteps'];
    if (evento.previouslySelectedIndex < evento.selectedIndex) {
      for (let i = 0; i < evento.selectedIndex; i ++) {
        pasos[i].classList.add('previous');
      }
    }
    else {
      for (let i = pasos.length - 1; i > evento.selectedIndex; i--) {
        pasos[i].classList.remove('previous');
      }
    }
    if (evento.selectedIndex === 4) {
      this.entidad = {...this.stepControl1.value, ...this.stepControl2.value};
    }
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.stepControl1 = this.formStepControl1.formValidators;
      this.stepControl2 = this.formStepControl2.formValidators;

    });
  }

  anterior() {
    this.stepper.previous();
  }

  siguiente() {
    this.stepper.next();
  }

  validarFormularios () {
    let valido = false;
    if (this.stepControl1 && this.stepControl2){
      valido = this.stepControl1.valid && this.stepControl2.valid;
    }
    return valido;
  }
  // calcularEdad(){
  //   let objeto = this.formStepControl2.validaCampos( false );
  //   if (objeto && objeto.formulario && objeto.formulario.valid) {
  //     let fecha = objeto.formulario.value;
  //   }
  //   this.stepper.next();
  // }
}
