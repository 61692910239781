<div>
  <neo-tabla-draggable #tablaDraggable1 [id]="identificadorTabla1"
                       [identificador]="identificadorTabla1"
                       [tablaOnline]="true"
                       [titulo]="'Tabla ONLINE'"
                       [filtrado]="false"
                       [keyFiltrado]="keyFiltrado"
                       [valoresInicialesFiltrado]="valoresInicialesFiltradoTabla"
                       [accionesMultiplesDesplegable]="accionesMultiplesDesplegableTablaDemo"
                       [ordenacionColumnas]="true"
                       [columnasFiltro]="['titulo', 'fechaPublicacion']"
                       [numeroFiltros]="inputsFiltros.length"
                       [btnDescargarPdfCabecera]="true"
                       [btnAccionesLote]="true"
                       (aplicarFiltros)="aplicarFiltrosTablaOnline($event)"
                       (ordenarColumnas)="aplicarOrdenacionTablaOnline($event)"
  ></neo-tabla-draggable>
<!--  Se omite debido a la problemática de la instancia de los servicios tabla y filtros tabla-->
<!--  <neo-tabla-draggable #tablaDraggable2 [id]="identificadorTabla2"-->
<!--                       [identificador]="identificadorTabla2"-->
<!--                       [tablaOnline]="false"-->
<!--                       [titulo]="'Tabla OFFLINE'"-->
<!--                       [filtrado]="true"-->
<!--                       [valoresInicialesFiltrado]="valoresInicialesFiltradoTabla"-->
<!--                       [accionesMultiplesDesplegable]="accionesMultiplesDesplegableTablaDemo"-->
<!--                       [ordenacionColumnas]="true"-->
<!--                       [columnasFiltro]="['autor', 'avatar', 'fechaPublicacion']"-->
<!--                       [numeroFiltros]="inputsFiltros.length"-->
<!--                       [btnDescargarPdfCabecera]="true"-->
<!--                       [btnAccionesLote]="true"-->
<!--                       (aplicarFiltros)="aplicarFiltrosTablaOnline($event)"-->
<!--  ></neo-tabla-draggable>-->


    <ng-template #dialogCreacion>
      <neo-acciones-dialog #accionDialogCreacion
                           [dialogTittle]="'Nueva entrada'"
                           [botonera]="[
         {
            label: 'Cancelar',
            id: 'btn-confirmar',
            tipoAccion: 'cerrar',
            type: 'button',
            color: 'warn',
            disabled: false,
            activo: true,
            flat: true
          },
          {
            label: 'Guardar',
            id: 'btn-guardar',
            tipoAccion: 'accion',
            type: 'button',
            color: 'primary',
            disabled: false,
            activo: true,
            flat: true
          },
        ]"
      >
        <neo-formulario #formularioCreacion
        ></neo-formulario>
      </neo-acciones-dialog>
    </ng-template>

</div>
