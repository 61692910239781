import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { USUARIO_DEMO } from '@appNeo/neoCore/services/auth/auth-data';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';

import { IRespApi } from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, catchError, map, Observable, Subject, tap, throwError } from 'rxjs';
import { USUARIOS_DEMO } from '../layoutUsuarios/usuarios/layout-usuario-data';
import { TICKETS_DEMO, TICKETS_ESTADOS_DEMO } from './layout-tickets-data';
import { ITicket } from './tickets-configuracion';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  url: string = environment.api_base_url;
  public editandoTicket$ = new BehaviorSubject<boolean>(false);

  // CONFIG
  urlTickets = environment.api_base_url + '/tickets';
  urlTicketsEstado = environment.api_base_url + '/ticketsEstado';
  public errorApi$: Subject<IRespApi> = new Subject<IRespApi>();
  public ticket$: BehaviorSubject<ITicket> = new BehaviorSubject<ITicket>(null);
  public tickets$: BehaviorSubject<ITicket> = new BehaviorSubject<ITicket>(null);
  public totalTickets$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    private http: HttpClient,
    private auxiliar: AuxiliarService,
  ) { }

  set editandoTicket(flag: boolean) {
    console.log(flag);
    this.editandoTicket$.next(flag);
  }

  set ticket(ticket: ITicket) {
    this.ticket$.next(ticket);
  }

  // CRUD
  getAll(tipoPaginador?: TipoPaginadorEnum, paginator?: Paginator, filtros?: any): Observable<ITicket[]> {
    let _filtros = filtros;
    if (!_filtros || _filtros && !filtros?.orden) {
      _filtros = { orden: 'nombre', tipoOrden: 'asc' };
    }
    let request = `${this.urlTickets}?${this.auxiliar.extraerFiltrosPaginacion(paginator)}${this.auxiliar.extraerFiltros(_filtros)}`;
    return this.http.get(request, {}).pipe(
      tap((data: IRespApi) => { this.tickets$.next(data.data); }),
      tap((data: IRespApi) => { this.totalTickets$.next(data.total); }),
      map((data: IRespApi) => this.mapearEntidades(data['data'])));
  }

  get(id): Observable<ITicket> {
    return this.http.get(`${this.urlTickets}/${id}`).pipe(
      map((data: IRespApi) => data['data'])
    );
  }


  mapearEntidades(listadoEntidad: ITicket[]) {
    if (listadoEntidad.length) {
    }
    return listadoEntidad;
  }

  // getEstados(): Observable<ITicketEstadoActual[]>  {
  //   return this.http.get(`${this.urlTicketsEstado}`).pipe(
  //     map((data: IRespApi) => data['data'])
  //   );
  // }

  create(request: any): Observable<any> {
    let _request = { ...request, idUsuario: USUARIOS_DEMO[0]?.id, usuario: USUARIOS_DEMO[0], importante: true, fecha: new Date(), idEstado: TICKETS_ESTADOS_DEMO[0]?.id }
    return this.http.post<IRespApi>(`${this.urlTickets}`, _request).pipe(
      tap(() => TICKETS_DEMO.push({ ..._request, id: TICKETS_DEMO.length + 1 })),
      tap(() => console.log(TICKETS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'CreateTicket');
      })
    );
  }

  update(id: string, request: any): Observable<any> {
    return this.http.patch<IRespApi>(`${this.urlTickets}/${id}`, request).pipe(
      tap(() => TICKETS_DEMO.push({ ...request })),
      tap(() => console.log(TICKETS_DEMO)),
      map((resp: IRespApi) => {
        return resp.data;
      }),
      catchError((err: IRespApi) => {
        console.log('Capturamos error ', err);
        return this.handleError(err, 'UpdateTicket');
      })
    );
  }


  /****************************/
  /* FICHEROS                */
  /*****************************/
  subirFichero(ficheros: File[], idObjeto = 1): Observable<any> {
    const formData: FormData = new FormData();
    if (ficheros.length) {
      for (let i = 0; i < ficheros.length; i++)
        formData.append('fichero[]', ficheros[i], ficheros[i].name);
    }
    return this.http.post(`${this.urlTickets}/${idObjeto}/ficheros`, formData).pipe(
      map((data: IRespApi) => data),
      catchError((err: IRespApi) => {
        return throwError(() => new Error(err.mensaje));
      })
    );
  }

  getFicheros(id: string) {
    return this.http.get(`${this.urlTickets}/${id}/ficheros`).pipe(
      map((data: IRespApi) => data));
  }

  getFichero(id: string) {
    return this.http.get(`${environment.api_base_url}/ficheros/12`);
  }

  eliminarFichero(id: string) {
    return this.http.delete(`${environment.api_base_url}/ficheros/${id}`).pipe(
      map((data: IRespApi) => data));
  }

  /************************************ 
   * Funciones apoyo                  *
  *************************************/
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITicket> {
    const id = route.params.id;
    return this.get(id);
  }

  /********************************/
  /* MANEJADOR ERRORES            */
  /********************************/
  private handleError(err: IRespApi, idRequest = '') {
    // dialog específico
    if (err.codigoError === 422) {
      err.request = idRequest;
      this.errorApi$.next(err);
    }

    // pantalla común
    let mensaje = err?.mensaje;
    if (err.codigoError === 403) {
      mensaje = `${err.mensaje}: `;
      err.data.forEach(element => {
        mensaje += element.mensaje;
      });
    }
    if (err.codigoError === 404) {
      let avisos = '';
      if (err.data) {
        if (typeof err.data === 'string') {
          avisos = ' ' + err.data + ' ';
        } else {
          err.data.forEach(element => {
            if (element.id === 999) {
              avisos = ' ' + element.mensaje + ' ';
            }
          });
        }
      }
      mensaje = err?.mensaje + avisos;
    }

    if (err.codigoError === 500) {
      let avisos = '';
      if (err.data) {
        err.data.forEach(element => {
          if (element.id === 999) {
            avisos = ' ' + element.mensaje + ' ';
          }
        });
      }
      mensaje = err?.mensaje + avisos;
    }
    return throwError({ 'error': true, 'data': err.data, mensaje });
  }
}
