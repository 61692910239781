


  <div id="migas">
    <ul class="migas">
      <ng-container *ngIf="migasDePan$ | async as migasdepan">
        <ng-container *ngIf="migasdepan.slice(1, migasdepan.length) as migasdepanfinales">
          <!-- {{migasdepanfinales | json}} -->
          <ng-container *ngIf="migasdepanfinales.length>0">
            <ng-container *ngFor="let nodo of migasdepanfinales.slice(0, migasdepanfinales.length); let i = index ">
              <!-- <ng-container *ngFor="let nodo of migasdepan.slice(0, maxMigasDePan); let i = index " > -->
              <li class="miga" *ngIf="nodo.label !== ''" class="miga-content bcrumb-{{ i + 1 }}">
                <a routerLink="{{nodo.link}}">
                  <i class="fas fa-home" *ngIf="i == 0"></i> {{ nodo.label }}
                </a>
              </li>
            </ng-container>
          </ng-container>

        </ng-container>
      </ng-container>
    </ul>
  </div>
