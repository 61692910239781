<h3 class="mb-20">{{labelPrincipal}}</h3>
<div class="campo-video">
  <div class="form-field">
    <neo-campo-select 
        [controlCampo]="getFormControlCampo(campo, _TipoCampoVideoEnum.plataforma)"
        [label]="labelPlataforma" 
        [textoAyuda]="textoAyudaPlataforma" 
        [multiple]="false" 
        [datos]="plataformas">
    </neo-campo-select>
  </div>
  <div class="form-field">
    <neo-input 
        [controlCampo]="getFormControlCampo(campo, _TipoCampoVideoEnum.identificador)" 
        [label]="labelIdentificador"
        [caracteresMaximo]="longitudMaximaIdentificador" 
        [textoAyuda]="textoAyudaIdentificador">
      </neo-input>
   </div>
</div>