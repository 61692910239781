import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IValorMultiple } from '@appNeo/neoShared/helpers/interfaces/IValorMultiple';


@Component({
  selector: 'neo-select-ngx-single-selection',
  templateUrl: './select-ngx-single-selection.component.html',
  styleUrls: ['./select-ngx-single-selection.component.scss']
})
export class SelectNgxSingleSelectionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() datos: IValorMultiple[];
  @Input() controlCampo: FormControl;
  @Input() label: string;
  @Input() icon: string;
  @Input() textoAyuda = '';

  entidadesFiltradas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  controlCampoFiltrado: FormControl = new FormControl();

  @ViewChild('select', { static: true }) select: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit() {
    this.entidadesFiltradas.next(this.datos.slice());

    this.controlCampoFiltrado.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.aplicarFiltrado();
      });
  }

  ngAfterViewInit() {
    this.determinarValorInicial();
  }

  establecerValorInicial(value) {
    this.controlCampo.setValue(value);
  };


  /**
   * Establece el valor inicial después de que los datos filtrados se carguen inicialmente
   */
  protected determinarValorInicial() {
    this.entidadesFiltradas
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // Desencadena la inicialización de la selección de acuerdo con el valor inicial del formControl
        this.select.compareWith = (a: any, b: any) => a && b && a === b;
      });
  }

  protected aplicarFiltrado() {
    if (!this.datos) {
      return;
    }
    let terminoBusqueda = this.controlCampoFiltrado.value;

    if (!terminoBusqueda) {
      this.entidadesFiltradas.next(this.datos.slice());
      return;
    } else {
      terminoBusqueda = terminoBusqueda.toLowerCase();
    }

    this.entidadesFiltradas.next(
      this.datos.filter(item => item.nombre.toLowerCase().indexOf(terminoBusqueda) > -1)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
