export enum AccionDialogEnum {
  Crear = 'crear',
  Editar = 'editar',
  Eliminar = 'eliminar',
  BorradoMultiple = 'borradoMultiple',
  EliminarArchivo = 'eliminarArchivo',
  Importar = 'importar',
  Actualizar = 'actualizar',
  Enviar = 'enviar',
}
