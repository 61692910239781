import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthQuery } from '@appNeo/neoCore/authState/state/auth.query';
import { Permissions } from '@appNeo/neoCore/authState/state/auth.store';

import { Subscription } from 'rxjs';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {

  @Input('hasPermission')
  checkPermissions: Permissions | Permissions[];
  private subscription: Subscription;

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
    private authQuery: AuthQuery
  ) {}

  ngOnInit() {
    this.authQuery
      .hasPermission(this.checkPermissions)
      .subscribe(hasPermission => {
        this.vcr.clear();

        if (hasPermission) {
          this.vcr.createEmbeddedView(this.tpl);
        }
      });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

}
