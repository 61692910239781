import { Injectable } from '@angular/core';
import { RolesEnum } from '@appNeo/neoCore/authState/state/auth.store';
import { AuthService } from '@appNeo/neoCore/services/auth/auth.service';
import { MenuItemClassEnum } from '@appNeo/neoShared/helpers/enums/MenuItemClass.enum';
import { IBarraIzquierdaMenuItems, ISeccionMenu } from '@appNeo/neoShared/helpers/interfaces/IBarraIzquierdaMenuItems';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BARRA_IZQUIERDA_MENU } from './barra-izquierda-menu-data';

@Injectable({
  providedIn: 'root'
})

export class BarraIzquierdaService {

  public expandido: boolean = environment.barra_desplegada;
  public expandido$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.expandido);

  seccionesMenu: ISeccionMenu[] = BARRA_IZQUIERDA_MENU;

  constructor(
    public authService: AuthService,
  ) { }

  alternarExpandido() {
    this.expandido = !this.expandido;
    this.expandido$.next(this.expandido);
  }

  establecerExpandido(valor: boolean) {
    if ( this.expandido !== valor) {
      this.alternarExpandido();
    }
  }

  establecerMenu(menu: ISeccionMenu[]) {
    this.seccionesMenu = menu;
  }

  menu(): Observable<ISeccionMenu[]> {
    return of(this.seccionesMenu);
  }

  mapearMenu(secciones): ISeccionMenu[] {
    let _seccionesEditable: IBarraIzquierdaMenuItems[] = [];

    let roles = this.authService.user$.value?.roles;
    let esAdministrador = roles.length == 0 || roles.includes(RolesEnum.ADMINISTRADOR);
    // let esAdministrador = roles?.length == 0 || (roles?.length > 0 && (roles.includes(RolesEnum.ADMINISTRADOR) || roles.includes(RolesEnum.ADMIN)));

    let _secciones: IBarraIzquierdaMenuItems[] = [];

    let ruta_padre = environment.adminDemo ? environment.ruta_padre_admin_demo : environment.ruta_padre_admin;
    let _seccionesA: IBarraIzquierdaMenuItems;
    if (secciones.length > 0) {
      // console.log('secciones ', secciones);
      secciones?.map(seccion => {
        _seccionesEditable.push({
          path: `${ruta_padre}edicionSeccion/${seccion.id}`,
          label: `${((seccion['idiomas'] && seccion['idiomas'][environment.codigo_idioma_defecto]?.label) ? seccion['idiomas'][environment.codigo_idioma_defecto].label : seccion.nombre)}`,
          class: MenuItemClassEnum.noDesplegable,
          ultimoNivelPath: '',
          iconoClase: (seccion?.icono) ? seccion.icono : 'icon-ibm--letter--Aa',
        })
      })
      _seccionesA = {
        path: `${ruta_padre}edicionSeccion`,
        label: 'Secciones editables',
        class: MenuItemClassEnum.noDesplegable,
        ultimoNivelPath: 'auth',
        iconoClase: 'icon-ibm--letter--Aa',
        submenu: _seccionesEditable
      }
    }
    let edicionSeccion: ISeccionMenu = {
      seccion: 'Contenido web',
      menu: [_seccionesA]
    };
    return (esAdministrador) ? [edicionSeccion] : [];
  }
}
