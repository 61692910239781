<div class="cabecera">
    <div class="contenedor-cabecera">
        <div>
            <h3 class="cabecera-tit" data-testid="tituloContenedor">
                <a class="link linkMain" *ngIf="paginaEnlace && paginaEnlace!=''; else tituloContenedor"
                    (click)="$event.stopPropagation()" (click)="onEnlace()">
                    <ng-container>
                        <ng-template [ngTemplateOutlet]="tituloContenedor"></ng-template>
                    </ng-container>
                </a>
                <ng-template #tituloContenedor>
                    {{titulo}}
                </ng-template>
            
            </h3>
            <h4 class="cabecera-sub" *ngIf="subTitulo" data-testid="subTituloContenedor">{{subTitulo}}</h4>
        </div>
        <div class="botonera" *ngIf="botonera?.length" data-testid="botoneraContenedor">
            <ng-container *ngFor="let boton of botonera">
                <neo-button tabindex="7"  *ngIf="boton.activo"
                    [basic]="true"
                    [type]="'button'"
                    [raised]="boton?.raised"
                    [stroked]="boton?.stroked"
                    [fab]="boton?.fab"
                    [miniFab]="boton?.miniFab"
                    [basic]="boton?.basic"
                    [flat]="boton?.flat"
                    [text]="boton.label"
                    [color]="boton.color"
                    [disabled]="boton?.disabled"
                    [iconClass]="boton?.iconoClase"
                    [icono]="boton?.icono"
                    [matprefix]="boton?.matprefix"
                    [matsuffix]="boton?.matsuffix"
                    [clase] = "(boton?.clase) ? boton?.clase: 'ml-10'"
                    (accion)="(!boton?.disabled) ? accion(boton) : return;"
                ></neo-button>
            </ng-container>
        </div>
        <div class="botonera-minifab" *ngIf="botonera?.length" data-testid="botoneraContenedor">
            <ng-container *ngFor="let boton of botonera">
                <neo-button tabindex="7"  *ngIf="boton.activo"
                    [miniFab]="true"
                    [type]="'button'"
                    [color]="boton.color"
                    [disabled]="boton?.disabled"
                    [iconClass]="boton?.iconoClase"
                    [icono]="boton?.icono"
                    [matprefix]="boton?.matprefix"
                    [matsuffix]="boton?.matsuffix"
                    [clase] = "(boton?.clase) ? boton?.clase: 'ml-10'"
                    (accion)="(!boton?.disabled) ? accion(boton) : return;"
                ></neo-button>
           
            </ng-container>
        </div>
    </div>
    <div class="contenedor-cuerpo">
        <ng-content></ng-content>
    </div>
</div>


