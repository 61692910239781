import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

export interface IBotonContenedorCabecera {
  label: string,
  id: string,
  color: string,
  disabled: boolean,
  activo: boolean,
  iconoClase: string,
  icono: string,
  matprefix?: boolean,
  matsuffix?: boolean,
  // TODO responsivo
  // tipo button y siempre buton angular neoMaterial basic
}

@Component({
  selector: 'neo-contenedor-cabecera[titulo]',
  templateUrl: './contenedor-cabecera.component.html',
  styleUrls: ['./contenedor-cabecera.component.scss']
})
export class ContenedorCabeceraComponent implements OnInit{

  @Input('titulo') titulo: string;
  @Input('paginaEnlace') paginaEnlace: string = '';
  @Input('idEntidadEnlace') idEntidadEnlace: string = '';
  @Input('subTitulo') subTitulo: string = '';
  @Input('botonera') botonera: IBotonContenedorCabecera[] = [];

  @Output() accionBoton = new EventEmitter<IBotonContenedorCabecera>();

  constructor(private router: Router) { }

  ngOnInit(){
    if (this.botonera && this.botonera.length) {
      this.botonera.forEach( boton => {
        // evitar que si activo matprefix, entonces este activo matsuffix
        if (boton?.matprefix) { boton.matsuffix = false}
        // por defecto activo matprefix como en logica boton
        if (!boton?.matprefix && !boton?.matsuffix) { boton.matprefix = true}
      })
    }
  }

  accion( boton: IBotonContenedorCabecera) {
    this.accionBoton.emit(boton);
  }

  onEnlace() {
    if (this.paginaEnlace && this.paginaEnlace != '') {
      this.router.navigate([`/${this.paginaEnlace}`, this.idEntidadEnlace]);
    } else {
      return throwError(new Error('No se ha establecido pagia enlace para la redireccion de este contenedor'));
    }
  }
}
