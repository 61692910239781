// cookiebot.config.ts
import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { environment } from '@environments/environment';

export class CookiebotConfig extends NgxCookiebotConfig {
  blockingMode: 'auto' | 'manual' | string = 'auto';
  cbId: string = environment.cookie_bot_id;
  culture?: string;
  framework?: string;
  level?: string;
  type?: string;

}