import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '@appNeo/neoCore/services/spinner/spinner.service';
import { AccionesDialogComponent } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';
import { DialogActionEnum } from '@appNeo/neoShared/helpers/enums/DialogAction.enum';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { TipoPaginadorEnum } from '@appNeo/neoShared/helpers/enums/TipoPaginador.enum';
import { Paginator } from '@appNeo/neoShared/models/paginator/paginator';
import { ClasesFormularioAnchoEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { Paginador } from '@appNeo/neoShared/models/Paginador/Paginador';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { IKanbanItem, KanbanService } from '@appNeo/neoShared/services/kanban/kanban.service';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { FormularioWizardTicketComponent } from '../components/formulario-wizard-ticket/formulario-wizard-ticket.component';
import { TICKETS_DEMO, TICKETS_ESTADOS_DEMO } from '../layout-tickets-data';
import { INPUTSCREACIONENTIDAD1, ITicket } from '../tickets-configuracion';
import { TicketsService } from '../tickets.service';

@Component({
  selector: 'neo-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  providers: [KanbanService, FormularioService]
})
export class TicketsComponent implements OnInit, AfterViewInit {

  entidad: ITicket;
  idEntidad: string;
  subEntidadPrincipal: Subscription;
  subRequestFormulario: Subscription;
  subErrorApi: Subscription;
  errorApi$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  grabando:boolean = false;
  formularioConCambios: boolean = false;
  @ViewChild('notificacion') notificacion: NotificacionComponent;


  // , classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg
  paginador = new Paginador();
  paginator = new Paginator();
  tipoPaginador: TipoPaginadorEnum = TipoPaginadorEnum.offline;
  /***************************************************************** */
  // configuracion necesaria para edicion/creacion entidad
  /***************************************************************** */
  refDialogFormulario;
  @ViewChild('dialogoFormulario') dialogoFormulario: TemplateRef<any>;
  @ViewChild('accionDialogFormulario') accionDialogoFormulario: AccionesDialogComponent;
  @ViewChild('formulario') formulario: FormularioComponent;
  @ViewChild('formularioWizard') formularioWizard: FormularioWizardTicketComponent;
  // congiguracion layout formulario
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  // TODO preparar para wizard
  inputsCreacionEntrada: IFormInput[] = INPUTSCREACIONENTIDAD1;

  constructor(
    private kanbanService: KanbanService,
    private ticketsService: TicketsService,
    public auxiliarService: AuxiliarService,
    private formularioService: FormularioService,
    private dialog: MatDialog,
    private router: Router,
    private spinnerService: SpinnerService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.subscriptionEntidadPrincipal();
  }

  ngAfterViewInit(): void {    
    this.subscriptionErrorApi();
  }

  ngOnDestroy() {
    if (this.subEntidadPrincipal) {
      this.subEntidadPrincipal.unsubscribe();
    }


    if (this.subRequestFormulario) {
      this.subRequestFormulario.unsubscribe();
    }

    if (this.subErrorApi) {
      this.subErrorApi.unsubscribe();
    }

    if (this.errorApi){
      this.errorApi.unsubscribe();
    }    
  }

  cargarDatos() {    
    this.subEntidadPrincipal = this.ticketsService.getAll( this.tipoPaginador, this.paginator, {} ).subscribe( data => {
      console.log('TICKETS BD ', data);
      let dataKanban:any[] = [];
      TICKETS_ESTADOS_DEMO.forEach( col => {
        let _data = data.filter(t=> t.idEstado ==col.id);
        this.mapearData(_data);
        dataKanban.push({columna: col, data: this.mapearData(_data)});
        setTimeout(()=>{
          dataKanban.forEach( item =>
            this.kanbanService.establecerColumna({id: `columna${item.columna.id}`, items: item.data, nombre: item.columna.nombre, totalCabecera: item.data.length.toString()})
          );
          this.spinnerService.hideSpinner();
        })
      });
    })
  }
// padre componente: puente
  // recibe data filtrada
  private mapearData(data){
    const dataColumna: IKanbanItem[] = [];
    data.forEach(item=> {
      dataColumna.push(
        {
          visibilidadFiltrado: true,
          id: `${item.id}`,
          nombre: `#${item.id} ${item.asunto}`,
          texto: `${item.descripcion}`,
          avatar: {nombre: `${item.usuario.nombre}`, src: item.usuario.rutaImagen},
          flag: item.important, // para alarmas o iconos extra
          extra: [{
            label: 'Fecha',
            iconoClase: 'icon-20 icon-ibm--calendar',
            texto:  formatDate(item.fecha,'dd/MM/yyyy',this.locale)
          }
        ]
        }
      );
    });
    return dataColumna;
  }

  subscriptionEntidadPrincipal() {

    this.cargarDatos();
    // this.subEntidadPrincipal = this.ticketsService.getAll( this.tipoPaginador, this.paginador, {} ).subscribe( data => {
    //   let dataKanban:any[] = [];
    //   TICKETS_ESTADOS_DEMO.forEach( col => {
    //     let _data = data.filter(t=> t.idEstado ==col.id);
    //     this.mapearData(_data);
    //     dataKanban.push({columna: col, data: this.mapearData(_data)});
    //     this.cargarDatos(dataKanban);
    //   });
    // })
    // info estatica
    // let dataKanban:any[] = [];
    // TICKETS_ESTADOS_DEMO.forEach( col => {
    //   let _data = TICKETS_DEMO.filter(t=> t.idEstado ==col.id);
    //   this.mapearData(_data);
    //   dataKanban.push({columna: col, data: this.mapearData(_data)});
     
    // });
    // this.cargarDatos(dataKanban);
  }

  abrirDialogo(dialog: DialogActionEnum, entity?): void {
    switch (dialog) {
      case DialogActionEnum.Create:
       // cargamos en servicio formulario los inputs correspondientes
      //  this.formularioService.inputs = this.inputsCreacionEntrada;
       this.refDialogFormulario = this.dialog.open(this.dialogoFormulario, {disableClose: true});
       this.formularioConCambios = this.formulario?.formValueChanges;
       this.formulario?.reset({});
       break;
    }
  }

  onClickMovimientoItemColumna(event) {
    // IKanbanItem
    console.log('Accion KANBAN onClickMovimientoItemColumna ',event);
    this.kanbanService.establecerColumna({id: event.id, items: event.items, totalCabecera: event.items.length.toString()});
  }

  onClickMovimientoItemEntreColumnas(event) {
    console.log('Accion KANBAN onClickMovimientoItemEntreColumnas ',event);
    this.kanbanService.establecerColumna({id: event.idOrigen, items: event.itemsOrigen, totalCabecera: event.itemsOrigen.length.toString()});
    this.kanbanService.establecerColumna({id: event.idDestino, items: event.itemsDestino, totalCabecera: event.itemsDestino.length.toString()});
  }

  submitAccionFormulario(event){
    this.grabando =  true;
    let validos = this.formularioWizard.validarFormularios();
    if (!validos) {
      this.grabando = false;
      this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
    } else{
      this.crear({...this.formularioWizard.stepControl1.value, ...this.formularioWizard.stepControl2.value});
    }
    // let objeto = this.formulario.validaCampos( false );
    // if (objeto &&  objeto.formulario && objeto.formulario.valid) {
    //   this.formulario.camposFichero.forEach(campo => {
    //     this.formulario.ficheros.push({id: campo.nombreCampo, ficheros: campo.storeFicheros});
    //   });
    //   console.log('Valido');
    //   this.crear(objeto.valores);
    // } else {
    //   this.grabando = false;
    //   this.showResult(`Debe rellenar los todos los campos obligatorios`, 'ko', DialogActionEnum.Create);
    //   return;
    // }

  }
 
  accionesCerrarFormulario() {}

  // Enlace APi
  crear(parametrosRequest) {
    //  let parEntidad = [-- keys request a filtrar --- en caso de diferentes peticiones---];
    //  let parametrosEntidad: any = this.auxiliarService.filtrarParametros(parametrosRequest, parEntidad);
    console.log('Creando...');
    this.subRequestFormulario = this.ticketsService.create(parametrosRequest).pipe(
      tap(x=> this.idEntidad = x)
    ).subscribe({
      next: (v) => console.log(v),
      error: (e) => this.manejarErrorFormularioCreacion(e, (this.idEntidad) ? 'ficheros' : 'crear'),
      complete: () => this.showResult('Ticket creado con éxito!', 'ok', DialogActionEnum.Create)
    });
  }

  manejarErrorFormularioCreacion(errorData, peticion?: 'crear' | 'ficheros' ) {
    console.log('Error ', errorData);
    this.grabando = false;
     let avisoApi = '';

     if ( errorData?.data ) {
       if (typeof errorData.data === 'string') {
         avisoApi = ': ' + errorData.data + ' ';
       }else{
         errorData?.data.forEach(element => {
           if ( element.id === 999 ) {
             avisoApi = ': ' + element.mensaje;
             return;
           }
         });
       }
     }
     // console.log(`Aviso API  [${ avisoApi }]`, errorData?.err?.request);
     if (this.idEntidad) {
       this.refDialogFormulario.close();
       this.showResult(`Se ha producido un error en el proceso de subida de ficheros en la creación de ticket ${ avisoApi }.`, 'ko');
       this.showResult('Ticket creado con éxito!', 'ok', DialogActionEnum.Create);
     } else {
       this.showResult(`Se ha producido un error en el proceso de creación ticket ${ avisoApi }.`, 'ko', DialogActionEnum.Create);
     }
   }


   showResult(message: string, status: string, dialogAction?: DialogActionEnum) {
    if (status === 'ok') {
      this.idEntidad= null;
      this.dialog.closeAll();
      this.notificacion.crearNotificacion(NotificacionEnum.Exito, 'Operación correcta', message, false, IconEnum.Exito, '', true);
      this.cargarDatos();
    } else {
      switch (dialogAction) {
        case DialogActionEnum.Create:
         this.accionDialogoFormulario?.notificacionDialogo.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error', message, true, IconEnum.Error);
          break;
        default:
            this.notificacion.crearNotificacion(NotificacionEnum.Error, 'Se ha producido un error en la subída de los ficheros', message, true, IconEnum.Error);
      }
    }
    
  }

   // errorApi
   set errorApi(data) {
    this.errorApi$.next(data);
  }  
  subscriptionErrorApi() {
    this.subErrorApi = this.ticketsService.errorApi$.subscribe(data => {
      // this.errorApi = data;
      // if(this.componenteSecundario)
      //   this.componenteSecundario.errorApi = data;
    } );
  }
}
