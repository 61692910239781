<div class="arbol">
   <neo-busqueda #buscadorArbol
                placeholder="Search"
                *ngIf="busqueda"
                [keyFiltrado]="'arbol'"
                (changeBuscador)="filtroCambiado($event)"
                (limpiarBuscador)="filtroLimpiar()"
  ></neo-busqueda>
  <mat-hint *ngIf="!elementosVisualizados">No hay coincidencias para {{termino}}</mat-hint>


  <div class="arbol-seleccion">
    <div class="acciones-multiples">
        <neo-button class="mr-10"
          [text]="estanTodosSeleccionados() ?  'Deseleccionar todo' : 'Seleccionar todo'"
          [color]="'primary'"
          [basic]="true"
          [iconClass]="'icon-20 icon-mdi--select-all'"
          [disabled]="!esBusqueda()"
          (accion)="convertirSeleccion()"
        ></neo-button>
        <neo-button  class="mr-10"
          [text]="'Expandir todo'"
          [basic]="true"
          [color]="'primary'"
          [iconClass]="'icon-20 icon-ibm--collapse-all'"
          [disabled]="!esBusqueda()"
          (accion)="controlArbolPlano.expandAll()"
        ></neo-button>
        <neo-button
          [text]="'Colapsar todo'"
          [basic]="true"
          [color]="'primary'"
          [iconClass]="'icon-20 icon-ibm--layers'"
          [disabled]="!esBusqueda()"
          (accion)="controlArbolPlano.collapseAll()"
        ></neo-button>
      </div>

      <neo-boton-desplegable #btnDepegableAccioneMultiples class="acciones-multiples-desplegables"
          [referenciaElemento]="'arbol'"
          [mostrarEnDialog]=true
          [disabled]="!esBusqueda()"
          [iconoClaseBotonPrincipal]="'icon-20 icon-mdi--arrow_drop_down'"
          [textoBotonPrincipal]="'Acciones múltiples'"
          [strokedBotonPrincipal]=true
          [claseBotonPrincipal]="'btn-sm'"
      ></neo-boton-desplegable>
  </div>


  <mat-tree [dataSource]="fuenteDatos" [treeControl]="controlArbolPlano">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
         <!-- <button mat-icon-button disabled class="button-not-alternar-expansion-nodo"></button>-->
          <mat-checkbox
                  class="checklist-leaf-node mat-checkbox-lg checkbox-with-icon"
                  color="primary"
                  [checked]="seleccionChecklist.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">
              <i class="icon-ibm--document icon-16"></i>
              <span class="checkbox-text" innerHTML="{{ node.item | resaltadoTermino : termino:'nodo-resaltado'}}"></span>
          </mat-checkbox>

          <!-- boton anidado -->
          <!-- <button mat-icon-button (click)="editarNodo(node)"><mat-icon>more_vert</mat-icon></button> -->
          <neo-boton-desplegable
            [referenciaElemento]="'arbol'"
            [disabled] = false
            [iconoBotonPrincipal]="'more_vert'"
            [textoBotonPrincipal]="''"
            [item]="node"
            [accionesBotonDesplegable]="[
                {id: '1', iconoClase: 'icon-ibm--edit icon-24 mr-10', texto: 'Editar'},
                {id: '2', iconoClase: 'icon-ibm--trash-can icon-24 mr-10', texto: 'Eliminar', clase: 'mat-menu-item-warn'},
            ]"
        ></neo-boton-desplegable>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: tieneHijo" matTreeNodePadding class="mat-primary">
          <mat-checkbox #checkBox
                      color="primary" class="mat-checkbox-lg checkbox-with-icon"
                      [checked]="descendientesTodosSeleccionados(node)"
                      [indeterminate]="descendientesParcialmenteSeleccionados(node)"
                      (change)="alternarSeleccionDesdencientes(node)">

              <button mat-icon-button matTreeNodeToggle class="button-alternar-expansion-nodo"
                  (mouseenter)="desactivarEfectoCheckbox(checkBox)"
                  (mouseleave)="activarEfectoCheckbox(checkBox)"
                      [attr.aria-label]="'Toggle ' + node.item">
                <span class="material-icons-round">
                    {{controlArbolPlano.isExpanded(node) ? 'arrow_drop_down' : 'arrow_drop_up'}}
                </span>
              </button>

              <i class="icon-ibm--folder icon-16 ml-5"></i>
              <span class="checkbox-text" innerHTML="{{ node.item | resaltadoTermino : termino:'nodo-resaltado'}}"></span>
          </mat-checkbox>

          <!-- boton anidado -->
          <!-- <button mat-icon-button (click)="editarNodo(node)"><mat-icon>more_vert</mat-icon></button> -->
          <neo-boton-desplegable
              [referenciaElemento]="'arbol'"
              [disabled] = false
              [iconoBotonPrincipal]="'more_vert'"
              [textoBotonPrincipal]="''"
              [item]="node"
              [accionesBotonDesplegable]="[
                {id: '1', iconoClase: 'icon-ibm--edit icon-24 mr-10', texto: 'Editar'},
                {id: '2', iconoClase: 'icon-ibm--trash-can icon-24 mr-10', texto: 'Eliminar', clase: 'mat-menu-item-warn'},
              ]"
          ></neo-boton-desplegable>
      </mat-tree-node>
  </mat-tree>

  <div class="mt-30 text-right" *ngIf="btnAccion">
    <neo-button
                [text]="'Guardar'"
                [color]="'primary'"
                [raised]="true"
                [iconClass]="'icon-20 icon-ibm--save'"
                (accion)="submit()"
    ></neo-button>
  </div>

</div>

<ng-template #dialogConfirmarEliminarNodo>
    <neo-acciones-dialog
      [dialogTittle]="'Eliminar'"
      [botonera]="[
      {
        label: 'Sí, eliminar',
        id: 'btn-confirmar',
        tipoAccion: 'accion',
        type: 'button',
        color: '',
        disabled: false,
        activo: true,
        flat: true
      },
      {
        label: 'No, mantener',
        id: 'btn-cancelar',
        tipoAccion: 'cerrar',
        type: 'button',
        color: 'primary',
        disabled: false,
        activo: true,
        flat: true
      }
    ]"
      (accionBoton)="submitAccionDialogConfirmarEliminar($event)"
      >
      <span *ngIf="nodoSeleccionado.expandable; else txtEliminacionNodoHijo"> Este grupo tiene otros items asociados, ¿seguro que quieres eliminar el grupo? </span>
     <ng-template #txtEliminacionNodoHijo>¿Seguro que quieres eliminar este item? </ng-template>
  </neo-acciones-dialog>
  </ng-template>
  <ng-template #dialogEditarNodo>
    <neo-acciones-dialog
    [dialogTittle]="'Editar'"
    [botonCerrarDialogoCabecera]=true
      [botonera]="[
      {
        label: 'Cancelar',
        id: 'btn-cancelar',
        tipoAccion: 'cerrar',
        type: 'button',
        color: '',
        disabled: false,
        activo: true,
        flat: true
      },
      {
        label: 'Guardar',
        id: 'btn-guardar',
        tipoAccion: 'accion',
        type: 'button',
        color: 'primary',
        disabled: false,
        activo: true,
        flat: true
      }
    ]"
      (accionBoton)="submitAccionDialogEditar($event)"
      >
      <neo-formulario #formularioEdicion
          [valoresIniciales]="nodoSeleccionado"
          [aplicarFlex]=false
          [formularioAncho]="'formulario-ancho-completo'"
      ></neo-formulario>
  </neo-acciones-dialog>
  </ng-template>
