
<neo-contenedor-cabecera
    [titulo]="'Permisos usuario'"
    [botonera]="[
        {
            label: 'Guardar',
            id: 'btn-cancelar',
            color: 'primary',
            disabled: false,
            activo: true,
            iconoClase: 'icon-20 icon-ibm--save'
        }
    ]"
    (accionBoton)="onAccionContenedorCabecera($event)"
>
    <neo-notificacion #notificacionArbolPermisos></neo-notificacion>
    <neo-arbol-anidado  #arbolPermisos
        [btnAccion]="false"
    ></neo-arbol-anidado>
</neo-contenedor-cabecera>