import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

// ARRASTRAR MODULO AUTHSTATE A CORE PERSONALIZADO
export interface AuthState {
  permissions: Permissions[];
  roles: RolesEnum[];
}

export enum Permissions {
  ADMINISTRADOR = 'Administrador',
}



export enum RolesIdentificadorEnum {
  ADMINISTRADOR = '2',
  COCINERO = '5',
  GERENTE = '4',
  RECEPCION = '6',
  RESERVA = '3',
}

export enum RolesEnum {
  ADMINISTRADOR = '2',
  COCINERO = '5',
  GERENTE = '4',
  RECEPCION = '6',
  RESERVA = '3',
}


export function createInitialState(): AuthState {

  return {
    permissions: [],
    roles: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }

}
