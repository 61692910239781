import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IActualizacionCeldaEditable,
  ICeldaEditable,
  IColumnaTabla,
  IColumnaTablaAvatar,
  IColumnaTablaEstado,
  IColumnaTablaIcono,
  IColumnaTablaImagen,
  TablaService,
  TipoColumnaEditableEnum,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {AccionDialogEnum} from '@appNeo/neoShared/helpers/enums/AccionDialog.enum';
import {FormularioFiltrosTablaService} from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import {BotonDesplegableService, IAccionesBotonDesplegable} from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import {LoremIpsum} from 'lorem-ipsum';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import {TagCampoEnum} from '@appNeo/neoShared/helpers/enums/TagCampo.enum';

export interface IRowTabla {
  imagen: IColumnaTablaImagen;
  titulo: ICeldaEditable;
  autor: string;
  ciudad: string;
  estado: IColumnaTablaEstado;
  avatar: IColumnaTablaAvatar;
  icono: IColumnaTablaIcono;
  // GAL: IColumnaTablaEstado;
  // ENG: IColumnaTablaEstado;
  fechaPublicacion: string;
  activo: boolean;
  importe: number;
  importeEditable: ICeldaEditable;
  cantidadEditable: ICeldaEditable;
  acciones: IAccionRowTabla [];
  id: string;
}

@Component({
  selector: 'neo-listado-ejemplo',
  templateUrl: './listado-ejemplo.component.html',
  styleUrls: ['./listado-ejemplo.component.scss'],
  providers: [TablaService, FormularioService, FormularioFiltrosTablaService, BotonDesplegableService]
})
export class ListadoEjemploComponent implements OnInit {
  @ViewChild('dialogCreacion') dialogCreacion: TemplateRef<any>;

  // columns: IColumnaTabla[] = [
  //   {key: 'select', nombre: '', tipo: TipoColumnaTablaEnum.Selector, ordenacion: false, ancho: AnchoColumnaTablaEnum.xs},
  //   {key: 'imagen', nombre: 'imagen', tipo: TipoColumnaTablaEnum.Imagen, ordenacion: false, ancho: AnchoColumnaTablaEnum.md},
  //   {key: 'titulo', nombre: 'Título', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, ancho: AnchoColumnaTablaEnum.lg, classResponsive: ClassResponsiveEnum.OcultarAPartirDeXs},
  //   {key: 'autor', nombre: 'Autor', tipo: TipoColumnaTablaEnum.Texto, ordenacion: false, ancho: AnchoColumnaTablaEnum.md, classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd},
  //   {key: 'ESP', nombre: 'ESP', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
  //   {key: 'ENG', nombre: 'ENG', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
  //   {key: 'GAL', nombre: 'GAL', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
  //   {key: 'fechaPublicacion', nombre: 'Fecha Publicación', tipo: TipoColumnaTablaEnum.Fecha, ordenacion: true, ancho: AnchoColumnaTablaEnum.md, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
  //   {key: 'activo', nombre: 'Activo', tipo: TipoColumnaTablaEnum.Boolean, ordenacion: true, ancho: AnchoColumnaTablaEnum.xs,classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
  //   {key: 'importe', nombre: 'Importe', tipo: TipoColumnaTablaEnum.Importe, ordenacion: true, unidad: '€', ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd},
  //   {key: 'acciones', nombre: 'Acciones', tipo: TipoColumnaTablaEnum.Acciones, ordenacion: false, ancho: AnchoColumnaTablaEnum.lg}
  // ];

  accionesMultiplesDesplegableTablaDemo: IAccionesBotonDesplegable[] =  [
    {id: 'publicar_seleccionados', iconoClase: '', texto: 'Publicar seleccionados'},
    {id: 'ocultar_seleccionados', iconoClase: '', texto: 'Ocultar seleccionados'},
    {id: 'eliminar_seleccionados', iconoClase: '', texto: 'Eliminar seleccionados'}
  ];

  columns: IColumnaTabla[] = [
    {key: 'select', nombre: '', tipo: TipoColumnaTablaEnum.Selector, ordenacion: false},
    // {key: 'icono', nombre: 'Icono', tipo: TipoColumnaTablaEnum.Icono, ordenacion: false},
    {key: 'imagen', nombre: 'Imagen', tipo: TipoColumnaTablaEnum.Imagen, ordenacion: false},
    {key: 'titulo', nombre: 'Título', tipo: TipoColumnaTablaEnum.Editable, tipoColumnaEditable: TipoColumnaEditableEnum.TextArea, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeSm},
    // {key: 'avatar', nombre: 'Avatar', tipo: TipoColumnaTablaEnum.Avatar, ordenacion: false, classResponsive: ClassResponsiveEnum.OcultarAPartirDeMd},
    // {key: 'autor', nombre: 'Autor', tipo: TipoColumnaTablaEnum.Texto, ordenacion: false, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'ciudad', nombre: 'Otro', tipo: TipoColumnaTablaEnum.Otro, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'importe', nombre: 'Importe', tipo: TipoColumnaTablaEnum.Importe, ordenacion: true, unidad: '€', classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'importeEditable', nombre: 'Importe Editable', tipo: TipoColumnaTablaEnum.Editable, tipoColumnaEditable: TipoColumnaEditableEnum.Importe, ordenacion: true, unidad: '€',  tagCampo: TagCampoEnum.Importe},
    {key: 'cantidadEditable', nombre: 'Cantidad Editable', tipo: TipoColumnaTablaEnum.Editable, tipoColumnaEditable: TipoColumnaEditableEnum.Cantidad, ordenacion: true, unidad: '€', tagCampo: TagCampoEnum.Cantidad},
    // {key: 'estado', nombre: 'Estado', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'fechaPublicacion', nombre: 'Fecha Publicación', tipo: TipoColumnaTablaEnum.Fecha, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'activo', nombre: 'Activo', tipo: TipoColumnaTablaEnum.Boolean, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'ENG', nombre: 'ENG', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'GAL', nombre: 'GAL', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'acciones', nombre: 'Acciones', tipo: TipoColumnaTablaEnum.Acciones, ordenacion: false}
  ];

  data: IRowTabla[] = [];


  inputsFiltros: IFormInput[] = [
    {
      tag: FormTagEnum.select,
      label: 'Categoría',
      type: '',
      placeholder: 'Categoría',
      formControlName: 'idCategoria',
      obligatorio: false,
      multiple: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.select,
      label: 'Subcategoría',
      type: '',
      placeholder: 'Subcategoría',
      formControlName: 'idSubCategoria',
      multiple: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.date,
      label: 'Fecha Completa',
      type: 'fechaHora',
      placeholder: 'Fecha Completa',
      formControlName: 'fechaPublicacion',
      min: new Date(2021, 8, 1),
      max: new Date(),
      horaMax: 12,
      minutoMax: 18,
      obligatorio: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.date,
      label: 'Fecha Publicación',
      type: 'fecha',
      placeholder: 'Fecha Publicación',
      formControlName: 'fechaCortaPublicacion',
      min: new Date(2021, 8, 1),
      max: new Date(),
      obligatorio: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.date,
      label: 'Hora Publicación',
      type: 'hora',
      placeholder: 'Hora Publicación',
      formControlName: 'horaPublicacion',
      horaMin: 11,
      horaMax: 11,
      minutoMax: 30,
      obligatorio: false,
      validadores: [],
    },
    //TODO: Añadir select all??
    {
      tag: FormTagEnum.select,
      label: 'Idioma',
      type: '',
      placeholder: 'Idioma',
      formControlName: 'idIdioma',
      multiple: true,
      obligatorio: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.estado,
      label: 'Estado',
      type: '',
      placeholder: 'Estado',
      formControlName: 'idEstado',
      multiple: true,
      obligatorio: true,
      validadores: [],
    }
  ];

  opcionesCategoria = [
      {
        'id': '1',
        'nombre': 'Categoría 1'
      },
      {
        'id': '2',
        'nombre': 'Categoría 2'
      },
      {
        'id': '3',
        'nombre': 'Categoría 3'
      },
      {
        'id': '4',
        'nombre': 'Categoría 4'
      },
      {
        'id': '5',
        'nombre': 'Categoría 5'
      },
    ];

  opcionesSubCategoria = [
    {
      'id': '1',
      'nombre': 'Sub Categoría 1'
    },
    {
      'id': '2',
      'nombre': 'Sub Categoría 2'
    },
    {
      'id': '3',
      'nombre': 'Sub Categoría 3'
    },
  ];

  opcionesIdioma = [
    {
      'id': '1',
      'nombre': 'Español'
    },
    {
      'id': '2',
      'nombre': 'Gallego'
    },
    {
      'id': '3',
      'nombre': 'Inglés'
    },
  ];

  opcionesEstado = [
    {
      'id': '1',
      'nombre': 'Publicada'
    },
    {
      'id': '2',
      'nombre': 'No publicada'
    },
  ];


  inputsCreacionEntrada: IFormInput[] = [
    {
      tag: FormTagEnum.input,
      label: 'Título',
      type: 'text',
      icon: 'title',
      placeholder: 'Título',
      formControlName: 'titulo',
      obligatorio: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.importe,
      formControlName: 'importe',
      label: 'Importe',
      min: 0,
      type: 'number',
      icon: 'euro',
      placeholder: 'Importe',
      obligatorio: true,
      validadores: [],
      unidades: 'euros'
    },
  ];

  keyFiltrado = 'listadoEjemplo';
  idTabla = 'tabla-listado-ejemplo1';

  valoresInicialesFiltradoTabla = {
    idCategoria: ['1'],
    idSubCategoria: '1',
    fechaPublicacion: '2021-09-22T22:00:10.000Z',
    fechaCortaPublicacion: '',
    horaPublicacion: '',
    idIdioma: ['1' , '2', '3'],
    idEstado: ['1', '2'],
    busqueda: '',
  };

  columnasFiltradoFecha = [];
  constructor(
    private tableService: TablaService,
    private dialog: MatDialog,
    private formularioService: FormularioService,
    private filtrosTablaService: FormularioFiltrosTablaService,
    private botonDesplegableService: BotonDesplegableService,
  ) {
    this.tableService.columnas = this.columns;
    this.mapearColumnasFiltroOfflineFecha();
    const loremIpsum = new LoremIpsum();
    const fechaMin = new Date(2021, 8, 23);
    const fechaMax = new Date(2021,  8, 23);
    for (let i = 1; i < 50; i++) {
      const rutaImagen = (i % 2 === 0) ? `https://picsum.photos/40/40?random=${i}` : '';
      let fecha = this.fechaAleatoria(fechaMin, fechaMax);
      this.data.push(
        {
          id: '1',
          imagen:  {src: rutaImagen, alt: 'Ejemplo'},
          icono: {nombre: 'test', class: 'icon-20 icon-ibm--checkmark--outline'},
          avatar: {nombre: 'Nombre Apellidos', src: rutaImagen},
          // titulo: `Título: ${i}`,
          titulo: {
            nombre: `${loremIpsum.generateWords(100)}`,
          },
          autor: 'Mario Casas Vargas Llosa Todos Los Santos Incluidos',
          ciudad: 'A Coruña',
          estado: {iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Activo'},
          fechaPublicacion: fecha.toLocaleString(),
          activo: Boolean(Math.round(Math.random())),
          importe: 1,
          importeEditable: {
            nombre: Math.floor(Math.random() * (150)),
          },
          cantidadEditable: {
            nombre: Math.floor(Math.random() * (150)),
          },
          acciones: [
            {
              idAccion: AccionesTablaEnum.Editar,
              tooltip: 'Editar entrada',
              icono: true,
              iconClass: 'icon-20 icon-ibm--edit',
              disabled: false,
            },
            {
              idAccion: AccionesTablaEnum.Ocultar,
              tooltip: 'Ocultar entrada',
              icono: true,
              iconClass: 'icon-20 icon-ibm--view--off',
              disabled: false,
            },
            {
              idAccion: AccionesTablaEnum.Eliminar,
              tooltip: 'Eliminar entrada',
              icono: true,
              iconClass: 'icon-20 icon-ibm--trash-can text-danger',
              disabled: false,
            },
          ],
        },
      );
    }

    this.tableService.data =  new MatTableDataSource(this.data);
    this.tableService.total = this.data.length;
    this.inputsFiltros[0].datos = this.opcionesCategoria;
    this.inputsFiltros[1].datos = this.opcionesSubCategoria;
    this.inputsFiltros[5].datos = this.opcionesIdioma;
    this.inputsFiltros[6].datos = this.opcionesEstado;
    this.filtrosTablaService.inputsForm =  this.inputsFiltros;
  }

  ngOnInit(): void {
    this.subscriptionAccionLote();
  }

  mapearColumnasFiltroOfflineFecha() {
    this.columns.forEach(columnaTabla => {
      if (columnaTabla.tipo === TipoColumnaTablaEnum.Fecha || columnaTabla.tipo === TipoColumnaTablaEnum.Hora || columnaTabla.tipo === TipoColumnaTablaEnum.FechaHora ) {
        this.columnasFiltradoFecha[0] = columnaTabla.key;
      }
    });
  }

  fechaAleatoria(fechaInicio, fechaFin) {
    fechaInicio = fechaInicio.getTime();
    fechaFin = fechaFin.getTime();
    const fecha = new Date(fechaInicio + Math.random() * (fechaFin - fechaInicio));
    return fecha;
  }

  subscriptionAccionLote() {
    this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
      console.log('Se selecciona la acción en lote con el id: ', accionSeleccionada);
    });
  }

  get _accionDialog() {
    return AccionDialogEnum;
  }

  abrirDialogo(accion: AccionDialogEnum): void {
    switch (accion) {
      case AccionDialogEnum.Crear:
        this.dialog.open(this.dialogCreacion, {});
        this.formularioService.inputs = this.inputsCreacionEntrada;
        break;
    }
  }

  //TODO IMPLEMENTAR TRAS INTEGRACIÓN CON API
  aplicarFiltrosTablaOnline(entidadFiltrado: object) {
    console.log('OBJETO A FILTRAR: ', entidadFiltrado);
  }

  aplicarAccionMultipleSeleccion(dataAccionSeleccion: object){
    console.log('Accion multiple y elementos seleccionados: ', dataAccionSeleccion);
  }

  aplicarAccionCabeceraTabla(accionCabecera) {
    console.log('Click acción cabecera tabla! ', accionCabecera);
  }

  aplicarAccionRow(accionRow: [object, IAccionRowTabla]) {
    console.log('Click acción row tabla! ', accionRow);
  }
  actualizacionCeldaEditable(actualizacion: IActualizacionCeldaEditable) {
    console.log('ACTUALIZACIÓN CAMBIO CELDA EDITABLE EN LISTADO EJEMPLO 2', actualizacion);
    let columnaKey = actualizacion.columnaKey;
    let row = actualizacion.row;
    let esColumnaEditable = this.columns.filter(columna => columna.tipo === TipoColumnaTablaEnum.Editable);
    if ( esColumnaEditable) {
      this.data.forEach(rowTablaAnterior => {
        if (rowTablaAnterior === row) {
          rowTablaAnterior[columnaKey].nombre = actualizacion.valorActualizado;
        }
      });
    }
  }
}
