import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  AccionesTablaEnum,
  IAccionRowTabla,
  IColumnaTabla,
  IColumnaTablaAvatar,
  IColumnaTablaEstado,
  IColumnaTablaIcono,
  IColumnaTablaImagen,
  TablaService,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {AccionDialogEnum} from '@appNeo/neoShared/helpers/enums/AccionDialog.enum';
import {FormularioFiltrosTablaService} from '@appNeo/neoShared/services/formulario-filtros-tabla/formulario-filtros-tabla.service';
import {BotonDesplegableService, IAccionesBotonDesplegable} from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import {LoremIpsum} from 'lorem-ipsum';
import {ClassResponsiveEnum} from '@appNeo/neoShared/helpers/enums/ClassResponsive.enum';
import {TagCampoEnum} from '@appNeo/neoShared/helpers/enums/TagCampo.enum';
import {TipoDatePickerEnum} from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';

export interface IRowTabla {
  imagen: IColumnaTablaImagen;
  autor: string;
  ciudad: string;
  estado: IColumnaTablaEstado;
  avatar: IColumnaTablaAvatar;
  iconoDrag: IColumnaTablaIcono;
  // GAL: IColumnaTablaEstado;
  // ENG: IColumnaTablaEstado;
  fechaPublicacion: string;
  activo: boolean;
  importe: number;
  acciones: IAccionRowTabla [];
  id: string;
}

@Component({
  selector: 'neo-listado-ejemplo-draggable',
  templateUrl: './listado-ejemplo-draggable.component.html',
  styleUrls: ['./listado-ejemplo-draggable.component.scss'],
  providers: [TablaService, FormularioService, FormularioFiltrosTablaService, BotonDesplegableService]
})
export class ListadoEjemploDraggableComponent implements OnInit {
  @ViewChild('dialogCreacion') dialogCreacion: TemplateRef<any>;

  accionesMultiplesDesplegableTablaDemo: IAccionesBotonDesplegable[] =  [
    {id: 'publicar_seleccionados', iconoClase: '', texto: 'Publicar seleccionados'},
    {id: 'ocultar_seleccionados', iconoClase: '', texto: 'Ocultar seleccionados'},
    {id: 'eliminar_seleccionados', iconoClase: '', texto: 'Eliminar seleccionados'}
  ];
  identificadorTabla1 = 'tabla-drag-1';
  identificadorTabla2 = 'tabla-drag-2';
  columns: IColumnaTabla[] = [
    {key: 'select', nombre: '', tipo: TipoColumnaTablaEnum.Selector,  ordenacion: false},
    {key: 'iconoDrag', nombre: 'Icono', tipo: TipoColumnaTablaEnum.Icono, tagCampo: TagCampoEnum.Icono,  ordenacion: false},
    {key: 'imagen', nombre: 'Imagen', tipo: TipoColumnaTablaEnum.Imagen, tagCampo: TagCampoEnum.Imagen,  ordenacion: false},
    {key: 'avatar', nombre: 'Avatar', tipo: TipoColumnaTablaEnum.Avatar, tagCampo: TagCampoEnum.Avatar, ordenacion: false, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'activo', nombre: 'Activo', tipo: TipoColumnaTablaEnum.Boolean, tagCampo: TagCampoEnum.Boolean, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'estado', nombre: 'Estado', tipo: TipoColumnaTablaEnum.Estado,  tagCampo: TagCampoEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'ciudad', nombre: 'Otro', tipo: TipoColumnaTablaEnum.Otro, tagCampo: TagCampoEnum.Input ,ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'importe', nombre: 'Importe', tipo: TipoColumnaTablaEnum.Importe, ordenacion: true, unidad: '€', classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'importeEditable', nombre: 'Importe Editable', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, unidad: '€', ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'cantidadEditable', nombre: 'Cantidad Editable', tipo: TipoColumnaTablaEnum.Editable, ordenacion: true, unidad: '€', ancho: AnchoColumnaTablaEnum.xs, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'autor', nombre: 'Autor', tipo: TipoColumnaTablaEnum.Texto,  tagCampo: TagCampoEnum.Input, ordenacion: false, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'fechaPublicacion', nombre: 'Fecha Publicación', tipo: TipoColumnaTablaEnum.Fecha,  tagCampo: TagCampoEnum.Date, tipoDatePicker: TipoDatePickerEnum.Fecha,  ordenacion: true},
    // {key: 'ENG', nombre: 'ENG', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    // {key: 'GAL', nombre: 'GAL', tipo: TipoColumnaTablaEnum.Estado, ordenacion: true, classResponsive: ClassResponsiveEnum.OcultarAPartirDeLg},
    {key: 'acciones', nombre: 'Acciones', tipo: TipoColumnaTablaEnum.Acciones, ordenacion: false}
  ];

  inputsFiltros: IFormInput[] = [
    {
      tag: FormTagEnum.select,
      label: 'Categoría',
      type: '',
      placeholder: 'Categoría',
      formControlName: 'idCategoria',
      obligatorio: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.select,
      label: 'Subcategoría',
      type: '',
      placeholder: 'Subcategoría',
      formControlName: 'idSubCategoria',
      multiple: true,
      obligatorio: false,
      validadores: [],
    },
    {
      tag: FormTagEnum.date,
      label: 'Fecha Publicación',
      type: 'fechaHora',
      placeholder: 'Fecha Publicación',
      formControlName: 'fechaPublicacion',
      min: new Date(2021, 8, 1),
      max: new Date(2021, 11, 12),
      obligatorio: false,
      validadores: [],
    },
    //TODO: Añadir select all??
    {
      tag: FormTagEnum.select,
      label: 'Idioma',
      type: '',
      placeholder: 'Idioma',
      formControlName: 'idIdioma',
      multiple: true,
      obligatorio: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.estado,
      label: 'Estado',
      type: '',
      placeholder: 'Estado',
      formControlName: 'idEstado',
      multiple: true,
      obligatorio: false,
      validadores: [],
    }
  ];

  opcionesCategoria = [
    {
      'id': '1',
      'nombre': 'Categoría 1'
    },
    {
      'id': '2',
      'nombre': 'Categoría 2'
    },
    {
      'id': '3',
      'nombre': 'Categoría 3'
    },
    {
      'id': '4',
      'nombre': 'Categoría 4'
    },
    {
      'id': '5',
      'nombre': 'Categoría 5'
    },
  ];

  opcionesSubCategoria = [
    {
      'id': '1',
      'nombre': 'Sub Categoría 1'
    },
    {
      'id': '2',
      'nombre': 'Sub Categoría 2'
    },
    {
      'id': '3',
      'nombre': 'Sub Categoría 3'
    },
  ];

  opcionesIdioma = [
    {
      'id': '1',
      'nombre': 'Español'
    },
    {
      'id': '2',
      'nombre': 'Gallego'
    },
    {
      'id': '3',
      'nombre': 'Inglés'
    },
  ];

  opcionesEstado = [
    {
      'id': '1',
      'nombre': 'Publicada'
    },
    {
      'id': '2',
      'nombre': 'No publicada'
    },
  ];

  accionesBotonDesplegable: IAccionesBotonDesplegable[] = [
    {id: '1', icono: 'publish', texto: 'Publicar todas'},
    {id: '2', icono: 'delete', texto: 'Eliminar todas'},
  ];


  inputsCreacionEntrada: IFormInput[] = [
    {
      tag: FormTagEnum.input,
      formControlName: 'titulo',
      label: 'Título',
      type: 'text',
      icon: 'title',
      placeholder: 'Título',
      obligatorio: true,
      validadores: [],
    },
    {
      tag: FormTagEnum.importe,
      formControlName: 'importe',
      label: 'Importe',
      type: 'number',
      min: 0,
      icon: 'euro',
      placeholder: 'Importe',
      obligatorio: true,
      validadores: [],
    },
  ];

  keyFiltrado = 'listadoEjemploDraggable';

  valoresInicialesFiltradoTabla = {
    idCategoria: '1',
    idSubCategoria: ['1'],
    fechaPublicacion: '',
    idIdioma: ['1' , '2', '3'],
    idEstado: ['1', '2'],
  };

  columnasFiltradoFecha = [];
  constructor(
    private tableService: TablaService,
    private dialog: MatDialog,
    private formularioService: FormularioService,
    private filtrosTablaService: FormularioFiltrosTablaService,
    private botonDesplegableService: BotonDesplegableService,
  ) {
    this.tableService.columnas = this.columns;
    this.mapearColumnasFiltroOfflineFecha();
    let data: IRowTabla[] = [];
    const loremIpsum = new LoremIpsum();
    const fechaMin = new Date(2021, 8, 23);
    const fechaMax = new Date(2021,  8, 23);
    for (let i = 1; i < 999; i++) {
      const rutaImagen = (i % 2 === 0) ? `https://picsum.photos/40/40?random=1` : '';
      let fecha = this.fechaAleatoria(fechaMin, fechaMax);
      data.push(
        {
        id: `${i}`,
        imagen:  {src: rutaImagen, alt: 'Ejemplo'},
          iconoDrag: {nombre: 'Icono Arrastre Drag', class: 'icon-20 icon-ibm--draggable'},
          avatar: {nombre: 'Nombre Apellidos', src: rutaImagen},
        autor: 'Mario Casas Vargas Llosa Todos Los Santos Incluidos',
        ciudad: 'A Coruña',
        estado: {iconoClase: 'icon-20 icon-ibm--checkmark--outline', color: '#00D385', nombre: 'Activo'},
        fechaPublicacion: fecha.toLocaleString(),
        activo: Boolean(Math.round(Math.random())),
        importe: 1,
        acciones: [
          {
            idAccion: AccionesTablaEnum.Editar,
            tooltip: 'Editar entrada',
            icono: true,
            iconClass: 'icon-20 icon-ibm--edit',
            disabled: false,
          },
          {
            idAccion: AccionesTablaEnum.Ocultar,
            tooltip: 'Ocultar entrada',
            icono: true,
            iconClass: 'icon-20 icon-ibm--view--off',
            disabled: false,
          },
          {
            idAccion: AccionesTablaEnum.Eliminar,
            tooltip: 'Eliminar entrada',
            icono: true,
            iconClass: 'icon-20 icon-ibm--trash-can text-danger',
            disabled: false,
          },
        ],
    },
      );
    }

    this.tableService.data =  new MatTableDataSource(data);
    this.tableService.total = data.length;
    this.inputsFiltros[0].datos = this.opcionesCategoria;
    this.inputsFiltros[1].datos = this.opcionesSubCategoria;
    this.inputsFiltros[3].datos = this.opcionesIdioma;
    this.inputsFiltros[4].datos = this.opcionesEstado;
    this.filtrosTablaService.inputsForm =  this.inputsFiltros;
    this.botonDesplegableService.acciones = this.accionesBotonDesplegable;

  }

  ngOnInit(): void {
    this.subscriptionAccionLote();
  }

  mapearColumnasFiltroOfflineFecha() {
    this.columns.forEach(columnaTabla => {
      if (columnaTabla.tipo === TipoColumnaTablaEnum.Fecha || columnaTabla.tipo === TipoColumnaTablaEnum.Hora || columnaTabla.tipo === TipoColumnaTablaEnum.FechaHora ) {
        this.columnasFiltradoFecha[0] = columnaTabla.key;
      }
    });
  }

  fechaAleatoria(fechaInicio, fechaFin) {
    fechaInicio = fechaInicio.getTime();
    fechaFin = fechaFin.getTime();
    const fecha = new Date(fechaInicio + Math.random() * (fechaFin - fechaInicio));
    return fecha;
  }

  subscriptionAccionLote() {
    this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
      if (accionSeleccionada) {
        console.log('Se selecciona la acción en lote con el id: ', accionSeleccionada);
      }
    });
  }

  get _accionDialog() {
    return AccionDialogEnum;
  }

  abrirDialogo(accion: AccionDialogEnum): void {
    switch (accion) {
      case AccionDialogEnum.Crear:
        this.dialog.open(this.dialogCreacion, {});
        this.formularioService.inputs = this.inputsCreacionEntrada;
        break;
    }
  }

  //TODO IMPLEMENTAR TRAS INTEGRACIÓN CON API
  aplicarFiltrosTablaOnline(entidadFiltrado: object) {
    console.log('OBJETO A FILTRAR: ', entidadFiltrado);
  }
  aplicarOrdenacionTablaOnline(entidadFiltrado: object) {
    console.log('OBJETO A FILTRAR: ', entidadFiltrado);
  }
}
