import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {FiltrosService} from '@appNeo/neoCore/services/filtros/filtros.service';
import {FormularioFiltrosTablaComponent} from '@appNeo/neoShared/components/tabla/formulario-filtros-tabla/formulario-filtros-tabla.component';
import {ValidationErrors} from '@node_modules/@angular/forms';

@Component({
  selector: 'neo-filtros-tabla',
  templateUrl: './filtros-tabla.component.html',
  styleUrls: ['./filtros-tabla.component.scss']
})
export class FiltrosTablaComponent implements OnInit {

  @Input() keyFiltrado;
  @Input() valoresIniciales: object;
  @Output() submitForm = new EventEmitter ();
  @Output() cambioFormulario = new EventEmitter ();
  @ViewChild('formularioFiltros') formularioFiltros: FormularioFiltrosTablaComponent;
  valoresInicialesFiltros: any;
  valoresFiltrado: any;

  constructor(
    private filtrosService: FiltrosService,
  ) {}

  ngOnInit() {
    this.valoresInicialesFiltros = this.filtrosService.getFiltros(this.keyFiltrado, this.valoresIniciales);
  }

  setFiltros() {
    this.formularioFiltros.reset(this.valoresIniciales);
  }

  setValores(valueForm: any) {
    this.formularioFiltros.setValores(valueForm);
  }

  clearFilter() {
    Object.keys(this.formularioFiltros.formValidators.controls).forEach(key => {
      if (!this.formularioFiltros.formValidators.get(key).disabled) {
        this.formularioFiltros.formValidators.get(key).reset();
      }
    });
    // this.formularioFiltros.reset(this.valoresIniciales);
  }

  public  validar( generarEventSubmit = false) {
    return this.formularioFiltros.validaCampos( generarEventSubmit );
  }

  determinarErroresValidacion(): boolean {
    let errores = 0;
    Object.keys(this.formularioFiltros.formValidators.controls).forEach(key => {
      const error: ValidationErrors = this.formularioFiltros.formValidators.get(key).errors;
      if (error) errores ++;
    });
    return errores > 0 ? true : false;
  }

  cambiosFormulario(valores: object) {
    this.valoresFiltrado = valores;
    this.cambioFormulario.emit(this.valoresFiltrado);
  }

}
