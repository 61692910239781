
<div class="calendarioCabecera">
    <div class="calendarioCabecera-izda">
        <neo-button *ngIf="botonHoy"
            mwlCalendarToday
            [text]="'Hoy'"
            [color]="'primary'"
            [stroked]="true"
            [clase]="'btn-sm hidden-xs'"
            [(viewDate)]="diaVista"
            (viewDateChange)="disVistaCambia.next(diaVista)"
        ></neo-button>
        <neo-button
            mwlCalendarPreviousView
            [iconClass]="'icon-20 icon-ibm--chevron--left'"
            [color]="'primary'"
            [icono]="true"
            [clase]="'btn-sm btn-cuadrado'"
            [view]="(vista === VistasCalendarioExtendidoEnum.Agenda) ?  CalendarView.Week  : vista"
            [(viewDate)]="diaVista"
            (viewDateChange)="disVistaCambia.next(diaVista)"
        ></neo-button>
        <neo-button
            mwlCalendarNextView
            [color]="'primary'"
            [icono]="true"
            [iconClass]="'icon-20 icon-ibm--chevron--right'"
            [clase]="'btn-sm btn-cuadrado'"
            [view]="(vista === VistasCalendarioExtendidoEnum.Agenda) ?  CalendarView.Week  : vista"
            [(viewDate)]="diaVista"
            (viewDateChange)="disVistaCambia.next(diaVista)"
        ></neo-button>
    </div>
    <div class="calendarioCabecera-ctro">
        <ng-container *ngIf="vista === CalendarView.Month || vista === CalendarView.Week || vista === CalendarView.Day; else viewTitleVistasExtendidas">
            <h3>{{ diaVista | calendarDate: vista + 'ViewTitle':locale }}</h3>
        </ng-container>
        <ng-template #viewTitleVistasExtendidas>
            <!-- TODO pipe CalendarDate -->
            <h3>{{ diaVista | calendarioFecha: vista + 'ViewTitle':locale  }}</h3>
        </ng-template>
    </div>
    <div class="calendarioCabecera-dcha">
        
        <neo-formulario #formularioSeleccionVista *ngIf="vistaSemana || vistaAgenda || vistaDia"
            [aplicarFlex]="false"
            [valoresIniciales]="{vista: vista}"
            [camposFormulario]="inputsFormularioVistaCalendario"
            (changeValueForm)="onCambioVista($event)"
        ></neo-formulario>
        <!-- <neo-button *ngIf="vistaSemana || vistaAgenda || vistaDia"
            [text]="'Mes'"
            [class]="'mr-5'"
            [class.active]="vista === CalendarView.Month"
            [color]="'primary'"
            [flat]="true"
            (click)="vistaCambia.emit(CalendarView.Month)"
        ></neo-button>
        <neo-button *ngIf="vistaSemana"
            [text]="'Semana'"
            [class]="'mr-5'"
            [class.active]="vista === CalendarView.Week"
            [color]="'primary'"
            [flat]="true"
            (click)="vistaCambia.emit(CalendarView.Week)"
        ></neo-button>
        <neo-button *ngIf="vistaDia"
            [text]="'Día'"
            [class]="'mr-5'"
            [class.active]="vista === CalendarView.Day"
            [color]="'primary'"
            [flat]="true"
            (click)="vistaCambia.emit(CalendarView.Day)"
        ></neo-button>
        <neo-button *ngIf="vistaAgenda"
            [text]="'Agenda'"
            [class]="'mr-5'"
            [class.active]="vista === VistasCalendarioExtendidoEnum.Agenda"
            [color]="'primary'"
            [flat]="true"            
            (click)="vistaCambia.emit(VistasCalendarioExtendidoEnum.Agenda)"
        ></neo-button> -->
    </div>
</div>