<div>
  <neo-notificacion #notificacion></neo-notificacion>
  <neo-tabla #tablaListado
             [tablaOnline]="false"
             [id]="idTabla"
             [identificador]="idTabla"
             [titulo]="'Nombre de la sección'"
             [filtrado]="true"
             [guardarValorBuscadorEnSesion]="true"
             [keyFiltrado]="keyFiltrado"
             [valoresInicialesFiltrado]="valoresInicialesFiltradoTabla"
             [accionesMultiplesDesplegable]="accionesMultiplesDesplegableTablaDemo"
             [paginacion]="true"
             [ordenacionColumnas]="true"
             [columnasFiltro]="['titulo', 'fechaPublicacion', 'importeEditable', 'cantidadEditable']"
             [numeroFiltros]="inputsFiltros.length"
             [btnDescargarPdfCabecera]="true"
             [btnAccionesLote]="true"
             [mostrarColumnasOcultasEnDialogo]=false
             [visualizarTotales]="true"
             (accionCabeceraClick)="aplicarAccionCabeceraTabla($event)"
             (aplicarFiltros)="aplicarFiltrosTablaOnline($event)"
             (accionMultiple)="aplicarAccionMultipleSeleccion($event)"
             (clickAccionRowTabla)="aplicarAccionRow($event)"
             (actualizacionCampoEditable)="actualizacionCeldaEditable($event)"
  ></neo-tabla>


  <ng-template #dialogCreacion>
    <neo-acciones-dialog #accionDialogCreacion
                         [dialogTittle]="'Nueva entrada'"
                         [botonera]="[
       {
          label: 'Cancelar',
          id: 'btn-cancelar',
          tipoAccion: 'cerrar',
          type: 'button',
          color: 'primary',
          disabled: false,
          activo: true,
          basic: true
        },
        {
          label: 'Guardar',
          id: 'btn-guardar',
          tipoAccion: 'accion',
          type: 'button',
          color: 'primary',
          disabled: false,
          activo: true,
          flat: true
        },
      ]">
      <neo-formulario #formularioCreacion
      ></neo-formulario>
    </neo-acciones-dialog>
  </ng-template>

</div>


