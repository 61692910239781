import {Injectable} from '@angular/core';
import {StorageService} from '@appNeo/neoCore/services/storage/storage.service';
import { HttpClient, HttpContext } from '@angular/common/http';
import {environment} from '@environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IRespApi} from '@appNeo/neoShared/helpers/interfaces/IResp-api';
import { IUsuario } from '@appNeo/neoCore/interfaces/IUsuario';
import {StorageTipo} from '@appNeo/neoCore/helpers/enums/storage-tipo.enum';
import {USUARIO_DEMO} from '@appNeo/neoCore/services/auth/auth-data';
import { AuthStore, Permissions, RolesEnum } from '@appNeo/neoCore/authState/state/auth.store';
import { AuthQuery } from '@appNeo/neoCore/authState/state/auth.query';
import { DISABLE_DELAY_LOADER, LOADER_TRANSPARENT } from '@appNeo/neoCore/interceptors/loaderInterceptor/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // TODO: hacer privado y crear observable?
  user$: BehaviorSubject<IUsuario>;
  userFront$: BehaviorSubject<IUsuario>;
  userFrontAnonimo$: BehaviorSubject<IUsuario>;
  rememberUser$: BehaviorSubject<string>;
  // url: string = environment.api_base_url;
  url: string = environment.api_base_url_backoffice;
  contextLoaderTransparent = { context: new HttpContext().set(LOADER_TRANSPARENT, true) };
  contextLoaderDisabledDelay = { context: new HttpContext().set(DISABLE_DELAY_LOADER, true) };
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
  ) {
    let user = JSON.parse(this.storage.get('user', StorageTipo.LocalStorage));
    if (environment.simulacion_datos_login && !user) {
      user = USUARIO_DEMO;
    }
    this.user$ = new BehaviorSubject<IUsuario>(user);
    this.rememberUser$ = new BehaviorSubject<string>('');


    let userFront = JSON.parse(this.storage.get('userFront', StorageTipo.LocalStorage));
    if (environment.simulacion_datos_login && !userFront) {
      userFront = USUARIO_DEMO;
    }
    this.userFront$ = new BehaviorSubject<IUsuario>(userFront);
  }

  set avatar(data) {
    let user = this.user$.value;
    user.avatar['rutaAbsoluta'] = data.ruta;
    this.storage.set('user', JSON.stringify(user), StorageTipo.LocalStorage);
    this.user$.next(user);
  }

  login(login: string, password: string, recuerdame: boolean): Observable<any> {
    console.log('Recuerdame', recuerdame);
    let user: IUsuario;
    return this.http.post<IRespApi>(`${this.url}/auth/login`, {login, password}).pipe(
      tap( (resp: IRespApi)   => {
        if ( !resp.error ) {
          if (recuerdame) {  this.rememberUser = login; } else { this.removeRememeberUser(); }
        }
      }),
      map( (resp: IRespApi) => {
        console.log(resp);
        if ( !resp.error ) {
          
          if (!user) {
            user = {...resp.data, token: resp.token};
          }
          if ( environment.activo_store_auth) {
            this.actualizarStoreAuth(resp['data']?.permisos, resp['data']?.roles);
            user = {...user, roles:this.extraerRoles(resp['data']?.roles)};
          }
          this.storage.set('user', JSON.stringify(user), StorageTipo.LocalStorage);
          console.log(user);
          this.user$.next(user);
        }
        return resp;
      }),
      catchError( err => {

        console.log('ERROR AUTH SERVICE LOGIN ', err);
        return of(err);
      })
    );
  }

  loginFront(codigoAcceso: string): Observable<any> {
    let userFront: IUsuario;
    return this.http.post<IRespApi>(`${this.url}/auth/loginFront`, { codigo: codigoAcceso }, { ...this.contextLoaderDisabledDelay }).pipe(
      map((resp: IRespApi) => {
        if (!resp.error) {


          if (!userFront) {
            userFront = { ...resp.data, token: resp.token };
          }
          if (environment.activo_store_auth) {
            this.actualizarStoreAuth(resp['data']?.permisos, resp['data']?.roles);
            userFront = { ...userFront, roles: this.extraerRoles(resp['data']?.roles) };
          }
          this.storage.set('userFront', JSON.stringify(userFront), StorageTipo.LocalStorage);
          console.log(userFront);
          this.userFront$.next(userFront);

        }
        return resp;
      }),
      catchError(err => {

        console.log('ERROR AUTH SERVICE LOGIN FRONT', err);
        return of(err);
      })
    );
  }

  loginFrontAnonimo(codigoAcceso: string): Observable<any> {
    let userFront: IUsuario;
    return this.http.post<IRespApi>(`${this.url}/auth/loginFront`, { codigo: codigoAcceso }, { ...this.contextLoaderTransparent }).pipe(
      map((resp: IRespApi) => {
        if (!resp.error) {


          if (!userFront) {
            userFront = { ...resp.data, token: resp.token };
          }
          if (environment.activo_store_auth) {
            this.actualizarStoreAuth(resp['data']?.permisos, resp['data']?.roles);
            userFront = { ...userFront, roles: this.extraerRoles(resp['data']?.roles) };
          }
          this.storage.set('userFrontAnonimo', JSON.stringify(userFront), StorageTipo.LocalStorage);
          console.log(userFront);
          this.userFront$.next(userFront);

        }
        return resp;
      }),
      catchError(err => {

        console.log('ERROR AUTH SERVICE LOGIN FRONT Anonimo', err);
        return of(err);
      })
    );
  }


  set rememberUser(usuario: string) {
    if (usuario) {
      this.storage.set('rememberUser', usuario, StorageTipo.LocalStorage);
    }
    this.rememberUser$.next(usuario);
  }

  public get userRemember(): string | '' {
    return this.storage.get('rememberUser', StorageTipo.LocalStorage) || '';
  }

  public removeRememeberUser() {
    this.storage.remove('rememberUser', StorageTipo.LocalStorage);
    this.rememberUser$.next('');
  }

  logout() {
    this.storage.remove('user', StorageTipo.LocalStorage);
    this.storage.remove(environment.keyIntentoAcceso, StorageTipo.LocalStorage);
    this.user$.next(null);
  }

  logoutFront() {
    this.storage.remove('userFront', StorageTipo.LocalStorage);
    this.storage.remove('reserva', StorageTipo.LocalStorage);
    this.userFront$.next(null);
  }

  logoutFrontAnonimo() {
    this.storage.remove('userFrontAnonimo', StorageTipo.LocalStorage);
    this.storage.remove('reserva', StorageTipo.LocalStorage);
    this.userFront$.next(null);
  }


  // TODO: necesario si el BehaviorSubject se hace privado
  public get user() {
    return this.user$.value;
  }

  public get userFront() {
    return this.userFront$.value;
  }

  public get userFrontAnonimo() {
    return this.userFrontAnonimo$.value;
  }


  registro(params): Observable<boolean> {
    return this.http.post<IRespApi>(`${this.url}/auth/registro`, params).pipe(
      tap( (resp: IRespApi)   => {
        // if ( !resp.error ) this.removeRememeberUser();
      }),
      map( (resp: IRespApi) => {
        if ( !resp.error ) {
          // const user = {...resp.data, token: resp.token};
          // this.storage.set('user', JSON.stringify(user), StorageTipo.LocalStorage);
          // this.user$.next(user);
        }
        return true;
      }),
      catchError( err => {
        return of(false);
      })
    );
  }

  // Actualizacion authStore
  public actualizarStoreAuth(_permisos: any, _roles?: any[]){
    const permisos: Permissions[] = this.extraerPermisos(_permisos);
    const roles = this.extraerRoles(_roles); 
    this.authStore.update({permissions: permisos, roles});
    this.authQuery.permisosCargados$.next(true);
  }

  private extraerRoles(rolesApi):RolesEnum[] {
    // console.log('rolesApi ', rolesApi);
    return Object.values(rolesApi).map((valor: any) => valor?.id as RolesEnum); 
  }

  private extraerPermisos(permisosApi):Permissions[]{
    // console.log('Extraer permisos api ', permisosApi);
    return  Object.values(permisosApi).map((valor:any) => valor?.identificador as Permissions); 
  }


}
